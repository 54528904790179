export const LANDING = '/';
export const HOME = '/home';
export const HOMEref = '/home/:id';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const MY_CONTENT = '/mycontent';
export const CCCid = '/ccc/';
export const CCC = '/ccc/:id';
export const MY_INFO = '/myinfo';
export const MY_PROFILEid = '/myprofile/';
export const ASH_SMITH = '/profile/ashsmith';
export const MY_PROFILE = '/myprofile/:id';
export const PROFILEid = '/profile/';
export const PROFILE = '/profile/:id';
export const PRIVACY = '/privacypolicy';
export const TERMS = '/termsofservice';
export const SEARCH = '/search';
export const GALLERY = '/gallery/:id';
export const GALLERYBUILDER = '/gallerybuilder/:id';
export const GALLERYDASHBOARD = '/gallerybuilder';
export const COMPLETE = '/profilecomplete';
export const FOF = '/404';
export const HASHSEARCH = '/hashtagsearch';
export const TEAMGAMEDemo = '/teamgame';
export const TEAMGAME = '/teamgame/:id';
export const TEAMGAMEid = '/teamgame/';
export const INSIGHTS = '/insights';
export const COMPANYPAGE = '/companypage';
export const COMPANYADMINPAGE = '/admin/companypage';
export const COMPANYSUMMARY = '/admin/companysummary';
export const VIBEPAGE = '/vibe';
export const INNOVATION_PAGE = '/innovation';
export const AFFINITYGROUPSPAGE = '/affinitygroups';
export const AFFINITY_MEMBERS_PAGE = '/affinitymembers';
export const ADMINDAILYQUESTION = '/admin/admindailyquestion';
export const AFFINITY_GROUP_CHAT = '/affinitychat';
export const USER_PAGE = '/userpage';
export const ADMIN_PAGE = '/adminpage';
export const MANAGER_PAGE = '/managerpage';
export const NOACCESS_PAGE = '/noaccesspage';

export const MY_TEAM = '/myteam';

//Custom Landing Pages
export const JPMORGAN = '/jpmorgan';
