/* eslint-disable react/prop-types */
import React, { Component,useContext } from 'react';
import Navigation from '../Navigation';
import Navbar from 'react-bootstrap/Navbar';
import * as ROUTES from '../../../constants/routes';
import AppConfig from '../../App/AppConfig';
import { AuthDataContext } from '../../Firebase/authdataprovider';
import SearchBar from '../../SearchBar/SearchBar';
import './header.css';
import SearchBarOfCompanyPage from '../../SearchBarOfCompanyPage/SearchBarOfCompanyPage';
const INITIAL_STATE = {

};



class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    
    
  }
  
  componentDidMount = () => {
    // console.log(window.location.href);
    this.gameHeader();
  }

  gameHeader = () => {
    if((window.location.href).includes(ROUTES.TEAMGAMEDemo) || (window.location.href).includes(ROUTES.TEAMGAME)) {
      return true;
    }
  }
  showSearchBar = () => {
    if (((window.location.href).includes(ROUTES.INNOVATION_PAGE) || 
         (window.location.href).includes(ROUTES.VIBEPAGE) || 
         (window.location.href).includes(ROUTES.AFFINITYGROUPSPAGE)) 
        && !(window.location.href).includes(ROUTES.CCCid)) {
      return true; 
    } 
  }

  showCompanyPageSearchBar = () => {
    if ((window.location.href).includes(ROUTES.COMPANYPAGE) && !(window.location.href).includes(ROUTES.COMPANYADMINPAGE)) {
      return true; 
    } 
  }

    
 
  
  
  render() {
    return (
      <Navbar 
        bg="dark" 
        variant="dark" 
        expand="lg"
        className={this.gameHeader() ? 'position-fixed' : ''}>
        <AuthDataContext.StreamConsumer>
          {profile => (
            <Navbar.Brand>
              <a href="http://keeperai.com" target="blank" className="logo-link">
                {AppConfig.getCodeSnippetOption('headerLogo')}
                {/* {console.log("PRofile.data", profile.data)} */}
                {!AppConfig.isJobsMode() && 
                !this.props.logo &&
                profile.data && 
                profile.data.company && 
                profile.data.company.data && (
                  <img 
                    src={profile.data.company.data.companyLogoWeb}
                    className="company-logo"
                    alt="Company Logo" />
                )}
                {this.props.logo && (
                  <img 
                    src={this.props.logo}
                    className="company-logo"
                    alt="Company Logo" />
                )}
              </a>
            </Navbar.Brand>
          )}
        </AuthDataContext.StreamConsumer>
        {this.showSearchBar() && 
          !this.props.loading && (
            // <div className='searchBar'>
            <SearchBar placeholder= {this.props.placeholder} data = {this.props.data} setSearchResults = {this.props.setSearchResults} filterBy = {this.props.filterBy}></SearchBar>
            // </div>)
          )
        }
        {
          this.showCompanyPageSearchBar() && (
            // <div className='searchBar'>
            <SearchBarOfCompanyPage placeholder= {this.props.placeholder} setOuterSearchWord={this.props.setSearchResults} ></SearchBarOfCompanyPage>
            // </div>
          )
        }
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">

          <Navigation fof={this.props.fof} showAbbreviatedSignIn={this.props.showAbbreviatedSignIn}/> 
        </Navbar.Collapse>
      </Navbar>
    );
  }
}



export default Header;
