import React, {Component} from 'react';
import {  
  Jumbotron, Spinner 
} from 'react-bootstrap';
import Layout from '../../components/Layout';
import Builder from '../../components/Gallery/builder';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import f from 'firebase';
import * as ROUTES from  '../../constants/routes';
import { Redirect } from 'react-router-dom';

export default class GalleryBuilder extends Component {
  constructor(props){
    super(props);
    this.state = { admin: 'pending' };
  }
  componentDidMount = async() => {
    const isAdmin = f.functions().httpsCallable('checkRole');
    const res = await isAdmin({ role: 'admin' });
    if(res.data === true){
      this.setState({ admin: 'true' });
    } else {
      this.setState({ admin: 'false' });
    }
  }
  render() {
    const id = this.props.match.params.id;
    const { admin } = this.state;
    if(admin === 'pending'){
      return (
        <Layout>
          <div className="spinner-container d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Layout>
      );
    } else if(admin === 'false'){
      return <Redirect to={ROUTES.FOF}/>;
    } else {
      return (
        <Layout>
          <Jumbotron className="text-center">
            <AuthDataContext.StreamConsumer>
              {profile => (
                <Builder profile={profile} galId={id} />
              )}
            </AuthDataContext.StreamConsumer>
          </Jumbotron>
        </Layout>
      );
    }
  }
}
