/* eslint-disable react/prop-types */
/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Form, Carousel } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { debounce } from 'lodash';
import DragAndDrop from '../../components/CCC/Search/dragAndDrop';

import API from '../../components/CCC/API';

const SearchType = {
  Giphy: 'GIPHY',
  Youtube: 'YOUTUBE',
  Google: 'GOOGLE',
  Imgur: 'IMGUR LINK',
  Upload: 'UPLOAD'
};

const ImageSelector = ({ profile, onSaveImages, onClose, currentImages }) => {
  const [search, setSearch] = useState(true);
  const [file,] = useState(null);
  const [term, setTerm] = useState('');
  const [searchType, setSearchType] = useState(SearchType.Giphy);
  const [changed, setChanged] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [postImages, setPostImages] = useState([...currentImages]);
  const [imageIndex, setImageIndex] = useState(0);

  const onChangeInput = (newValue) => {
    setTerm(newValue);
    setSearch(true);
    setChanged(true);
  };

  const debouncedChangeHandler = useCallback(debounce(onChangeInput, 300), []);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeSearch = (newSearchType) => {
    if(searchType !== newSearchType) {
      setSearchType(newSearchType);
      setSearch(false);
      setChanged(false);
      debouncedChangeHandler(term);
    }
  };

  const handleItemSelect = (item) => {
    switch(searchType) {
      case SearchType.Giphy:
        setPostImages([
          ...postImages,
          item.images.downsized_medium.url
        ]);
        break;
      case SearchType.Google:
        setPostImages([
          ...postImages,
          item.link
        ]);
        break;
      case SearchType.Imgur:
        setPostImages([
          ...postImages,
          item.link
        ]);
        break;
      case SearchType.Upload:
        setPostImages([
          ...postImages,
          item.link
        ]);

        break;
      default:

    }
  };

  const handleSaveImages = () => {
    onSaveImages([...postImages]);
    onClose();
  };

    
  const handleDeleteImage = (event, index) => {
    event.preventDefault();
    const _postImages = [...postImages];
    _postImages.splice(index, 1);
    setImageIndex(0);
    setPostImages([..._postImages]);
  };

  const handleImageChange = (index) => {
    setImageIndex(index);
  };

  return (
    <div className="post-image-selector">
      {postImages.length ?
        <>
          <div className="save-button" onClick={handleSaveImages}>
                        Save
          </div>
          <div className="images-to-post">
            <Carousel 
              activeIndex={imageIndex}
              onSelect={handleImageChange}
            >
              {postImages.map((url, index) => {
                return (
                  <Carousel.Item key={`$image-${index}`}>
                    <img src={url} alt=""/>
                    <div className="imgdelete-button" onClick={(event) => handleDeleteImage(event, index)}>
                      <FontAwesome name="trash"/>
                    </div>
                                        
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </> : (null)
      }
      <div className="search-input">
        <div className="search-text">
          <Form.Control
            type="text"
            value={inputValue}
            placeholder="Search Image"
            onChange={(e) => {
              setInputValue(e.target.value);
              setSearch(false);
              setChanged(false);
              debouncedChangeHandler(e.target.value);
            }}
          />
        </div>
        <div
          className={searchType === SearchType.Giphy ? 'type-button selected' : 'type-button'}
          onClick={() => changeSearch(SearchType.Giphy)}
        >
                    Giphy
        </div>
        <div
          className={searchType === SearchType.Google ? 'type-button selected' : 'type-button'}
          onClick={() => changeSearch(SearchType.Google)}
        >
                    Google
        </div>
        <div
          className={searchType === SearchType.Imgur ? 'type-button selected' : 'type-button'}
          onClick={() => changeSearch(SearchType.Imgur)}
        >
                    IMGUR LINK
        </div>
      </div>
      {search ? 
        <API
          file={file}
          term={term}
          searchType={searchType} 
          id={''}
          title={''}
          sub={''}
          changed={changed}
          profile={profile}
          showEditModal={false}
          setShowEditModal={() => {}}
          showExampleModal={false}
          example={null}
          onItemSelect={handleItemSelect}
        /> : (null)
      }
    </div>
  );
};

export default ImageSelector;
