import React from 'react';
import '../Innovations/innovations.scss';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import * as ROUTES from '../../../constants/routes';
import { Carousel } from 'react-bootstrap';
import { getTimeStamp } from '../../../utils';

export default function MyVibe({ profile, props }) {
  
  
  const  defaultProps = {
    id: 'vibe',
    title: 'vibe',
    sub: 'What is your vibe?',
  }

  
  const { id } = defaultProps;
  return (
    <div className='myInnovations-container'>
   
      <Container className='post-1'>
          <div className='innovations'>
              <h4>What am I grateful for?</h4>
              { profile.uid === props.profile.uid && <Link to={ROUTES.CCCid+ id}>
                  <button className='button-cont' variant='primary'>+</button>
              </Link>
}
              <span>Share your gratitude</span>
              <Carousel
                className='gallery-carousel'
                interval={null}
                indicators={false}
                  
              >
              <Carousel.Item>
                  <img src={profile.data.profiles.default?.vibeAnswers?.vibe.content === undefined ? '/assets/media/grateful.png' : profile.data.profiles.default?.vibeAnswers?.vibe?.content} alt = 'vibe.png'/>
                  <p>{profile.data.profiles.default?.vibeAnswers?.vibe.answer === undefined ? 'Share your vibe' : profile.data.profiles.default?.vibeAnswers?.vibe?.answer}</p>
                  <span className="date">{profile.data.profiles.default?.vibeAnswers?.vibe.timeStamp === undefined ? 'Date' : getTimeStamp(profile.data.profiles.default?.vibeAnswers?.vibe.timeStamp)}</span>
              </Carousel.Item>
              {profile.data.profiles.default?.vibeAnswers === {} ? '' : profile.data.profiles.default?.vibeAnswers?.vibe.previousAnswers?.map((answer, index) => {
              return (
                  <Carousel.Item key={`answer-${index}`}>
                      <img src={answer.content === undefined? '': answer.content} alt = 'vibe.png'/>
                      <p>{answer.answer === undefined? '': answer.answer}</p>
                      <span className="date">{answer.timestamp === undefined? '' : getTimeStamp(answer.timestamp)}</span>
                  </Carousel.Item>
                  )})}
                </Carousel>
          </div>
       
            <div className="buttonBlock">
              <div className="buttonWrapper"><button className="button btn-solid-purple createBtnBottom" onClick={()=>props.history.push(ROUTES.VIBEPAGE)} >COMMUNITY GRATITUDE</button></div>
            </div>
   
        </Container>
    </div>
  )
}