/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { compose } from 'recompose';
import { Jumbotron, Container, Button } from 'react-bootstrap';

import { withAuthorization } from '../../components/Session';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import Layout from '../../components/Layout';
import NameInput from '../../components/NameInput';
import styled, { keyframes } from 'styled-components';
import { fadeInUp, fadeInDown } from 'react-animations';
import ResetPassword from '../../components/ResetPassword/resetpassword';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';
import AppConfig from '../../components/App/AppConfig';
import ConfirmModal from '../../components/ConfirmModal';

const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;
const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
`;

const AccountPage = (props) => {
  const [confirmShow, setConfirmShow] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAccount = async() => {
    const { profile } = props;
    if(!profile) return null;
    if(isDeleting) return null;
    setIsDeleting(true);
    try {
      await profile.deleteAccount(profile.uid);
      props.history.push('/');
      setConfirmShow(false);
    } catch(error) {
      console.log(`Error in handleDeleteAccount ${error}`);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Layout>
      <ScrollTopOnPageLoad/>
      <FadeDown>
        <Jumbotron>
          <h1 className="text-center heading">My Account</h1>
        </Jumbotron>
      </FadeDown>
      <AuthDataContext.StreamConsumer>
        {profile => (
          <FadeUp>
            <Container>
              <NameInput profile={profile}/>
              <ResetPassword profile={profile}/>
              <DeleteButton>
                <Button
                  className="mx-auto my-3"
                  variant="danger"
                  onClick={() => setConfirmShow(true)}
                >
                  Delete Account
                </Button>
              </DeleteButton>
            </Container>
          </FadeUp>
        )}
      </AuthDataContext.StreamConsumer>
      <ConfirmModal
        show={confirmShow}
        onClose={() => {
          if(!isDeleting)
            setConfirmShow(false);
        }}
        onOk={() => handleDeleteAccount()}
        title="Delete Acount"
        msg="Sure you want to delete your account?"
        actionText="Yes, delete"
        isWorking={isDeleting}
      />
    </Layout>
  );
};

const condition = (profile) => {
  if(AppConfig.isJobsMode()){
    return profile.uid !== null && !profile.isAnonymous;
  }

  return profile.uid !== null && !profile.isAnonymous && profile.emailVerified === true;
};

export default compose(
  withAuthorization(condition),
)(AccountPage);
