/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import * as ROUTES from '../../../constants/routes';
import { Nav, Button, NavDropdown, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AuthDataContext } from '../../Firebase/authdataprovider';
import './Navigation.scss';
import PasswordForgetModal from '../../PasswordForget';
import SignOutButton from '../../../modules/SignOut';
import SignUpLink from '../../SignUpLink';
import SignInModal from '../../SignIn';
import SignUpModal from '../../SignUp';
import AppConfig from '../../App/AppConfig';
import { Link } from 'react-router-dom';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSignIn: false,
      showSignUp: false,
      showDropdown: false,
      showForgotPassword: false,
      showGDPR: true,
      width: 0,
      showAbbreviatedSignIn: false,
      mode: 'job'
    };
  }

  componentDidMount = () => {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
    if (AppConfig.isJobsMode()) {
      this.setState({ mode: 'job' });
    } else {
      this.setState({ mode: 'team' });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWidth);
  };

  handleShowSignIn = () => {
    this.setState({
      showSignIn: true
    });
  };

  handleCloseSignIn = () => {
    this.setState({
      showSignIn: false
    });
  };

  handleShowSignUp = () => {
    this.setState({
      showSignUp: true
    });
  };

  handleCloseSignUp = () => {
    this.setState({
      showSignUp: false
    });
  };

  handleSwapSignUpModals = () => {
    this.setState({
      showSignUp: true,
      showSignIn: false
    });
  };

  handleShowForgotPassword = () => {
    this.setState({
      showForgotPassword: true
    });
  };

  handleCloseForgotPassword = () => {
    this.setState({
      showForgotPassword: false
    });
  };

  handleSwapSignInModals = () => {
    this.setState({
      showSignIn: false,
      showForgotPassword: true
    });
  };

  updateWidth = () => {
    this.setState({
      width: window.innerWidth
    });

    if (this.state.width >= 992) {
      this.setState({
        showDropdown: false
      });
    }
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      showDropdown: !prevState.showDropdown
    }));
  };

  hasRole = (userRoles, role) => {
    for (let i = 0; i < userRoles.length; i++) {
      if (userRoles[i].startsWith(role)) {
        return true;
      }
    }
    return false;
  }

  renderDropdown = (profile) => {
    const { showDropdown, width } = this.state;
    const fullName = ((profile.data &&
      profile.data.profiles &&
      profile.data.profiles.default &&
      profile.data.profiles.default.fullName) ?
      profile.data.profiles.default.fullName : 'New User');

    if (width >= 992) {
      return (
        <Nav className="ml-auto">
          <NavDropdown
            variant="dark"
            show={showDropdown}
            onClick={this.toggleDropdown}
            title={fullName}
          >
            {/* <LinkContainer to={ROUTES.HOME}>
              <NavDropdown.Item>
                Home
              </NavDropdown.Item>
            </LinkContainer> */}

            {
              <LinkContainer to={ROUTES.MY_CONTENT}>
                <NavDropdown.Item>
                  Profile Questions
                </NavDropdown.Item>
              </LinkContainer>
            }

            {/* <LinkContainer to={ROUTES.MY_INFO}>
              <NavDropdown.Item>
                My Info
              </NavDropdown.Item>
            </LinkContainer> */}

            {(profile.uid !== null && profile.uid !== undefined) && <LinkContainer to={ROUTES.INSIGHTS + '/' + profile.uid}>
              <NavDropdown.Item>
                My Profile
              </NavDropdown.Item>
            </LinkContainer>}

            {this.state.mode === 'team' && <LinkContainer to={ROUTES.MY_TEAM}>
              <NavDropdown.Item>
                My Team
              </NavDropdown.Item>
            </LinkContainer>}


            <LinkContainer to={ROUTES.COMPANYPAGE}>
              <NavDropdown.Item>
                Community Page
              </NavDropdown.Item>
            </LinkContainer>

            {
              (profile.uid !== null && profile.uid !== undefined && profile.data !== undefined && profile.data.userRoles && profile.data.userRoles.length > 0 && this.hasRole(profile.data.userRoles, "ADMIN")) &&
              <LinkContainer to={ROUTES.COMPANYADMINPAGE}>
                <NavDropdown.Item>
                  Company Admin Page
                </NavDropdown.Item>
              </LinkContainer>

            }
            {
              (profile.uid !== null && profile.uid !== undefined && profile.data !== undefined && profile.data.userRoles && profile.data.userRoles.length > 0 && this.hasRole(profile.data.userRoles, "MANAGER")) &&
              <LinkContainer to={ROUTES.MANAGER_PAGE}>
                <NavDropdown.Item>
                  Company Manager Page
                </NavDropdown.Item>
              </LinkContainer>
            }
            {/* <LinkContainer to={ROUTES.ADMIN_PAGE}>
                <NavDropdown.Item>
                  Admin Page
                </NavDropdown.Item>
              </LinkContainer> */}
            {
              (profile.uid !== null && profile.uid !== undefined && profile.data !== undefined && profile.data.userRoles && profile.data.userRoles.length > 0 && profile.data.userRoles.indexOf('ADMIN') !== -1) &&
              <LinkContainer to={ROUTES.COMPANYSUMMARY}>
                <NavDropdown.Item>
                  Company Summary
                </NavDropdown.Item>
              </LinkContainer>

            }


            <LinkContainer to={ROUTES.VIBEPAGE}>
              <NavDropdown.Item>
              Community Gratitude
              </NavDropdown.Item>
            </LinkContainer>

            {(profile.data.company.name !== "open") &&
              <LinkContainer to={ROUTES.INNOVATION_PAGE}>
                <NavDropdown.Item>
                  Collaborative Ideas
                </NavDropdown.Item>
              </LinkContainer>
            }
            <LinkContainer to={ROUTES.AFFINITYGROUPSPAGE}>
              <NavDropdown.Item>
                Affinity Groups
              </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to={ROUTES.ACCOUNT}>
              <NavDropdown.Item>
                Account
              </NavDropdown.Item>
            </LinkContainer>

            {/* <LinkContainer to={ROUTES.USER_PAGE}>
              <NavDropdown.Item>
                User Page
              </NavDropdown.Item>
            </LinkContainer> */}

            {/* <LinkContainer to={ROUTES.ADMIN_PAGE}>
              <NavDropdown.Item>
                Admin Page
              </NavDropdown.Item>
            </LinkContainer> */}

            <NavDropdown.ItemText>
              <SignOutButton />
            </NavDropdown.ItemText>
          </NavDropdown>
        </Nav>
      );
    } else {
      return (
        <>
          <NavDropdown.Item className="full-name">
            {fullName}
          </NavDropdown.Item>
          {/* <LinkContainer to={ROUTES.HOME}>
            <NavDropdown.Item>
              Home
            </NavDropdown.Item>
          </LinkContainer> */}

          <LinkContainer to={ROUTES.MY_CONTENT}>
            <NavDropdown.Item>
              Profile Questions
            </NavDropdown.Item>
          </LinkContainer>

          {/* <LinkContainer to={ROUTES.MY_INFO}>
            <NavDropdown.Item>
              My Info
            </NavDropdown.Item>
          </LinkContainer> */}

          {(profile.uid !== null && profile.uid !== undefined) && <LinkContainer to={ROUTES.INSIGHTS + '/' + profile.uid}>
            <NavDropdown.Item>
              My Profile
            </NavDropdown.Item>
          </LinkContainer>}

          {this.state.mode === 'team' && <LinkContainer to={ROUTES.MY_TEAM}>
            <NavDropdown.Item>
              My Team
            </NavDropdown.Item>
          </LinkContainer>}

          <LinkContainer to={ROUTES.COMPANYPAGE}>
            <NavDropdown.Item>
              Community Profile
            </NavDropdown.Item>
          </LinkContainer>
          {
            (profile.uid !== null && profile.uid !== undefined && profile.data !== undefined && profile.data.userRoles && profile.data.userRoles.length > 0 && profile.data.userRoles.indexOf('ADMIN') !== -1) &&
            <LinkContainer to={ROUTES.COMPANYADMINPAGE}>
              <NavDropdown.Item>
                Company Admin Page
              </NavDropdown.Item>
            </LinkContainer>
          }

          {
            <LinkContainer to={ROUTES.MANAGER_PAGE}>
              <NavDropdown.Item>
                Company Manager Page
              </NavDropdown.Item>
            </LinkContainer>
          }

          {
            (profile.uid !== null && profile.uid !== undefined && profile.data !== undefined && profile.data.userRoles && profile.data.userRoles.length > 0 && profile.data.userRoles.indexOf('ADMIN') !== -1) &&
            <LinkContainer to={ROUTES.COMPANYSUMMARY}>
              <NavDropdown.Item>
                Company Summary
              </NavDropdown.Item>
            </LinkContainer>

          }

          {
            <LinkContainer to={ROUTES.VIBEPAGE}>
              <NavDropdown.Item>
                Community Gratitude
              </NavDropdown.Item>
            </LinkContainer>
          }
          {(profile.data.company.name !== "open") &&
            <LinkContainer to={ROUTES.INNOVATION_PAGE}>
              <NavDropdown.Item>
                Collaborative Ideas
              </NavDropdown.Item>
            </LinkContainer>
          }
          <LinkContainer to={ROUTES.AFFINITYGROUPSPAGE}>
            <NavDropdown.Item>
              Affinity Groups
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={ROUTES.ACCOUNT}>
            <NavDropdown.Item>
              Account
            </NavDropdown.Item>
          </LinkContainer>

          <NavDropdown.Item>
            <SignOutButton />
          </NavDropdown.Item>
        </>
      );
    }
  }

  renderSignUpSignIn = (profile) => {
    if (this.props.showAbbreviatedSignIn && profile.isAnonymous) {
      return (
        <>
          <Nav.Link href={ROUTES.HOME}>
            <Button
              className="auth-btn"
              variant="outline-light"
            > Join Keeper
            </Button>
          </Nav.Link>
        </>
      );
    }
    else {
      return (
        <>
          <Nav.Link>
            <Button
              id="navbar-sign-up-button"
              disabled={!(profile && profile.uid)}
              className="auth-btn"
              variant="outline-light"
              onClick={this.handleShowSignUp}
            >Sign Up
            </Button>
          </Nav.Link>

          <Nav.Link>
            <Button
              disabled={!(profile && profile.uid)}
              className="auth-btn"
              variant="outline-light"
              onClick={this.handleShowSignIn}>
              Sign In
            </Button>
          </Nav.Link>

        </>
      );
    }
  }

  render() {
    return (
      <AuthDataContext.StreamConsumer>
        {profile =>
          (profile && !profile.isAnonymous && profile.uid) ? (
            <>
              <Link to={ROUTES.INSIGHTS + '/' + profile.uid}>
                <div className="headerPic">
                  {(profile.data.profiles.default.profilePicture==="https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"||profile.data.profiles.default.profilePicture==='/images/default-avatar.svg')?
                  <img
                    src='/assets/media/profile/default-avatar.jpeg'
                    style={{ backgroundColor: profile.data.profiles.default.insights?.color }} />:
                    <img
                    src={profile.data.profiles.default.profilePicture}
                    style={{ backgroundColor: profile.data.profiles.default.insights?.color }} />}

                </div>
              </Link>
              <div>
                {this.renderDropdown(profile)}
              </div>
            </>
          ) : (
            <Nav className="ml-auto">
              {!this.props.fof && (
                <AuthDataContext.StreamConsumer>
                  {profile => (
                    <>
                      {this.renderSignUpSignIn(profile)}
                    </>
                  )}
                </AuthDataContext.StreamConsumer>
              )}
              <Modal
                show={this.state.showSignIn}
                onHide={this.handleCloseSignIn}
                keyboard>
                <Modal.Header closeButton />
                <Modal.Body>
                  <SignInModal
                    hide={this.handleCloseSignIn}
                  />
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={this.handleSwapSignInModals}>
                    Forgot password?
                  </Button>
                  <p onClick={this.handleSwapSignUpModals}>
                    <span>Don't have an account?</span><SignUpLink />
                  </p>
                </Modal.Body>
              </Modal>

              <Modal
                show={this.state.showSignUp}
                onHide={this.handleCloseSignUp}
                keyboard>
                <Modal.Header closeButton />
                <Modal.Body>
                  <SignUpModal
                    hide={this.handleCloseSignUp}
                  />
                </Modal.Body>
              </Modal>

              <Modal
                show={this.state.showForgotPassword}
                onHide={this.handleCloseForgotPassword}
                keyboard>
                <Modal.Header closeButton />
                <Modal.Body>
                  <PasswordForgetModal
                    hide={this.handleCloseForgotPassword}
                  />
                </Modal.Body>
              </Modal>
            </Nav>
          )
        }
      </AuthDataContext.StreamConsumer>
    );
  }
}

export default Navigation;
