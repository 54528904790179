/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { Component } from 'react';
import './JPMORGAN.scss';
import Layout from '../../../components/Layout';
import { Container, Modal } from 'react-bootstrap';
import Cards from '../../../components/Cards';
import * as ROUTES from '../../../constants/routes';
import { Link } from 'react-router-dom';
import AshSmith from '../../../constants/ashleySmith';
import AshleySmithTeams from '../../../constants/ashleySmithTeam';
import CodeHelper from '../../../components/Invite/helper';
import { AuthDataContext } from '../../../components/Firebase/authdataprovider';
import ScrollTopOnPageLoad from '../../../components/ScrollTopOnPageLoad';
import AppConfig from '../../../components/App/AppConfig';
import HomeIntroSection from '../../../components/HomeIntroSection';
import { withAuthDataContext } from '../../../components/Firebase/authdataprovider';
import Notiflix from 'notiflix';

class JPMORGANHomePageBase extends Component {
  constructor(props) {
    super(props);

    const showResendModal = (
      process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION === 'true' 
      && !this.props.profile.isAnonymous 
      && !this.props.profile.emailVerified);

    // console.log("Constructor: showResendModal: ", showResendModal, process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION, this.props.profile.isAnonymous, this.props.profile.emailVerified);
    this.state = {
      code: null,
      showResendModal,
      resendModalDismissed: false,
      mode: 'job'
    };
  }
  
  logData = (category, data, type, extra) => {
    // const logData = f.functions().httpsCallable('logData');
    // logData({category: category, data: data, type: type, extra: extra});
  }
  
  componentDidMount = () => {
    document.documentElement.scrollTop = 0;
    if(AppConfig.isJobsMode()){
      this.setState({ mode: 'job' });
    } else {
      this.setState({ mode: 'team' });
    }
    const query = this.props.location.search;
    const ref = this.props.match.params.id;
    if(ref){
      this.setState({ ref });
      // this.logData("Home", "user has a ref code", "refcode", {ref: ref});
    }

    if(query.includes('invitecode=')){
      const code = query.slice(12);
      this.setState({ code });
      // this.logData("Home", "user has an invite code", "invcode", {code: code});
    }
  }




  componentDidUpdate = () => {
    // console.log("process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION", process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION, process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION === "true");
    // console.log("this.props.profile.isAnonymous", this.props.profile.isAnonymous);
    // console.log("this.props.profile.emailVerified", this.props.profile.emailVerified);
    if(!this.state.showResendModal && process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION === 'true' && !this.props.profile.isAnonymous && !this.props.profile.emailVerified){
      // console.log("Trying to show the modal");
      this.setState({ showResendModal: true });
    }


  }

  handleClose = () => {
    this.setState({
      resendModalDismissed: true
    });
  }

  handleResendVerificationEmail = async () => {
    let res = await this.props.profile.doSendVerificationEmail();
    if (res.message !== "Verification Email Sent"){
      Notiflix.Notify.failure(res.message);
    } else {
      Notiflix.Notify.success(res.message);
    }
  }

  handleCheckAgain = () => {
    window.location.reload(false);
  }

  render() {
    const { showResendModal, resendModalDismissed, mode } = this.state;
    const logo = 'https://firebasestorage.googleapis.com/v0/b/keeper-teams-test-2.appspot.com/o/JPMorganLogoWhiteBackground.png?alt=media&token=80e29637-2f89-4928-b2d2-7cb84391778f';
    const logoLink = ROUTES.JPMORGAN;
    return (
      <Layout logo={logo} logoLink={logoLink}>
        <ScrollTopOnPageLoad/>
        <div className="home-page">
          <div>
            <HomeIntroSection></HomeIntroSection>
            <svg className="svgTag" viewBox="0 0 500 80" preserveAspectRatio="none">
              <path className="svgCurve" d="M-0.11,71.15 C247.06,-2.85 248.19,-1.88 501.58,71.15 L500.00,150.00 L0.00,150.00 Z"/>
            </svg>
          </div>
          <div className="homeContentTop text-center">
            <div className="header">
              <p className="headerTitle">What is a Keeper Profile?</p>
              <div className="headerSub text-center">
                {AppConfig.isJobsMode() ? 
                  <span>
                      Your Keeper profile is an elegant and easy way to show your personality. <br />
                      Pick content, explain it, and your profile is sent along with your resume and standard info to employers.
                  </span>
                  :
                  <span>
                      Keeper is your digital icebreaker. Your Keeper profile is a fun, easy way to show your
                      personality and learn more about your teammates.
                  </span>
                }
              </div>
            </div>
            <div className="cards">
              <Container>
                <Cards
                  edit={false}
                  flip={false}
                  template={[
                    'attitude_on_life',
                    'something_inspiring',
                  ]}
                  softSkillsAnswers={mode === 'job' ? AshSmith.profiles.default.softSkillsAnswers : AshleySmithTeams.profiles.default.softSkillsAnswers}
                />
              </Container>
            </div>
          </div>
          <AuthDataContext.StreamConsumer>
            {profile => (
              <div className="text-center">
                {/*todo: extract this to own component. Make surre animation is css base, config css class via props*/}
                <CodeHelper profile={profile} reff={this.state.ref} code={this.state.code}/>
                {/* {console.log("Asppconfig.isjobsmode", AppConfig.isJobsMode())}
                  {console.log("is anon?", profile.isAnonymous)} */}
                  {(
                    (AppConfig.isJobsMode() || (profile && profile.uid && !profile.isAnonymous)) && 
                    profile.data?.profiles?.default?.email && 
                    profile.data?.profiles?.default?.email !== "") && 
                  <Link to={ROUTES.MY_CONTENT}>
                    <button 
                      disabled={!(profile && profile.uid)}
                      className="btn-solid-purple createBtnBottom"
                      onClick={() => this.logData('Home', 'user pressed ceate profile', 'buttonclick', [])} 
                    >
                      CREATE PROFILE
                    </button>
                  </Link>
                  }
                  {((!AppConfig.isJobsMode() && profile && profile.isAnonymous) || (!profile.data?.profiles?.default?.email || profile.data?.profiles?.default?.email === ""))  && 
                    <button 
                      disabled={!(profile && profile.uid)}
                      className="btn-solid-purple createBtnBottom shouldTriggerSignUpModal" 
                    >
                      SIGN UP
                    </button>
                }
              </div>
            )}
          </AuthDataContext.StreamConsumer>
          <Modal 
            show={showResendModal && !resendModalDismissed} 
            onHide={this.handleClose} 
            animation={true} 
            size="sm"
            keyboard
            centered>
            <Modal.Header closeButton>
              <div className="mx-auto mt-4 pt-2">
                <h2>Confirm Account</h2>
              </div>
            </Modal.Header>
            <div className="text-center">
              <p><strong>Check your email for verfication link</strong></p>
              <p className="mb-1">Don't see your link?</p>
            </div>
            <Modal.Footer>
              <button onClick={this.handleResendVerificationEmail}className="mx-auto btn-solid-purple">RESEND</button>
            </Modal.Footer>
            <Modal.Footer>
              <button onClick={this.handleCheckAgain}className="mx-auto btn-solid-purple">CHECK AGAIN</button>
            </Modal.Footer>
          </Modal>
        </div>
      </Layout>
    );
  }
}

const JPMORGANHomePage = withAuthDataContext(JPMORGANHomePageBase);

export default JPMORGANHomePage;
