import React from 'react';
import './InsightsSideBar.scss';
import * as ROUTES from '../../../constants/routes';
import { LinkContainer } from 'react-router-bootstrap';
import { InsightCard } from './InsightCard';

export default function InsightsSideBar({ image, name, profile }) {
 
  return (
    <div className="sidebar">
      {/* <aside>
        <img src = {image} alt ="profile.png"/><span>{name}</span>
                    
      </aside> */}

      {/* <InsightCard 
        heading={'Profile Navigation'}
        type={'profileLinks'}
        uid={profile.uid}
        profile={profile}
        
      /> */}

          
      <InsightCard 
        heading={'Tip of the week'}
        type={'tipofWeek'}
        name={name}
      />

      {(profile.data.company.name !== "open") &&
          <LinkContainer to={ROUTES.TEAMGAMEDemo}>
            <button>PLAY THE GAME</button>
          </LinkContainer>
      }
    </div>
  );
}
