/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import f from 'firebase';
import Notiflix from 'notiflix';
const INITIAL_STATE = {
  managerEmail : '',
  officePhone: '',
  managerEmailDisabled: false,
  loading: false,
  profile: '',
};
class SelfAddManager extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  async componentDidMount(){
    const profile = this.props.profile.getDefaultProfile();
    let disabled = false;
    if(profile.managerEmail === 'exempt'){
      disabled = true;
    }
    this.setState({ managerEmailDisabled: disabled, managerEmail: profile.managerEmail, officePhone: profile.officePhone, profile });
  }

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  updatePhone = (event) => {
    if(this.state.officePhone !== undefined){
      const profile = this.props.profile.getDefaultProfile();
      profile.officePhone = this.state.officePhone;
      this.props.profile.save();
    }
  }
  addManager = async() => {
    if(this.state.managerEmail && this.state.profile.managerEmail !== this.state.managerEmail){
      this.setState({
        loading: true
      })
      let selfAddToTeam = f.functions().httpsCallable('updateSelfOnTeam');
      let res = await selfAddToTeam({template: 'default', managerEmail: this.state.managerEmail});
      if (res.data.success){
        Notiflix.Notify.success("Manager Added");
        window.location.reload(false);
      } else {
        const profile = this.props.profile.getDefaultProfile();
        profile.managerEmail = this.state.managerEmail;
        if (profile.teamId){
          let removeUserFromTeam = f.functions().httpsCallable('removeUserFromTeam');
          let r = await removeUserFromTeam({userId: this.props.profile.uid, teamId: profile.teamId});
          if (r.data.success){
            Notiflix.Notify.success("Removed User");
          } else {
            Notiflix.Notify.failure(r.data.data);
          }
          profile.teamId = '';
        }
        if(profile.managerName){
          profile.managerName = '';
        }
        this.props.profile.save();
        Notiflix.Notify.failure(res.data.data);
      }
      this.setState({
        loading: false
      });
    }
  }

  render(){
    const { managerEmailDisabled, managerEmail, officePhone, loading } = this.state;
    return (
      <Form className="mb-3">
        <Row>
          <Col>
            <Form.Label>Team Leader's Email</Form.Label>
            <div className="manager-input-box">
              <Form.Control 
                onChange={this.handleInput}
                name="managerEmail"
                placeholder="teamlead@email.com" 
                value={managerEmail && managerEmail}
                onBlur={this.addManager}
                disabled={managerEmailDisabled}/>
              {loading && (
                <div className="search-spinner manager-input">
                  <Spinner animation="border" /> 
                </div> 
              )}
            </div>
          </Col>
          <Col>
            <Form.Label>Office Phone</Form.Label>
            <Form.Control
              onChange={this.handleInput}
              name="officePhone"
              placeholder="(555)555-5555"
              value={officePhone && officePhone}
              onBlur={this.updatePhone}/>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default SelfAddManager;
