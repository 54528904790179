/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import {
  Card, 
  Container, 
  Col, 
  Row
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../../../styles/search.scss';
import { Link } from 'react-router-dom';
import {images} from '../../../assets';
import Notiflix from 'notiflix'; 

const INITIAL_STATE = {
  test : '',
};

const url = process.env.REACT_APP_LOCAL + '/';

class Results extends Component{
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = () => {
    Notiflix.Notify.init({
      cssAnimationStyle: 'from-top',
      success: { background: '#7846c2' },
      cssAnimationDuration: 600,
    });
  }

  onCopy = event => {
    event.preventDefault();
    Notiflix.Notify.success('Link copied to clipboard');
  }

  render(){
    return (
      <div className="search">
        {(this.props.results).map(result => {
          // console.log('result', result);
          return (
            <Card className="mb-3" key={result.id}>
              <Container className="render-info">
                <Row>
                  <Col className="col-auto">
                    <Link 
                      className="profile-pic-link" 
                      target="_blank" 
                      to={`profile/${result.uid}`}>
                      <img 
                        className="profile-pic profile-pic-small" 
                        alt={result.content.fullName} 
                        src={result.content.profilePicture ? 
                          result.content.profilePicture : images.avatar} />
                    </Link>
                  </Col>
                  <Col className="info">                    
                    {/*originally the className included in the link was in the div around each link. Changed it for a quick styling fix after adding links broke styling. may need to be reverted if styling is being refactored */}
                    <Row>
                      <Col>
                        <Link 
                          className="basicInfo mt-4" target="_blank" to={`profile/${(result.uid)}`}>
                          <p className="name">{result.content.fullName}</p>
                          <p className="email">{result.content.email}</p>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col>
                        <div className="linkContents">
                          <div className="url">
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <FontAwesome name="external-link"/>
                                </div>
                              </div>
                              <div className="form-control">
                                {new URL(`profile/${result.uid}`, url).toString()}
                              </div>
                              <CopyToClipboard 
                                text={new URL(`profile/${result.uid}`, url).toString()}>
                                <div 
                                  className="input-group-append"
                                  onClick={this.onCopy}>
                                  <span className="input-group-text copy-btn">
                                    Copy Link
                                  </span>
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <div></div>
                </Row>
              </Container>
            </Card>
          );
        })}
      </div>
    );
  }

}

export default Results;
