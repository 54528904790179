/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import  './SignUp.scss';
import FontAwesome from 'react-fontawesome';
import {
  Form,
  Button,
  Jumbotron,
  Row,
  Col,
  Container,
  FormControl,
  InputGroup
  , Spinner
} from 'react-bootstrap';
import AppConfig from '../App/AppConfig';

const SignUpModal = (props) => (
  <AuthDataContext.StreamConsumer>
    {profile => (
      <div>
        <Jumbotron>
          <h1 className="modal-title">Become a Keeper</h1>
        </Jumbotron>
        <SignUpForm profile={profile} hide={props.hide} onSignUpSuccess={props.onSignUpSuccess} />
      </div>
    )}
  </AuthDataContext.StreamConsumer>
);

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
  passwordType: 'password',
  confirmType: 'password',
  loading:false,
  client:false
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      fullName: this.props.profile.getDefaultProfile() ? this.props.profile.getDefaultProfile().fullName : ''
    };
  }

  convertAnonymousUser = async(email, passwordOne, fullName, roles) => {
    const that = this;
    let company = await this.props.profile.getCompanyFromEmail(email);
    

    if(company === false || (company && Object.keys(company).length === 0)) {
      // this.setState({ error: { message: 'Email not from allowed domain' }, loading: false });
      
      // return;
      company = {
        data: {
          companyLogo: "",
          companyLogoWeb: "",
          domainName: "anything.com",
          name: "open"
          
        },
        id: "qnuaLqGAzCPYnCTkILXF",
        name: "open"
      }
    }else{
    company = { data: company.company.data, id: company.id, name: company.name };
    }
    this.props.profile.doConvertAnonymousUser(email, passwordOne, fullName, roles, company,
      () => {
        //reset the form
        this.setState({ ...INITIAL_STATE });
        this.props.hide();
        if(this.props.onSignUpSuccess) {
          this.props.onSignUpSuccess();
        }
      },
      (error) => {
        if(error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        that.setState({ error, loading: false });
      });
  }

  createEmailUser = (email, passwordOne, fullName) => {
    this.props.profile.doCreateUserWithEmailAndPassword(email, passwordOne, fullName)
      .then((response) => {
        if(response === true) {
          this.setState({ ...INITIAL_STATE });
          this.props.hide();
        }
        else {
          this.setState({ error: response, loading: false });
        }
      }
      );
  }

  createCompanyEmailUser = (email, passwordOne, fullName) => {
    this.props.profile.doWhitelistedCreateUserWithEmailAndPassword(email, passwordOne, fullName)
      .then((response) => {
        if(response === true) {
          this.setState({ ...INITIAL_STATE });
          this.props.hide();
        }
        else {
          this.setState({ error: response, loading: false });
        }
      }
      );
  }


  onSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });

    const { fullName, email, passwordOne, isAdmin } = this.state;
    const roles = {};

    if(isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    if(email.includes('@keeperai')){
      this.setState({ client: true });
    }
   
    if(!AppConfig.isJobsMode()){
      this.createCompanyEmailUser(email, passwordOne, fullName);
    } else if(this.props.profile.isAnonymous === true && this.props.profile.uid !== undefined && this.props.profile.uid !== null) {
      this.convertAnonymousUser(email, passwordOne, fullName, roles);
    }
    else {
      this.createEmailUser(email, passwordOne, fullName);
    }
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value, error: null });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  showHidePassword = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
    });
  };

  showHideConfirm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      confirmType: this.state.confirmType === 'password' ? 'text' : 'password'
    });
  };

  render() {
    const {
      fullName,
      email,
      passwordOne,
      passwordTwo,
      error,
      passwordType,
      confirmType,
      loading
    } = this.state; 

    const passwordRegex =
      (passwordOne.length >= 8 && passwordOne.match(/[A-Z]/) && passwordOne.match(/[0-9]/)) && passwordOne.match(/^(\s*)/)[1].length <= 2;
      
    //Changes:
    //1. Adding alength check to avoid 8 or more blank space in password
    //2. Adding a length check to avoid username to be too long (default to be 30 chars)
    const isInvalid =
      passwordOne !== passwordTwo ||
      !passwordRegex ||
      email === '' ||
      fullName === '' ||
      fullName.length >= 30 ||
      fullName.match(/[`!@#$%^&*()+=\[\]{};':"\\|,.<>\/?~]/);

    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <Form onSubmit={this.onSubmit} className="sign-up-form">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                name="fullName"
                value={fullName}
                onChange={this.onChange}
                type="text"
                placeholder="Full Name"
                className="text-left"
              />
              <Form.Label className="mt-1">{AppConfig.getCodeSnippetOption('signUpSignInEmail')}</Form.Label>
              <Form.Control
                name="email"
                value={email.toLocaleLowerCase()}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
                className="text-left"
              />

              <Form.Label className="mt-1">Password</Form.Label>
              <InputGroup>
                <Form.Control
                  name="passwordOne"
                  value={passwordOne}
                  onChange={this.onChange}
                  type={passwordType}
                  placeholder="Password"
                  className="password-fields text-left"
                />
                <InputGroup.Append onClick={this.showHidePassword}>
                  <InputGroup.Text>
                    {/* <i className={passwordType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye' } /> */}
                    <FontAwesome name={passwordType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>

              <Form.Label className="mt-1">Confirm Password</Form.Label>
              <InputGroup>
                <FormControl
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={this.onChange}
                  type={confirmType}
                  placeholder="Confirm Password"
                  className="password-fields text-left"
                />
                <InputGroup.Append onClick={this.showHideConfirm}>
                  <InputGroup.Text>
                    {/* <i className={confirmType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye' } /> */}
                    <FontAwesome name={passwordType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              {fullName!==''?
                <p className="sub-label text-left mt-4"> User Name must obey:
                  <ul className="pw-rules">
                    <li>
                      {fullName.length <= 30 ? <FontAwesome name="check" /> : <FontAwesome name="times" />}  
                      Username can not be longer than 30 chars
                    </li>
                    <li> 
                      {!(fullName.match(/[`!@#$%^&*()+=\[\]{};':"\\|,.<>\/?~]/)) ? <FontAwesome name='check' /> : <FontAwesome name="times" />}
                      Use only allowed special chars ("_" and "-")
                    </li>
                  </ul>
                </p>
              :''}
              {(passwordOne !== '' && !passwordRegex) ?
                <p className="sub-label text-left mt-4">
                  Password must contain:
                  <ul className="pw-rules">
                    <li>
                      {passwordOne.length >= 8 ? <FontAwesome name="check" /> : <FontAwesome name="times" />}
                      8 characters
                    </li>
                    <li>
                      {passwordOne.match(/[A-Z]/) ? <FontAwesome name="check" /> : <FontAwesome name="times" />}
                      An uppercase letter
                    </li>
                    <li>
                      {passwordOne.match(/[0-9]/) ? <FontAwesome name="check" /> : <FontAwesome name="times" />}
                      A number
                    </li>
                    <li>
                      {passwordOne.match(/^(\s*)/)[1].length <= 2 ? <FontAwesome name='check' /> : <FontAwesome name="times" />}
                      Password cannot be 2 or more consecutive pure blank spaces
                    </li>
                  </ul>
                </p> : ''}
              {(passwordTwo !== '' && passwordOne !== passwordTwo) ?
                <p className="error mb-0 mt-3">Passwords must match</p>
                : ''}
              {loading ?
                <Button
                  disabled
                  variant="primary"
                  type="submit"
                  className="btn-solid-purple sign-up-btn sign-up-btn-loader">
                  <Spinner
                    as="span"
                    className="spinner-border-2"
                    animation="border"
                    variant="secondary"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="load-text"> Sign Up </span>
                </Button> :
                <Button
                  disabled={isInvalid}
                  variant="primary"
                  type="submit"
                  className="btn-solid-purple sign-up-btn">
                  Sign Up
                </Button>
              }
              {error && <p className="error">{error.message}</p>}
              <p className="modal-legal">By signing up you agree to Keeper's
                <Link
                  to={ROUTES.TERMS}
                  target="_blank"
                  className="modal-legal-link">
                  Terms of Service
                </Link> and
                <Link
                  to={ROUTES.PRIVACY}
                  target="_blank"
                  className="modal-legal-link">
                  Privacy Policy
                </Link>. You also agree to receive subsequent email and
                third-party communications, which you may opt out of at
                any time.
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const SignUpForm = compose(
  withRouter,
)(SignUpFormBase);

export default SignUpModal;

export { SignUpForm };
