import React from 'react';
import './innovations.scss';
import { Container } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';
import { getTimeStamp } from '../../../utils';
import { Link } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes';

export default function Innovations({ profile , props}) {

  const  defaultProps = {
    id: 'innovations',
    title: 'innovations',
    sub: 'What do you want to innovate?',
  }

  const {id} = defaultProps;
 
  return (
    <div className='myInnovations-container'>
      <Container className='post-1'>
          
          <div className='innovations'>
              <h4>Ideas</h4>
              { profile.uid === props.profile.uid && <Link to={ROUTES.CCCid + id}>
                  <button className='button-cont' variant='primary'>+</button>
              </Link> 
}
              <span>Have an idea? Share it!</span>
              <Carousel 
                className='gallery-carousel'
                interval={null}
                indicators={false}
        
              >
              <Carousel.Item>
                  <img src={profile.data.profiles.default?.innovationAnswers?.innovations.content === undefined ? '/assets/media/ideas.webp' : profile.data.profiles.default?.innovationAnswers?.innovations?.content} alt = 'innovation.png'/>
                  <p>{profile.data.profiles.default?.innovationAnswers?.innovations.answer === undefined ? 'What do you want to innovate?' : profile.data.profiles.default?.innovationAnswers?.innovations?.answer}</p>
                  <span className="date">{profile.data.profiles.default?.innovationAnswers?.innovations.timeStamp === undefined ? 'Date' : getTimeStamp(profile.data.profiles.default?.innovationAnswers?.innovations.timeStamp)}</span>
                 
              </Carousel.Item>
              {profile.data.profiles.default?.innovationAnswers?.innovations === {} ? '': profile.data.profiles.default?.innovationAnswers?.innovations.previousAnswers?.map(answer => {
                return (
                <Carousel.Item>
                  
                    <img src={answer.content === undefined? '' : answer.content} alt = 'innovation.png'/>
          <p>{answer.answer === undefined? '' : answer.answer}</p>
                    <span className="date">{answer.timestamp === undefined? '' : getTimeStamp(answer.timestamp)}</span>
                
                </Carousel.Item>
              )})}
            </Carousel>
                  
          </div>
          {(profile.data.company.name !== "open") &&
            <div className="buttonBlock">
              <div className="buttonWrapper"><button className="button btn-solid-purple createBtnBottom" onClick={()=>props.history.push(ROUTES.INNOVATION_PAGE)} >COLLABORATIVE IDEAS</button></div>
            </div>
          } 
      </Container>
  
     
   
    </div>
  )
}