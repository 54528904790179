/* eslint-disable max-len */
import React, { Component } from 'react';
import Layout from '../../components/Layout';
import { Jumbotron, Container } from 'react-bootstrap';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';

class Terms extends Component {
  render(){
    return (
      <Layout>
        <ScrollTopOnPageLoad/>
        <Container>
          <Jumbotron>
            <h1 className="text-center">Terms of Service</h1>
          </Jumbotron>

          <div className="text-justify">
            <p>
              <strong>Last Updated: </strong>May 30, 2019
            </p>
            <p>
              Welcome to the Keeper Recruitment Corporation (&ldquo;<strong>Keeper</strong>&rdquo;) website
              located at keeperai.com (&ldquo;
              <strong>
                <em>Site</em>
              </strong>
              &rdquo;). Please read these Terms of Service (the &ldquo;<strong>Terms</strong>&rdquo;) and our
              Privacy Policy (keeperai.com/privacy) carefully because they govern your use of our Site and our
              [Enter a brief description of services (e.g., &ldquo;a place to share photos,&rdquo; etc.)
              services accessible via our Site and our mobile device application (&ldquo;<strong>App</strong>
              &rdquo;). To make these Terms easier to read, the Site, our services and App are collectively
              called the &ldquo;<strong>Services</strong>.&rdquo;
            </p>
            <p>
              <strong>Agreement to Terms </strong>
            </p>
            <p>
              By using our Services, you agree to be bound by these Terms and by our Privacy Policy. If you
              don&rsquo;t agree to these Terms and our Privacy Policy, do not use the Services.
            </p>
            <p>
              <strong>Changes to Terms or Services </strong>
            </p>
            <p>
              We may modify the Terms at any time, in our sole discretion. If we do so, we&rsquo;ll let you know
              either by posting the modified Terms on the Site or through other communications. It&rsquo;s
              important that you review the Terms whenever we modify them because if you continue to use the
              Services after we have posted modified Terms on the Site, you are indicating to us that you agree
              to be bound by the modified Terms. If you don&rsquo;t agree to be bound by the modified Terms,
              then you can&rsquo;t use the Services anymore. Because our Services are evolving over time we may
              change or discontinue all or any part of the Services, at any time and without notice, at our sole
              discretion.
            </p>
            <p>
              <strong>Who May Use the Services </strong>
            </p>
            <p>
              <u>Eligibility </u>
            </p>
            <p>
              You may use the Services only if you are 18 years or older and capable of forming a binding
              contract with Keeper and are not barred from using the Services under applicable law.
            </p>
            <p>
              <u>Registration and Your Information </u>
            </p>
            <p>
              If you want to use certain features of the Services you&rsquo;ll have to create an account
              (&ldquo;<strong>Account</strong>&rdquo;). You can do this via the Site or App.
            </p>
            <p>
              It&rsquo;s important that you provide us with accurate, complete and up-to-date information for
              your Account and you agree to update such information, as needed, to keep it accurate, complete
              and up-to-date. If you don&rsquo;t, we might have to suspend or terminate your Account. You agree
              that you won&rsquo;t disclose your Account password to anyone and you&rsquo;ll notify us
              immediately of any unauthorized use of your Account. You&rsquo;re responsible for all activities
              that occur under your Account, whether or not you know about them.
            </p>
            <p>
              <strong>Feedback </strong>
            </p>
            <p>
              We welcome feedback, comments and suggestions for improvements to the Services (&ldquo;
              <strong>Feedback</strong>&rdquo;). You can submit Feedback by emailing us at 
              <a href="mailto:hello@keeperai.com"> hello@keeperai.com</a>.
              You grant to us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free,
              sublicensable and transferable license under any and all intellectual property rights that you own
              or control to use, copy, modify, create derivative works based upon and otherwise exploit the
              Feedback for any purpose.
            </p>
            <p>
              <strong>Privacy Policy </strong>
            </p>
            <p>
              Please refer to our Privacy Policy (<strong>keeperai.com/privacy</strong>) for information on how
              we collect, use and disclose information from our users.
            </p>
            <p>
              <strong>Content and Content Rights </strong>
            </p>
            <p>
              For purposes of these Terms: (i) &ldquo;<strong>Content</strong>&rdquo; means text, graphics,
              images, music, software, audio, video, works of authorship of any kind, and information or other
              materials that are posted, generated, provided or otherwise made available through the Services;
              and (ii) &ldquo;<strong>User Content</strong>&rdquo; means any Content that Account holders
              (including you) provide to be made available through the Services. Content includes without
              limitation User Content.
            </p>
            <p>
              <strong>Content Ownership, Responsibility and Removal </strong>
            </p>
            <p>
              Keeper does not claim any ownership rights in any Content that you make available through the
              Services and nothing in these Terms will be deemed to restrict any rights that you may have to use
              and exploit your User Content. Subject to the foregoing, Keeper and its licensors exclusively own
              all right, title and interest in and to the Services and Content, including all associated
              intellectual property rights. You acknowledge that the Services and Content are protected by
              copyright, trademark, and other laws of the United States and foreign countries. You agree not to
              remove, alter or obscure any copyright, trademark, service mark or other proprietary rights
              notices incorporated in or accompanying the Services or Content.
            </p>
            <p>
              <u>Rights in User Content Granted by You </u>
            </p>
            <p>
              By making available any User Content available through the Services, you hereby grant to Keeper a
              non-exclusive, transferable, sublicenseable, worldwide, royalty-free license to use, copy, modify,
              create derivative works based upon, distribute, publicly display, publicly perform and distribute
              your User Content in connection with operating and providing the Services and Content to you and
              to other Account holders.
            </p>
            <p>
              You are solely responsible for all your User Content. You represent and warrant that you own all
              your User Content or you have all rights that are necessary to grant us the license rights in your
              User Content under these Terms. You also represent and warrant that neither your User Content, nor
              your use and provision of your User Content to be made available through the Services, nor any use
              of your User Content by Keeper on or through the Services will infringe, misappropriate or violate
              a third party&rsquo;s intellectual property rights, or rights of publicity or privacy, or result
              in the violation of any applicable law or regulation.
            </p>
            <p>
              You can remove your User Content by specifically deleting it. However, in certain instances, some
              of your User Content (such as posts or comments you make) may not be completely removed and copies
              of your User Content may continue to exist on the Services. We are not responsible or liable for
              the removal or deletion of (or the failure to remove or delete) any of your User Content.
            </p>
            <p>
              <u>Rights in Content Granted by Keeper </u>
            </p>
            <p>
              Subject to your compliance with these Terms, Keeper grants you a limited, non-exclusive,
              non-transferable, non-sublicensable license to download, view, copy, display and print the Content
              solely in connection with your permitted use of the Services and solely for your personal and
              non-commercial purposes.
            </p>
            <p>
              <strong>Rights and Terms for Apps </strong>
            </p>
            <p>
              <u>Rights in App Granted by Keeper </u>
            </p>
            <p>
              Subject to your compliance with these Terms, Keeper grants you a limited non-exclusive,
              non-transferable, non-sublicensable license to download and install a copy of the App on a any
              mobile device or computer that you own or control and to run such copy of the App solely for your
              own personal non-commercial purposes. Keeper reserves all rights in and to the App not expressly
              granted to you under these Terms.
            </p>
            <p>
              <u>Additional Terms for App Store Apps </u>
            </p>
            <p>
              If you download the App through or from any app store or distribution platform (like the Apple App
              Store or Google Play) where the App is made available (each, an &ldquo;
              <strong>App Provider</strong>&rdquo;), then you acknowledge and agree that:
            </p>
            <ul>
              <li>
                These Terms are concluded between you and Keeper, and not with the App Provider, and that Keeper
                (not the App Provider), is solely responsible for the App.
              </li>
              <li>
                The App Provider has no obligation to furnish any maintenance and support services with respect
                to the App.
              </li>
              <li>
                In the event of any failure of the App to conform to any applicable warranty, you may notify the
                App Provider, and the App Provider will refund the purchase price for the App to you (if
                applicable) and to the maximum extent permitted by applicable law, the App Provider will have no
                other warranty obligation whatsoever with respect to the App. Any other claims, losses,
                liabilities, damages, costs or expenses attributable to any failure to conform to any warranty
                will be the sole responsibility of Keeper.
              </li>
              <li>
                The App Provider is not responsible for addressing any claims you have or any claims of any
                third party relating to the App or your possession and use of the App, including, but not
                limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any
                applicable legal or regulatory requirement; and (iii) claims arising under consumer protection
                or similar legislation.
              </li>
              <li>
                In the event of any third party claim that the App or your possession and use of that App
                infringes that third party&rsquo;s intellectual property rights, Keeper will be solely
                responsible for the investigation, defense, settlement and discharge of any such intellectual
                property infringement claim to the extent required by these Terms.
              </li>
              <li>
                The App Provider and its subsidiaries are third party beneficiaries of these Terms as related to
                your license of the App, and that, upon your acceptance of the terms and conditions of these
                Terms, the App Provider will have the right (and will be deemed to have accepted the right) to
                enforce these Terms as related to your license of the App against you as a third party
                beneficiary thereof.
              </li>
              <li>You must also comply with all applicable third party terms of service when using the App.</li>
              <li>
                You agree to comply with all U.S. and foreign export laws and regulations to ensure that neither
                the App nor any technical data related thereto nor any direct product thereof is exported or
                re-exported directly or indirectly in violation of, or used for any purposes prohibited by, such
                laws and regulations. By using the App you represent and warrant that: (i) you are not located
                in a country that is subject to a U.S. Government embargo, or that has been designated by the
                U.S. Government as a &ldquo;terrorist supporting&rdquo; country; and (ii) you are not listed on
                any U.S. Government list of prohibited or restricted parties.
              </li>
            </ul>
            <p>
              You may not: (i) copy, modify or create derivative works based on the App; (ii)&nbsp;distribute,
              transfer, sublicense, lease, lend or rent the App to any third party; (iii)&nbsp;reverse engineer,
              decompile or disassemble the App; or (iv) make the functionality of the App available to multiple
              users through any means.
            </p>
            <p>
              <strong>General Prohibitions</strong>
              <strong> and Keeper&rsquo;s Enforcement Rights </strong>
            </p>
            <p>You agree not to do any of the following:</p>
            <ul>
              <li>
                Post, upload, publish, submit or transmit any Content that: (i)&nbsp;infringes, misappropriates
                or violates a third party&rsquo;s patent, copyright, trademark, trade secret, moral rights or
                other intellectual property rights, or rights of publicity or privacy; (ii) violates, or
                encourages any conduct that would violate, any applicable law or regulation or would give rise
                to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory,
                obscene, pornographic, vulgar or offensive; (v)&nbsp;promotes discrimination, bigotry, racism,
                hatred, harassment or harm against any individual or group; (vi) is violent or threatening or
                promotes violence or actions that are threatening to any person or entity; or
                (vii)&nbsp;promotes illegal or harmful activities or substances;
              </li>
              <li>
                Use, display, mirror or frame the Site or App, or any individual element within the Site,
                Services or App, Keeper&rsquo;s name, any Keeper trademark, logo or other proprietary
                information, or the layout and design of any page or form contained on a page, without
                Keeper&rsquo;s express written consent;
              </li>
              <li>
                Access, tamper with, or use non-public areas of the Site or App, Keeper&rsquo;s computer
                systems, or the technical delivery systems of Keeper&rsquo;s providers;
              </li>
              <li>
                Attempt to probe, scan, or test the vulnerability of any Keeper system or network or breach any
                security or authentication measures;
              </li>
              <li>
                Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological
                measure implemented by Keeper or any of Keeper&rsquo;s providers or any other third party
                (including another user) to protect the Site, Services, App or Collective Content;
              </li>
              <li>
                Attempt to access or search the Site, Services, App or Collective Content or download Collective
                Content from the Site, Services or App through the use of any engine, software, tool, agent,
                device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other
                than the software and/or search agents provided by Keeper or other generally available third
                party web browsers;
              </li>
              <li>
                Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam,
                chain letters or other form of solicitation;
              </li>
              <li>
                Use any meta tags or other hidden text or metadata utilizing a Keeper trademark, logo URL or
                product name without Keeper&rsquo;s express written consent;
              </li>
              <li>
                Use the Site, Services, App or Collective Content for any commercial purpose or the benefit of
                any third party or in any manner not permitted by these Terms;
              </li>
              <li>
                Forge any TCP/IP packet header or any part of the header information in any email or newsgroup
                posting, or in any way use the Site, Services, App or Collective Content to send altered,
                deceptive or false source-identifying information;
              </li>
              <li>
                Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to
                provide the Site, Services, App or Collective Content;
              </li>
              <li>
                Interfere with, or attempt to interfere with, the access of any user, host or network,
                including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing
                the Site, Services or App;
              </li>
              <li>
                Collect or store any personally identifiable information from the Site, Services or App from
                other users of the Site, Services or App without their express permission;
              </li>
              <li>Impersonate or misrepresent your affiliation with any person or entity;</li>
              <li>Violate any applicable law or regulation; or</li>
              <li>Encourage or enable any other individual to do any of the foregoing.</li>
            </ul>
            <p>
              <strong>
                Although we&rsquo;re not obligated to monitor access to or use of the Services or Content or to
                review or edit any Content, we have the right to do so for the purpose of operating the
                Services, to ensure compliance with these Terms, or to comply with applicable law or other legal
                requirements. We reserve the right, but are not obligated, to remove or disable access to any
                Content, at any time and without notice, including, but not limited to, if we, at our sole
                discretion, consider any Content to be objectionable or in violation of these Terms. We have the
                right to investigate violations of these Terms or conduct that affects the Services. We may also
                consult and cooperate with law enforcement authorities to prosecute users who violate the law.
              </strong>
            </p>
            <p>
              <strong>DMCA/Copyright Policy </strong>
            </p>
            <p>
              Keeper respects copyright law and expects its users to do the same. It is Keeper&rsquo;s policy to
              terminate in appropriate circumstances Account holders who repeatedly infringe or are believed to
              be repeatedly infringing the rights of copyright holders. Please see Keeper&rsquo;s Copyright
              Policy at keeperai.com/copyright, for further information.
            </p>
            <p>
              <strong>Links to Third Party Websites or Resources </strong>
            </p>
            <p>
              The Services and App may contain links to third-party websites or resources. We provide these
              links only as a convenience and are not responsible for the content, products or services on or
              available from those websites or resources or links displayed on such sites. You acknowledge sole
              responsibility for and assume all risk arising from, your use of any third-party websites or
              resources.
            </p>
            <p>
              <strong>Termination </strong>
            </p>
            <p>
              We may terminate your access to and use of the Services, at our sole discretion, at any time and
              without notice to you. You may cancel your Account at any time by sending an email to us at
              <a href="mailto:cancellation@keeperai.com"> cancellation@keeperai.com</a>. Upon any termination, 
              discontinuation or cancellation of Services or your Account, all provisions of these Terms which 
              by their nature should survive will survive, including, without limitation, ownership provisions, 
              warranty disclaimers, limitations of liability, and dispute resolution provisions.
            </p>
            <p>
              <strong>Warranty Disclaimers </strong>
            </p>
            <p>
              THE SERVICES AND CONTENT ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE
              FOREGOING, WE EXPLICITLY DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING
              OR USAGE OF TRADE. We make no warranty that the Services will meet your requirements or be
              available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the
              quality, accuracy, timeliness, truthfulness, completeness or reliability of any Content.
            </p>
            <p>
              <strong>Limitation of Liability </strong>
            </p>
            <p>
              NEITHER KEEPER NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES OR
              CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING
              LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR
              THE COST OF SUBSTITUTE SERVICES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE
              OF OR INABILITY TO USE THE SERVICES OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT
              (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT KEEPER HAS
              BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS
              FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
            </p>
            <p>
              IN NO EVENT WILL KEEPER’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM
              THE USE OF OR INABILITY TO USE THE SERVICES OR CONTENT EXCEED THE AMOUNTS YOU HAVE PAID TO KEEPER
              FOR USE OF THE SERVICES OR CONTENT OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT
              OBLIGATIONS TO KEEPER, AS APPLICABLE. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
              ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN KEEPER AND YOU.
            </p>
            <p>
              THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
              BETWEEN KEEPER AND YOU.
            </p>
            <p>
              <strong>Governing Law</strong>
            </p>
            <p>
              These Terms and any action related thereto will be governed by the laws of the State of California
              without regard to its conflict of laws provisions.
            </p>
            <p>
              <strong>General Terms </strong>
            </p>
            <p>
              These Terms constitute the entire and exclusive understanding and agreement between Keeper and you
              regarding the Services and Content, and these Terms supersede and replace any and all prior oral
              or written understandings or agreements between Keeper and you regarding the Services and Content.
              If for any reason a court of competent jurisdiction finds any provision of these Terms invalid or
              unenforceable, that provision will be enforced to the maximum extent permissible and the other
              provisions of these Terms will remain in full force and effect.
            </p>
            <p>
              You may not assign or transfer these Terms, by operation of law or otherwise, without
              Keeper&rsquo;s prior written consent. Any attempt by you to assign or transfer these Terms,
              without such consent, will be null and of no effect. Keeper may freely assign or transfer these
              Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the
              benefit of the parties, their successors and permitted assigns.
            </p>
            <p>
              Any notices or other communications provided by Keeper under these Terms, including those
              regarding modifications to these Terms, will be given: (i) by Keeper via email; or (ii) by posting
              to the Services. For notices made by e-mail, the date of receipt will be deemed the date on which
              such notice is transmitted.
            </p>
            <p>
              <strong>
                Keeper&rsquo;s failure to enforce any right or provision of these Terms will not be considered a
                waiver of those rights. The waiver of any such right or provision will be effective only if in
                writing and signed by a duly authorized representative of Keeper. Except as expressly set forth
                in these Terms, the exercise by either party of any of its remedies under these Terms will be
                without prejudice to its other remedies under these Terms or otherwise.
              </strong>
            </p>
            <p>
              <strong>Contact Information </strong>
            </p>
            <p>
              If you have any questions about these Terms or the Services, please contact Keeper at
              <a href="mailto:support@keeperai.com"> support@keeperai.com</a><em>. </em>
            </p>
          </div>
        </Container>
      </Layout>
    );
  }
}

export default Terms;
