/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Notiflix from 'notiflix';
import { images } from '../../assets';
import FontAwesome from 'react-fontawesome';
import './ProfilePicInput.scss';

const INITIAL_STATE = {
  profilePicUploadName: '',
  profilePicture: '',
  error: '',
};

class UploadInput extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  // componentDidMount = () => {
  //   Notiflix.Notify.init({
  //     cssAnimationStyle: 'from-top',
  //     success: { background: '#7846c2' },
  //     cssAnimationDuration: 600,
  //   });
  // }

  uploadImage = async(event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const fileUrl = await this.props.profile.saveFile(file);
    const validFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif', 'image/svg+xml'];
    if (false === validFileTypes.includes(file.type)){
      Notiflix.Notify.failure('Invalid picture type');
      return;
    }

    const currProfile = this.props.profile.getDefaultProfile();
    currProfile.profilePicture = fileUrl;
    this.props.profile.save();
    this.setState({});
    Notiflix.Notify.success('Image uploaded successfully');
  }

  deleteImage = async(event) => {
    event.preventDefault();
    const currProfile = this.props.profile.getDefaultProfile();
    currProfile.profilePicture = '/assets/media/profile/default-avatar.jpeg';
    this.props.profile.save();
    this.setState({});
  }

  render() {
    const currProfile = this.props.profile.getDefaultProfile();
    const imgStyle = {
      backgroundImage: `url(${currProfile.profilePicture ? currProfile.profilePicture : images.avatar})`
    };

    return (
      <div className="avatar-upload-container">
        <div className="avatar-upload">
          {currProfile.profilePicture && 
            <div 
              className="delete-btn"
              onClick={this.deleteImage}>
              <FontAwesome name="times" />
            </div>}
          <div className="avatar-edit">
            <Form.Control 
              name="profilePicture" 
              onChange={this.uploadImage}
              type="file" 
              id="imageUpload" 
              accept=".png, .jpg, .jpeg" />
            <label className={currProfile.profilePicture ? 'edit' : 'add'} htmlFor="imageUpload" />
          </div>
          <div className="avatar-preview" style={imgStyle}>
          </div>
        </div>
      </div>
    );
  }
}


export default UploadInput;
