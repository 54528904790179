import React, { useState, useEffect } from 'react';
import './DailyQuestion.scss'
import { fadeInUp } from 'react-animations';
import styled, { keyframes } from 'styled-components';


const FadeUp = styled.div`animation: 0.2s ${keyframes`${fadeInUp}`}`;

const DailyQuestion = (props) => {
 
  const [, setNewAnswer] = useState()
  const [newQuestion, setNewQuestion] = useState()
  const [newQuestionId, setNewQuestionId] = useState()
  
  useEffect(() =>{
    setNewAnswer(props.profile.data.profiles.default.sampleAnswer)
    setNewQuestion(props.dailyQuestion.data.question)
    setNewQuestionId(props.dailyQuestion.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [props.profile.data.profiles.default.sampleQuestion])
  

    return (
      
      <>
        <FadeUp className="my-auto">
          <div className="DailyQuestion">
            <div>
               <h1>{newQuestion}</h1> 
            </div>

            <form onSubmit={(event) =>{
                event.preventDefault();
                // const time = Date.now();
                const formData = new FormData(event.currentTarget);
                const answer = formData.get("question");
                setNewAnswer(answer);
                props.profile.data.profiles.default.dailyQuestions = ({ questionId: newQuestionId, dailyAnswer: answer }) 
                props.profile.save()
                event.currentTarget.reset();
                props.handleAnswer(answer, newQuestionId);
                
              
            }} >
              <textarea name="question"
                        type="text"
                        className='answer-input' 
                        placeholder = 'Type answer here'
                        required
              />
              <button className="button btn-solid-purple createBtnBottom" type="submit" >Submit Answer</button>
                
             </form>
            
          
          </div>
        </FadeUp>                
      </>
    );
  }


 export default DailyQuestion