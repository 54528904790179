/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './Controllers.scss';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
};

class Controllers extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  static defaultProps = {
    backRoute: ROUTES.HOME,
    nextRoute: ROUTES.MY_INFO,
  }

  render() {
    const {backRoute, nextRoute} = this.props;

    return (
      <div className="controllers">
        <div className="">
          <Link to={backRoute}>
            <button
              className="btn-solid-purple backBtn"
              type="submit">
              <FontAwesome 
                name="arrow-left" 
                className="backBtnArrowBack"/>
              <span className="backBtnText">
                  BACK
              </span>
            </button>
          </Link>
        </div>
        {nextRoute && 
          <div className="">
            <Link to={nextRoute}>
              <button
                className="btn-solid-purple nextBtn"
                type="submit"> 
                NEXT
                <FontAwesome 
                  name="arrow-right" 
                  className="arrowNext"/>
              </button>
            </Link>
          </div>
        }
      </div>
    );
  }
}

export default Controllers;
