/* eslint-disable no-empty */
import React, { Component } from 'react';

const INITIAL_STATE = {
  isPageLoad: true
};

class ScrollTopOnPageLoad extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      ...INITIAL_STATE,
    };
  }

  render(){
    if(this.state.isPageLoad){
      try {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
      catch(e){}    
    }

    return (
      <></>
    );
  }

  componentDidMount(){
    this.setState({ isPageLoad: false });
  }
}

export default ScrollTopOnPageLoad;
