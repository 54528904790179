import React, { useState, useRef } from 'react';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import "./MultiSearchBar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from 'react-bootstrap';


const MultiSearchBar = ({ data, setSearchResults }) => {

    const [searchWord, setSearchWord] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [placeholder, setPlaceholder] = useState('Enter a problem');
    const [filterBy, setFilterBy] = useState('question');
    const inputRef = useRef(null);

    const handleInput = (event) => {
        if (event.target.value) {
            //console.log('input', event.target.value)
            const enteredWord = event.target.value;
            setSearchWord(enteredWord);
        } else {
            setSearchWord("");
            setSearchResults(data);
        }
    }
    const handleDateInput = (date) => {
        if (date) {
            setStartDate(date);
            setSearchWord(date);
            const newSearch = data.filter((value) => {
                //console.log('aaa',date)
                return (value[filterBy].toDateString()).includes(date.toDateString());
            });
            setSearchResults(newSearch);
        } else {
            setSearchWord("");
            setSearchResults(data);
            setStartDate(new Date());
        }
    }

    const handleEnter = (event) => {
        if (event.keyCode === 13) {
            const newSearch = data.filter((value) => {
                //console.log('aaa',value[filterBy])
                if (filterBy === 'dateTime') {
                    return (value[filterBy].toDateString()).includes(searchWord.toDateString());
                } else {
                    return value[filterBy].toLowerCase().includes(searchWord.toLowerCase());
                }
            });
            setSearchResults(newSearch);
        }
    }

    const handleClick = () => {
        if (searchWord.length > 0) {
            const newSearch = data.filter((value) => {
                //console.log('aaa',value[filterBy])
                return value[filterBy].toLowerCase().includes(searchWord.toLowerCase());
            });
            setSearchResults(newSearch);
            inputRef.current.focus();
        }
    }

    const handleDateClick = () => {
        if (searchWord.length > 0) {
            const newSearch = data.filter((value) => {
                //console.log('aaa',value[filterBy])
                return (value[filterBy].toDateString()).includes(searchWord.toDateString());
            });
            setSearchResults(newSearch);
        }
    }

    const clearInput = () => {
        //console.log('click');
        setSearchWord("");
        setSearchResults(data);
        inputRef.current.focus();
    }

    const clearDateInput = () => {
        setSearchWord("");
        setSearchResults(data);
    }

  return (
    <>
        {filterBy === 'dateTime' ? (
            <div className='searchMulti'>               
                <div className='searchInputs'>
                <div className='searchIcon'>
                     <SearchIcon className='searchBtn' onClick={handleDateClick}/>
                </div>
                    <div className='datePicker'>
                        <DatePicker placeholderText={placeholder} value={searchWord} selected={startDate} onChange={handleDateInput} onKeyDown={handleEnter} showYearDropdown
                        dateFormatCalendar="MMMM" yearDropdownItemNumber={15} scrollableYearDropdown />
                    </div>
                    <div className='closeIcon'>
                        {searchWord.length !== 0 ? (<CloseIcon className='clearBtn' onClick={clearDateInput} />) : (<CloseIcon className='clearBtn' style={{visibility:'hidden'}} onClick={clearDateInput} />)}
                    </div>
                </div>
            </div>
        ) : (
            <div className='searchMulti'>
                <div className='searchIcon'>
                    <SearchIcon className='searchBtn' onClick={handleClick}/>
                </div>
                <div className='searchInputs'>
                    <input className='inputField' type='text' placeholder={placeholder} value={searchWord} onChange={handleInput} onKeyDown={handleEnter} ref={inputRef}/>
                    <div className='closeIcon'>
                        {searchWord.length !== 0 ? (<CloseIcon className='clearBtn' onClick={clearInput} />) : (<CloseIcon className='clearBtn' style={{visibility:'hidden'}} onClick={clearInput} />)}
                    </div>
                </div>
            </div>
        )}
        <Dropdown >
            <Dropdown.Toggle className='multiDropdown-btn' id="dropdown-basic">
                {filterBy === 'question' ? 'Question' : 'DateTime'}
            </Dropdown.Toggle >
                <Dropdown.Menu className='multiDropdown-menu'>
                    <Dropdown.Item onClick={e => { setFilterBy('question'); setSearchWord(''); setPlaceholder('Enter a problem');}}>Question</Dropdown.Item>
                    <Dropdown.Item onClick={e => { setFilterBy('dateTime'); setSearchWord(''); setPlaceholder('MM/DD/YYYY');}}>DateTime</Dropdown.Item>
                </Dropdown.Menu>
        </Dropdown>
    </>
  )
}

export default MultiSearchBar