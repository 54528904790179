import React, { useEffect, useState } from 'react';
import * as ROUTES from '../../../constants/routes';
import { Spinner, Button } from 'react-bootstrap';
import "./AffinityGroups.scss";

export default function AffinityGroups({profile, props}) {
  const [loading, setLoading] = useState(true);
  const [affinityGroupsList, setAffinityGroupsList] = useState([
    {
      groupImgUrl: '/assets/media/5edc63d58376f479b4a4e04673c1455a.jpeg',
      groupName: 'Cats',
      subscribed: true,
      groupTagline: 'Proud collaborative work with team',
    }
  ]);
  useEffect(() => {    
    getAllAffinityGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllAffinityGroups = async () => {
    await props.profile.getAllAffinityGroups(profile.uid)
     .then(result => {
      const { profiles } = profile.data;
      let affinityGroups = [];
      if (profiles?.default?.insights?.affinityGroups) affinityGroups = profiles?.default?.insights?.affinityGroups;
      let groups = [];
      if(result && result.length)
        groups = result.filter(group => affinityGroups.findIndex(id => id === group.id) >= 0).map(group => {
          return {
            groupImgUrl: group.data.groupImgUrl,
            groupName: group.id,
            subscribed: true,
            groupTagline: group.data.groupTagline,
          }
        });
      setAffinityGroupsList(groups);
      setLoading(false);
    });
  };

  const routeToMembersPage=  (profile) =>   (e) => {
    props.history.push(ROUTES.AFFINITY_MEMBERS_PAGE + "/" + profile.groupName)
  }

  const groups = loading ? ( 
    <div className='spinner-container d-flex justify-content-center'>
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    </div>   
  ) : affinityGroupsList.map(group => (
      <div className="group1 col-md-3 mt-2" key={group.groupName}>
        <div className="cursor-pointer" onClick={ routeToMembersPage(group) }>
          <span>{group.groupName}</span>
          <img src={group.groupImgUrl} alt='affinitygroup' />        
        </div>
      </div>
  ));
  if(groups.length > 0 ){
  return (
    <div className="affinityGroups">
      <div className="title">
         <span><b>Affinity Groups</b></span>
      </div>
      <section className="row">
         {groups}<a href = "/affinitygroups"><Button className="btn-solid-purple sign-up-btn">Join More Groups</Button></a>
      </section>
         
    </div>
    );
  }else{
    return (
      <div className="affinityGroups">
        <div className="title">
           <span><b>Affinity Groups</b></span>
        </div>
        <section className="empty">
          <a href = "/affinitygroups"><Button className="btn-solid-purple sign-up-btn">Join Affinity Groups</Button></a>
        </section>
           
      </div>
      );
    
  }
}

       
