import React, { useState, useRef } from 'react';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import '../SearchBar/SearchBar.css'

const SearchBarOfCompanyPage = ({ placeholder, setOuterSearchWord }) => {
    const [searchWord, setSearchWord] = useState("");
    const inputRef = useRef(null);

    const handleInput = (event) => {
        if (event.target.value) {
            //console.log('input', event.target.value)
            const enteredWord = event.target.value;
            setSearchWord(enteredWord);
        } else {
            setSearchWord("");
            setOuterSearchWord("");
        }
    }

    const handleEnter = (event) => {
        if (event.keyCode === 13) {
            // console.log('searchword', searchWord);
            setOuterSearchWord(searchWord);
        }
    }

    const handleClick = () => {
        setOuterSearchWord(searchWord);
        inputRef.current.focus();
    }

    const clearInput = () => {
        //console.log('click');
        setSearchWord("");
        setOuterSearchWord("");
        inputRef.current.focus();
    }

  return (
    <div className='searchBar'>
        <div className='searchIcon'>
            <SearchIcon className='searchBtn' onClick={handleClick}/>
        </div>
        <div className='searchInputs'>
            <input className='inputField' type='text' placeholder={placeholder} value={searchWord} onChange={handleInput} onKeyDown={handleEnter} ref={inputRef}/>
            <div className='closeIcon'>
                {searchWord.length !== 0 ? (<CloseIcon className='clearBtn' onClick={clearInput} />) : (<CloseIcon className='clearBtn' style={{visibility:'hidden'}} onClick={clearInput} />)}
            </div>
        </div>
    </div>
  )
}

export default SearchBarOfCompanyPage