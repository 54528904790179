/* eslint-disable max-len */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { Modal, Spinner } from 'react-bootstrap';
import SignUpModal from '../../SignUp';
import { postProfile } from '../../../utils.js';

const INITIAL_STATE = {
  redirectRoute: false,
  showSignUp: false,
  complete: false,
  userData: '',
  loading: false
};

class ProfileController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  /// handler to redirect back to editing your profile
  handleEdit = () => {
    this.setState({
      redirectRoute: ROUTES.MY_CONTENT,
    });
    document.documentElement.scrollTop = 0;
  }

  /// Opens modal to create an account and sets important tags on user account on the database
  handleSave = async () => {
    this.props.profile.save();
    // this.setState({ loading: true});
    this.props.updateLoading(true);

    const newProfile = {
      email: this.props.profile.data?.profiles?.default?.email,
      full_name: this.props.profile.data?.profiles?.default?.fullName,
      // company: this.props.profile.data.company,
      company: this.props.profile.data.company?.data?.name,
      user_id: this.props.profile.uid,
      power_to_change: {
        text: this.props.profile.data.profiles?.default?.softSkillsAnswers?.power_to_change?.answer,
        url: this.props.profile.data.profiles?.default?.softSkillsAnswers?.power_to_change?.content
      },
      workplace: {
        text: this.props.profile.data.profiles?.default?.softSkillsAnswers?.workplace?.answer,
        url: this.props.profile.data.profiles?.default?.softSkillsAnswers?.workplace?.content
      },
      perception: {
        text: this.props.profile.data.profiles?.default?.softSkillsAnswers?.perception?.answer,
        url: this.props.profile.data.profiles?.default?.softSkillsAnswers?.perception?.content
      },
      happy_place: {
        text: this.props.profile.data.profiles?.default?.softSkillsAnswers?.happy_place?.answer,
        url: this.props.profile.data.profiles?.default?.softSkillsAnswers?.happy_place?.content
      },
      favorite_hobby: {
        text: this.props.profile.data.profiles?.default?.softSkillsAnswers?.favorite_hobby?.answer,
        url: this.props.profile.data.profiles?.default?.softSkillsAnswers?.favorite_hobby?.content
      },
      curiosity: {
        text: this.props.profile.data.profiles?.default?.softSkillsAnswers?.curiosity?.answer,
        url: this.props.profile.data.profiles?.default?.softSkillsAnswers?.curiosity?.content
      },
      // company: "Company",
      birthday: this.props.profile.data.profiles?.default?.myBirthday,
      location: this.props.profile.data.profiles?.default?.myLocation,
      job_title: this.props.profile.data.profiles?.default?.myRole,
      team: this.props.profile.data.profiles?.default?.myTeam,
      profile_picture: this.props.profile.data.profiles?.default?.profilePicture,
      raw_attributes: this.props.profile.data.profiles?.default?.softSkillsAnswers

    }
    // user input data is sent to python engine
    await postProfile(newProfile)
      .then(result => {
        this.userData = result.insights;
        console.log('postprofile ', result, ' this.props.profile.data ', this.props.profile.data);
        if (!this.props.profile.data.profiles.default.insights) {
          this.props.profile.data.profiles.default.insights = {}
        }
        try {
          if (result.insights?.color) {
            this.props.profile.data.profiles.default.insights.color = result.insights?.color;
          }

        } catch (error) {
          console.log('error ', error);
        }

        // try {
        //   this.props.profile.data.profiles.default.insights.affinityGroups = this.userData.affinity_groups;
        // } catch (error) {
        //   console.log('error ', error);
        // }
        try {
          if (result.insights.mission_statement) {
            this.props.profile.data.profiles.default.insights.missionStatement = JSON.parse(result.insights.mission_statement);
          }

        } catch (error) {
          console.log('error ', error);
        }
        try {
          if (result.insights.theme) {
            this.props.profile.data.profiles.default.insights.theme = JSON.parse(result.insights.theme);
          }

        } catch (error) {
          console.log('error ', error);
        }
        try {
          this.props.profile.data.profiles.default.insights.wordCloud = JSON.parse(result.insights.word_cloud);
        } catch (error) {
          console.log('error ', error);
        }
        try {
          this.props.profile.data.profiles.default.insights.fiveFactorsScores = JSON.parse(result.insights.five_factor_scores);
        } catch (error) {
          console.log('error ', error);
        }
        console.log('this.props.profile.data.profiles.default.insights :: ', this.props.profile.data.profiles.default.insights);
        this.props.profile.save();
      })
      .catch(err=>{
        console.log(err.message)
      })
      .finally(await console.log('done'));
    // await postProfile(newProfile)
    //   .then(setTimeout(() => {console.log("working on it")}, 20000))
    //   // user insight is fetched from the python engine
    //   .then(this.userData = await getInsights(this.props.profile.uid))
    //   .then(
    //     this.props.profile.data.profiles.default.insights?.color = this.userData?.color,
    //     this.props.profile.data.profiles.default.insights.affinityGroups = this.userData.affinity_groups,
    //     this.props.profile.data.profiles.default.insights.missionStatement = this.userData.mission_statement,
    //     this.props.profile.data.profiles.default.insights.theme = this.userData.theme,
    //     this.props.profile.data.profiles.default.insights.wordCloud = this.userData.word_cloud,
    //     this.props.profile.data.profiles.default.insights.fiveFactorsScores = this.userData.five_factor_scores,
    //   )

    //   .then(await this.props.profile.save())
    //   .finally(await console.log('done'));
    // this.setState({ loading: false});
    this.props.updateLoading(false);
    if (!this.props.profile.isAnonymous) {
      this.props.profile.getDefaultProfile().isComplete = true;
      this.props.profile.save();
      this.setState({ complete: true });
    } else {
      this.setState({ showSignUp: true });
    }
  }
  handleCloseSignUp = () => {
    this.setState({ showSignUp: false });
  }

  handleSignUpComplete = () => {
    this.props.profile.getDefaultProfile().isComplete = true;
    this.props.profile.save();
    this.setState({ complete: true });
  }

  detectBrowser = () => {
    const { detect } = require('detect-browser');
    const browswer = detect();
    if (browswer.name === 'edge') {
      return true;
    };
  };

  render() {
    const { redirectRoute, complete } = this.state;
    console.log(this.props)

    if (redirectRoute) {
      document.documentElement.scrollTop = 0;
      return <Redirect to={redirectRoute} />
    }

    if (complete) {
      document.documentElement.scrollTop = 0;
      return <Redirect to={ROUTES.COMPLETE} />
    }



    return (
      <div className='field is-grouped'>
        {this.props.parentLoading ? 
        <div className='end-btn-group-loading'>
          <Spinner
            as="span"
            className="spinner-border-2"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="load-text">Saving...</span>

        </div> :
          <div className='end-btn-group'>
            <button
              rounded={"true"}
              className='btn-outline-purple'
              onClick={this.handleEdit}
            >
              EDIT
            </button>
            {this.detectBrowser && <div className='edge-spacer' />}
            <button
              rounded={"true"}
              className='btn-solid-purple'
              onClick={this.handleSave}
            >
              SAVE
            </button>
          </div>
        }
        <Modal
          show={this.state.showSignUp}
          onHide={this.handleCloseSignUp}
          keyboard>
          <Modal.Header closeButton />
          <Modal.Body>
            <SignUpModal
              hide={this.handleCloseSignUp}
              onSignUpSuccess={this.handleSignUpComplete} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default ProfileController;
