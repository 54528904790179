import React, { useEffect, useState } from 'react';

import './VibeInnovationPage.scss';
import {
  AuthDataContext,
  withAuthDataContext,
} from '../../components/Firebase/authdataprovider';
import InsightsSideBar from '../Insights/InsightsSideBar';
import { Link, Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withAuthorization } from '../../components/Session';
import { compose } from 'recompose';
import AppConfig from '../../components/App/AppConfig';
import Layout from '../../components/Layout';
import { Spinner } from 'react-bootstrap';
import { fadeInDown, fadeInUp } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import SearchBar from '../../components/SearchBar/SearchBar';
const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;
const TeamVibePage = (props) => {
  // const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [vibeList, setVibeList] = useState([
    {
      vibe_url: '/assets/media/5edc63d58376f479b4a4e04673c1455a.jpeg',
      profileName: 'James Ford',
      liked: false,
	  likes: 50,
      profileImage:
        '/assets/media/profile/11d6a19c0c442e99b032fd8857ccc55f.png',
      vibe_title: 'Business Analyst',
    },
    {
      vibe_url: '/assets/media/199d8ba579d0c2dcd7155aa7c032d895.jpeg',
      profileName: 'James Ford A',
      liked: false,
	  likes: 50,
      profileImage:
        'https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg',
      vibe_title:
        'Text 1dd sdfds fgsdf dsfsf sd dsads dsad dasd sadasdsadsad asda sd sadsad ad ad',
    },
    {
      vibe_url: '/assets/media/e1def3f2816a301a99c1a584bca0368a.jpeg',
      profileName: null,
      liked: false,
	  likes: 50,
      profileImage:
        '/assets/media/profile/e4b9385928d8ef326ec64441ce775e7e.jpeg',
      vibe_title: 'Business Analyst 123434',
    },
    {
      vibe_url: '/assets/media/0342b0116d0a9afaeb54bff98c6d8954.jpeg',
      profileName: 'James Ford',
      liked: false,
	  likes: 50,
      profileImage:
        'https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg',
      vibe_title: 'Business Analyst 435454353435',
    },
    {
      vibe_url: '/assets/media/d408e59e7c2150dc1ac4bd549f70bd53.jpeg',
      profileName: 'James Ford A',
      liked: false,
	  likes: 50,
      profileImage:
        'https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg',
      vibe_title:
        'Text 1dd sdfds fgsdf dsfsf sd dsads dsad dasd sadasdsadsad asda sd sadsad ad ad',
    },
    {
      vibe_url: '/assets/media/0097523f59d6c4b7cde3ddcbfc414617.jpeg',
      profileName: null,
      liked: false,
	  likes: 50,
      profileImage:
        'https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg',
      vibe_title: 'Business Analyst 123434',
    },
    {
      vibe_url: '/assets/media/cf6fe116a28689975685284ffcfb684a.jpeg',
      profileName: 'James Ford',
      liked: false,
	  likes: 50,
      profileImage:
        'https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg',
      vibe_title: 'Business Analyst 435454353435',
    },
  ]);

  const [searchVibeList, setSearchVibeList] = useState(vibeList);


  useEffect(() => {
    if (props.profile.data?.profiles?.default?.email && props.profile.data?.profiles?.default?.email !== "") {
      window.scrollTo(0, 0);
      setLoading(true)
      getCompanyVibes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);



  const getCompanyVibes = async () => {
     await props.profile.getAllCompanyVibes(props.profile.data?.company?.id)
     .then(result => {
       let vibes = [];
       let vibes2 = [];
      //  console.log(result)
        if(result.data){
          for(var i in result.data){
            result.data[i]['vibe_id'] = i;
            // result.data[i]['profileName'] = 'James Ford';
            let liked = false; 
            if(result.data[i].likedBy && result.data[i].likedBy.length > 0){
              if(result.data[i].likedBy.indexOf(props.profile.uid) !== -1){
              liked = true;
              }
            }
                result.data[i]['liked'] = liked;
                if(!result.data[i].hasOwnProperty("likes")){
                  result.data[i].likes = 0;
                }
            // result.data[i]['profileImage'] = 'https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg';
            vibes.push(result.data[i]);
          }
        }
      
        for(let i = 0; i<vibes.length; i++){
          if (vibes[i].hasOwnProperty('answer')) vibes2.push(vibes[i])
        }
        vibes2.sort((a,b) => b.timeStamp - a.timeStamp );
        // console.log('vibes :: ', vibes2);
         //console.log('aaaa6 :: ', vibeList);
        if (vibes2 && vibes2.filter(vib=> vib.hasOwnProperty('content')).length > 0) {
          setVibeList(vibes2.filter(vib=> vib.hasOwnProperty('content')));
          //console.log('aaaa1 :: ', vibes2);
          //console.log('aaaa2 :: ', vibeList);
          setSearchVibeList(vibes2.filter(vib=> vib.hasOwnProperty('content')));
          //console.log('aaaa3 :: ', searchVibeList);
        } else {
          setVibeList([]);
          setSearchVibeList([]);
        }
        // setVibeList(vibes2);
        setLoading(false)
     
   
    })
    .catch(error =>{
      console.log('error', error.message)
    });
  
  };

  const handleprofileSelect=  (index, profile) =>   (e) => {
    props.history.push(ROUTES.INSIGHTS + "/" + profile.userId)
  }

  const updateFieldChanged =  (index, profile) =>   (e) => {

    let newArr = [...vibeList.filter(vib=> vib.hasOwnProperty('content'))]; // copying the old datas array
    newArr[index].liked = !newArr[index].liked; // replace e.target.value with whatever you want to change it to
    if(newArr[index].liked){
      // if(!newArr[index].hasOwnProperty("likes")){
      //   newArr[index].likes = 0;
      // }
      newArr[index].likes = newArr[index].likes + 1;
      props.profile.addVibeLike(profile.userId, props.profile.uid);
    }else{
      newArr[index].likes = newArr[index].likes - 1;
      if(newArr[index].likes < 0){
        newArr[index].likes = 0;
      }
      props.profile.removeVibeLike(profile.userId, props.profile.uid);
    }
     
    setVibeList(newArr);

  }

  // if(loading) return ( 
  //   <div className='spinner-container d-flex justify-content-center'>
  //     <Spinner animation='border' role='status'>
  //       <span className='sr-only'>Loading...</span>
  //     </Spinner>
  //   </div>   
  // )

  if (!props.profile.data?.profiles?.default?.email || props.profile.data?.profiles?.default?.email === "") {
    return <Redirect to={ROUTES.HOME} />
    }

  return (
    <>
      <div className="insightsPage">
      <Layout data = {vibeList} setSearchResults = {setSearchVibeList} loading = {loading} 
      filterBy={'fullName'} placeholder = {'Enter a Username'}>
      <AuthDataContext.StreamConsumer requireAccount={true}>
        {(profile) => (
          <>
       
            <InsightsSideBar
              image={profile.data.profiles.default.profilePicture}
              name={profile.data.profiles.default.fullName}
               profile={profile}
            />

            <div className="teamVIPage">
            <FadeDown>
              <div className="row m-0">
              <Link to={ROUTES.CCCid+ "vibe"}>
                <button
                  className="btn btn-link btn-plus"
                  type="button"
                >
                  <i
                    className="fa fa-plus icon-size"
                    aria-hidden="true"
                  ></i>
                </button>
                </Link>
                {/* <button class="button-cont" variant="primary">+</button> */}
                {/* <a>
                  <div class="plus radius">
                  </div></a> */}

                <div className="publishTitle">
                  <h3>
                    What's on your <br />
                    Mind?
                    
                  </h3>
                </div>
              </div>
              </FadeDown>
              <FadeUp className="my-auto">
              <div className="teamVI">
                <div className="title">
                  <span>
                    <b>Community Gratitude</b>
                  </span>
                </div>

                {/* <div>
                  { !loading && <SearchBar placeholder='Enter a Username' data={vibeList} setSearchResults={setSearchVibeList} /> }
                </div> */}

                {/* <div>
                  { !loading && <SearchBar placeholder='Enter a Username' data={vibeList} setSearchResults={setSearchVibeList} filterBy='fullName' /> }
                </div> */}

                <section>
                  <div className="row"></div>

                  {/* <h3>this is where company folks will go</h3> */}
                  <div className="row vi-gallery pb-5">
                    { !loading ? 
                          searchVibeList.length > 0 ? (
                            searchVibeList.map((profile, i) => {
                              let image;
                              if(profile.profilePicture==="https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"||profile.profilePicture==="/images/default-avatar.svg"){
                                image = '/assets/media/profile/default-avatar.jpeg'
                              }else{
                                image = profile.profilePicture
                              }
                              if(!profile.profilePicture) image = '/assets/media/profile/default-avatar.jpeg';
                              return (
                                <div
                                  key={i}
                                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 img-width-res i-overlay mt-3"
                                >
                                  <img
                                    src={profile?.content}
                                    alt="Avatar"
                                    className="img-overlay"
                                    style={{ width: '100%' }}
                                  />
                                  <div className="top-left-corner">
                                    <span className="cursor-pointer" onClick={ handleprofileSelect(i, profile) }>
                                      <img
                                        src={image}
                                        alt="Avatar"
                                        className="img-profile"
                                      />
                                      <div className='profile-name' >{profile.fullName}</div>
                                    </span>
                                  </div>

                                  <div className="top-right-corner">
                                    <div className="circleBase type1">
                                      <button
                                        className="btn btn-link btn-like"
                                        type="button"
                                        onClick={ updateFieldChanged(i, profile) }
                                      >
                                      <span>{ profile.liked? <i className="fa fa-heart icon-size btn-like-active" aria-hidden="true" ></i> : <i className="fa fa-heart-o icon-size" aria-hidden="true" ></i>}
                                      </span>
                                      </button>
                                      </div>
                                      <div className="likes-txt mt-2 p-2">
                                      {profile?.likes} likes
                                    </div>
                                  </div>

                                  <div className="bottom-left-corner">
                                    <div className="i-txt-name">
                                      {profile?.profileName}
                                    </div>
                                    <div className="i-txt">
                                      {profile?.answer}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-md-12 text-center p-2">
                              <span className='white' style={{color:"white"}}>No Vibes Found</span>
                            </div>
                          )
                      :
                      (
                        <Spinner animation='border' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </Spinner>
                      )
                  }
                  </div>
                  {(profile.data.company.name !== "open") && 
                  <div className="row m-0 bottom-button">
                    <div className="col-md-12 pt-3 pb-3 btn-right-corner" >
                      <button className="button shadow-none btn-solid-purple createBtnBottom"
                        onClick={()=>props.history.push(ROUTES.INNOVATION_PAGE)}
                      >
                        COLLABORATIVE IDEAS
                      </button>
                    </div>
                  </div>}
                 
                </section>
              </div>
              </FadeUp>
            </div>
          </>
        )}
      </AuthDataContext.StreamConsumer>
      </Layout>
      </div>
    </>
  );
};

// export default VibeInnovationPage

const VibeInnovationPageM = withAuthDataContext(TeamVibePage);

const condition = (profile) => {
  // console.log("JObs mode? Verified? ", AppConfig.isJobsMode(), profile.emailVerified)
  if (AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(withAuthorization(condition))(
  VibeInnovationPageM,
);
