/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { 
  Row, 
  Col, 
  Form, 
  Jumbotron,
  Container,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import _templates from '../../constants/templatelist';
import 'react-router-dom';
import f from 'firebase';
import Notiflix from 'notiflix';
import styled, { keyframes } from 'styled-components';
import { fadeIn, fadeInDown, fadeInUp } from 'react-animations';

const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
const FadeIn = styled.div`animation: 1.2s ${keyframes`${fadeIn}`}`;
const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;

const INITIAL_STATE = {
  profiles: {},
  name: '',
  title: '',
  dateCreated: '',
  searchCode: '',
  galleryId: 'tbd',
  method: 'create',
  id: '',
  showAddProfile: false,
  profileList: [],
  template: 'default',
  reloadToEdit: false,
  loading: false,
};

class Builder extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  async componentDidMount(){
    Notiflix.Notify.init({
      cssAnimationStyle: 'from-top',
      success: { background: '#7846c2' },
      cssAnimationDuration: 600,
    });
    const id = this.props.galId;
    if(id !== 'new'){
      //Get Galleery
      let response;
      try {
        //TODO: handle templates (specifically, which answers are being used to popular list)
        response  = (await this.getGallery(id)).data;
        if(response != null){
          this.setState({
            profiles: response.profiles,
            galleryId: id,
            dateCreated: response.dateCreated,
            name: response.name,
            title: response.title,
            method: 'edit', //edit
            showAddProfile: true,
            profileList: response.profileList,
            template: response.template,
            id,
          });
        } else {
          //do something about not having access
        }
      }
      catch(exception){
        console.log(exception);
        //do something with the error warning
      }
    }
  }
  getGallery = async(id) => {
    this.setState({ loading:true });
    const getGal = f.functions().httpsCallable('getGalleryToEdit');
    const res = await getGal({ id });
    // console.log('getGal', res);
    this.setState({ loading:false });
    return res;
  }

  addUserToGallery = async() => {
    const addGalleryUser = f.functions().httpsCallable('addGalleryUser');
    return await addGalleryUser({ input: this.state.searchCode, galId: this.state.galleryId });
  }
  removeUserFromGallery = async(userId) => {
    const removeGalleryUser = f.functions().httpsCallable('removeGalleryUser');
    return await removeGalleryUser({ input: userId, galId: this.state.galleryId });
  }
  updateGallery = async() => {
    const manageGallery = f.functions.httpsCallable('manageGallery');
    return await manageGallery({
      galId: this.state.galleryId,
      name: this.state.name, 
      title: this.state.title, 
      url: this.state.url, 
      dateCreate: this.state.dateCreated, 
      template: this.state.template
    });
  }

  addUser = async(event) => {
    this.setState({ loading:true });
    event.preventDefault();
    try {
      const response = (await this.addUserToGallery()).data;
      if(response !== 'failed'){
        this.setState({            
          profiles: response.profiles,
          showAddProfile: true,
          profileList: response.profileList,
        });
      } else {
        Notiflix.Notify.failure('Couldn\'t add user');
      }
    } 
    catch(exception){
      console.log(exception);
    }
    this.setState({ loading:false });
  }

  deleteUser = async(event) => {
    this.setState({ loading:true });
    event.preventDefault();
    const userId = event.target.userId.value;
    try {
      const response = (await this.removeUserFromGallery(userId)).data;
      if(response !== 'failed'){
        this.setState({ profiles: response.profiles, profileList: response.profileList });
      }
      else {
        Notiflix.Notify.failure('Failed to delete user');
      }
    }
    catch(exception){
      console.log(exception);
    }
    this.setState({ loading:false });
    //delete user
    //handle response
    //re-set state
  }

  submitGallery= async(event) => {
    this.setState({ loading:true });
    event.preventDefault();
    try {
      const manageGallery = f.functions().httpsCallable('manageGallery');
      const response = (await manageGallery({
        name: this.state.name, 
        title: this.state.title, 
        galId: this.state.galleryId, 
        template: this.state.template,
        dateCreate: this.state.dateCreated}))
      if (response.data !== 'failed'){
        Notiflix.Notify.success('Successfully updated gallery');
        if(response.data !== 'success'){
          this.setState({
            galleryId : response.data, 
            reloadToEdit: true
          });
        }
      } else {
        Notiflix.Notify.failure('Failed to update gallery');
      }
      
    } catch(exception){
      //do something with the error
      console.log('error', exception);
      Notiflix.Notify.failure('Failed to update gallery');
      //Notiflix.Notify.failure(exception.message);
    }
    this.setState({ loading:false });
  }

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  render(){
    const { 
      id, 
      name, 
      title, 
      method, 
      loading,
      profiles,
      template, 
      galleryId,
      searchCode, 
      profileList, 
      reloadToEdit,
      showAddProfile, 
    } = this.state;

    if(reloadToEdit === true) {
      window.location.replace(`/gallerybuilder/${galleryId}`);
    }

    if(loading){
      return (
        <div className="spinner-container d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    } else {
      return (
        <Container className="gallery-builder-form">
          <div>
            <FadeDown>
              <Jumbotron>
                <FadeIn>
                  <h1 className="heading">
                    {method === 'create' ? 'Create a Gallery' : 'Edit Gallery'}
                  </h1>
                </FadeIn>
              </Jumbotron>
              <Form.Group>
                <Row className="text-left">
                  <Col lg={4}>
                    <Form.Label>Gallery Name</Form.Label>
                    <Form.Control className="text-left" onChange={this.handleInput} name="name" value={name && name}/>
                  </Col>
                  <Col lg={4}>
                    <Form.Label>Gallery Title</Form.Label>
                    <Form.Control className="text-left" onChange={this.handleInput} name="title" value={title && title}/>
                  </Col>
                  <Col lg={4}>
                    <Form.Label>Template</Form.Label>
                    <Form.Control 
                      as="select" 
                      className="text-left" 
                      onChange={this.handleInput}
                      name="template"
                      value={template && template}>
                      <option disabled>Choose...</option>
                      {_templates.templates.map(temp => {
                        return (<option>{temp}</option>);
                      })}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="text-right">
                    <Link 
                      to={'/gallerybuilder'} 
                      rel="noopener noreferrer">
                      <button className="btn-solid-purple">
                          Gallery Builder 
                      </button>
                    </Link>
                  </Col>
                  <Col className="text-left">
                    <button 
                      className="btn-solid-purple"
                      onClick={this.submitGallery}>
                      {method === 'create' ? 'Create Gallery' : 'Edit Gallery'}
                    </button>
                  </Col>
                </Row>
              </Form.Group>
            </FadeDown>
          </div>
          {
            showAddProfile === true ? 
              <FadeUp>
                <div className="profiles-form">
                  <h3 className="heading mt-4 mb-1">Add a Profile</h3>
                  <p>Add a user by UserId or Email</p>
                  <Form.Group onSubmit={this.addUser}>
                    <InputGroup>
                      <Form.Control 
                        onChange={this.handleInput} 
                        name="searchCode" 
                        value={searchCode && searchCode}/>
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <button className="add-btn" onClick={this.addUser}>
                            <FontAwesome name="plus" />
                          </button>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <h3 className="heading">Current Profiles</h3>
                  <Row>
                    <Col className="text-left">
                      <Form.Label>Name</Form.Label>
                    </Col>
                    <Col>
                      <Form.Label className="text-center">URL</Form.Label>
                    </Col>
                    <Col>
                      <Form.Label className="text-center">Delete</Form.Label>
                    </Col>
                  </Row>
                  {profileList && profileList.map(id => {
                    const profile = profiles[id];
                    return (<Row key={id}>
                      <Col className="text-left">
                        {profile.profiles[template] && profile.profiles[template].fullName}
                      </Col>
                      <Col>
                        <a
                          target="_blank" 
                          rel="noopener noreferrer" 
                          href={`/profile/${id}`}
                        >{`${id}`}
                        </a>
                      </Col>
                      <Col>
                        <form onSubmit={this.deleteUser}>
                          <input 
                            type="hidden" 
                            name="userId" 
                            value={id}/>
                          <button 
                            type="submit" 
                            className="delete-user-btn text-center">
                            <FontAwesome name="times" />
                          </button>
                        </form>
                      </Col>
                    </Row>);}
                  )
                  }
                </div>
              </FadeUp>
              :
              <div><span>Please Create a Gallery before adding users.</span></div>
          }
            
          {
            method === 'create' ? <></> : 
              <Row className="mt-4">
                <Col className="text-right">
                  <a 
                    href="/galleryBuilder/new">
                    <button className="btn-solid-purple">
                      Create New Gallery
                    </button>
                  </a>
                </Col>
                <Col className="text-left">
                  <Link 
                    to={`/gallery/${id}`} 
                    target="_blank" 
                    rel="noopener noreferrer">
                    <button className="btn-solid-purple">
                      View Current Gallery
                    </button>
                  </Link>
                </Col>
              </Row>
          }
        </Container>
      );
    }
  }
}

export default Builder;
