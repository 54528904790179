/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { 
  Row,
  Col, 
  Form, 
  Button, 
  Container,
  Jumbotron
} from 'react-bootstrap';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';

const PasswordForgetModal = () => (
  <Container>
    <Jumbotron>
      <h1 className="text-center modal-title">Forgot Password?</h1>
    </Jumbotron>
    <AuthDataContext.StreamConsumer>
      {profile => (
        <PasswordForgetForm profile={profile} showUpdateEmail={false} />
      )}
    </AuthDataContext.StreamConsumer>
  </Container>
);

const INITIAL_STATE = {
  email: '',
  error: null,
  message: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if(this.props.profile.currentUser){
      this.setState({ email: this.props.profile.currentUser.email });
    }
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.profile
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  updateEmail = event => {
    const { email } = this.state;
    
    this.props.profile.currentUser.updateEmail(email)
      .then(() => { this.setState({ message: 'Email Updated Successfully!' });})
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  resetPassword = event => {
    this.props.profile.doSendPasswordReset(this.state.email)
      .then(() => { this.setState({ message: 'Check your email for password reset link.' });})
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { message, error } = this.state;

    return (
      <Form className="sign-up-form" onSubmit={this.onSubmit}>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Row>
            <Col mx={2}>
              <Form.Control
                name="email"
                type="email"
                onChange={this.onChange}
                value={this.state.email || ''} />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            { this.props.showUpdateEmail && 
                <Col className="d-flex">
                  <Button onClick={this.updateEmail} className="btn-solid-purple mx-auto">
                    Update My Email
                  </Button>
                </Col> 
            }
            <Col className="d-flex">
              <Button onClick={this.resetPassword} className="btn-solid-purple mx-auto">
                    Reset My Password
              </Button>
            </Col>
          </Row>
        </Form.Group>
        <div className="info-message">{message}</div>
        {error && <p className="error">{error.message}</p>}
      </Form>
    );
  }
}

export default PasswordForgetModal;
