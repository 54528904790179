import React, { useState, useEffect } from 'react';
import './AdminDailyQuestion.scss';
import { fadeInUp } from 'react-animations';
// import Notiflix from 'notiflix';
import styled, { keyframes } from 'styled-components';
import { Button, Spinner } from 'react-bootstrap';
import { Select } from 'antd';
import Notiflix from 'notiflix';

const FadeUp = styled.div`animation: 0.2s ${keyframes`${fadeInUp}`}`;

const AdminQuestionPage = (props) => {

  const [, setNewQuestion] = useState()
  const [groupList, setGroupList] = useState([]);
  const [selectedGroupID, setSelectedGroupID] = useState("allGroups");
  // const [previousQuestions, setPreviousQuestions] = useState([])

  useEffect(() => {
    console.log("profile", props.profile);
    const getGroupList = async () => {
      const list = await props.profile.getGroupsByCompany(props.profile.data.company.id);
      setGroupList(list);
    }
    setNewQuestion(props.profile.data.profiles.default.sampleQuestion);
    getGroupList();
  }, [props.profile.data.profiles.default.sampleQuestion])

  const handleSubmit = (event) => {
    // console.log("groupID", selectedGroupID);
    event.preventDefault();
    if (selectedGroupID === null) {
      Notiflix.Notify.failure("You didn't select group, please select group.");
    } else {
      // const time = Date.now();
      const formData = new FormData(event.currentTarget);
      const question = formData.get("question");
      setNewQuestion(question);
      props.profile.data.profiles.default.sampleQuestion = question;
      props.profile.data.profiles.default.sampleAnswer = '';
      props.profile.save()

      event.currentTarget.reset();
      props.handleQuestion(question, selectedGroupID);
    }
  }

  const generateGroupDropdown = (groupList) => {
    const dropDownList = groupList.map((group) => {
      return {
        value: group.groupID,
        label: group.groupName,
      };
    });
    return [{ value: "allGroups", label: "all groups" }, ...dropDownList];
  };

  return (

    <>
      <FadeUp className="my-auto">
        <form
          style={{ padding: "20px" }}
          onSubmit={handleSubmit}
        >
          <div>
            <label>Daily Question</label>
          </div>
          <input name="question"
            className='question-input'
            placeholder='Enter question here'
            required
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Select className='questionSelectMenu'
              allowClear={true}
              showSearch
              // bordered={false}
              style={{ minWidth: 150, textAlign: "left", marginRight: "10px" }}
              placeholder="Select a group"
              optionFilterProp="children"
              defaultValue="allGroups"
              onChange={(value, option) => {
                // console.log("option", option);
                if (value === undefined) {
                  setSelectedGroupID(null);
                } else {
                  setSelectedGroupID(value);
                }
              }}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={generateGroupDropdown(groupList)}
            />
            {props.isQuestionLoading ?
              <Button
                style={{ display: "flex", alignItems: "center" }}
                disabled
                variant="primary"
                type='submit'
                className='btn-solid-purple sign-up-btn sign-up-btn-loader'>
                <Spinner
                  as="span"
                  className="spinner-border-2"
                  animation="border"
                  variant="secondary"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="load-text">Add Question</span>
              </Button>
              :
              <Button
                variant="primary"
                type='submit'
                className='btn-solid-purple sign-up-btn'>
                Add Question
              </Button>
            }
          </div>
          {/* <button className="button btn-solid-purple createBtnBottom" type="submit" >Add Question</button> */}

        </form>


      </FadeUp>
    </>
  );
}


export default AdminQuestionPage