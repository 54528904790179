import React, { Component } from 'react';
import * as ROUTES from '../../../constants/routes';
import { Redirect } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import SignUpModal from '../../SignUp';
import ProfileController from '../ProfileController';
import AppTemplates from '../../../constants/apptemplates';
import Notiflix from 'notiflix';
import AppConfig from '../../../components/App/AppConfig';

const INITIAL_STATE = {
  send: null,
  redirectRoute: null, 
};
class ProfileFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };
  }

  static defaultProps = {
    user: '',
    review: false,
    send: true
  }
  componentDidMount(){
    this.setState({ send: this.props.profile.getDefaultProfile().isPublic });
  }
  handleSendCheckbox = event => {
    event.persist();
    this.setState(prevState => ({ 
      send: !prevState.send
    }));
    this.props.profile.getDefaultProfile().isPublic = event.target.checked;
    this.props.profile.save();
  }

  calculateContentCompleteness = () => {
    const template = AppTemplates.default;
    let numComplete = 0;
    const numNeeded = template.softSkills.length;
    const profile = this.props.profile.getDefaultProfile();

    for(let i = 0; i < template.softSkills.length; i++){
      const skillId = template.softSkills[i];
      if(profile.softSkillsAnswers && skillId in profile.softSkillsAnswers && profile.softSkillsAnswers[skillId].answer !== undefined){
        numComplete++;
      }
    }

    return { 
      complete: numComplete === numNeeded,
      numComplete, 
      numNeeded
    };
  }

  calculateInfoCompleteness = () => {
    const template = AppTemplates.default;
    let numComplete = 0;
    let numNeeded = 1;

    if(AppConfig.isJobsMode()){
      if(template.jobs.resume.require){
        numNeeded++;
      }
    }

    const profile = this.props.profile.getDefaultProfile();

    if(profile.fullName && profile.fullName !== ''){
      numComplete++;
    }
    
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    // if (profile.myBirthday && profile.myBirthday !== "" && profile.myBirthday.match(regEx) != null){
    //   numComplete++;
    // }


    if(AppConfig.isJobsMode()){
      if(template.jobs.resume.require && profile.resumeURL){
        numComplete++;
      }
    }

    return { 
      complete: numComplete === numNeeded,
      numComplete, 
      numNeeded
    };
  }


  render(){
    const contentCompleteness = this.calculateContentCompleteness();
    const infoCompleteness = this.calculateInfoCompleteness();
    const that = this;
    // console.log("Jobs: ", AppConfig.isJobsMode());
    // console.log("Complete: ", infoCompleteness.complete);
    // console.log("Testing Complete: ", (infoCompleteness.complete === false) && (AppConfig.isJobsMode()===false));
    if(!contentCompleteness.complete){
      Notiflix.Report.warning(
        'You&apos;re not quite done!',
        `${contentCompleteness.numComplete} / ${contentCompleteness.numNeeded} Skills Completed`,
        'Take me there',
        () => {
          that.setState({ redirectRoute: ROUTES.MY_CONTENT });
        }
      );
    }
    else if(!infoCompleteness.complete){
      Notiflix.Report.warning(
        'You&apos;re not quite done!',
        'More profile info needed',
        'Take me there',
        () => {
          that.setState({ redirectRoute: ROUTES.MY_INFO });
        }
      );
    }
    const { user, review } = this.props;
    if(this.state.redirectRoute !== null){
      return <Redirect to={this.state.redirectRoute}/>;
    }
    return (
      <div>
        {(user && review && AppConfig.isJobsMode()) && (
          <div className="footer-container">
            <div className="footer-container-text" id="public">
              {/* <p>You only have one Keeper profile! All changes you 
                  make will also appear on previous applications</p> */}
              {/* <div className='checkbox-container'>
                  <input 
                    className='check' 
                    type='checkbox' 
                    name='Checkbox' 
                    id='check_2' 
                    ref='send_checkbox'
                    checked={this.state.send} 
                    onChange={this.handleSendCheckbox}/>
                  <label htmlFor='check_2'>
                    Make my Keeper profile visible to participating companies
                  </label>
                </div> */}
            </div>
            <ProfileController profile={this.props.profile} parentLoading={this.props.parentLoading} updateLoading = {this.props.updateLoading}/>                  
          </div>
        )}
        {user && !review && (
          <div className="field editButtonView">
            <div className="">
              <button
                rounded={'true'}
                className="btn-outline-purple"
                onClick={this.handleEdit}
              >
                  EDIT
              </button>
            </div>
          </div>
        )}
        <Modal 
          show={this.state.showSignUp} 
          onHide={this.handleCloseSignUp}
          keyboard>
          <Modal.Header closeButton />
          <Modal.Body>
            <SignUpModal
              hide={this.handleCloseSignUp}
              onSignUpSuccess={this.handleSignUpComplete} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}


export default ProfileFooter;
