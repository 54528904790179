/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Header from './Header';
import DesktopFooter from './Footer/Desktop';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import GDPR from '../GDPR';


const INITIAL_STATE = {
  showGDPR: true
};

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  static defaultProps = {
    dark: false,
    fof: false,
    showAbbreviatedSignIn: false,
  };

  // logData = (category, data, type, extra) => {
  //   const logData = f.functions().httpsCallable('logData');
  //   logData({category: category, data: data, type: type, extra: extra});
  // }

  currentPage = () => {
    // let currentPage = window.location.pathname;
    // this.logData(currentPage, `user has landed on ${currentPage} page`, "currentpage", []);
  }

  componentDidMount = () => {
    this.currentPage();
  }

  handleOnClick = event => {
    if(event.target.classList.contains('shouldTriggerSignUpModal')){
      document.getElementById('navbar-sign-up-button').click();
    }
  }
  
  render() {
    const mergedProps = { ...this.state, ...this.props };
    const { footerOnly } = this.props

    return (
      <AuthDataContext.StreamConsumer>
        {profile => (
          <div className={this.props.dark ? ('body dark'):('body')} onClick={this.handleOnClick}>
            {!footerOnly? <Header fof={this.props.fof} 
              data={this.props.data}
              setSearchResults={this.props.setSearchResults}
              loading = {this.props.loading}
              filterBy = {this.props.filterBy}
              placeholder = {this.props.placeholder}
              showAbbreviatedSignIn={this.props.showAbbreviatedSignIn} 
              logo={this.props.logo}
              logoLink={this.props.logoLink}
            /> : null}
            <main>
              {this.props.children}
              {mergedProps.showGDPR && <GDPR profile={profile} />}
            </main>
            {!this.props.nofooter && <DesktopFooter />}
          </div>
        )}
      </AuthDataContext.StreamConsumer>
    );
  }
}

export default Layout;
