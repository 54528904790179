import f from 'firebase';

// post profile data to prepare insights 
export function postProfile(profile){
  console.log(profile);
  return fetch('https://keeperinsightsdemo.uc.r.appspot.com/profiles/fastprofilefloat', {
    method: 'POST',
    
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(profile),
  })
  .then(res => res.json());
}

// get Insight function 
export  function getInsights(id){
  return fetch(`https://keeperinsightsdemo.uc.r.appspot.com/profiles/fastprofile/${id}`)
    .then(response => response.json())
    .then(json => json.insights);
}


// get Insight function 
export  function getInsightsFromID(id){
  return fetch(`https://keeperinsightsdemo.uc.r.appspot.com/profiles/getprofile/${id}`)
    .then(response => response.json())
    // .then(json => json.insights);
}

// update YRCI profile 
export  function updateYRCIProfile(data){
  return fetch(`https://keeperinsightsdemo.uc.r.appspot.com/profiles/updateyrci`,{
    method: 'POST', 
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    // .then(json => json.insights);
}



export function getBirthdayMonth(date){
   
  const monthNames = ['January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September', 'October',
    'November', 'December'];
  const today = new Date(date);
  const month = today.getUTCMonth();
  const monthNow = monthNames[month];
    
  return  monthNow;
}
export function getMonthName(number){
  const monthNames = ['January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September', 'October',
    'November', 'December'];
    const month = monthNames[number];
    return month
    
}

export function getJoiningYears(date){
   
  var today = new Date();
    var birthDate = new Date(date);
   
    var years = today.getFullYear() - birthDate.getFullYear();
    if(today.getTime() >= birthDate.getTime()){
        var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			years--;
		}	
    }else {
        years = -1;
    }
    let joinedText = "--";
	if(years === 0){
		joinedText = "less than a year";
	}else if (years === 1) {
    joinedText =  years+ " year";
  }
  else if (years !== -1) {
		joinedText = + years+ " years";
	}
  return  joinedText;
}

export function getToday(day){
  
  const today = new Date(day);
  return today.getUTCDate();
}
export function getTimeStamp(date){
  const timestamp = new Date(date);
 
  return timestamp.toUTCString();
}

export const getPostedTime = (creationDate) => {        
  const serverTime = f.firestore.Timestamp.now();
  const difference = serverTime.seconds - creationDate.seconds;
  const min = parseInt(difference / 60);
  if (min < 60) {
      return min === 0 ? "< 1m" : `${min}m`;
  }
  const hour = parseInt(min / 60);
  if (hour < 24) {
      return `${hour}h`;
  }
  const day = parseInt(hour / 24);
  if (day < 6) {
      return `${day}d`;
  }
  return new Date(creationDate.seconds * 1000).toDateString()
}