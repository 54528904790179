/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import f from 'firebase';
import _template from '../../constants/apptemplates';
import {
  Spinner,
  Row,
  Col,
  Container,
} from 'react-bootstrap';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import Profile from '../Gallery/profile';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';



const INITIAL_STATE = {
  managerProfile: [],
  teamProfiles: [],
  skills: [],
  picture: '',
  complete: false,
  loading: false,
  managerId: '',
  teamId: '',
  managingTeamId: '',
  teamProfilesAsManager: [],
  managerProfileAsManager: [],
  managerIdAsManager: '',
  showTeamGameButton: false,
  showTeamGameButtonAsManager: false,
};

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  async componentDidMount() {
    const teamId = this.props.profile.data.profiles.default.teamId;
    if(teamId && teamId !== 'exempt') {
      await this.getProfiles(teamId, false);
    }
    const managingTeamId = this.props.profile.data.profiles.default
      .managingTeamId;
    if(managingTeamId) {
      await this.getProfiles(managingTeamId, true);
    }
  }

  getProfiles = async(teamId, manager) => {
    const getTeamById = f.functions().httpsCallable('getTeamById');
    const skills = this.getSkills('default');
    this.setState({
      loading: true,
    });
    const res = await getTeamById({ teamId });
    // console.log("res", res.data);
    // console.log("members", res.data.members)
    // console.log("membersList", res.data.membersList)
    const showTeamGameButton = res.data.showGame;
    const arr1 = Object.keys(res.data.members);
    const arr2 = res.data.membersList;
    const managerId = arr1.filter((x) => !arr2.includes(x));
    // let managerProfile = res.data.members[managerId];
    const managerProfile = await this.props.profile.getUserProfile(
      managerId[0],
    );
    const teamPros = res.data.members;
    delete teamPros[managerId];

    const teamProfiles = [];
    for(let i = 0; i < Object.keys(teamPros).length; i++) {
      const response = await this.props.profile.getUserProfile(
        Object.keys(teamPros)[i],
      );
      teamProfiles.push(response);
    }
    if(manager === false) {
      this.setState({
        managerId,
        skills,
        managerProfile,
        teamProfiles,
        complete: true,
        loading: false,
        teamId,
        showTeamGameButton,
      });
    } else {
      this.setState({
        managerIdAsManager: managerId,
        skills,
        managerProfileAsManager: managerProfile,
        teamProfilesAsManager: teamProfiles,
        complete: true,
        loading: false,
        managingTeamId: teamId,
        showTeamGameButtonAsManager: showTeamGameButton,
      });
    }
  };

  getSkills = (templateName) => {
    const template = _template[templateName];
    return template.softSkills;
  };

  teamRender = (props) => {
    const { skills } = this.state;
    return (
      <>
        {/* <FadeUp> */}
        {props.showTeamGameButton === true && (
          <div className="text-center">
            <Link to={ROUTES.TEAMGAMEid + props.teamId}>
              <button
                className="btn-solid-purple ViewProfileBtn"
                type="submit"
              >
                Play Team Game
              </button>
            </Link>
          </div>
        )}
        <Container>
          <div className="cards">
            <Row className="justify-center">
              <Col md={6}>
                <div className="singleCard">
                  <div className="cards-container">
                    {/* <h2 className='text-center'>Team Manager</h2> */}
                    <Profile
                      profile={props.managerProfile}
                      skills={skills}
                      manager={true}
                      current={
                        props.managerProfile.email ===
                        this.props.profile.data.profiles.default.email
                      }
                    />
                    {/* {console.log(props.managerProfile.email)}
                        {console.log(this.props.profile.data.profiles.default.email)} */}
                  </div>
                </div>
              </Col>
            </Row>
            <h2 className="text-center teamMembers">Team Members:</h2>
            <Row>
              {props.mode.length !== 0 &&
                Object.keys(props.mode).map((profiles) => {
                  return (
                    <Col md={6}>
                      <div className="singleCard">
                        <div className="cards-container">
                          <Profile
                            profile={props.mode[profiles]}
                            skills={skills}
                            current={
                              props.mode[profiles].email ===
                              this.props.profile.data.profiles.default
                                .email
                            }
                          />
                          {/* {console.log(props.mode[profiles].email)}
                          {console.log(this.props.profile.data.profiles.default.email)} */}
                        </div>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {/* {console.log("props.mode",props.mode)} */}
            {props.mode.length === 0 && (
              <div className="text-center noTeamMembers">
                You have no team members yet
              </div>
            )}
          </div>
        </Container>
        {/* </FadeUp> */}
      </>
    );
  };

  render() {
    const {
      managerProfile,
      teamProfiles,
      loading,
      managingTeamId,
      teamId,
      teamProfilesAsManager,
      managerProfileAsManager,
      showTeamGameButton,
      showTeamGameButtonAsManager,
    } = this.state;
    if(loading) {
      return (
        <div className="spinner-container d-flex justify-content-center">
          <Spinner
            className="d-flex justify-content-center"
            animation="border"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    }

    let content = <></>;

    if(managingTeamId !== '' && teamId !== '') {
      content = (
        <div className="text-center">
          <Tabs>
            <TabList>
              <Tab>As Team Member</Tab>
              <Tab>As Team Manager</Tab>
            </TabList>

            <TabPanel>
              <this.teamRender
                managerProfile={managerProfile}
                mode={teamProfiles}
                teamId={teamId}
                showTeamGameButton={showTeamGameButton}
              />
            </TabPanel>
            <TabPanel>
              <this.teamRender
                managerProfile={managerProfileAsManager}
                mode={teamProfilesAsManager}
                teamId={managingTeamId}
                showTeamGameButton={showTeamGameButtonAsManager}
              />
            </TabPanel>
          </Tabs>
        </div>
      );
    } else if(managingTeamId !== '') {
      content = (
        <this.teamRender
          managerProfile={managerProfileAsManager}
          mode={teamProfilesAsManager}
          teamId={managingTeamId}
          showTeamGameButton={showTeamGameButtonAsManager}
        />
      );
    } else if(teamId !== '') {
      content = (
        <this.teamRender
          managerProfile={managerProfile}
          mode={teamProfiles}
          teamId={teamId}
          showTeamGameButton={showTeamGameButton}
        />
      );
    } else {
      content = (
        <div className="text-center noTeamMembers">
          You aren't a part of a team yet, please fill out your
          Teams Leader's information on the MyInfo Page.
        </div>
      );
    }

    return content;
  }
}

export default Team;
