import React, { Component } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import Notiflix from 'notiflix';
import { images } from '../../assets';
import FontAwesome from 'react-fontawesome';
import './CompanyBusinessValue.scss';

class CompanyBusinessValue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title1: props.info.title1,
            content1: props.info.content1,
            title2: props.info.title2,
            content2: props.info.content2,
            title3: props.info.title3,
            content3: props.info.content3,
            title4: props.info.title4,
            content4: props.info.content4,
            saving: false,
        };
    };

    onChangeTitle1 = (event) => {
        this.setState({
            title1: event.target.value
        });
    }

    onChangeTitle2 = (event) => {
        this.setState({
            title2: event.target.value
        });
    }

    onChangeTitle3 = (event) => {
        this.setState({
            title3: event.target.value
        });
    }

    onChangeTitle4 = (event) => {
        this.setState({
            title4: event.target.value
        });
    }

    onChangeContent1 = (event) => {
        this.setState({
            content1: event.target.value
        });
    }

    onChangeContent2 = (event) => {
        this.setState({
            content2: event.target.value
        });
    }

    onChangeContent3 = (event) => {
        this.setState({
            content3: event.target.value
        });
    }

    onChangeContent4 = (event) => {
        this.setState({
            content4: event.target.value
        });
    }

    handleUpdateCompanyBusinessValue = async (event) => {
        event.preventDefault();
        if (this.state.saving) return;
        this.setState({
            saving: true
        });
        try {
            await this.props.profile.saveCompanyBusinessValue(
                this.props.companyId,
                {
                    title1: this.state.title1,
                    content1: this.state.content1,
                    title2: this.state.title2,
                    content2: this.state.content2,
                    title3: this.state.title3,
                    content3: this.state.content3,
                    title4: this.state.title4,
                    content4: this.state.content4,
                }
            );
        } catch (error) {
            console.log(`Error in handleUpdateCompanyBusinessValue ${error}`);
        } finally {
            this.setState({
                saving: false
            });
        }
    }

    render() {

        return (
            <Form
                style={{ width: "100%" }}
                onSubmit={this.handleUpdateCompanyBusinessValue}
            >
                <div className='companylogo-upload-container'>
                    <div className='company-details-businessvalue'>
                        <Form.Label>title 1</Form.Label>
                        <Form.Control
                            name='companyName'
                            value={this.state.title1}
                            onChange={this.onChangeTitle1}
                            type='text'
                            required
                        />
                        <Form.Label>content 1</Form.Label>
                        <Form.Control
                            name='companyName'
                            value={this.state.content1}
                            onChange={this.onChangeContent1}
                            type='text'
                            required
                        />
                        <Form.Label>title 2</Form.Label>
                        <Form.Control
                            name='companyName'
                            value={this.state.title2}
                            onChange={this.onChangeTitle2}
                            type='text'
                            required
                        />
                        <Form.Label>content 2</Form.Label>
                        <Form.Control
                            name='companyName'
                            value={this.state.content2}
                            onChange={this.onChangeContent2}
                            type='text'
                            required
                        />
                        <Form.Label>title 3</Form.Label>
                        <Form.Control
                            name='companyName'
                            value={this.state.title3}
                            onChange={this.onChangeTitle3}
                            type='text'
                            required
                        />
                        <Form.Label>content 3</Form.Label>
                        <Form.Control
                            name='companyName'
                            value={this.state.content3}
                            onChange={this.onChangeContent3}
                            type='text'
                            required
                        />
                        <Form.Label>title 4</Form.Label>
                        <Form.Control
                            name='companyName'
                            value={this.state.title4}
                            onChange={this.onChangeTitle4}
                            type='text'
                            required
                        />
                        <Form.Label>content 4</Form.Label>
                        <Form.Control
                            name='companyName'
                            value={this.state.content4}
                            onChange={this.onChangeContent4}
                            type='text'
                            required
                        />
                    </div>
                </div>
                <div className="company-save-button">
                    <Button variant="info" className="button btn-solid-purple createBtnBottom" type="submit">
                        {this.state.saving && <Spinner animation="border" role="status" />}
                        <div className="but-text">Save</div>
                    </Button>
                </div>
            </Form>
        )
    }
};

export default CompanyBusinessValue;