/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { compose } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import styled, { keyframes } from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { fadeInUp } from 'react-animations';

import * as ROUTES from '../../constants/routes';
import Layout from '../../components/Layout';
import AppConfig from '../../components/App/AppConfig';
import { withAuthorization } from '../../components/Session';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import InsightsSideBar from '../Insights/InsightsSideBar';
import { RANDOM_PROFILE_PLACEHOLDER } from '../../constants/utilConstant';

import './CompanyPage.scss';
import SearchBarOfCompanyPage from '../../components/SearchBarOfCompanyPage/SearchBarOfCompanyPage';

const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;
const LOADING_LIMIT = 12;

const LoadingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
`;

class CompanyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyMembersList: [],
      randomProfileImageURL: RANDOM_PROFILE_PLACEHOLDER,
      hasMore: true,
      lastDoc: 0,
      searchWord: '',
      searchCompanyMembersList: []
    };

    this.getCompanyMembersList = this.getCompanyMembersList.bind(this);
    this.handleprofileSelect = this.handleprofileSelect.bind(this);
    this.loadMembers = this.loadMembers.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  loadMembers = () => {
    this.getCompanyMembersList();
    // const newSearch = this.state.companyMembersList.filter((value) => {
    //   return value.data.profiles.default.fullName.toLowerCase().includes(this.state.searchWord.toLowerCase());
    // });
    // this.setState({
    //   searchCompanyMembersList: newSearch
    // })
  }

  getCompanyMembersList = async () => {
    // const respon = await props.profile.getAllCompanyMembers(props.profile.data.id)
    // if (this.props.profile?.data){
    //   if (this.props.profile.data?.company?.id) {
    //     const res = await this.props.profile.getActiveUsersByCompany(this.props.profile.data?.company?.id)
    //     if (res){
    //       this.setState({
    //         companyMembersList: res,
    //         loading: false
    //       })          
    //     }
    //   }
    // }
    if (this.props.profile?.data) {
      if (this.props.profile.data?.company?.id) {
        const res = await this.props.profile.getActiveUsersByCompany(this.props.profile.data?.company?.id, this.state.lastDoc, LOADING_LIMIT);
        //console.log('res', res);
        if (res && res.length) {
          const newSearch = res.filter((value) => {
              return value.data.profiles.default.fullName.toLowerCase().includes(this.state.searchWord.toLowerCase());
          });
          //console.log('newSearch', newSearch);
          this.setState({
            companyMembersList: [...this.state.companyMembersList, ...res],
            searchCompanyMembersList: [...this.state.searchCompanyMembersList, ...newSearch],
            lastDoc: res[res.length - 1].data.creationDate
          });
          if (res.length < LOADING_LIMIT) {
            this.setState({
              hasMore: false
            });
          }
        } else {
          this.setState({
            hasMore: false,
          });
        }
      }
    }

  }

  setSearchWord = (word) => {
    this.setState({
      searchWord: word,
      lastDoc: 0,
      hasMore: true,
      companyMembersList: [],
      searchCompanyMembersList: []
    });
  }

  handleprofileSelect = async (profileId) => {
    //console.log('profileId 123:: ', profileId);
    this.props.history.push(ROUTES.INSIGHTS + "/" + profileId)
  }


  render() {

    const { searchCompanyMembersList } = this.state
    // console.log('searchword', this.state.searchWord);
    // console.log('companyMembersList', this.state.companyMembersList);
    // console.log('searchCompanyMembersList', this.state.searchCompanyMembersList);
    // console.log('hasMore', this.state.hasMore);

    // if(this.state.loading) return ( 
    //   <div className='spinner-container d-flex justify-content-center'>
    //     <Spinner animation='border' role='status'>
    //       <span className='sr-only'>Loading...</span>
    //     </Spinner>
    // </div>)


    return (
      <>

        <div className="insightsPage">
          <Layout placeholder={'Enter a Username'} setSearchResults={this.setSearchWord}>
            <div className="insightsBox">

              <AuthDataContext.StreamConsumer requireAccount={true}>
                {profile => (
                  <>
                    <InsightsSideBar
                      image={profile.data.profiles.default.profilePicture}
                      name={profile.data.profiles.default.fullName}
                      profile={profile}
                    />
                    <div className="wrapper">
                    <FadeUp className="my-auto">
                      <div className="company">
                        <div className="title">
                          <span><b>Your Community</b></span>
                        </div>
                        
                        <section>
                          <div className="row m-0 ">
                            <div className="col-md-6 pt-3 pb-3" >
                              <button className="button btn-solid-purple createBtnBottom"
                              onClick={()=>this.props.history.push(ROUTES.VIBEPAGE)}
                            >
                              COMMUNITY GRATITUDE
                            </button>
                          </div>
                          {/* <SearchBarOfCompanyPage placeholder={'Enter a Username.'} setOuterSearchWord={this.setSearchWord}/> */}
                          {(profile.data.company.name !== "open") &&
                          <div className="col-md-6 pt-3 pb-3 btn-right-corner-innovation" >
                            <button className="button btn-solid-purple createBtnBottom"
                              onClick={()=>this.props.history.push(ROUTES.INNOVATION_PAGE)}
                            >
                              COLLABORATIVE IDEAS
                            </button>
                            </div>}
                          </div> 
                          {/* <h3>this is where company folks will go</h3> */}
                          <InfiniteScroll
                              className="companyPageProfileGrid"
                              pageStart={0}
                              hasMore={this.state.hasMore}
                              loadMore={this.loadMembers}
                              loader={
                                <LoadingBox key="loader">
                                  <Spinner animation='border' role='status'>
                                    <span className='sr-only'>Loading...</span>
                                  </Spinner>
                                </LoadingBox>
                              }
                            >
                              {(!this.state.hasMore && searchCompanyMembersList.length === 0 
                                ? <div><span>No Team Member Found</span></div> 
                                : (searchCompanyMembersList 
                                  ? searchCompanyMembersList.map((profil, i) => {
                                    let profilePicture;
                                      if (profil?.data?.profiles?.default?.profilePicture==="https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"||profil?.data?.profiles?.default?.profilePicture==="/images/default-avatar.svg"){
                                        profilePicture = '/assets/media/profile/default-avatar.jpeg'; 
                                      }else{
                                        profilePicture = profil?.data?.profiles?.default?.profilePicture;
                                      }

                                      if(!profil?.data?.profiles?.default?.profilePicture) profilePicture = '/assets/media/profile/default-avatar.jpeg'; 
                                      
                                      const fullName = profil?.data?.profiles?.default?.fullName;
                                      const myRole = profil?.data?.profiles?.default?.myRole;
                                      const color = profil?.data?.profiles?.default?.insights?.color;
                                      
                                      return (
      
                                        <div key={i} className="profileBlock" onClick={() => this.handleprofileSelect(profil?.id)}>
                                          <div className="imageBlock">
                                            <img  src={profilePicture} 
                                                  alt="profile_image" 
                                                  style={{ backgroundColor: color, padding: "5px" }}
                                            />
                                          </div>
                                          <div className="textBlock">
                                            <div className="profileName"><span>{fullName}</span></div>
                                            <div className="profileRole"><span>Role: {myRole}</span></div>
                                          </div>
                                        </div>
                                      )
                                    }) 
                                  : <div><span>No Team Member Found</span></div>)
                                )
                              }
                            </InfiniteScroll>
                          </section>
                        </div>
                      </FadeUp>
                    </div>
                  </>
                )}
              </AuthDataContext.StreamConsumer>
            </div>
          </Layout>
        </div>
      </>
    );
  }
}




const condition = (profile) => {
  if (AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(
  withAuthorization(condition),
)(CompanyPage);





