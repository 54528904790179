import React, {Component} from 'react';
import {  
  Jumbotron,
  Container,
  Spinner 
} from 'react-bootstrap';
import Layout from '../../components/Layout';
import Dashboard from '../../components/Gallery/dashboard';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import f from 'firebase';
import * as ROUTES from  '../../constants/routes';
import { Redirect } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { fadeIn, fadeInDown } from 'react-animations';

const FadeDown = styled.div`animation: 1.2s ${keyframes`${fadeInDown}`}`;
const FadeIn = styled.div`animation: 2s ${keyframes`${fadeIn}`}`;


export default class GalleryDashboard  extends Component {
  constructor(props){
    super(props);
    this.state = { admin: 'pending' };
  }
  componentDidMount = async() => {
    const isAdmin = f.functions().httpsCallable('checkRole');
    const res = await isAdmin({ role: 'admin' });
    if(res.data === true){
      this.setState({ admin: 'true' });
    } else {
      this.setState({ admin: 'false' });
    }
  }
  render(){
    const { admin } = this.state;
    if(admin === 'pending'){
      return (
        <Layout>
          <div className="spinner-container d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Layout>
      );
    } else if(admin === 'false'){
      return <Redirect to={ROUTES.FOF}/>;
    } else {
      return (
        <Layout>
          <FadeDown>
            <Jumbotron className="text-center">
              <FadeIn><h1 className="heading"> View All Galleries </h1></FadeIn>
            </Jumbotron>
            <Container className="text-center">
              <AuthDataContext.StreamConsumer>
                {profile => (
                  <Dashboard profile={profile}/>
                )}
              </AuthDataContext.StreamConsumer>
            </Container>
          </FadeDown>
        </Layout>
      );
    }
  }
}
