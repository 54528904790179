/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { Component } from 'react';
import '../Footer.scss';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import { Row, Col } from 'react-bootstrap';
import AppConfig from '../../../App/AppConfig';

const INITIAL_STATE = {

};

class DesktopFooter extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  render() {
    return (
      <footer>
        <div className="footer-content">
          <Row>
            <Col className="footer-left">
              <a
                href="https://www.keeperai.com"
                target="_blank"
                title="KeeperAI.com"
                rel="noopener noreferrer">
                {AppConfig.getCodeSnippetOption('footerLogo')}
              </a>
              {(AppConfig.isJobsMode() && 
              <p><span className="show-text">SHOW</span> THEM WHO YOU ARE</p>)}
              <div>
                &#169; 2020 KeeperAI
                <p>
                  <Link to={ROUTES.TERMS} target="_blank" className="footer-left-link">
                    Terms of Service
                  </Link> | <a href="https://keeperai.com/privacy-policy/" target="_blank" className="footer-left-link">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </Col>
            <Col className="footer-links">
              <Col className="footer-text-links">
                <a 
                  href="https://www.keeperai.com" 
                  className="footer-router-link" 
                  target="_blank" 
                  rel="noopener noreferrer">
                    ABOUT KEEPER
                </a>
              </Col>
              <Col className="footer-text-links">
                <a 
                  className="footer-router-link" 
                  href="mailto:support@keeperai.com">
                    CONTACT US
                </a>
              </Col>
              <a
                href="https://www.keeperai.com"
                className="footer-link"
                target="_blank"
                title="KeeperAI.com"
                rel="noopener noreferrer">
                <FontAwesome name="globe" />
              </a>
              <a
                href="https://www.facebook.com/keeperaiai/"
                className="footer-link"
                target="_blank"
                title="Keeper on Facebook"
                rel="noopener noreferrer">
                <FontAwesome name="facebook-square" />
              </a>
              <a
                href="https://www.instagram.com/keeperai/"
                className="footer-link"
                target="_blank"
                title="Keeper on Instagram"
                rel="noopener noreferrer">
                <FontAwesome name="instagram" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCvMWMLOUfMR6-IhBSNHxHOw"
                className="footer-link"
                target="_blank"
                title="Keeper on YouTube"
                rel="noopener noreferrer">
                <FontAwesome name="youtube-square" />
              </a>
              <a
                href="https://twitter.com/keeperai/"
                className="footer-link"
                target="_blank"
                title="Keeper on Twitter"
                rel="noopener noreferrer">
                <FontAwesome name="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/keeperai/"
                className="footer-link"
                target="_blank"
                title="Keeper on LinkedIn"
                rel="noopener noreferrer">
                <FontAwesome name="linkedin-square" />
              </a>
            </Col>
          </Row>
        </div>
      </footer>
    );
  }
}

export default DesktopFooter;
