/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Layout from '../../components/Layout';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
// import * as ROUTES from '../../constants/routes';
// import { Link} from 'react-router-dom';
import Panel from '../../components/Admin/panel';
import { Container, Spinner } from 'react-bootstrap';
import ManageHashtags from '../../components/Hashtags/manage';
import f from 'firebase';
import * as ROUTES from '../../constants/routes';
import { Redirect } from 'react-router-dom';

class AdminWrapper extends Component {
  render() {
    return (
      <Layout>
        <Container>
          <div className="MyContent">
            <AuthDataContext.StreamConsumer requireAccount={true}>
              {(profile) =>
                profile ? (
                  <AdminPanel profile={profile} />
                ) : (
                  <div>
                    <h1>Oops! Something went wrong!</h1>
                  </div>
                )
              }
            </AuthDataContext.StreamConsumer>
          </div>
        </Container>
      </Layout>
    );
  }
}
class AdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: 'processing',
      isCompanyAdmin: false,
    };
  }
  async componentDidMount() {
    const profile = this.props.profile;
    try {
      const listRoles = f.functions().httpsCallable('listRoles');
      const roles = (await listRoles(profile.currentUser.email)).data;
      // console.log(roles)
      if(roles === {}) {
        // console.log('stuff');
        this.setState({ isAdmin: 'false' });
      } else if(roles.admin) {
        // console.log('true')
        this.setState({ isAdmin: 'true' });
      } else if(roles.companyAdmin) {
        // console.log('true')
        this.setState({ isAdmin: 'true', isCompanyAdmin: true });
      } else {
        // console.log('false');
        this.setState({ isAdmin: 'false' });
      }
    } catch(e) {
      this.setState({ isAdmin: 'false' });
    }
  }
  render() {
    const { isAdmin, isCompanyAdmin } = this.state;
    // console.log('isAdmin', isAdmin)
    if(isAdmin === 'processing') {
      return (
        <div className="spinner-container d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    } else if(isAdmin === 'true') {
      return (
        <div>
          {!isCompanyAdmin && <ManageHashtags />}
          <Panel isCompanyAdmin={isCompanyAdmin} />
        </div>
      );
    } else {
      return <Redirect to={ROUTES.FOF} />;
    }
  }
}

export default AdminWrapper;
