/* eslint-disable max-len */
import React, { Component } from 'react';
import { 
  Button, 
  Modal
} from 'react-bootstrap';
import SignUpModal from '../SignUp';

class SignUpLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    return (
      <>
        <Button variant="link" className="p-0 mb-1 ml-1" onClick={this.handleShow}>Sign Up</Button>
        <Modal 
          show={this.state.show} 
          onHide={this.handleClose}
          keyboard>
          <Modal.Header closeButton />
          <Modal.Body>
            <SignUpModal />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default SignUpLink;
