/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import Results from './Results';
import '../../styles/search.scss';
import { Form, Spinner } from 'react-bootstrap';
import f from 'firebase';
import Notiflix from 'notiflix';

const INITIAL_STATE = {
  searchInput: '',
  searching: false,
  searched: false,
  results: [],
  showResults: false,
  resultLength: 0,
  showInviteBox: false,
  showInviteButton: false,
  companyId: null,
};

class Searches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount = async() => {
    // console.log('this.props', this.props);
    if(this.props.version === 'team'){
      const profile = await this.props.profile.getEntireUser(this.props.profile.uid);
      // console.log(profile);
      if(profile && profile.company && profile.company.id){
        // console.log('companyyyyyy');
        this.setState({ companyId: profile.company.id });
      } else {
        Notiflix.Notify.failure('Warning: Please login to search within your company.')
      }
    }
  }

  onChange = (event) => {
    const key = event.target.name;
    const query = event.target.value;
    this.setState({
      [key]: query,
    });
    setTimeout(() => {
      if(query !== this.state[key]) {return;}
      this.searchTerm();
    }, 300);
  };

  searchTerm = () => {
    if(this.state.searchInput === '') {
      this.setState({
        results: [],
        resultLength: 0,
        searching: false,
        searched: false,
        showInviteButton: false,
        showInviteBox: false,
      });
    } else {
      this.setState({
        showInviteBox: false,
      });
      this.searchDB();
    }
  };

  delay = () => {
    this.searchTerm();
  }

  searchDB = async() => {
    this.setState({ searching: true });
    if(this.props.version === 'team'){
      // console.log('team');
      const searchDb = f.functions().httpsCallable('companySearchProfiles');
      const searchRes = await searchDb({ query: this.state.searchInput, company: this.state.companyId });
      if(searchRes !== undefined) {
        this.setState({
          results: searchRes.data.hits,
          resultLength: searchRes.data.hits.length,
          showInviteButton: true,
        });
      }
      // console.log('res', searchRes);
    } else {
      // console.log('job');
      const searchDb = f.functions().httpsCallable('searchProfiles');
      const searchRes = await searchDb({ query: this.state.searchInput });
      if(searchRes !== undefined) {
        this.setState({
          results: searchRes.data.hits,
          resultLength: searchRes.data.hits.length,
          showInviteButton: true,
        });
      }
    }
    this.setState({ searching: false });
  }

  resultMessage = () => {
    const { resultLength } = this.state;

    switch(resultLength) {
      case 0:
        break;
      case 1:
        return 'Found 1 result';
      default:
        return `Found ${resultLength} results`;
    }
  }

  render() {
    const {
      results,
      searching,
    } = this.state;
    return (
      <div className="search">
        <Form.Group>
          <Form.Label>{`Search for ${this.props.version === 'team' ? 'Teammates' : 'Candidates'}`}</Form.Label>
          <div className="input-group mb-2 mr-sm-2">
            <div className="input-group-prepend">
              <div className="input-group-text"><FontAwesome name="search" /></div>
            </div>
            <input className="form-control" id="inlineFormInputGroupUsername2"
              onChange={this.onChange}
              type="text"
              name="searchInput"
              placeholder="Search by Name or Email"
            />
          </div>
        </Form.Group>
        <div className="text-center mb-3">
          {this.resultMessage()}
        </div>
        {searching &&
          <div className="spinner-container d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        }
        {(results.length > 0) && <Results results={results}/>}
        {/* {(showInviteButton===true)&&
          <div className='text-center my-4'>
            <p>Didn't find who you're looking for?</p>
            <button
              className='btn-solid-purple'
              onClick={() => this.setState({showInviteButton: false, showInviteBox: true})}>
                Invite User
            </button>
          </div>
        }
        {(showInviteBox===true)&&<Invite profile={this.props.profile} email={searchInput}/>} */}
      </div>
    );
  }
}

export default Searches;
