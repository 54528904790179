import React, { useEffect, useState } from 'react';
import { Carousel } from "react-bootstrap";
import FontAwesome from 'react-fontawesome';
import "./AffinityGroupChat.scss";

const CustomCarousel = ({images, onDeselectImages, deselect = true, cName = "images-to-post"}) => {
    const [imageIndex, setImageIndex]= useState(0);
    const [imagestoRender, setImagestoRender] = useState([]);

    const handleImageChange = (index) => {
        setImageIndex(index);
    }

    useEffect(() => {
        setImagestoRender(images);
        setImageIndex(0);
    }, [images]);

    const handledeSelectImages = () => {
        setImageIndex(0);
        onDeselectImages();
    }

    if (!imagestoRender.length) return (null);
    return (
        <div className={cName}>
            <Carousel 
                activeIndex={imageIndex}
                onSelect={handleImageChange}
            >
                {imagestoRender.map((url, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <img src={url} alt=""/>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
            {deselect && <div className="deselect-image" onClick={handledeSelectImages}>
                <FontAwesome name='trash'/>
            </div>}
        </div>
    )
}

export default CustomCarousel;