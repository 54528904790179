/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Layout from '../../components/Layout';
import Controllers from '../../components/Controllers';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import { withAuthorization } from '../../components/Session';
import * as ROUTES from '../../constants/routes';
import Container from 'react-bootstrap/Container';
import SoftSkills from '../../constants/softskills';
import Search from '../../components/CCC/Search';
import styled, { keyframes } from 'styled-components';
import { fadeInDown } from 'react-animations';
import { compose } from 'recompose';
import templates from '../../constants/apptemplates.json';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';
import AppConfig from '../../components/App/AppConfig'
import SearchVibe from '../../components/CCC/SearchVibe'
import SearchInnovation from '../../components/CCC/SearchInnovation'
const Fade = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;

const INITIAL_STATE = {
  title: '',
  sub: '',
  id: '',
  idArray: templates.default.softSkills,
  vibeInnovationArray: templates.default.vibeInnovation,
  redirect: false,
};

class CCC extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  static defaultProps = {
    match: '',
  }

  async componentDidMount(){
    const id = this.props.match.params.id;
    let array = this.state.idArray;
    let arrayTwo=this.state.vibeInnovationArray;
    let render = false;
    for(let i = 0; i < array.length; ++i) {
      if(array[i] === id){
        render = true;
        break; 
      }
    };
    for (let i=0; i<arrayTwo.length; i++){
      if(arrayTwo[i] === id){
        render = true;
        break;
      }
    };
    if (render === true) {
      const question = SoftSkills.softSkills[id];
      const title = question.title;
      const sub = question.sub;
      this.setState({
        title,
        sub,
        id
      });
    } else {
      this.setState({
        redirect: true,
      });
    }
  }

  render(){
    const { title, sub, id, redirect } = this.state;
    if(redirect){
      return <Redirect to={ROUTES.FOF} />;
    }
    if (id ==='vibe'){
    return(
      <Layout>
        <ScrollTopOnPageLoad/>
        <AuthDataContext.StreamConsumer>
          {profile => (
            <Fade>
              <div className="MyContent">
                <Container className="ccc-container">
                  <Controllers 
                    backRoute={id === 'vibe' ? ROUTES.INSIGHTS +"/" + profile.uid : ROUTES.MY_CONTENT}
                    nextRoute={false}
                    profile={profile}
                    className="mt-4" />
                  <div className="instruc">
                    <p className="attributeHeading">{title}</p>
                    <p className="attributeSub">{sub}</p>
                  </div>
                </Container>
                <div className='ccc'>
                <SearchVibe title={title} sub={sub} id={id} profile={profile} />
                </div> 
              </div>
            </Fade>
          )}
        </AuthDataContext.StreamConsumer>
      </Layout>
    );
          }else if (id === 'innovations'){
            return(
              <Layout>
                <ScrollTopOnPageLoad/>
                <AuthDataContext.StreamConsumer>
                  {profile => (
                    <Fade>
                      <div className='MyContent'>
                        <Container className='ccc-container'>
                          <Controllers 
                            backRoute={id === 'innovations' ? ROUTES.INSIGHTS +"/" + profile.uid:ROUTES.MY_CONTENT}
                            nextRoute={false} 
                            profile={profile}
                            className='mt-4' />
                          <div className='instruc'>
                            <p className='attributeHeading'>{title}</p>
                            <p className='attributeSub'>{sub}</p>
                          </div>
                        </Container>
                        <div className='ccc'>
                        <SearchInnovation title={title} sub={sub} id={id} profile={profile}/>
                        </div> 
                      </div>
                    </Fade>
                  )}
                </AuthDataContext.StreamConsumer>
              </Layout>
            );

          }else{
            return(
              <Layout>
                <ScrollTopOnPageLoad/>
                <AuthDataContext.StreamConsumer>
                  {profile => (
                    <Fade>
                      <div className='MyContent'>
                        <Container className='ccc-container'>
                          <Controllers 
                            backRoute={id === 'vibe' ? ROUTES.INSIGHTS +"/" + profile.uid:ROUTES.MY_CONTENT}
                            nextRoute={false} 
                            profile={profile}
                            className='mt-4' />
                          <div className='instruc'>
                            <p className='attributeHeading'>{title}</p>
                            <p className='attributeSub'>{sub}</p>
                          </div>
                        </Container>
                        <div className='ccc'>
                        <Search title={title} sub={sub} id={id} profile={profile}/>
                        </div> 
                      </div>
                    </Fade>
                  )}
                </AuthDataContext.StreamConsumer>
              </Layout>
            );
      }
    }
  }

const condition = (profile) => {
  if(AppConfig.isJobsMode()){
    return profile.currentUser !== null;
  }

  return profile.currentUser !== null && profile.emailVerified === true;
};

export default compose(
  withAuthorization(condition),
)(CCC);
