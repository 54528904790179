/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import { compose } from 'recompose';
import { withAuthorization } from '../../Session';
import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Jumbotron,
  Spinner,
  Modal,
} from 'react-bootstrap';
import Dustbin from '../Dustbin';
import Box from '../Box';
import update from 'immutability-helper';
import FontAwesome from 'react-fontawesome';
import f from 'firebase';
import _template from '../../../constants/apptemplates';
import _softSkills from '../../../constants/softskills.json';
import AppConfig from '../../App/AppConfig';

const INITIAL_STATE = {
  managerProfile: [],
  teamProfiles: [],
  skills: [],
  complete: false,
  loading: true,
  managerId: '',
  teamId: '',
  managingTeamId: '',
  teamProfilesAsManager: [],
  managerProfileAsManager: [],
  managerIdAsManager: '',
  scoreArr: [],
  usedArr: [],
  percentScore: '',
  dustbinArr: [],
  boxesArr: [],
  droppedBoxNames: [],
  setDroppedBoxNames: [],
  pScore: '',
  dustbins: [],
  boxes: [],
  chosenProfiles: [],
  showAttributeModal: false,
  currentAttribute: '',
};

class GameContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  async componentDidMount() {
    // console.log("dustbinArr:", this.state.dustbinArr)
    // console.log("teamId:", this.state.teamId)
    // console.log("managingTeamId:", this.state)
    // this.setState({
    //   INITIAL_STATE
    // })
    const teamId = this.props.teamId;
    const teamIdE = this.props.profile.data.profiles.default.teamId;
    const managingTeamId = this.props.profile.data.profiles.default
      .managingTeamId;

    // if (
    //   this.state.dustbinArr.length === 0
    //   // ||
    //   // teamId !== this.state.teamId
    // ) {
    if(teamId === teamIdE) {
      if(teamIdE && teamId !== 'exempt') {
        await this.getProfiles(teamIdE, false);
      }
    } else if(teamId === managingTeamId) {
      if(managingTeamId) {
        await this.getProfiles(managingTeamId, true);
      }
    }
    // } else {
    //   const { dustbinArr, boxesArr } = this.state;
    //   this.setState({
    //     dustbins: dustbinArr,
    //     dustbinArr,
    //     boxesArr,
    //     boxes: boxesArr,
    //   });
    // }

    // if (this.state.dustbinArr.length === 0) {
    //   this.Users();
    // } else {
    //   const { dustbinArr, boxesArr } = this.state;
    //   this.setState({
    //     dustbins: dustbinArr,
    //     dustbinArr,
    //     boxesArr,
    //     boxes: boxesArr,
    //   });
    // }
    this.setState({
      loading: false,
    });
  }

  // componentDidUpdate(prevProps){
  //   console.log("prevProps:", prevProps.teamId)
  //   console.log("this:", this.props.teamId)
  //   if (prevProps.teamId !== this.props.teamId){
  //     this.Users();
  //   }
  // }

  // componentWillUnmount(){
  //   this.setState({
  //     dustbins: [],
  //     dustbinArr:[],
  //     boxesArr: [],
  //     boxes: [],
  //   })
  // }
  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getProfiles = async(teamId, manager) => {
    const getTeamById = f.functions().httpsCallable('getTeamById');
    const skills = this.getSkills('default');
    const res = await getTeamById({ teamId });
    const { dustbinArr, boxesArr } = this.state;
    const newDustbinArr = [...dustbinArr];
    const newBoxesArr = [...boxesArr];
    // console.log("res", res.data);
    // console.log("members", res.data.members)
    // console.log("membersList", res.data.membersList)
    const arr1 = Object.keys(res.data.members);
    const arr2 = res.data.membersList;
    const managerId = arr1.filter((x) => !arr2.includes(x));
    // let managerProfile = res.data.members[managerId];
    const managerProfile = await this.props.profile.getUserProfile(
      managerId[0],
    );
    const teamPros = res.data.members;
    delete teamPros[this.props.profile.uid];
    // delete teamPros[managerId];

    const teamProfiles = [];
    for(let i = 0; i < Object.keys(teamPros).length; i++) {
      let response = await this.props.profile.getUserProfile(
        Object.keys(teamPros)[i],
      );
      // console.log('test', Object.keys(teamPros)[i])
      response = { ...response, uid: Object.keys(teamPros)[i] };
      teamProfiles.push(response);
    }
    // console.log('Teammates', Teammates);
    // console.log('teamProfiles', teamProfiles);
    console.log('teamProfiles:', teamProfiles);
    const chosen = [];
    const chosenProfiles = [];
    Object.keys(teamProfiles).map((user, index) => {
      let random = this.getRandomInt(teamProfiles.length);
      while(chosen.includes(random)) {
        random = this.getRandomInt(teamProfiles.length);
      }
      chosen.push(random);
      chosenProfiles.push(teamProfiles[random].uid);
      console.log('chosen:', chosen);
      console.log('random:', random);
      console.log('user:', teamProfiles[random].uid);
      if(index < 5) {
        if(
          teamProfiles[random].softSkillsAnswers !== {} &&
          teamProfiles[random].softSkillsAnswers.happy_place &&
          teamProfiles[random].softSkillsAnswers.happy_place
            .content &&
          teamProfiles[random].softSkillsAnswers.curiosity &&
          teamProfiles[random].softSkillsAnswers.curiosity.content &&
          teamProfiles[random].softSkillsAnswers.power_to_change &&
          teamProfiles[random].softSkillsAnswers.power_to_change
            .content &&
          teamProfiles[random].softSkillsAnswers.workplace &&
          teamProfiles[random].softSkillsAnswers.workplace.content &&
          teamProfiles[random].softSkillsAnswers.favorite_hobby &&
          teamProfiles[random].softSkillsAnswers.favorite_hobby
            .content &&
          teamProfiles[random].softSkillsAnswers.perception &&
          teamProfiles[random].softSkillsAnswers.perception.content
        ) {
          newDustbinArr.push({
            accepts: [
              this.formatName(teamProfiles[random].fullName),
              'any',
            ],
            lastDroppedItem: null,
            profile: teamProfiles[random],
          });
          newDustbinArr.sort(() => 0.5 - Math.random());
          // Map out users to boxes array in a random order
          newBoxesArr.push({
            name: this.formatName(teamProfiles[random].fullName),
            profile: teamProfiles[random],
            type: 'any',
          });
        }
      }
      return null;
    });
    this.setState({
      dustbins: newDustbinArr,
      dustbinArr: newDustbinArr,
      boxesArr: newBoxesArr,
      boxes: newBoxesArr,
      chosenProfiles,
    });

    if(manager === false) {
      this.setState({
        managerId,
        skills,
        managerProfile,
        teamProfiles,
        complete: true,
        loading: false,
        teamId,
      });
    } else {
      this.setState({
        managerIdAsManager: managerId,
        skills,
        managerProfileAsManager: managerProfile,
        teamProfilesAsManager: teamProfiles,
        complete: true,
        loading: false,
        managingTeamId: teamId,
      });
    }
  };

  getSkills = (templateName) => {
    const template = _template[templateName];
    return template.softSkills;
  };

  // Map out users to dustbin array in a random order
  // Users = () => {
  //   const { dustbinArr, boxesArr } = this.state;
  //   // this.setState({
  //   //   dustbins: [],
  //   //   dustbinArr: [],
  //   //   boxesArr: [],
  //   //   boxes: [],
  //   // });

  //   Object.keys(Teammates).map(user => {
  //     console.log('user', user);

  //     dustbinArr.push({
  //       accepts: [this.formatName(Teammates[user].fullName), 'any'],
  //       lastDroppedItem: null,
  //       profile: Teammates[user],
  //     });
  //     dustbinArr.sort(() => 0.5 - Math.random());
  //     // Map out users to boxes array in a random order
  //     boxesArr.push({
  //       name: this.formatName(Teammates[user].fullName),
  //       profile: Teammates[user],
  //       type: 'any',
  //     });

  //     // boxesArr.sort(() =>  0.5 - Math.random())
  //   });

  //   this.setState({
  //     dustbins: dustbinArr,
  //     dustbinArr,
  //     boxesArr,
  //     boxes: boxesArr,
  //   });
  // };

  formatName = (fullName) => {
    console.log('fullName', fullName);
    const splitName = fullName.split(' ');
    let initials = '';
    try {
      initials = `${splitName[0]} ${
        splitName[1].match(/\b\w/g) || []
      }.`;
    } catch(error) {
      initials = `${splitName[0]}`;
    }
    return initials;
  };

  isDropped(boxName) {
    return this.state.droppedBoxNames.indexOf(boxName) > -1;
  }

  handleScore = () => {
    // this.state.usedArr = [];
    this.setState({
      usedArr: []
    });
    let rawScore = 0;
    const arrScore = [];
    for(let i = 0; i < this.state.scoreArr.length; i++) {
      const s = this.state.scoreArr[i][1];
      const scoreObj = {
        uid: this.state.scoreArr[i][0],
        response: this.state.scoreArr[i][1],
      };
      arrScore.push(scoreObj);
      // console.log('s', this.state.scoreArr)
      if(s === true) {
        rawScore += 100 / this.state.scoreArr.length;
      }
    }
    const percentScore = `${rawScore}%`;

    console.log('chosenProfiles:', this.state.scoreArr);
    const defaultProfile = this.props.profile.getDefaultProfile();
    const teamGameScores = defaultProfile.teamGameScores
      ? defaultProfile.teamGameScores
      : [];
    const obj = {
      pScore: percentScore,
      chosenProfiles: this.state.chosenProfiles,
      scoreArray: arrScore,
      teamId: this.state.teamId,
    };
    teamGameScores.push(obj);
    defaultProfile.teamGameScores = teamGameScores;
    this.props.profile.save();

    this.setState({
      pScore: percentScore,
    });
    return percentScore;
  };

  // Establish scoring array and percentage score vars "globally"

  checkUsed = (teammate, lastDropped, index) => {
    // console.log('usedArr', usedArr);
    const usedArr = this.state.usedArr;
    const found = this.state.usedArr.find((element) => {
      return element === teammate.uid;
    });
    // console.log("found: ", found);
    if(!found) {
      usedArr[index] = teammate.uid;
      this.setState({
        usedArr,
      });
      // console.log("usedArr: ", usedArr)
      return false;
    } else {
      return true;
    }
  };

  // Method to compare correct answer array at a given index
  // to the provided answer array at the same index
  checkCorrect = (teammate, lastDropped, index) => {
    const { scoreArr } = this.state;
    // console.log('teammate', teammate)
    // console.log('teammate.uid', teammate.uid)
    // console.log('lastDropped', lastDropped)
    // console.log('lastDropped.uid', lastDropped.uid)
    if(teammate.uid === lastDropped.uid) {
      scoreArr[index] = [teammate.uid, true];
      this.setState({
        scoreArr,
      });
      // console.log("scoreArr", scoreArr)
      return true;
    } else {
      scoreArr[index] = [teammate.uid, false];
      this.setState({
        scoreArr,
      });
      return false;
    }
  };

  handleDrop = (index, item, userProfile) => {
    const { name, profile } = item;
    this.checkCorrect(profile, userProfile, index);
    if(!this.checkUsed(profile, userProfile, index)) {
      this.setState({
        droppedBoxNames: update(
          this.state.droppedBoxNames,
          name ? { $push: [name, profile] } : { $push: [] },
        ),
        dustbins: update(this.state.dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      });
    }
  };
  // Callback method that fires when a user clicks the x button above
  // a box after dropping one in a dustbin
  clearDustbin = (index) => {
    const usedArr = this.state.usedArr;
    // let scoreArr = this.state.scoreArr;
    usedArr[index] = null;
    // if (scoreArr.length === 1) {
    //   delete scoreArr[index];
    //   scoreArr.length = scoreArr.length - 1;
    // } else {
    //   delete scoreArr[index];
    // }
    // scoreArr.length = scoreArr.length - 1
    // scoreArr[index] = '';
    // scoreArr.splice(index, 1);
    // console.log('usedArr', usedArr);
    this.setState({
      usedArr,
      // scoreArr,
      dustbins: update(this.state.dustbins, {
        [index]: {
          lastDroppedItem: {
            $set: null,
          },
        },
      }),
    });
  };

  showAttributeModal(attribute) {
    this.setState({
      showAttributeModal: true,
      currentAttribute: attribute,
    });
  }

  closeAttributeModal() {
    this.setState({
      showAttributeModal: false,
    });
  }

  render() {
    const {
      scoreArr,
      dustbinArr,
      boxesArr,
      pScore,
      boxes,
      usedArr,
      dustbins,
      loading,
      showAttributeModal,
      currentAttribute,
    } = this.state;
    return (
      <>
        {loading && (
          <div className="spinner-container d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {dustbins.length > 1 ? (
          <>
            <div className="team-game-container">
              {/* {console.log('testing123', dustbinArr)}
          {console.log('testing123', boxesArr)}
          {console.log('dustbins', dustbins)}
          {console.log('usedArr', usedArr)} */}
              <div className="sticky-header">
                <Jumbotron className="text-center">
                  <h1 className="heading">GUESS WHO !</h1>
                  {/* <span className="sub-script">
                    *For Demonstration Purposes Only*
                  </span> */}
                  {!pScore && (
                    <p className="font-weight-bold">
                      THINK YOU KNOW YOUR TEAM?
                      <br />
                      Drag a co-worker's profile photo into their
                      correct Keeper profile!
                    </p>
                  )}
                </Jumbotron>
                <Row
                  className="team-container"
                  style={{ overflow: 'hidden', clear: 'both' }}
                >
                  {boxes.map(({ name, type, profile }, index) => (
                    <Box
                      name={name}
                      type={type}
                      profile={profile}
                      isDropped={this.isDropped(name)}
                      usedArr={usedArr}
                      key={index}
                      index={index}
                      pScore={pScore}
                      scoreArr={scoreArr}
                      boxesArr={boxesArr}
                    />
                  ))}
                </Row>
                {pScore && (
                  <>
                    <Row>
                      <span className="mx-auto score-text" id="score">
                        YOU SCORED
                        <span
                          className={
                            pScore === '100%'
                              ? 'purple ml-2'
                              : 'score-text ml-2'
                          }
                        >
                          {pScore}
                        </span>
                      </span>
                    </Row>
                    <Row>
                      <button
                        className="btn-solid-purple mx-auto mb-3"
                        onClick={() => window.location.reload()}
                      >
                        PLAY AGAIN?
                      </button>
                    </Row>
                  </>
                )}
                <hr />
              </div>

              <Container
                className={
                  pScore
                    ? 'game-card-container spacer'
                    : 'game-card-container'
                }
              >
                {/* {console.log('dbs', dustbins)} */}
                {dustbins.map(
                  ({ accepts, lastDroppedItem, profile }, index) => (
                    <Card className="teammate-card">
                      <div className="card-top">
                        <Row>
                          <Col xs="2" sm="2" md="2" lg="2">
                            {lastDroppedItem && (
                              <div
                                className="delete-btn"
                                onClick={() =>
                                  this.clearDustbin(index)
                                }
                              >
                                <FontAwesome name="times" />
                              </div>
                            )}
                            <Dustbin
                              className=""
                              accept={accepts}
                              lastDroppedItem={lastDroppedItem}
                              onDrop={(item) =>
                                this.handleDrop(index, item, profile)
                              }
                              key={index}
                            />
                          </Col>
                          <Col
                            xs="10"
                            sm="10"
                            md="10"
                            lg="10"
                            className="attribute-card-container"
                          >
                            <Row className={'gameAttributes'}>
                              {this.state.skills &&
                                this.state.skills.map(
                                  (att, index) => (
                                    <Col>
                                      <Card className="attribute-card">
                                        <div className="attribute-header">
                                          {
                                            _softSkills.softSkills[
                                              att
                                            ].title
                                          }
                                        </div>
                                        <FontAwesome
                                          name="external-link"
                                          onClick={() =>
                                            this.showAttributeModal({
                                              ...profile
                                                .softSkillsAnswers[
                                                  att
                                                ],
                                              aid: `${att}`,
                                            })
                                          }
                                        />
                                        {profile.softSkillsAnswers[
                                          att
                                        ].content.includes(
                                          'youtube',
                                        ) === true ? (
                                            <iframe
                                              className="attribute-image"
                                              src={
                                                profile
                                                  .softSkillsAnswers[
                                                    att
                                                  ].content
                                              }
                                              title="Something Inspiring"
                                              scrolling="no"
                                            />
                                          ) : (
                                            <img
                                              alt="something inspiring"
                                              className="attribute-image"
                                              src={
                                                profile
                                                  .softSkillsAnswers[
                                                    att
                                                  ].content
                                              }
                                            />
                                          )}
                                      </Card>
                                    </Col>
                                  ),
                                )}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      {/* <div className="card-bottom-menu">
                        <Row>
                          <div className="my-auto ml-1 text-center col-2">
                            {pScore ? (
                              <a
                                alt={`${profile.fullName}'s profile`}
                                title={`${profile.fullName}'s profile`}
                                href={profile.profileURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="active-bottom-menu-link">
                                  SHOW ME
                                </span>
                              </a>
                            ) : (
                              <span className="bottom-menu-link">
                                SHOW ME
                              </span>
                            )}
                          </div>
                          <div className="my-auto text-right col">
                            <span className="bottom-menu-link mx-4">
                              INVITE TO PLAY
                            </span>
                            <span className="bottom-menu-link mx-4">
                              ADD TO MY LINKS
                              <FontAwesome
                                name="link"
                                className="ml-2"
                              />
                            </span>
                            <span className="bottom-menu-link mx-4">
                              OPEN PROFILE
                              <FontAwesome
                                name="external-link"
                                className="ml-2"
                              />
                            </span>
                          </div>
                        </Row>
                      </div> */}
                    </Card>
                  ),
                )}
              </Container>
              <Row>
                <button
                  className="btn-solid-purple mx-auto mb-4"
                  onClick={() => this.handleScore()}
                  disabled={
                    usedArr.includes(null) ||
                    usedArr.length < dustbinArr.length ||
                    usedArr === []
                  }
                  title={
                    usedArr.length < dustbinArr.length
                      ? 'Please select all teammates'
                      : 'Submit'
                  }
                >
                  SUBMIT
                </button>
              </Row>
            </div>
          </>
        ) : (
          // <div className="card-top">
          <>
            {!loading && (
              <div className="team-game-container">
                <div className="sticky-header">
                  <Jumbotron className="text-center">
                    <div>
                      Please Ask team to complete their profiles
                    </div>
                  </Jumbotron>
                </div>
              </div>
            )}
          </>
        )}
        {currentAttribute !== '' && (
          <Modal
            show={showAttributeModal}
            onHide={() => this.closeAttributeModal()}
          >
            <Modal.Header closeButton></Modal.Header>
            {console.log('skills', this.state.skills)}
            {console.log('skills', currentAttribute)}
            <Modal.Body>
              <p className="attributeHeading">
                {_softSkills.softSkills[currentAttribute.aid].title}
              </p>
              <p className="attributeSub">
                {_softSkills.softSkills[currentAttribute.aid].sub}
              </p>
              <div className="contentDiv">
                {/* <img className="content" src={''} /> */}
                {currentAttribute.content.includes('youtube') ===
                true ? (
                    <iframe
                      className="content"
                      src={currentAttribute.content}
                      title="Something Inspiring"
                      scrolling="no"
                    />
                  ) : (
                    <img
                      alt="something inspiring"
                      className="content"
                      src={currentAttribute.content}
                    />
                  )}
              </div>
            </Modal.Body>
            <Modal.Footer className="example-modal">
              <p className="attribute-text">
                {currentAttribute.answer}
              </p>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

const condition = (profile) => {
  if(AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(withAuthorization(condition))(GameContainer);
