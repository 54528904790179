/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { Form, Row, Card, Container, Col } from 'react-bootstrap';
import f from 'firebase';
import Notiflix from 'notiflix';
import _templates from '../../constants/templatelist.json';
import { Link } from 'react-router-dom';
import { images } from '../../assets';
import FontAwesome from 'react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../../styles/search.scss';

const INITIAL_STATE = {
  template : 'default',
  userEmail : '',
  hashtags: '',
  searchInput: '',
  profile: {},
  showEdit: false
};
const url = process.env.REACT_APP_LOCAL + '/';

class ManageHashtags extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  lookupUser = async(event) => {
    event.preventDefault();
    console.log('lookupUser');
    const lookupUser = f.functions().httpsCallable('userLookup');
    const result = await lookupUser({ input: this.state.searchInput });
    console.log('result', result);
    if(result.data){
      console.log('result', result.data);
      this.setState({ uid: result.data.id, showEdit: true, profile: result.data.data.profiles[this.state.template], hashtags: result.data.data.profiles[this.state.template].hashtags ? result.data.data.profiles[this.state.template].hashtags.toString() : '' });
    } else {
      Notiflix.Notify.failure('No such user. Try Again');
    }
  }

  handleSubmit = async(event) => {
    event.preventDefault();
    const updateHashtags = f.functions().httpsCallable('updateHashtags');
    const result = await updateHashtags({ uid: this.state.uid, template: this.state.template, hashtags: this.state.hashtags });
    console.log(result);
    if(result.data === 'success'){
      Notiflix.Notify.success('User updated');
      this.setState({...INITIAL_STATE});
    } else {
      Notiflix.Notify.failure('Oops. Something went wrong');
    }
  }

  render(){
    const { showEdit, hashtags, template, searchInput, uid, profile } = this.state;
    return (
      <>
        <h3>Hashtags</h3>
        <p>Look up a user by email or id. Add, edit, or delete hashtags associated with user. To delete hashtags, clear text box.</p>
        <Form.Group onSubmit={this.lookupUser}>
          <Form.Control 
            onChange={this.handleChange} 
            placeholder="User email or id"
            name="searchInput" 
            value={searchInput && searchInput}/>
          <Form.Control 
            as="select" 
            className="text-left" 
            onChange={this.handleChange}
            name="template"
            value={template && template}>
            <option disabled>Choose...</option>
            {_templates.templates.map(temp => {
              return (<option>{temp}</option>);
            })}
          </Form.Control>
          <button type="submit" onClick={this.lookupUser}>Lookup User</button>
        </Form.Group>
        {showEdit && (
          <>
            <span>Seperate hashtags with commas. </span>
            <Card className="mb-3 search">
              <Container className="render-info">
                <Row>
                  <Col className="col-auto">
                    <Link 
                      className="profile-pic-link" 
                      target="_blank" 
                      to={`profile/${uid}`}>
                      <img 
                        className="profile-pic profile-pic-small" 
                        alt={profile.fullName} 
                        src={profile.profilePicture ? 
                          profile.profilePicture : images.avatar} />
                    </Link>
                  </Col>
                  <Col className="info">                    
                    {/*originally the className included in the link was in the div around each link. Changed it for a quick styling fix after adding links broke styling. may need to be reverted if styling is being refactored */}
                    <Row>
                      <Col>
                        <Link 
                          className="basicInfo mt-4" target="_blank" to={`profile/${uid}`}>
                          <p className="name">{profile.fullName}</p>
                          <p className="email">{profile.email}</p>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col>
                        <div className="linkContents">
                          <div className="url">
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <FontAwesome name="external-link"/>
                                </div>
                              </div>
                              <div className="form-control">
                                {new URL(`profile/${uid}`, url).toString()}
                              </div>
                              <CopyToClipboard 
                                text={new URL(`profile/${uid}`, url).toString()}>
                                <div 
                                  className="input-group-append"
                                  onClick={this.onCopy}>
                                  <span className="input-group-text copy-btn">
                                  Copy Link
                                  </span>
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <div></div>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <Form.Control type="text" placeholder="hashtags" name="hashtags" value={hashtags} onChange={this.handleChange}/>
                      <button type="submit" className="btn-solid-purple" onClick={this.handleSubmit}>Submit</button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Card>
          </>
        )}
      </>
    );
  }
}

export default ManageHashtags;
