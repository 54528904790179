/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import f from 'firebase';
import Notiflix from 'notiflix';
import { Row, Col } from 'react-bootstrap';
import './Gallery.scss';

const INITIAL_STATE = {
  searchTerm : '',
  searchResults : [],
  galleries : null,
  showDeleteModal: false,
  loading: false,
};

class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  async componentDidMount(){
    Notiflix.Notify.init({
      cssAnimationStyle: 'from-top',
      success: { background: '#7846c2' },
      cssAnimationDuration: 600,
    });
    try {
      const res = (await this.getGalleries()).data;
      this.setState({ galleries: res, searchResults: res });
    } catch(exception){
      console.error(exception);
    }
  }

  getGalleries = async(uid) => {
    const getGal = f.functions().httpsCallable('getGalleries');
    return await getGal();
  }

  onChange = async(event) => {
    const key = event.target.name;
    const query = event.target.value;
    this.setState({
      [key]: query,
    });
    setTimeout(() => {
      if(query !== this.state[key]) {return;}
      this.search();
    }, 300);
  }


  search = async() => {
    if(this.state.searchTerm === ''){
      this.setState({ searchResults: this.state.galleries });
    } else {
      try {
        const res = this.state.galleries.filter(gallery => ((gallery.data.name).toLowerCase()).includes((this.state.searchTerm).toLowerCase()));
        this.setState({ searchResults: res });
      } catch(exception){
        console.error(exception);
        //do something with error
      }
    }
  }

  deleteModal = (name, id) => {
    const that = this;
    return (
      Notiflix.Report.warning(
        `Are you sure you want to delete ${name}?`,
        'This is permanent and cannot be undone.',
        'Delete Gallery',
        async() => {
          await that.deleteGallery(id);
        }
      )
    );
  }

  deleteGallery = async (id) => {
    this.setState({loading:true})
    let deleteGal = f.functions().httpsCallable('removeGallery');
    let res = (await deleteGal({id: id})).data;
    if (res === 'success'){
      Notiflix.Notify.success('Successfully deleted gallery');
    } else {
      Notiflix.Notify.failure(res.message);
    }
    const newGalleries = (await this.getGalleries()).data;
    this.setState({ galleries: newGalleries, searchTerm: '', searchResults: newGalleries, loading:false });
  }


  render(){
    const { searchResults, galleries, loading } = this.state;
    if(galleries === null || loading){
      return (
        <div className="spinner-container d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    } else {
      return (
        <div className="gallery-builder-dashboard-form">
          <Link to="/gallerybuilder/new">
            <button className="btn-solid-purple mb-4">
                Create New Gallery
            </button>
          </Link>
          <Form.Group>
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text"><FontAwesome name="search" /></div>
              </div>
              <Form.Control className="form-control" id="inlineFormInputGroupUsername2"
                onChange={this.onChange}
                type="text"
                name="searchTerm"
                placeholder="Search by Gallery Name"
              />
            </div>
          </Form.Group>
          <Row>
            <Col className="col-sm-4 form-label">Gallery Name</Col>
            <Col className="col-sm-4 form-label">Gallery Title</Col>
            <Col />
            <Col className="text-center form-label">Actions</Col>
            <Col />
          </Row>
          {searchResults.map(gal => {
            return (<Row className="mb-2 text-left" key={gal.data.name}>
              <Col className="col-sm-4 align-middle">
                {gal.data.name}
              </Col>
              <Col className="col-sm-4 align-middle">
                {gal.data.title}
              </Col>
              <Col>
                <Link to={`/gallery/${gal.id}`}>
                  <button className="btn-solid-purple action-btn">
                        VIEW
                  </button>
                </Link>
              </Col>
              <Col className="text-right">
                <Link to={`/gallerybuilder/${gal.id}`}>
                  <button className="btn-solid-purple action-btn">
                        EDIT
                  </button>
                </Link>
              </Col>
              <Col className="text-right">
                <button 
                  onClick={() => this.deleteModal(gal.data.name, gal.id)}
                  className="x-btn">
                  <FontAwesome name="times"/>
                </button>
              </Col>
            </Row>);
          })
          }
          <Link to="/gallerybuilder/new">
            <button className="btn-solid-purple my-4">
                Create New Gallery
            </button>
          </Link>
        </div>
      );
    }
  }
}

export default Dashboard;
