/* eslint-disable react/prop-types */
import React, { Component, useEffect } from 'react';
import { Spinner, Modal, Accordion, Card, Dropdown } from 'react-bootstrap';
import * as ROUTES from '../../constants/routes';
import Notiflix from 'notiflix';
import moment from 'moment';
import { compose } from 'recompose';
import './CompanySummaryPage.scss';
import AppConfig from '../../components/App/AppConfig';
import { withAuthorization } from '../../components/Session';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';


import Layout from '../../components/Layout';
// import InsightsSideBar from './InsightsSideBar';
import { fadeInDown, fadeInUp } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import InsightsSideBar from '../Insights/InsightsSideBar';
import { Redirect } from 'react-router-dom';
import AdminDailyQuestion from '../AdminDailyQuestion';
import { ConsoleView } from 'react-device-detect';
import MultiSearchBar from '../../components/MultiSearchBar/MultiSearchBar';
import SortBar from '../../components/SortBar/SortBar';



const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;

class CompanySummaryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      companyMembersList: [],
      vibeList: [],
      innovationList: [],
      innovationLoading: false,
      checkboxChecked: false,
      showDeleteModal: false,
      displayQuestion: false,
      answersLoading: false,
      previousAnswersLoading: false,
      show: false,
      showPrevious: true,
      isQuestionLoading: false,
      dailyQuestion: '',
      answer: [],
      previousQuestions: [],
      //searchBy:'question',
      searchResults:[]
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getCompanyMembersList = this.getCompanyMembersList.bind(this);
    this.handleQuestion = this.handleQuestion.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
      vibeLoading: true,
      innovationLoading: true,
      answerLoading: true,
      previousAnswersLoading: true
     
    })
    this.getCompanyMembersList();
    this.getCompanyVibes();
    this.getCompanyInnovations();
    let answersArray = [];
    let previousAnswerArray = [];
    this.props.profile.getAllQuestions(this.props.profile.data.company.id)
      .then(async (_res) => {
        const currentQuestion = _res.reduce((a, b) => moment(a.dateTime).isAfter(moment(b.dateTime)) ? a : b)

        this.setState({
          dailyQuestion: currentQuestion.data.question,
          

        });
        
        // ===== getting current question and its responses ==========
        if (currentQuestion.data?.responses?.length > 0) {
          const promises = currentQuestion.data.responses.map(async res => {
            await res.userRef.get()
              .then(response => {

                answersArray.push({ data: response.data(), answer: res.userAnswer })
              })
          })
          await Promise.all(promises)
          this.setState({
            answer: answersArray,
            answerLoading: false
          })
          
        }
       // ========== getting previous question and their responses ================

        const sorted = _res.sort((a,b) => b.data.timestamp-a.data.timestamp)
       
        const earlierResponses = sorted.map(async answer => {
          let responseArray = [];
          //console.log('answer',answer)
          if(answer.data.question !== this.state.dailyQuestion){
            answer.data.responses.map(async res => {
              await res?.userRef?.get()
              .then(response => {
                responseArray.push({ data: response.data(), answer: res.userAnswer,  })
              })
            })
          }

          if(answer.data.question !== this.state.dailyQuestion) previousAnswerArray.push({ question: answer.data.question, 
            answer: responseArray, dateTime: answer.dateTime,
            timestamp:answer.data.timestamp
           })  

        })
        await Promise.all(earlierResponses)
        //const sortedPreviousAnswers = previousAnswerArray.sort((a, b) => moment(a.dateTime).isAfter(moment(b.dateTime)? 1:-1))
        const sortedPreviousAnswers = previousAnswerArray.sort((a, b) => b.timestamp - a.timestamp);
        // console.log("======= Previous",sortedPreviousAnswers, previousAnswerArray)
        this.setState({
          previousQuestions: sortedPreviousAnswers,
          previousAnswersLoading: false,
          searchResults: sortedPreviousAnswers,
        })
      })
      
  }

  getCompanyMembersList = async () => {
    // const respon = await props.profile.getAllCompanyMembers(props.profile.data.id)
    if (this.props.profile?.data) {
      if (this.props.profile.data?.company?.id) {
        let res = await this.props.profile.getAllUsersByCompany(this.props.profile.data?.company?.id)
      
        if (res) {
          res = res.map(element => {
            let data = element.data;
            data.accountStatus = 'accountStatus' in element.data ? element.data.accountStatus : true;

            return { data, ...element };
          })

     
          this.setState({
            companyMembersList: res,
            loading: false
          })

        }
      }
    }

  }

  getCompanyVibes = async () => {
    let res = await this.props.profile.getAllCompanyVibes(this.props.profile.data?.company?.id)
    if (res && res.data) {
      res.data = res.data.filter(vib => vib.hasOwnProperty('content'));
      this.setState({
        vibeList: res.data
      })
    }
    this.setState({
      vibeLoading: false
    })

  }

  getCompanyInnovations = async () => {
    let res = await this.props.profile.getAllCompanyInnovations(this.props.profile.data?.company?.id)
    if (res && res.data) {
      res.data = res.data.filter(vib => vib.hasOwnProperty('content'));
      this.setState({
        innovationList: res.data
      })
    }
    this.setState({
      innovationLoading: false
    })

  }
  handleAskQuestion = () => {
    this.setState({
      displayQuestion: !this.state.displayQuestion
    })
    // console.log(this.state.displayQuestion)
  }
  handleShow() {
    this.setState({ show: true });
    

  }
  handleDeletePreviousAnswers = () =>{
    this.setState({ answers: []})
  }

  handleClose() {
    this.setState({ show: false });
  }

  async handleQuestion(value, groupID) {
    this.setState({ question: value, isQuestionLoading:true , answer: [] });
    const { data } = this.props.profile
    const payload = {
      user_id: this.props.profile.uid,
      company_id: data.company.id,
      question: value,
      responses: []
    }
    try {
      let response = null;
      if (groupID === "allGroups") {
        response = await this.props.profile.addDailyQuestion(payload);
      } else {
        response = await this.props.profile.addDailyQuestionByGroupID(payload, groupID);
        if (response === false) {
          throw new Error('Error while posting the data to collection - dailyQuestion');
        }
      }
      // console.log("======::::>",response)
      this.setState({
        dailyQuestion: payload.question,
        dailyQuestionId: '',
        isQuestionLoading:false
      });
      this.handleClose()
      Notiflix.Notify.success(response.data.message)
     
    } catch (error) {
      Notiflix.Notify.failure(error.message)
    }

  }
handleShowPrevious =() =>{
  this.setState({
    showPrevious: !this.state.showPrevious
  })
}
// setSearchBy = (search) => {
//   this.setState({
//     searchBy: search
//   })
// }
setSearchResults = (value) => {
  this.setState({
    searchResults: value
  })
}
  render() {
    const { companyMembersList, vibeList, innovationList, question, answer, isQuestionLoading } = this.state;


    if (!this.props.profile.data.userRoles || this.props.profile.data.userRoles.length <= 0 || this.props.profile.data.userRoles.indexOf('ADMIN') === -1) {
      return <Redirect to={ROUTES.INSIGHTS + "/" + this.props.profile.uid} />
    }

    return (
      <>

        <div className="insightsPage">
          <Layout>
            <div className="insightsBox">

              <AuthDataContext.StreamConsumer requireAccount={true}>
                {profile => (

                  <>

                    <InsightsSideBar
                      image={profile.data.profiles.default.profilePicture}
                      name={profile.data.profiles.default.fullName}
                      profile={profile}
                    />
                    <div className="wrapper">
                      <FadeUp className="my-auto">
                        <div className="company">
                          <div className="title">
                            <span><b>Company Summary</b></span>
                          </div>
                          <section className="summary-main-section">
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 pt-4">
                                <div className="card">
                                  <div className="card-body">
                                    {!this.state.loading ? (
                                      <>
                                        <h5 className="card-title"> Users</h5>
                                        <h3 className="card-text"> {companyMembersList.length}</h3>
                                        <button className="button btn-solid-purple createBtnBottom"
                                          onClick={() => this.props.history.push(ROUTES.COMPANYADMINPAGE)}
                                        >
                                          Company Manager Profiles
                                        </button>
                                      </>) : (<Spinner animation='border' role='status'>
                                        <span className='sr-only'>Loading...</span>
                                      </Spinner>)
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 pt-4">
                                <div className="card">
                                  <div className="card-body">
                                    {!this.state.vibeLoading ? (
                                      <>
                                        <h5 className="card-title"> Vibes</h5>
                                        <h3 className="card-text"> {vibeList ? vibeList.length : '0'}</h3>
                                        <button className="button btn-solid-purple createBtnBottom"
                                          onClick={() => this.props.history.push(ROUTES.VIBEPAGE)}
                                        >
                                          TEAM'S VIBE
                                        </button>
                                      </>) : (<Spinner animation='border' role='status'>
                                        <span className='sr-only'>Loading...</span>
                                      </Spinner>)
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 pt-4">
                                <div className="card">
                                  <div className="card-body">
                                    {answer ? (
                                      <>
                                        <h5 className="card-title"> Innovations</h5>
                                        <h3 className="card-text"> {innovationList ? innovationList.length : "0"}</h3>
                                        <button className="button btn-solid-purple createBtnBottom"
                                          onClick={() => this.props.history.push(ROUTES.INNOVATION_PAGE)}
                                        >
                                          TEAM'S INNOVATION
                                        </button>
                                      </>) : (<Spinner animation='border' role='status'>
                                        <span className='sr-only'>Loading...</span>
                                      </Spinner>)
                                    }
                                  </div>
                                </div>
                              </div>

                            </div>


                          </section>

                        </div>
                        <div className="company">
                          <div className="title">
                            <span><b>Daily Question</b></span>
                          </div>

                          <section className="summary-main-section">
                            <div><h4>Current Question: </h4></div>
                            <div><h4>{this.state.dailyQuestion}</h4></div>
                            <button className="button btn-solid-purple createBtnBottom" onClick={this.handleShow}>Ask New Question</button>

                            <Dropdown.Divider style={{ marginTop: "20px" }} />
                            
                            <h4>Current Responses: </h4>

                            <div className='answer-list-wrapper'>

                              {this.state.answer.length > 0 ? this.state.answer.map((answer, index) => {
                                let image;
                                if (answer?.data?.profiles?.default?.profilePicture === 'https://ramcotubular.com/wp-content/uploads/default-avatar.jpg') image = '/assets/media/profile/default-avatar.jpeg';
                                else image = answer?.data?.profiles?.default?.profilePicture

                                return <div key={index} className="answer-list"><img src={image} />  <b>{answer?.data?.profiles?.default?.fullName}:</b>  {answer?.answer} </div>

                              }) : "-- No answers --"}
                            </div>
                            
                              <div>
                                <Dropdown.Divider style={{ marginTop: "20px" }} />
                                {!this.state.showPrevious ? 
                                  (<div className='outerContainer'>
                                      <div className='innerContainer'>
                                        <button className="button btn-solid-purple createBtnBottom" style={{ marginBottom: "15px"}}onClick={this.handleShowPrevious}>Hide Previous Questions</button>
                                        <MultiSearchBar data={this.state.previousQuestions} setSearchResults={this.setSearchResults} ></MultiSearchBar>
                                      </div>
                                      <div className='innerContainer'>
                                        <SortBar data={this.state.searchResults} setData={this.setSearchResults} />
                                      </div>
                                    </div>)
                                : <button className="button btn-solid-purple createBtnBottom" onClick={this.handleShowPrevious}>Show Previous Questions</button> 
                                }

                                {!this.state.showPrevious ? (this.state.searchResults.length > 0 ? (this.state.searchResults.map((question, index) => {
                                  return (
                                  <Accordion defaultActiveKey={index} key={index}>
                              
                                      <Accordion.Toggle as={Card.Header} eventKey="0" style={{cursor:"pointer"}}>
                                        <div>
                                          {Number(question.dateTime.getUTCMonth())+1}/{question.dateTime.getDate()}/{question.dateTime.getFullYear()}:       <b>{question?.question}</b>
                                        </div> 
                                        <div className="dailyResponse">
                                          <span>Responses: {question.answer.length}</span>
                                          <span>Click to expand</span>
                                        </div>
                                      </Accordion.Toggle>
                                      {question?.answer?.map((response, index) => {
                                        let image;
                                        if (response?.data?.profiles?.default?.profilePicture === 'https://ramcotubular.com/wp-content/uploads/default-avatar.jpg') image = '/assets/media/profile/default-avatar.jpeg';
                                        else image = response?.data?.profiles?.default?.profilePicture
                                        return <Accordion.Collapse eventKey="0">
                                          <Card.Body>
                                            <div key={index} ><img src={image} /> <b>{response?.data?.profiles?.default?.fullName}: </b>  {response?.answer} </div>
                                          </Card.Body>
                                        </Accordion.Collapse>
                                      })}
                             
                                  </Accordion>)
                                })) : (
                                        <div className="col-md-12 text-center p-2">
                                            <span className='white' style={{color:"black"}}>No Mathching Results Found</span>
                                          </div>
                                        )) : ''}
                              </div>
                       

                            <Modal
                              show={this.state.show}
                              onHide={this.handleClose}
                              keyboard
                              backdrop="static"
                              centered>
                              <Modal.Header closeButton />
                              <Modal.Body>
                                <AdminDailyQuestion
                                  isQuestionLoading={isQuestionLoading} 
                                  profile={profile}
                                  question={question}
                                  handleQuestion={this.handleQuestion}
                                  handleClose={this.handleClose}
                                  handleDeletePreviousAnswers={this.handleDeletePreviousAnswers}
                                />
                              </Modal.Body>
                            </Modal>
                          </section>
                        </div>

                      </FadeUp>
                    </div>
                  </>
                )}




              </AuthDataContext.StreamConsumer>
            </div>
          </Layout>
        </div>


      </>
    );
  }
}




const condition = (profile) => {
  if (AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(
  withAuthorization(condition),
)(CompanySummaryPage);





