export const GIFs = {
  congrats: require('./GIFs/Congrats_800.gif'),
  oops: require('./GIFs/oops.gif'),
};

export const icons = {
  flip: require('./icons/flip_icon.svg'),
};

export const images = {
  avatar: require('./images/default-avatar.svg'),
  how_to: require('./images/how-to.jpg'),
  teams_home_splash_image: require('./images/teams-happy-people-logo.svg'),
};

export const logos = {
  k_logo_white: require('./logos/Keeper-K_White.svg'),
  k_logo_color: require('./logos/logoK.png'),
  profile_icon: require('./logos/icons8.png'),
  keeper_logo: require('./logos/KeeperSVG.svg'),
  keeper_footer_logo: require('./logos/teams-footer-logo.svg'),
  keeper_logo_teams: require('./logos/KeeperTeamsSVG.svg'),
  cisco_logo: require('./logos/tag-cisco-logo.svg')
};

export const videos = {
  how_to: require('./videos/KHR_MASTER_New_BG_Square_100219.mp4'),
};
