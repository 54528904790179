/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { Component } from 'react';
import Layout from '../../components/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import { compose } from 'recompose';
import styled, { keyframes } from 'styled-components';
import { withAuthorization } from '../../components/Session';
import { fadeIn, tada } from 'react-animations';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as ROUTES from '../../constants/routes';
import { GIFs } from '../../assets';
import Notiflix from 'notiflix';
import '../../styles/Complete.scss';
import { withAuthDataContext } from '../../components/Firebase/authdataprovider';
import CompleteGame from '../../components/CompleteGame';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';


const INITIAL_STATE = {
};


const Fade = styled.div`animation: 1.5s ${keyframes`${fadeIn}`}`;
const Tada = styled.div`animation: 1.5s ${keyframes`${tada}`}`;

class CompleteBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  static defaultProps = {
  }

  // async componentDidMount(){
  //   Notiflix.Notify.init({
  //     cssAnimationStyle: 'from-top',
  //     success: { background: '#7846c2' },
  //     cssAnimationDuration: 600,
  //   });
  // }

  render(){
    const uid = this.props.profile.uid;
    
    return (
      <Layout>
        <ScrollTopOnPageLoad/>
        <Container className="complete-container">
          <div className="gifContainer mt-4">
            <img 
              src={GIFs.congrats}
              className="current-gif"
              alt="Success GIF" />
          </div>

          <div className="congrats-container">
            <Tada>
              <h1 className="subtitle">
                CONGRATULATIONS!
              </h1>
            </Tada>
          </div>


          <p className="text-center sub-text">
            Your Keeper account was successfully created
          </p>
          <Fade>
            <Row className="btn-container">
              <Col md={6}>
                <Link 
                  to={ROUTES.INSIGHTS  + '/' + uid} 
                  onClick={() => document.documentElement.scrollTop = 0} 
                  rel="noopener noreferrer" >
                  <button
                    className="btn-solid-purple button">
                    <span className="backBtnText">
                          VIEW PROFILE & INSIGHTS
                    </span>
                  </button>
                </Link>
              </Col>
              <Col md={6}>
                <CopyToClipboard text={process.env.REACT_APP_LOCAL + ROUTES.PROFILEid  + '/' + uid}
                  onCopy={() => Notiflix.Notify.success('Link copied to clipboard')}>
                  <button className="copyButton btn-solid-purple button">
                    SHARE YOUR LINK
                  </button>
                </CopyToClipboard>
              </Col>
            </Row>
          </Fade>

          <CompleteGame profile={this.props.profile} />
        </Container>
      </Layout>
    );
  }
}


const condition = profile => !profile.isAnonymous;

const Complete = withAuthDataContext(CompleteBase);

export default compose(
  withAuthorization(condition),
)(Complete);
