/* eslint-disable react/jsx-key */
import { logos } from '../../../assets';
import React, { Component } from 'react';
import * as ROUTES from '../../../constants/routes';
import { Link } from 'react-router-dom';
import AppTemplates from '../../../constants/apptemplates';


const images = {
  headerLogo: [logos.keeper_logo, logos.keeper_logo_teams],
  footerLogo: [logos.keeper_logo, logos.keeper_logo_teams]
};

const template = AppTemplates.default;
const numNeeded = template.softSkills.length;
const text = {

  headerLogo: [
    (<>
      <img 
        src="/assets/KeeperAI_white.png"
        width='180'
        height='auto'
        style ={{ padding: "10px" }}
        className="d-inline-block align-top img-logo-width"
        alt="KeeperAI Logo" />
    </>), 
    (<>
      <img 
        src={logos.keeper_logo_teams}
        className="teams-logo"
        alt="Keeper for Teams Logo" />
    </>)
  ],

  footerLogo: [
    (
      <img
        className="k-logo"
        src="/assets/KeeperAI_white.png"
        width='180'
        height='auto'
        style={{ paddingBottom: "5px"}}
        alt="KeeperAI" />
    ), 
    (
      <img
        className="teams-footer-logo"
        src={logos.keeper_logo_teams}
        alt="Keeper for Teams" />
    )
  ],
    
  myContentIntro: [
    (
      <>
        {/* <p className='kredSub'>Show employers who you are.</p>  */}<br></br>
        <p className="kredText">Take a few minutes to fill out these 6 key questions. Your image choices and answers will help form a picture of your personality.  </p>
        <p className="kredText"><b>Tips: </b>Take your time.  Keep your answers short and sweet.</p>
        <p className="kredText">When you have completed your profile, please click 'NEXT' to fill out your information.</p>
        <p className="kredText">You can also go back at any time to change your answers and image choices.</p>
        {/* <p className='kredText'>Need inspiration? Flip the card to see an example or 
            <Link className='ml-1'to={ROUTES.ASH_SMITH} target="_blank">VIEW A COMPLETE PROFILE</Link></p> */}
      </>         
    ), 
    (
      <>
        <p className="kredText">Use visual content to convey your personality. 
            You must answer all {numNeeded} questions before your profile is complete.</p>
        <p className="kredText">Need inspiration? Flip the card to see an example or 
          <Link className="ml-1"to={ROUTES.ASH_SMITH} target="_blank">VIEW A COMPLETE PROFILE</Link></p>
      </>
    )
  ],

  signUpSignInEmail: [
    (<span>Email</span>), 
    (<span>Work Email</span>)
  ]

};

const APP_MODE_TEAMS = 'teams';
const APP_MODE_JOBS = 'jobs';

export default class AppConfig extends Component {
    static isJobsMode = () => {
      return (process.env.REACT_APP_MODE.toUpperCase() === 'JOBS');
    }

    static getMode = () => {
      return (process.env.REACT_APP_MODE.toUpperCase() === 'JOBS') ? APP_MODE_JOBS : APP_MODE_TEAMS;
    }

    static getImageOption = (key) => {
      const index = AppConfig.isJobsMode() ? 0 : 1;
      if(images[key]){
        return images[key][index];
      }

      return null;
    }

    static getCodeSnippetOption = (key) => {
      const index = AppConfig.isJobsMode() ? 0 : 1;
      if(text[key]){
        return text[key][index];
      }

      return null;
    }

}
