/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { 
  Row, 
  Col, 
  Form, 
} from 'react-bootstrap';

class NameInput extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      displayName: '',
      error: ''
    };
  }

  componentDidMount = async() => {
    const currName = this.props.profile.getDefaultProfile().fullName;
    this.setState({
      displayName: currName || ''
    });
  }

  onBlur = event => {
    event.preventDefault();
    const { displayName } = this.state;
    this.props.profile.getDefaultProfile().fullName = displayName;
    this.props.profile.save();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <Form.Group>
        <Row className="text-left">
          <Col>
            <Form.Label>Full Name *</Form.Label> 
            <Form.Control
              name="displayName"
              value={this.state.displayName}
              onBlur={this.onBlur}
              onChange={this.onChange}
              type="text"
              maxLength= {20}
              placeholder="max 20 characters"
            />
      
     
          </Col>
        </Row>
      </Form.Group>
    );
  }
}

export default NameInput;
