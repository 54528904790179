import React from 'react'

const NoAccess = () => {
  return (
    <div>
      <p>You don't have access.</p>
    </div>
  )
}

export default NoAccess
