import React, { useCallback, useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import Post from './Post';
import f from 'firebase';
import "./Posts.scss";
import FontAwesome from 'react-fontawesome';

const LoadingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px auto;
`;

const LOADING_LIMIT = 3;

const Posts = ({ profile, groupName, newPostId, history}) => {
    const [posts, setPosts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [lastPost, setLastPost] = useState(new Date("2100-01-01"));
    const [loadingPosts, setLoadingPosts] = useState(false);
    const [showNewPost, setShowNewPost] = useState(false);
    const [newPostIds, setNewPostIds] = useState([]);
    const [loadingNew, setLoadingNew] = useState(false);

    const postRef = useRef(posts);
    postRef.current = posts;
    const newPostIdsRef = useRef(newPostIds);
    newPostIdsRef.current = newPostIds;
    
    const handleAddNewPost = useCallback((postId) => {
        setShowNewPost(true);
        setNewPostIds([postId, ...newPostIdsRef.current]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPostIdsRef.current]);

    const handleEditPost = useCallback((postId, newData) => {
        const _posts = [...postRef.current]; 
        const index = _posts.findIndex(post => post.postId === postId);
        if (index !== -1) {
            _posts[index].content = newData.content;
            _posts[index].images = newData.images;
            _posts[index].lastUpdate = newData.lastUpdate;
            setPosts([..._posts]);
        }
        setPosts([..._posts]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postRef.current]);

    const handleEditPostStatic = (postId, newImages, newContent) => {
        const _posts = [...postRef.current]; 
        const index = _posts.findIndex(post => post.postId === postId);
        if (index !== -1) {
            _posts[index].images = newImages;
            _posts[index].content = newContent;
            _posts[index].lastUpdate = f.firestore.Timestamp.now();
            setPosts([..._posts]);
        }
    }

    const handleDeletePost = useCallback((postId) => {
        const _posts = [...postRef.current]; 
        const index = _posts.findIndex(post => post.postId === postId);
        if (index === -1) {
            const _newPostIds = [...newPostIdsRef.current];
            const deletedIndex = _newPostIds.findIndex(id => id === postId);
            if (deletedIndex === -1) return;
            _newPostIds.splice(deletedIndex, 1);
            setNewPostIds([..._newPostIds]);
            if (!_newPostIds.length) setShowNewPost(false);
            return;
        };
        _posts.splice(index, 1);
        setPosts([..._posts]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postRef.current, newPostIdsRef.current]);

    useEffect(() => {
        const db = f.firestore();
        const launchedTime = f.firestore.Timestamp.now();
        var unsubscribe = db.collection("posts")
            .where("affinityGroupName", "==", groupName)
            .where("companyId", "==", profile.userCompanyId)
            .where("postedBy", "!=", profile.uid)
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach(async (change) => {
                    const newData = change.doc.data();
                    if (change.type === "added") {
                        if (newData.postedBy !== profile.uid && newData.creationDate.seconds + 10 >= launchedTime.seconds) {
                            handleAddNewPost(change.doc.id);
                        }
                    }
                    if (change.type === "modified") {
                        handleEditPost(change.doc.id, newData);
                    }
                    if (change.type === "removed") {
                        if (newData.postedBy !== profile.uid) {
                            handleDeletePost(change.doc.id);
                        }
                    }
                });
            });

        return () => {
            unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (newPostId && posts.indexOf(post => post.postId === newPostId) === -1) {
            loadNewPostData(newPostId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPostId]);

    const handleNewPost = async () => {
        if (loadingNew) return;
        setShowNewPost(false);
        window.scrollTo(0, 0);
        try  {
            setLoadingNew(true);
            const newPostsPromise = [];
            newPostIds.forEach(postId=> {
                newPostsPromise.push(profile.getPost(postId))
            });
            let newPosts = await Promise.all(newPostsPromise);
            newPosts = newPosts.filter(post => post);
    
            const userPromises = [];
            newPosts.forEach(post => {
                userPromises.push(profile.getUserProfile(post.postedBy))
            });
            const users = await Promise.all(userPromises);
            newPosts = newPosts.map((post, index) => {
                return {
                    ...post,
                    user: users[index]
                }
            });
            setPosts([...newPosts, ...posts]);
        } catch (e) {

        } finally {
            setLoadingNew(false);
            setNewPostIds([]);
        }
    }

    const loadNewPostData = async (newPostId) => {
        try {
            const data = await profile.getPost(newPostId);
            if (!data) return;
            const newData = {
                ...data,
                user: profile.data?.profiles?.default,
            };
            setPosts([newData, ...posts]);
        } catch (error) {
            console.log(`Error in loadNewPostData ${error}`);
        }
    }

    const loadFunc = () => {
        loadPosts();
    }

    const loadPosts = async () => {
        if (!hasMore || loadingPosts) return;
        try {
            setLoadingPosts(true);
            let morePosts = await profile.getPosts(
                profile.userCompanyId,
                groupName,
                lastPost,
                LOADING_LIMIT
            );
            const userPromises = [];
            morePosts.forEach(post => {
                userPromises.push(profile.getUserProfile(post.postedBy))
            });
            const users = await Promise.all(userPromises);
            morePosts = morePosts.map((post, index) => {
                return {
                    ...post,
                    user: users[index],
                }
            });
            if (morePosts && morePosts.length) {
                setPosts([...posts, ...morePosts]);
                setLastPost(morePosts[morePosts.length - 1].creationDate);
                if (morePosts.length < LOADING_LIMIT) {
                    setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.log(`Error in loadPosts ${error}`);
        } finally {
            setLoadingPosts(false);
        }
    }

    return (
        <>
            {showNewPost && <div className="new-post" onClick={handleNewPost}>
                <FontAwesome name="arrow-up"/>
                <div>New Post</div>                
            </div>}
            {loadingNew && <LoadingBox key="loader">
                <Spinner animation='border' role='status'>
                    <span className='sr-only'>Loading...</span>
                </Spinner>
            </LoadingBox>}
            <InfiniteScroll
                pageStart={0}
                hasMore={hasMore}
                loadMore={loadFunc}
                loader={
                    <LoadingBox key="loader">
                        <Spinner animation='border' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </Spinner>
                    </LoadingBox>
                }
            >
                {posts.map((post) => {
                    return (
                        <Post
                            key={post.postId}
                            post={post}
                            profile={profile}
                            history={history}
                            onDeletePost={handleDeletePost}
                            onEditPost={handleEditPostStatic}
                        />
                    )
                })}
            </InfiniteScroll>    
        </>    
    )
}

export default Posts;