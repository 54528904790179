/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDrag } from 'react-dnd';
import { images } from '../../../assets';
import { Col, Row } from 'react-bootstrap';

// The boxes hold the teammates and render up top of the game.
// Boxes are then dropped into dustbins

// Setup functional component with hook for dragging callback on selection
const Box = ({ name, type, profile, usedArr, index, pScore, scoreArr, boxesArr }) => {
  const [, drag] = useDrag({
    item: { name, type, profile }
  });
  const found = usedArr.find((element) => { 
    return element === profile.uid; 
  });

  const newArr = [false, false, false, false, false];
  if(pScore){
    scoreArr.forEach((element1, index1) => {
      boxesArr.forEach((element2, index2) => {
        if((element1[0] === element2.profile.uid) && (element1[1] === true)){
          newArr[index2] = true;
        }
      });
    });
  }

  return (
    <div>
      <Col className="profile-pic-container" ref={drag}>
        {found ? (
          <div>
            <img 
              className="profile-pic-used" 
              src={profile.profilePicture ? 
                profile.profilePicture : 
                images.avatar} 
              alt=""
            />
            <p className="profile-name">
              <strike>{name}</strike>
            </p>
          </div>
        ) : (
          <div>
            <img 
              className="profile-pic" 
              src={profile.profilePicture ? 
                profile.profilePicture : 
                images.avatar} 
              alt=""
            />
            <p className="profile-name">
              {name}
            </p>
          </div>
        )}
      </Col>
      <Row className="team-container">
        <Col className="profile-pic-container">
          {pScore && (
            <div>
              {newArr[index] ? (
                <div className="correct">CORRECT</div>
              ) : (
                <div className="incorrect">INCORRECT</div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Box;
