import React, { useState, useRef, useMemo } from 'react';
import { Dropdown, Form, Modal } from "react-bootstrap";
import FontAwesome from 'react-fontawesome';
import ConfirmModal from '../../../components/ConfirmModal';
import Replies from './Replies';
import { getPostedTime } from '../../../utils';
import ImageSelector from '../ImageSelector';
import CustomCarousel from '../CustomCarousel';


const Post = ({ post, profile, history, onDeletePost, onEditPost }) => {
   
    const { user, images, content, creationDate, lastUpdate, likedUsers, replies, postedBy } = post;
    let profileImage;
    if(user?.profilePicture === "https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"||user?.profilePicture==="/images/default-avatar.svg"){
        profileImage = '/assets/media/profile/default-avatar.jpeg'
    }else{
        profileImage=user?.profilePicture
    }
    const [isLiked, setIsLiked] = useState((likedUsers.findIndex(uid => uid === profile.uid) >= 0) ? true : false);
    const [likedCount, setLikedCount] = useState(likedUsers.length);
    const [confirmShow, setConfirmShow] = useState(false);
    const [showReplies, setShowReplies] = useState(false);
    const [repliesCount, setRepliesCount] = useState(replies.length);

    const imagesRef = useRef(images);
    imagesRef.current = images;
    const [isEdit, setIsEdit] = useState(false);
    const [editContent, setEditContent] = useState(content);
    const [editImages, setEditImages] = useState(images);

    const [showSelectImageModal, setShowSelectImageModal] = useState(false);
    const showImageModal = () => setShowSelectImageModal(true);
    const hideImageModal = () => setShowSelectImageModal(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleLiked = async () => {
        try {
            let res = false;
            if (isLiked) { // dislike 
                res = await profile.likePost(post.postId, profile.uid, false);
                setLikedCount(likedCount - 1);
            }
            else { // like
                res = await profile.likePost(post.postId, profile.uid, true);
                setLikedCount(likedCount + 1);
            }
            if (res)
                setIsLiked(!isLiked);
        } catch (error) {}
    }

    const handleEdit = () => {
        setIsEdit(true);
    }

    const handleDelete = async () => {
        if (isDeleting) return null;
        setIsDeleting(true);
        try {
            await profile.deletePost(post.postId);
            onDeletePost(post.postId);
            setConfirmShow(false);
        } catch (error) {

        } finally {
            setIsDeleting(false);
        }
    }

    const renderImages = useMemo(() => {
        let imagestoRender = [];
        if (postedBy === profile.uid) imagestoRender = editImages;
        else imagestoRender = imagesRef.current;
        if (!imagestoRender || !imagestoRender.length) return (null);
        return (
            <CustomCarousel
                images={imagestoRender}
                deselect={false}
            />
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagesRef.current, editImages]);

    const handleSaveImages = (images) => {
        setEditImages(images);
    }

    const handleDeleteImages = () => {
        setEditImages([]);
    }

    const handleCancelEdit = () => {
        setEditImages([...images]);
        setEditContent(content);
        setIsEdit(false);
    }

    const handleSaveEditedPost = async () => {
        try {
            const res = await profile.updatePost(post.postId, editImages, editContent);
            if (res) {
                setIsEdit(false);
                onEditPost(post.postId, editImages, editContent);
            }
        } catch(error) {
            console.log(`Error in handleSaveEditedPost ${error}`);
        } finally {

        }
    }

    const renderPosterActions = () => {
        if (postedBy !== profile.uid) return (null);
        return (
            <div className="poster-action">
                <Dropdown>
                    <Dropdown.Toggle>
                        <FontAwesome name="ellipsis-h"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleEdit}>
                            <FontAwesome name="edit" style={{margin: "auto 10px auto 0"}}/>
                            Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setConfirmShow(true)}>
                            <FontAwesome name="trash" style={{margin: "auto 10px auto 0"}}/>
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    return (
        <div className="post-block">
            <div className="poster-box">
                <img className="poster_image" 
                src={profileImage} alt=""/>
                <div className="poster-detail">
                    <a href={`/insights/${postedBy}`} className="poster-name">
                        {user?.fullName}
                    </a>
                    <div className="poster-role">{user?.myRole}</div>
                    <div className="poster-date">
                        {getPostedTime(creationDate)}
                        {creationDate.seconds !== lastUpdate.seconds ? ` - edited (${getPostedTime(lastUpdate)})`:""}
                    </div>
                </div>
                {renderPosterActions()}
            </div>
            {renderImages}
            <div className="post-content">
                {isEdit?
                    <>
                        <div className="edit-images">
                            <div className="add-image" onClick={showImageModal}>
                                <FontAwesome name='image'/>
                            </div>
                            {editImages.length ? <div className="add-image" onClick={handleDeleteImages}>
                                <FontAwesome name='trash'/>
                            </div>:(null)}
                        </div>
                        <Form.Control
                            value={editContent}
                            onChange={(e) => setEditContent(e.target.value)}
                            as="textarea"
                            rows={3}
                            placeholder="What do you want to eidt about?"
                        />
                        <div className="edit-action">
                            <div className="action-button" onClick={handleSaveEditedPost}>Save</div>
                            <div className="action-button" onClick={handleCancelEdit}>Cancel</div>
                        </div>
                    </>:content}
            </div>
            <div className="post-status">
                <div style={{margin: "auto 0"}}>
                    <FontAwesome name="heart" style={{color: "red"}}/>
                </div>
                <div className="liked-count">{likedCount}</div>
                <div
                    className="replies-count"
                    onClick={() => setShowReplies(true)}
                >{repliesCount} replies</div>
            </div>
            <div className="post-footer">
                <div
                    className={isLiked ? "action-button liked": "action-button"}
                    onClick={handleLiked}
                >
                    <FontAwesome className="action-icon" name="heart" style={{color: "red"}}/>
                    <div className="action-name">Like</div>
                </div>
                <div
                    className={"action-button reply"}
                    onClick={() => setShowReplies(true)}
                >
                    <FontAwesome className="action-icon" name="commenting" style={{color: "grey"}}/>
                    <div className="action-name" style={{color: "grey"}}>Reply</div>
                </div>
            </div>
            {showReplies && <Replies
                postId={post.postId}
                profile={profile}
                replies={replies}
                onSetRepliesCount={setRepliesCount}
                repliesCount={repliesCount}
            />}
            <ConfirmModal
                show={confirmShow}
                onClose={() => {
                  if (!isDeleting)
                    setConfirmShow(false)
                }}
                onOk={() => handleDelete()}
                title="Delete Post"
                msg="Sure you want to delete your post?"
                actionText="Yes, delete"
                isWorking={isDeleting}
            />
            <Modal
                show={showSelectImageModal} 
                onHide={hideImageModal} 
                animation={true} 
                size='lg'
                keyboard
            >
                <Modal.Header closeButton/>
                <Modal.Body>
                    <ImageSelector
                        profile={profile}
                        onSaveImages={handleSaveImages}
                        onClose={hideImageModal}
                        currentImages={editImages}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Post;