/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../../components/Session';
import { Jumbotron } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { fadeInUp, fadeInDown } from 'react-animations';
import * as ROUTES from  '../../constants/routes';
import { Redirect } from 'react-router-dom';
import Layout from '../../components/Layout';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import Team from '../../components/Team';
import AppConfig from '../../components/App/AppConfig';

const FadeUp = styled.div`animation: 1.2s ${keyframes`${fadeInUp}`}`;
const FadeDown = styled.div`animation: 1.2s ${keyframes`${fadeInDown}`}`;

const INITIAL_STATE = {
};

class MyTeamPage extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  async componentDidMount(){
  }

  render(){
    if(AppConfig.isJobsMode()){
      return <Redirect to={ROUTES.HOME} />;
    }
    return (
      <Layout>
        <AuthDataContext.StreamConsumer requireAccount={true}>
          {profile => (
            <>
              <FadeDown>
                <Jumbotron>
                  <h1 className="text-center heading">My Team</h1>
                </Jumbotron>
              </FadeDown>
              <FadeUp>
                <Team profile={profile}/>
              </FadeUp>
            </>
          )}
        </AuthDataContext.StreamConsumer>
      </Layout>
    );
  }
}

const condition = (profile) => {
  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(
  withAuthorization(condition),
)(MyTeamPage);
