import React, { Component } from 'react'

const INITIAL_STATE = {
    dragging: false
  };
class dragAndDrop extends Component {
    
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

      };
    dropRef = React.createRef()

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})
        if (e.dataTransfer.files && e.dataTransfer.files.length === 1) {
            this.props.handleDrop(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
            this.setState({dragging: false})    
        }
    }
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++  
        if (e.dataTransfer.items && e.dataTransfer.items.length === 1) {
            this.setState({dragging: true})
        } else {
            this.setState({dragging: false})
        }
    }
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter > 0) return
        this.setState({dragging: false})
    }
    componentDidMount() {
        this.dragCounter = 0
        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }
    componentWillUnmount() {
        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }
    render() {
        return (
          <div
            style={{position: 'relative'}}
            ref={this.dropRef}
          >
            {this.state.dragging &&
              <div 
                style={{
                  border: 'dashed grey 4px',
                  backgroundColor: 'rgba(255,255,255,.8)',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0, 
                  right: 0,
                  zIndex: 9999
                }}
              >
                <div 
                  style={{
                    position: 'absolute',
                    right: 0,
                    left: 0,
                    textAlign: 'center',
                    color: 'grey',
                    fontSize: 36
                  }}
                >
                  <div>drop your file here!</div>
                </div>
              </div>
            }
            {this.props.children}
          </div>
        )
    }
    
    
}

export default dragAndDrop