/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { AuthDataContext, withAuthDataContext } from '../Firebase/authdataprovider';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      if(!condition(this.props.profile)) {
        this.props.history.push(ROUTES.HOME);
      }
    }

    componentWillUnmount() {
    }

    componentDidUpdate() {
      if(!condition(this.props.profile)) {
        this.props.history.push(ROUTES.HOME);
      }
    }

    render() {
      return (
        <AuthDataContext.StreamConsumer>
          {profile =>
            condition(profile) ? <Component profile={profile} {...this.props} /> : null
          }
        </AuthDataContext.StreamConsumer>
      );
    }
  }

  return compose(
    withRouter,
    withAuthDataContext
  )(WithAuthorization);
};

export default withAuthorization;
