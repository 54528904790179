/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './TeamGame.scss';
import Layout from '../../components/Layout';
import { isMobile } from 'react-device-detect';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import GameContainer from '../../components/TeamGame/GameContainer';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';

export default class TeamGame extends Component {
  static defaultProps = {
    match: '',
  }
  render() {
    if(isMobile) {
      return (
        <Layout>
          <ScrollTopOnPageLoad />
          <Container className="mobile-text-container">
            <p className="mobile-text">
              This feature is not currently mobile compatible
            </p>
            <p>
              Click <Link to={ROUTES.HOME}>here</Link> to return home
            </p>
          </Container>
        </Layout>
      );
    }
    return (
      <Layout>
        <ScrollTopOnPageLoad />
        <div className="text-justify">
          <DndProvider backend={Backend}>
            <AuthDataContext.StreamConsumer requireAccount={true}>
              {profile => <GameContainer profile={profile} teamId={this.props.match.params.id}/>}
            </AuthDataContext.StreamConsumer>
          </DndProvider>
        </div>
      </Layout>
    );
  }
}
