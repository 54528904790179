import React, { Component } from 'react';
import Card from '../Card';
import { Row, Col, Button } from 'react-bootstrap';
import CardBootstrap from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { icons } from '../../../assets';
import '../Cards.scss';
import { AuthDataContext } from '../../Firebase/authdataprovider';

const INITIAL_STATE = {
  flip: [false, false, false, false, false, false],
};

class CardFlip extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.handleFlip = this.handleFlip.bind(this);
  }
  static defaultProps = {
    id :'',
    answers : {},
    question : {},
    edit :false,
    index: 0,
  }

  /// <summary>
  /// handles the card flip states
  /// </summary>
  async handleFlip(index){
    const newFlip = [false, false, false, false, false, false];
    newFlip[index] = !newFlip[index];
    await this.setState({
      flip:newFlip,
    });
  }

  render() {
    
    let card = (<div></div>);
    const { id, answers, question, edit, index } = this.props;
    card = (
      <div className={`flip-card-inner ${this.state.flip[index] && ('flip' + index)}`}>
        <div className={`flip-card-front ${this.state.flip[index] ? ('flipHide') : ('flipShow')}`}>
          <div className="cards-container">
            <CardBootstrap body className="text-center">
              <AuthDataContext.StreamConsumer>
                {profile => (
                  <>
                    <Card
                      key={index + '_front'}
                      id={id}
                      answers={answers}
                      question={question}
                      edit={edit}
                      save={this.props.saveCard}
                      profile={profile}
                    />
                    {(answers[id] && answers[id].answer) ? 
                      <Row className="mb-2">
                        <Col className="text-left mx-3">
                          <div className="card-flip" onClick={() => this.handleFlip(index)}>
                            <img className="flip" src={icons.flip} alt="Flip Card"/>
                            <span className="sample-text">TIPS & GUIDANCE</span>
                          </div>
                        </Col>
                        <Col className="text-right mx-3">
                          <Link to={ROUTES.CCCid + id}>
                            <Button variant="link" className="sample-text">
                                EDIT
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                      : 
                      <Row>
                        <Col>
                          <div className="card-flip" onClick={() => this.handleFlip(index)}>
                            <img className="flip" src={icons.flip} alt="Flip Card"/>
                            <span className="sample-text">TIPS & GUIDANCE</span>
                          </div>
                        </Col>
                      </Row>
                    }
                  </>
                )}
              </AuthDataContext.StreamConsumer>
            </CardBootstrap>
          </div>
        </div>
        <div className={`flip-card-back ${!this.state.flip[index] ? ('flipHide') : ('flipShow')}`} onClick={this.handleFlip}>
          <div className="cards-container">
            <CardBootstrap body className="text-center">
              <Card
                key={index + '_back'}
                id={id}
                answers={answers}
                question={question}
                example={true}
                edit={false}
              />
              <img className="flip" src={icons.flip} alt="Flip Card" onClick={() => this.handleFlip(index)}/>
            </CardBootstrap>
          </div>
        </div>
      </div>
    );
    return (
      <div className="singleCard">
        {card}
      </div>
    );
  }
}

export default CardFlip;
