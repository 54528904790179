/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { Component } from 'react';
import Layout from '../../components/Layout';
import { Jumbotron, Container } from 'react-bootstrap';

class Privacy extends Component {
  render(){
    return (
      <Layout>
        <Container>
          <Jumbotron>
            <h1 className="text-center">Privacy Policy</h1>
          </Jumbotron>

          <div className="text-justify">
            <p>
              <em>Effective Date:</em>{' '}
              <strong>
                <em>May 30, 2019</em>
              </strong>
            </p>
            <p>
              <strong>
                <u>Privacy Policy</u>
              </strong>
            </p>
            <p>
              Keeper Recruitment Corporation provides this Privacy Policy to inform you of our policies and
              procedures regarding the collection, use and disclosure of personal information we receive from
              users of keeperai.com (the &ldquo;
              <strong>
                <em>Site</em>
              </strong>
              &rdquo;).&nbsp; This Privacy Policy applies only to information that you provide to us through
              this Site.&nbsp; This Privacy Policy may be updated from time to time.&nbsp; We will notify you of
              any material changes by posting the new Privacy Policy on the Site.&nbsp; You are advised to
              consult this policy regularly for any changes.
            </p>
            <p>
              As used in this policy, the terms &ldquo;using&rdquo; and &ldquo;processing&rdquo; information
              include using cookies on a computer, subjecting the information to statistical or other analysis
              and using or handling information in any way, including, but not limited to collecting, storing,
              evaluating, modifying, deleting, using, combining, disclosing and transferring information within
              our organization or among our affiliates within the United States or internationally.
            </p>
            <p>
              <strong>Information Collection and Use:&nbsp; </strong>
              <strong>Personally Identifiable Information</strong>
            </p>
            <ul>
              <li>
                In the course of using this Site, you may provide us with personally identifiable information.
                This refers to information about you that can be used to contact or identify you (&ldquo;
                <strong>
                  <em>Personal Information</em>
                </strong>
                &rdquo;). Personal Information includes, but is not limited to, your name, phone number, credit
                card or other billing information, email address and home and business postal addresses.&nbsp;
                We use your Personal Information mainly to provide our services and administer your
                inquiries.&nbsp; For example, when you register with us through the Site, as a registered user
                or when you choose to create your own profile webpage we will ask you for Personal Information.
              </li>
            </ul>
            <ul>
              <li>
                We also collect the other information that you provide as part of registration and the
                administration and personalization of your profile webpage (<em>g.</em>, without limitation, zip
                code (on its own) and individual preferences) (&ldquo;
                <strong>
                  <em>Non-Identifying Information</em>
                </strong>
                &rdquo;).
              </li>
            </ul>
            <ul>
              <li>
                We use your Personal Information (in some cases, in conjunction with your Non-Identifying
                Information) mainly to provide the service, complete your transactions, and administer your
                inquiries.
              </li>
            </ul>
            <ul>
              <li>
                Certain Non-Identifying Information would be considered a part of your Personal Information if
                it were combined with other identifiers (for example,&nbsp;combining your zip code with your
                street address)&nbsp;in a way that enables you to be identified. <em>But </em>the same pieces of
                information are considered Non-Identifying Information when they are taken alone or combined
                only with other non-identifying information (for example,&nbsp;your viewing preferences).
              </li>
            </ul>
            <p>
              <em>
                <u>Log Data</u>
              </em>
              <em>.&nbsp; </em>When you visit the Site, whether as a registered user or a non-registered user
              just browsing (any of these, a{' '}
              <strong>
                &ldquo;<em>Keeper Recruitment Corporation User</em>&rdquo;
              </strong>
              ), our servers automatically record information that your browser sends whenever you visit a
              website (
              <strong>
                <em>&ldquo;Log Data&rdquo;</em>
              </strong>
              ).&nbsp; This Log Data may include information such as your computer&rsquo;s Internet Protocol
              (&ldquo;IP&rdquo;) address, browser type or the webpage you were visiting before you came to our
              Site, pages of our Site that you visit, the time spent on those pages, information you search for
              on our Site, access times and dates, and other statistics.&nbsp; We use this information to
              monitor and analyze use of the Site and for the Site's technical administration, to increase our
              Site&rsquo;s functionality and user-friendliness, and to better tailor the Site to our
              visitors&rsquo; needs.&nbsp; For example, some of this information is collected so that when you
              visit the Site again, it will recognize you and serve advertisements and other information
              appropriate to your interests.&nbsp; We also use this information to verify that visitors to the
              Site meet the criteria required to process their requests.&nbsp; We do not treat Log Data as
              Personal Information or use it in association with other Personal Information, though we may
              aggregate, analyze and evaluate such information for the same purposes as stated above regarding
              other Non-Identifying Information.
            </p>
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              Like many websites, we use &ldquo;cookies&rdquo; to collect information. &nbsp;A cookie is a small
              data file that we transfer to your computer&rsquo;s hard disk for record-keeping purposes.&nbsp;
              We do link the information we store in cookies to the Personal Information you submit while on our
              Site.
            </p>
            <p>
              We use cookies for two purposes.&nbsp; First, we utilize persistent cookies to save your
              registration ID and login password for future logins to the Site.&nbsp; Second, we utilize session
              ID cookies to enable certain features of the Site, to better understand how you interact with the
              Site and to monitor aggregate usage by Keeper Recruitment Corporation Users and web traffic
              routing on the Site.&nbsp; Unlike persistent cookies, session cookies are deleted from your
              computer when you log off from the Site and then close your browser.&nbsp; Third party advertisers
              on the Site may also place or read cookies on your browser.&nbsp; You can instruct your
              browser,&nbsp;by changing its options, to stop accepting cookies or to prompt you
              before&nbsp;accepting a cookie from the websites you visit.&nbsp; If you do not accept cookies,
              however, you may not be able to use all portions of the Site or all functionality of our
              services.&nbsp;
            </p>
            <p>
              <strong>Web Beacons</strong>
            </p>
            <p>
              We also use &ldquo;web beacons,&rdquo; &ldquo;pixel tags,&rdquo; &ldquo;clear GIFs&rdquo; or
              similar means (individually or collectively &ldquo;
              <strong>
                <em>Web Beacons</em>
              </strong>
              &rdquo;) on our Site.&nbsp; A Web Beacon is an electronic image, often a single pixel, embedded on
              web pages.&nbsp; Web Beacons are ordinarily not visible to users. Web Beacons allow us to count
              the number of users who have visited certain pages of the Site, to deliver branded services and to
              generate statistics about how our Site is used.
            </p>
            <p>
              <strong>Information Sharing and Disclosure</strong>
            </p>
            <p>
              <em>Keeper Recruitment Corporation Registered Users</em>.&nbsp; When you register through the Site
              and submit your Personal Information to create a profile webpage, other registered users will see
              your &ldquo;Screen Name&rdquo; and the month and year you first registered with Keeper Recruitment
              Corporation (&ldquo;<strong>Required Subscriber Identifiers</strong>&rdquo;). You can choose what
              other Personal Information you provide as part of your profile (&ldquo;
              <strong>Other Subscriber Information</strong>&rdquo;) and in some cases you can choose whether to
              share that information publicly on the Site by making selections via the &ldquo;user
              preferences&rdquo; section of your profile webpage.&nbsp; Providing Other Subscriber Information
              is voluntary and should correlate with the degree of interaction you want to have with Keeper
              Recruitment Corporation Users. We recommend that you guard your anonymity and sensitive
              information and that you not use your real name as your &ldquo;Screen Name,&rdquo; since that
              would allow others to identify you.
            </p>
            <p>
              <em>
                <u>Service Providers</u>
              </em>
              .&nbsp; We may employ third party companies and individuals to facilitate our services, to provide
              our services on our behalf, to perform Site-related services (<em>e.g.</em>, without limitation,
              maintenance services, database management, web analytics and improvement of the Site's features)
              or to assist us in analyzing how our Site is used.&nbsp; These third parties have access to your
              Personal Information only to perform these tasks on our behalf and are obligated not to disclose
              or use it for any other purpose.
            </p>
            <p>
              <em>
                <u>Social Networking Sites.</u>
              </em>
              &nbsp; We may share the information in your Keeper Recruitment Corporation account profile
              (including your Personal Information) with the SNSs you have linked to your Keeper Recruitment
              Corporation account to provide you with enhanced services. These third parties have access to your
              Personal Information only for this purpose (unless they have received your Personal Information
              independently of the Keeper Recruitment Corporation service) and are obligated not to disclose or
              use it for any other purpose.
            </p>
            <p>
              <em>
                <u>Compliance with Laws and Law Enforcement</u>
              </em>
              .&nbsp; Keeper Recruitment Corporation cooperates with government and law enforcement officials
              and private parties to enforce and comply with the law.&nbsp; We will disclose any information
              about you to government or law enforcement officials or private parties as we, in our sole
              discretion, believe necessary or appropriate to respond to claims and legal process (including but
              not limited to subpoenas), to protect the property and rights of Keeper Recruitment Corporation or
              a third party, to protect the safety of the public or any person, or to prevent or stop activity
              we may consider to be, or to pose a risk of being, any illegal, unethical or legally actionable
              activity.
            </p>
            <p>
              <em>
                <u>Business Transfers</u>
              </em>
              .&nbsp; Keeper Recruitment Corporation may sell, transfer or otherwise share some or all of its
              assets, including your Personal Information, in connection with a merger, acquisition,
              reorganization or sale of assets or in the event of bankruptcy.
            </p>
            <p>
              <strong>Security</strong>
            </p>
            <p>
              Keeper Recruitment Corporation is very concerned with safeguarding your information.&nbsp; We
              employ administrative, physical and electronic measures designed to protect your information from
              unauthorized access.
            </p>
            <p>
              &nbsp;We will make any legally required disclosures of any&nbsp; breach of the security,
              confidentiality, or integrity of your unencrypted electronically stored &ldquo;personal
              data&rdquo; (as defined in applicable state statutes on security breach notification) to you via
              email or conspicuous posting on this Site in the most expedient time possible and without
              unreasonable delay, insofar as consistent with (i) the legitimate needs of law enforcement or (ii)
              any measures necessary to determine the scope of the breach and restore the reasonable integrity
              of the data system.
            </p>
            <p>
              <strong>International Transfer</strong>
            </p>
            <p>
              Your information may be transferred to &mdash; and maintained on &mdash; computers located outside
              of your state, province, country or other governmental jurisdiction where the privacy laws may not
              be as protective as those in your jurisdiction.&nbsp; If you are located outside the United States
              and choose to provide information to us, Keeper Recruitment Corporation transfers Personal
              Information to the United States and processes it there.&nbsp; Your consent to this Privacy Policy
              followed by your submission of such information represents your agreement to that transfer.
            </p>
            <p>
              <strong>Our Policy Toward Children</strong>
            </p>
            <p>
              This Site is not directed to children under 18. We do not knowingly collect personally
              identifiable information from children under 13.&nbsp; If a parent or guardian becomes aware that
              his or her child has provided us with Personal Information without their consent, he or she should
              contact us at <a href="mailto:support@keeperai.com"> support@keeperai.com</a>.&nbsp; If we become 
              aware that a child under 13 has provided us with Personal Information, we will delete such 
              information from our files.
            </p>
            <p>
              <strong>Phishing</strong>
            </p>
            <p>
              Identity theft and the practice currently known as &ldquo;phishing&rdquo; are of great concern to
              Keeper Recruitment Corporation.&nbsp; Safeguarding information to help protect you from identity
              theft is a top priority.&nbsp; We do not and will not, at any time, request your credit card
              information, your account ID, login password, or national identification numbers in a non-secure
              or unsolicited e-mail or telephone communication.&nbsp; For more information about phishing, visit
              the Federal Trade Commission&rsquo;s website.
            </p>
            <p>
              <strong>Links to Other Sites</strong>
            </p>
            <p>
              Our Site contains links to other websites.&nbsp; If you choose to visit an advertiser by
              &ldquo;clicking on&rdquo; a banner ad or other type of advertisement, or click on another third
              party link, you will be directed to that third party&rsquo;s website.&nbsp; The fact that we link
              to a website or present a banner ad or other type of advertisement is not an endorsement,
              authorization or representation of our affiliation with that third party, nor is it an endorsement
              of their privacy or information security policies or practices.&nbsp; We do not exercise control
              over third party websites.&nbsp; These other websites may place their own cookies or other files
              on your computer, collect data or solicit personal information from you.&nbsp; Other sites follow
              different rules regarding the use or disclosure of the personal information you submit to
              them.&nbsp; We encourage you to read the privacy policies or statements of the other websites you
              visit.
            </p>
            <p>
              <strong>Contacting Us </strong>
            </p>
            <p>If you have any questions about this Privacy Policy, please contact us at: 
              <a href="mailto:hello@keeperai.com"> hello@keeperai.com</a></p>
          </div>
        </Container>
      </Layout>
    );
  }
}

export default Privacy;
