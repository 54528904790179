import React, { Component } from 'react';
import './fof.scss';
import Layout from '../../components/Layout';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Container } from 'react-bootstrap';
// import ReactGA from 'react-ga';
// ReactGA.initialize(process.env.REACT_APP_GA!);
// ReactGA.pageview(window.location.pathname + window.location.search);

/// Renders 404 component
class FourOFour extends Component {
  render(){
    return (
      <Layout fof={true} dark={true}>
        <Container>
          <div className="FOF-container">
            <div>
              <p className="FOFtitle">OOPS</p>
              <p className="FOFsubTitle">PAGE NOT FOUND</p>
            </div>
            <div className="gifContainer">
              <Link to={ROUTES.HOME}>
                <img 
                  src="https://media.giphy.com/media/14uQ3cOFteDaU/source.gif" 
                  alt="404, Page not Found" />
              </Link>
            </div>
          </div>
        </Container>
      </Layout>
    );
  }
}

export default FourOFour;
