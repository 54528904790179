/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Card from './Card';
import CardFlip from './CardFlip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardBootstrap from 'react-bootstrap/Card';
import './Cards.scss';


class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      renderCards:true, 
      softSkillsAnswers: this.props.softSkillsAnswers,
    };
  }

  static defaultProps = {
    edit: false,
    flip: false,
    match: '',
  }


  cardList = () => {
    
    try {
      const template = this.props.template;
      const softSkillsAnswers = this.state.softSkillsAnswers;
      const edit = this.props.edit;
      const flip = this.props.flip;
      const cards = [];

      for(let i = 0; i < template.length; i++) {
        cards.push(
          flip ? 
            <Col key={'column' + i} md={6} className="flip-card">
              <CardFlip
                
                key={template[i] + '_flippable'}
                id={template[i]}
                index={i}
                answers={softSkillsAnswers}
                edit={edit}
                saveCard={this.props.profile.save}
              />
            </Col> :
            <Col key={'column' + i} md={6}>
              <div className="cards-container">
                <CardBootstrap body className="text-center">
                  <Card
                    key={template[i] + '_nonflippable'}
                    id={template[i]}
                    answers={softSkillsAnswers}
                    edit={edit}
                    dontShowAddButton={this.props.profile}
                  />
                </CardBootstrap>
              </div>
            </Col>
        );
      }
      return cards;
    } catch(exception){
      console.log(exception);
    }
  }

  
  render() {
    return (
      <div className="cards">
        <Row>
          {this.cardList()}
        </Row>
      </div>
    );
  }
}

export default Cards;
