/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import '../../../styles/resume.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const INITIAL_STATE = {
  pageNumber: 1,
  numPages: 1,
};

class Reader extends Component{
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  previousPage = () => this.changePage(-1);
  nextPage = () => this.changePage(1);

  changePage = (offset) => this.setState(prevState => ({
    pageNumber: prevState.pageNumber + offset,
  }));

  render(){
    const { pageNumber, numPages } = this.state;
    const { resumeURL, isMyProfile } = this.props;
    return (
      <>
        <Card className="resume-container">
          <Document file={resumeURL} onLoadSuccess={this.onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          {
            (numPages > 1)
              && <div className="block resume-navCandidateView">
                <button 
                  className="btn-pagination"
                  disabled={pageNumber <= 1} 
                  onClick={this.previousPage}>
                  <FontAwesome name="arrow-left" className="previousPage"/>
                </button>
                <span className="container resumePageNumber">
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </span>
                <button 
                  className="btn-pagination"
                  disabled={pageNumber >= numPages} 
                  onClick={this.nextPage}>
                  <FontAwesome name="arrow-right" className="nextPage"/>
                </button>
              </div>
          }
          {!isMyProfile && (
            <div className = "user-edit">
              <a href={resumeURL} className="btn-solid-purple download" download target="Blank" ><FontAwesome name="arrow-to-bottom" className="arrow" /> DOWNLOAD</a>
            </div>
          )}
        </Card>
      </>);
  }
}

export default Reader;
