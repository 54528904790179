import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import f from 'firebase';

import "./Replies.scss";
import ImageSelector from '../../ImageSelector';
import Reply from './Reply';
import CustomCarousel from '../../CustomCarousel';

const LOADING_LIMIT = 3;

const Replies = ({ profile, postId, repliesCount, onSetRepliesCount }) => {
    let profileImage;
    if(profile?.data?.profiles?.default?.profilePicture === "https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"){
        profileImage = '/assets/media/profile/default-avatar.jpeg'
    }else{
        profileImage=profile?.data?.profiles?.default?.profilePicture
    }
    ;
    const [comment, setComment] = useState("");
    const [replyImages, setReplyImages] = useState([]);
    const [repliesOfPost, setRepliesOfPost] = useState([]);

    const [loadingReplies, setLoadingReplies] =useState(false);
    const [latestReply, setLatestReply] = useState(new Date("2100-01-01"));
    const [hasMore, setHasMore] = useState(true);

    const [showSelectImageModal, setShowSelectImageModal] = useState(false);
    const showImageModal = () => setShowSelectImageModal(true);
    const hideImageModal = () => setShowSelectImageModal(false);

    useEffect(() => {
        loadReplies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleReply = async () => {
        try {
            const data = {
                postId: postId,
                comment: comment,
                images: replyImages,
                repliedBy: profile.uid,
                likedUsers: [],
                creationDate: f.firestore.Timestamp.now(),
                lastUpdate: f.firestore.Timestamp.now(), 
            }
            const replyId = await profile.createReply(data);
            setRepliesOfPost([
                {replyId, ...data, user: profile.data.profiles.default}, ...repliesOfPost,
            ]);
            setComment("");
            setReplyImages([]);
            onSetRepliesCount(repliesCount + 1);
        } catch (error) {
            console.log(`Error in handleReply ${error}`);
        } finally {

        }
    }

    const loadReplies = async () => {
        if (loadingReplies) return;
        try {
            setLoadingReplies(true);
            let moreReplies = await profile.getReplies( postId, latestReply, LOADING_LIMIT);
            const userPromises = [];
            moreReplies.forEach(post => {
                userPromises.push(profile.getUserProfile(post.repliedBy))
            });
            const users = await Promise.all(userPromises);
            moreReplies = moreReplies.map((post, index) => {
                return {
                    ...post,
                    user: users[index]
                }
            });
            console.log("replies", moreReplies);
            if (moreReplies && moreReplies.length) {
                setRepliesOfPost([...repliesOfPost, ...moreReplies]);
                setLatestReply(moreReplies[moreReplies.length - 1].creationDate);
                if (moreReplies.length < LOADING_LIMIT) {
                    setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.log(`Error in loadReplies ${error}`);
        } finally {
            setLoadingReplies(false);
        }
    }

    const handleEditReply = (replyId, newImages, newComment) => {
        const _replies = repliesOfPost;
        const index = _replies.findIndex(reply => reply.replyId === replyId);
        if (index !== -1) {
            _replies[index].images = [...newImages];
            _replies[index].comment = newComment;
            _replies[index].lastUpdate = f.firestore.Timestamp.now();
        }
        setRepliesOfPost([..._replies]);
    }

    const handleReplyDelete = (replyId) => {
        const _replies = repliesOfPost;
        const index = _replies.findIndex(reply => reply.replyId === replyId);
        _replies.splice(index, 1);
        setRepliesOfPost([..._replies]);
        onSetRepliesCount(repliesCount - 1);
    }

    const handleSaveImages = (images) => {
        setReplyImages(images);
    }

    return (
        <div className="replies">
            <div className="replier-box">
                <div className="replier-image">
                    <img src={profileImage} alt=""/>
                </div>
                <div className="replier-content">
                    <div className="content">
                        <Form.Control
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            as="textarea"
                            rows={1}
                            placeholder="Add a comment..."
                        />
                        <CustomCarousel
                            cName="images-to-reply"
                            images={replyImages}
                            onDeselectImages={() => {setReplyImages([])}}
                        />
                        {comment?<div className="post-button" onClick={handleReply}>Post</div>:(null)}
                    </div>
                    <div className="add-image" onClick={showImageModal}>
                        <FontAwesome name='image'/>
                    </div>
                </div>
            </div>
            <div className="reply-list">
                {repliesOfPost.map(((reply) => {
                    return (
                        <Reply
                            key={reply.replyId}
                            reply={reply}
                            profile={profile}
                            onReplyDelete={handleReplyDelete}
                            onEditReply={handleEditReply}
                        />
                    )
                }))}
                {(hasMore && !loadingReplies) ?
                    <div className="loadmore" onClick={loadReplies}>Load more...</div>
                    :(null)
                }
            </div>
            <Modal
                show={showSelectImageModal} 
                onHide={hideImageModal} 
                animation={true} 
                size='lg'
                keyboard
            >
                <Modal.Header closeButton/>
                <Modal.Body>
                    <ImageSelector
                        profile={profile}
                        onSaveImages={handleSaveImages}
                        onClose={hideImageModal}
                        currentImages={[]}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Replies;