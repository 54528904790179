import React from 'react';
import { Jumbotron, Container } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Searches from '../../components/Searches';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import { compose } from 'recompose';
import { withAuthorization } from '../../components/Session';
import AppConfig from '../../components/App/AppConfig';
const isJob = AppConfig.isJobsMode();
const Search = () => (
  <Layout>
    <Jumbotron className="text-center">
      <h1 className="heading">Search</h1>
    </Jumbotron>
    <AuthDataContext.StreamConsumer requireAccount={true}>
      {profile => 
        (
          <Container>
            <Searches profile={profile} version={isJob ? 'job' : 'team'}/>
          </Container>
        )}
    </AuthDataContext.StreamConsumer>
  </Layout>
);

const condition = (profile) => {
  if(AppConfig.isJobsMode()){
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(
  withAuthorization(condition),
)(Search);
