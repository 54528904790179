/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { images } from '../../../assets';
import FontAwesome from 'react-fontawesome';
import AppConfig from '../../../components/App/AppConfig';


const INITIAL_STATE = {
};

class ProfileHeader extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  static defaultProps = {
    user:'',
    isMyProfile: false,
    review: false,
    userProfile: null,
    profile:'',
  }

  iconHandler = (customLink) => {
    if(customLink.includes('facebook')) {
      return 'facebook-square';
    } else if(customLink.includes('twitter')) {
      return 'twitter';
    } else if(customLink.includes('linkedin')) {
      return 'linkedin-square';
    } else if(customLink.includes('instagram')) {
      return 'instagram';
    } else if(customLink.includes('github')) {
      return 'github';
    } else if(customLink.includes('amazon.com/hz/wishlist')) {
      return 'amazon';
    } else {
      return 'link';
    }
  }

  render(){
    const { userProfile, review, isMyProfile } = this.props;
    const thisProfile = userProfile;

    return (
      <div>
        {isMyProfile ? (
          <div className="review">
            <p className="heading profile-title">REVIEW PROFILE</p>
          </div>
        ) : (
          <div className="view-profile" />
        )}
        <div className={review ? 
          ('candidate-info candidate-info-top') : 
          ('candidate-info')}>
          <Row className="column-info">
            <div className="profile-pic-container">
              <img 
                alt={thisProfile.fullName} 
                src={(thisProfile.profilePicture!=='https://ramcotubular.com/wp-content/uploads/default-avatar.jpg') ? 
                  thisProfile.profilePicture : 
                  images.avatar}
                className="profile-pic" />
            </div>
            <div className="column-name">
              <div className="profile-name">{thisProfile.fullName}</div>
            
              <div className="icons">
                {(AppConfig.isJobsMode() &&
                (thisProfile.email && thisProfile.email !== '')) && (
                  <a 
                    href={'mailto:' + thisProfile.email} 
                    className="icon-link">
                    <FontAwesome name="envelope" />
                  </a>
                )}
                {(!AppConfig.isJobsMode() &&
                <div>
                  {thisProfile.email && (<p className="officeInfo">Email: {thisProfile.email}</p>)}
                  {thisProfile.officePhone && (<p className="officeInfo">Office Phone: {thisProfile.officePhone}</p>)}
                  {thisProfile.managerEmail && thisProfile.managerEmail !== 'CEO-EXEMPT' && (<p className="officeInfo">Manager Email: {thisProfile.managerEmail}</p>)}
                </div>
                )}
                {thisProfile.customLinks && thisProfile.customLinks.map(customLink => 
                  customLink !== '' && 
                <a 
                  href={customLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="icon-link"
                  key={customLink}>
                  <FontAwesome name={this.iconHandler(customLink)} />
                </a>
                )}
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}


export default ProfileHeader;
