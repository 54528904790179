/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import FontAwesome from 'react-fontawesome';
import { Spinner } from 'react-bootstrap';
import {
  AuthDataContext
} from '../../components/Firebase/authdataprovider';
import {
  Col,
  Form,
  Button,
  Jumbotron,
  InputGroup
} from 'react-bootstrap';



const SignInModal = (props) => (
  <AuthDataContext.StreamConsumer>
    {profile => (
      <>
        <Jumbotron>
          <h1 className="modal-title">Sign In</h1>
        </Jumbotron>
        <SignInForm profile={profile} hide={props.hide} />
        {/*<SignInGoogle profile={profile} /> */}
        {/* <SignInFacebook /> */}
      </>
    )}
  </AuthDataContext.StreamConsumer>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  errorMessage: '',
  passwordType: 'password',
  loading: false
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  // componentDidMount = () => {
  //   Notiflix.Notify.init({
  //     cssAnimationStyle: 'from-top',
  //     success: { background: '#7846c2' },
  //     cssAnimationDuration: 600,
  //   });
  // }

  onSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });

    const { email, password } = this.state;

    this.props.profile.doSignInWithEmailAndPassword(email, password)
      .then((res) => {
        this.setState({ ...INITIAL_STATE });
        if(email.includes('@gmail.com')) {
          localStorage.setItem('disable', false);
        } else {
          localStorage.setItem('disable', true);
        }
        if(res && res.profiles?.default.insights?.color !== '') {
          console.log('has profile');
          this.props.history.push(`/insights/${res.uid}`);
        } else {
          this.props.history.push('mycontent');
        }
      })
      .catch(error => {
        console.log('error :: ', error);
        if(error.code === 'auth/too-many-requests') {
          this.setState({ error, loading: false, errorMessage: 'Too many login attempts. Please try again later.' });
        } else if(error.code === 'auth/user-disabled') {
          this.setState({ error, loading: false, errorMessage: error.message });
        } else {
          this.setState({ error, loading: false, errorMessage: 'Login failed. Email and/or password are incorrect.' });

        }
      });
  };

  onChange = async(event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showHidePassword = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
    });
  }

  render() {
    const {
      email,
      password,
      error,
      errorMessage,
      passwordType,
      loading
    } = this.state;
    const isInvalid = password === '' || email === '';


    return (
      <Form onSubmit={this.onSubmit} className="text-center sign-up-form">
        <Col mx={2}>
          <Form.Group className="text-left">
            <Form.Label className="mt-1">Email</Form.Label>
            <Form.Control
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
              className="text-left"
            />
          </Form.Group>
          <Form.Group className="text-left">
            <Form.Label className="mt-1">Password</Form.Label>
            <InputGroup>
              <Form.Control
                name="password"
                value={password}
                onChange={this.onChange}
                type={passwordType}
                placeholder="Password"
                className="text-left password-fields"
              />
              <InputGroup.Append onClick={this.showHidePassword}>
                <InputGroup.Text>
                  {/* <i className={passwordType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye' } /> */}
                  <FontAwesome name={passwordType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        {loading ?
          <div className="sign-up-btn-loader"> <Button
            disabled
            variant="primary"

            className="btn-solid-purple mb-2 sign-up-btn-loader">
            <Spinner
              as="span"
              className="spinner-border-2"
              animation="border"
              variant="secondary"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="load-text"> Sign In </span>
          </Button></div> :
          <Button
            className="btn-solid-purple mb-2"
            disabled={isInvalid}
            onClick={this.onSubmit}>
            Sign In
          </Button>
        }

        {error && <p>{errorMessage}</p>}
      </Form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    event.preventDefault();


    this.props.profile.doSignInWithGoogle()
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button className="btn-sso btn-google my-3" type="submit">
          <FontAwesome className="mr-2" name="google" />Sign In with Google
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

// class SignInFacebookBase extends Component {
//   constructor(props) {
//     super(props);

//     this.state = { error: null };
//   }

//   onSubmit = event => {
//     event.preventDefault();

//     this.props.profile.doSignInWithFacebook()
//       .then(() => {
//         this.setState({ ...INITIAL_STATE });
//         this.props.hide();
//       })
//       .catch(error => {
//         this.setState({ error });
//       });
//   };

//   render() {
//     const { error } = this.state;

//     return (
//       <form onSubmit={this.onSubmit}>
//         <Button className='btn-sso btn-facebook my-3' type='submit'>
//           <FontAwesome className='mr-2' name='facebook'/>Sign In with Facebook
//         </Button>

//         {error && <p>{error.message}</p>}
//       </form>
//     );
//   }
// }



const SignInForm = compose(
  withRouter,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
)(SignInGoogleBase);

// const SignInFacebook = compose(
//   withRouter
// )(SignInFacebookBase);

export default SignInModal;

export { SignInForm, SignInGoogle };
