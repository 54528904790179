/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './MyInfo.scss';
import { compose } from 'recompose';
import { 
  Container,
  Jumbotron,
  Form
} from 'react-bootstrap';

import { withAuthorization } from '../../components/Session';
import SelfAddManager from '../../components/TeamsCRUD/selfAddManager';
import Layout from '../../components/Layout';
import NameInput from '../../components/NameInput';
import ProfilePicInput from '../../components/ProfilePicInput';
import SocialLinks from '../../components/SocialLinks';
import Controllers from '../../components/Controllers';
import * as ROUTES from '../../constants/routes';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import styled, { keyframes } from 'styled-components';
import { fadeInDown, fadeInUp } from 'react-animations';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';
import AppConfig from '../../components/App/AppConfig';
import MyLocation from '../../components/MyLocation';
import MyTeam from '../../components/MyTeam';
import MyRole from '../../components/MyRole';
import MyBirthday from '../../components/MyBirthday';
import JoiningDate from '../../components/JoiningDate';
const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;


class MyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'job'
    };
 
  }

  componentDidMount() {
   
    if(AppConfig.isJobsMode()){
      this.setState({ mode: 'job' });
    } else {
      this.setState({ mode: 'team' });
    }
  }

  JobRender = () => {
    const profile = this.props.profile;
    console.log(profile)
    return (
      <Container>
        <Form onSubmit={(event) => {event.preventDefault();}}>
          <FadeDown>
            <ProfilePicInput profile={profile} />
            {/* <Controllers backRoute={ROUTES.MY_CONTENT} nextRoute={ROUTES.MY_PROFILEid + profile.uid} /> */}
          </FadeDown>
          <FadeUp className="my-auto">
            <NameInput profile={profile}/>
            <MyLocation profile={profile} />
            {(profile.data.company.name !=="open") &&
            <MyTeam profile={profile} />}
             {(profile.data.company.name !=="open") &&
            <MyRole profile={profile} />}
            {/* <MyBirthday profile={profile} /> */}
            {(profile.data.company.name !=="open") &&
            <JoiningDate profile={profile} />}
            {/* <ResumeInput profile={profile}/> */}
    
          </FadeUp>
          {/*<CurrentUserPDF profile={profile}/>*/}
        </Form>
        <FadeUp className="social-links-container">
          <SocialLinks profile={profile}/>
        </FadeUp>
        <Controllers profile={profile} backRoute={ROUTES.MY_CONTENT} nextRoute={ROUTES.MY_PROFILEid + profile.uid} />
      </Container>
    );
  } 

  TeamRender = () => {
    const profile = this.props.profile;
    console.log(profile)
    return (
      <Container>
        <Form onSubmit={(event) => {event.preventDefault();}}>
          <FadeDown>
            <ProfilePicInput profile={profile} />
            {/* <Controllers backRoute={ROUTES.MY_CONTENT} nextRoute={ROUTES.MY_PROFILEid + profile.uid} /> */}
          </FadeDown>
          <FadeUp className="my-auto">
            <MyLocation profile={profile} />
            {console.log('MyLocation')}
            <MyTeam profile={profile} />
            <MyRole profile={profile} />
            <SelfAddManager profile={profile}/>
            {/* <ResumeInput profile={profile}/> */}
          </FadeUp>
          {/*<CurrentUserPDF profile={profile}/>*/}
        </Form>
        <FadeUp className="social-links-container">
          <SocialLinks profile={profile}/>
        </FadeUp>

        <Controllers profile={profile} backRoute={ROUTES.MY_CONTENT} nextRoute={ROUTES.MY_PROFILEid + profile.uid} />
      </Container>
    );
  }

  render() {
    const { mode } = this.state;
  
    return (
      <Layout>
        <ScrollTopOnPageLoad/>
        <Container className="text-center">
          <FadeDown>
            <Jumbotron>
              <h1 className="heading">Tell us a Bit More About Yourself!</h1>
            </Jumbotron>
            <p>{mode === 'job' ? 'Required fields marked with *' : 'Required fields marked with *'}</p>
          </FadeDown>
          <AuthDataContext.StreamConsumer>
            {profile => (
              mode === 'job' ? <this.JobRender profile={profile}/> : <this.TeamRender profile={profile}/>
            )}
          </AuthDataContext.StreamConsumer>
        </Container>
      </Layout>
    );
  }
}


const condition = (profile) => {
  if(AppConfig.isJobsMode()){
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(
  withAuthorization(condition),
)(MyInfo);
