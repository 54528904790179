import React, { useState } from 'react'
import "./SortBar.css";
import Dropdown from 'react-bootstrap/Dropdown';

const SortBar = ({ data, setData }) => {

  const [sortedBy, setSortedBy] = useState("Date: Latest to Oldest");

  return (
      // <div className='sortbar'>
      //     <div className='dropdown-btn'>
      //         Sort by: {sortedBy}
      //     </div>
      //     <div className='dropdown-content'>
      //         <div className='dropdown-item'>
      //             one
      //         </div>
      //         <div className='dropdown-item'>
      //             one
      //         </div>
      //         <div className='dropdown-item'>
      //             one
      //         </div>
      //         <div className='dropdown-item'>
      //             one
      //         </div>
      //     </div>
      // </div>
    <Dropdown>
      <Dropdown.Toggle className='sortDropdown-btn' id="dropdown-basic" >
        Sort by: {sortedBy}
      </Dropdown.Toggle>

      <Dropdown.Menu className='sortDropdown-menu'>
        <Dropdown.Item onClick={e => {
            setSortedBy(e.target.textContent);
            data.sort((a,b) => a.timestamp - b.timestamp);
            setData(data);
            }}>Date: Oldest to Latest</Dropdown.Item>
        <Dropdown.Item onClick={e => {
            setSortedBy(e.target.textContent);
            data.sort((a, b) => b.timestamp - a.timestamp);
            setData(data);
            }}>Date: Latest to Oldest</Dropdown.Item>
        <Dropdown.Item onClick={e => {
            setSortedBy(e.target.textContent);
            data.sort((a, b) => {
              if (a.answer.length !== b.answer.length) {
                return b.answer.length - a.answer.length;
              }
              return b.timestamp - a.timestamp;
            });
            setData(data);
            }}>Response: Most to Least</Dropdown.Item>
        <Dropdown.Item onClick={e => {
            setSortedBy(e.target.textContent);
            data.sort((a, b) => {
              if (a.answer.length !== b.answer.length) {
                return a.answer.length - b.answer.length;
              }
              return b.timestamp - a.timestamp;
            });
            setData(data);
            }}>Response: Least to Most</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SortBar;