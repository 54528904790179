/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import '../Cards.scss';
import SoftSkills from '../../../constants/softskills';
import FontAwesome from 'react-fontawesome';

const INITIAL_STATE = {
  contentType: 0,
  answer: '',
  mediaURL: '',
  title: '',
  subTitle: '',
  exAnswer: '',
  exContent: '',
  exContentType: 0,
};

class Card extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const { id, answers } = this.props;
    const details = SoftSkills.softSkills[id];
    let ans = {};
    if(answers && id in answers) {
      ans = answers[id];
    } else {
      ans = {
        answer: '',
        content: '',
        contentType: 0,
      };
    }
   
    this.setState({
      title: details.title,
      subTitle: details.sub,
      exAnswer: details.sampleAnswer,
      exContent: details.sampleContent,
      exContentType: details.sampleContentType,
      answer: ans.answer,
      mediaURL: ans.content,
      contentType: ans.contentType,
    });
  }
  onBlur = (event) => {
    event.preventDefault();

    const defaultProfile = this.props.profile.getDefaultProfile();
    defaultProfile.softSkillsAnswers[this.props.id].answer =
      event.target.value;
    this.props.profile.save();
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });

    if(event.target.value === '') {
      const defaultProfile = this.props.profile.getDefaultProfile();
      defaultProfile.softSkillsAnswers[this.props.id].answer =
        event.target.value;
      this.props.profile.save();
    }
  };

  render() {
    let {
      mediaURL,
      contentType,
      answer,
      title,
      subTitle,
      exContent,
      exAnswer,
      exContentType,
    } = this.state;

    const { id, example } = this.props;

    if(example) {
      mediaURL = exContent;
      contentType = exContentType;
      answer = exAnswer;
    }

    let content = <></>;
    let showAddContent = false;

    switch(contentType) {
      // giphy
      case 1:
        const imgStyle = {
          backgroundImage: `url(${mediaURL})`,
        };

        content = (
          <div className="content-image" style={imgStyle}></div>
        );
        break;
      // youtube
      case 2:
        content = (
          <iframe
            className="embed-responsive-item"
            src={mediaURL}
            title="Selected Content"
            scrolling="no"
          />
        );
        break;
      //google
      case 3:
        const imgStyle2 = {
          backgroundImage: `url(${mediaURL})`,
        };

        content = (
          <div className="content-image" style={imgStyle2}></div>
        );
        break;
      // render add content button
      //Imgur
      case 4:
        const imgStyle3 = {
          backgroundImage: `url(${mediaURL})`,
        };

        content = (
          <div className="content-image" style={imgStyle3}></div>
        );
        break;
      default:
        showAddContent = true;
        break;
      
    }

    

    return (
      <div className="singleCard">
        <div
          className={'attributeHeader'}
        >
          <div className="attributeHeading">{title}</div>
          {/* {url.includes(ROUTES.MY_CONTENT) && ( */}
          <div className="attributeSub">{subTitle}</div>
          {/* )} */}
        </div>
        <div className="attributeImage">
          {content}
          {showAddContent && this.props.dontShowAddButton !== null && (
            <div className="add-content">
              <Link to={ROUTES.CCCid + id}>
                <button className="editButton">
                  {/* <i className="fas fa-plus-circle" /> */}
                  <FontAwesome name="plus-circle" />
                  ADD CONTENT
                </button>
              </Link>
            </div>
          )}
          {showAddContent && this.props.dontShowAddButton === null && (
            <div className="add-content">
              <div className="editButton">
                USER HAS TO ADD CONTENT
              </div>
            </div>
          )}
        </div>
        <div className="attributeText">
          <div
            className={
              answer && answer.length >= 150
                ? 'description-text sm-text'
                : 'description-text'
            }
          >
            {answer && answer}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
