/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { 
  Row, 
  Col, 
  Form,
  Spinner
} from 'react-bootstrap';
import _templates from '../../constants/templatelist';
import 'react-router-dom';
import f from 'firebase';
import Notiflix from 'notiflix';

const INITIAL_STATE = {
  managerName: '',
  managerEmail: '',
  template: 'default',
  companies: [],
  company: '',
  spinner: false,
};
class CreateTeam extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  async componentDidMount(){
    // console.log(this.props);
    await this.getCompanies();
  }
  async getCompanies(){
    const getCom = f.functions().httpsCallable('getAllTeamsCompanies');
    const companies = (await getCom({})).data;
    this.setState({ companies });
  }
  handleInput = (event) => {
    // console.log('event', event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = async(event) => {
    event.preventDefault();
    this.setState({ spinner:true });
    const create = f.functions().httpsCallable('createTeam');
    const result = await create({ managerEmail: this.state.managerEmail, companyId: this.state.company, template: this.state.template });
    if(result.data.success){
      // _______________________________________
      // if(!AppConfig.isJobsMode()){
      const checkManagerStatus = f.functions().httpsCallable('checkManagerStatus');
      const res = await checkManagerStatus({ email: this.state.managerEmail });
      // console.log("checkManagerStatus ", res);
      if(res.data.length >= 1){
        //Add People to Team
        const addUserToTeam = f.functions().httpsCallable('addUserToTeam');
        for(let i = 0; i < res.data.length; i++){
          await addUserToTeam({ template: this.state.template, managerEmail: this.state.managerEmail, userId: res.data[i].id });
        }
      }
      // }
      //_______________________________________
      Notiflix.Notify.success("Manager Added");
    } else {
      Notiflix.Notify.failure(result.data.data);
    }
    this.setState({ spinner:false });
  }

  handleSubmitCompanyAdmin = async(event, profile) => {
    event.preventDefault();
    this.setState({ spinner:true });
    const create = f.functions().httpsCallable('createTeam');
    const result = await create({ managerEmail: this.state.managerEmail, companyId: profile.data.company.id, template: this.state.template });
    if(result.data.success){
      // _______________________________________
      // if(!AppConfig.isJobsMode()){
      const checkManagerStatus = f.functions().httpsCallable('checkManagerStatus');
      const res = await checkManagerStatus({ email: this.state.managerEmail });
      // console.log("checkManagerStatus ", res);
      if(res.data.length >= 1){
        //Add People to Team
        const addUserToTeam = f.functions().httpsCallable('addUserToTeam');
        for(let i = 0; i < res.data.length; i++){
          await addUserToTeam({ template: this.state.template, managerEmail: this.state.managerEmail, userId: res.data[i].id });
        }
      }
      // }
      //_______________________________________
      Notiflix.Notify.success("Manager Added");
    } else {
      Notiflix.Notify.failure(result.data.data);
    }
    this.setState({ spinner:false });
  }

  render(){
    const { managerEmail, template, company, companies, spinner } = this.state;
    const { isCompanyAdmin, profile } = this.props;
    if(spinner){
      return (
        <Spinner className="d-flex justify-content-center" animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }
    return (
      <>
        <h3>Create a Team</h3>
        <p> From a manager's email</p>
        <Form>
          <Row>
            <Col>
              <Form.Control type="text" name="managerEmail"onChange={this.handleInput} value={managerEmail} placeholder="Manager Email"/>
            </Col>
            {!isCompanyAdmin && (
              <Col>
                <Form.Control 
                  as="select" 
                  className="text-left" 
                  onChange={this.handleInput}
                  name="company"
                  value={company && company}>
                  <option disabled>Choose...</option>
                  {companies.map(temp => {
                    return (<option value={temp.id} key={temp.id}>{temp.data.name}</option>);
                  })}
                </Form.Control>
              </Col>
            )}
            <Col> 
              <Form.Control 
                as="select" 
                className="text-left" 
                onChange={this.handleInput}
                name="template"
                value={template && template}>
                <option disabled>Choose...</option>
                {_templates.templates.map(temp => {
                  return (<option key={temp}>{temp}</option>);
                })}
              </Form.Control>
            </Col>
            <Col>
              <button type="submit" onClick={isCompanyAdmin ? ((event) => this.handleSubmitCompanyAdmin(event, profile)) : (this.handleSubmit)}>Create</button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

export default CreateTeam;
