/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import f from 'firebase';
import {
  Card, 
  Container, 
  Col, 
  Row
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../../styles/search.scss';
import { Link } from 'react-router-dom';
import { images } from '../../assets';

const INITIAL_STATE = {
  searchInput: '',
  results: [],
  showTips: false,
};

const url = process.env.REACT_APP_LOCAL + '/';
export default class HSearch extends Component{
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = async(event) => {
    event.preventDefault();
    const search = f.functions().httpsCallable('searchHashtags');
    const result = await search({ input: this.state.searchInput });
    console.log('results', result.data.hits);
    this.setState({ results: result.data.hits });
  } 

  render(){
    const { results, showTips } = this.state;
    return (
      <>
        {showTips ? 
          <>
            <p>Search by hashtags. Example searches: </p>
            <ul>
              <li> #hiking </li>
              <li> #hiking OR #outdoors</li>
              <li> #hiking AND #outdoors</li>
              <li>Please note # symbols and capitalization on "AND"/"OR" matter.</li>
            </ul>
            <Button variant="link" onClick={() => {this.setState({ showTips: false });}}>- Hide Tips</Button>
          </>
          :
          <Button variant="link" onClick={() => this.setState({ showTips: true })}>+ Show Tips</Button>
        }
        <Form.Group onSubmit={this.handleSubmit}>
          <div className="input-group mb-2 mr-sm-2">
            <div className="input-group-prepend">
              <div className="input-group-text"><FontAwesome name="search" /></div>
            </div>
            <Form.Control className="form-control" id="inlineFormInputGroupUsername2"
              onChange={this.handleInput}
              type="text"
              name="searchInput"
              placeholder="Search by #"
            />
          </div>
          <div className="d-flex">
            <button type="submit" className="mx-auto btn-solid-purple" onClick={this.handleSubmit}>Search</button>
          </div>
        </Form.Group>
        {results && (
          results.map(result => {
            return (
              <Card className="mb-3 search"  key={result.id}>
                <Container className="render-info">
                  <Row>
                    <Col className="col-auto">
                      <Link 
                        className="profile-pic-link" 
                        target="_blank" 
                        to={`profile/${result.uid}`}>
                        <img 
                          className="profile-pic profile-pic-small" 
                          alt={result.profileContent.fullName} 
                          src={result.profileContent.profilePicture ? 
                            result.profileContent.profilePicture : images.avatar} />
                      </Link>
                    </Col>
                    <Col className="info">                    
                      {/*originally the className included in the link was in the div around each link. Changed it for a quick styling fix after adding links broke styling. may need to be reverted if styling is being refactored */}
                      <Row>
                        <Col>
                          <Link 
                            className="basicInfo mt-4" target="_blank" to={`profile/${(result.uid)}`}>
                            <p className="name">{result.profileContent.fullName}</p>
                            <p className="email">{result.profileContent.email}</p>
                          </Link>
                        </Col>
                      </Row>
                      <Row className="">
                        <Col>
                          <div className="linkContents">
                            <div className="url">
                              <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <FontAwesome name="external-link"/>
                                  </div>
                                </div>
                                <div className="form-control">
                                  {new URL(`profile/${result.uid}`, url).toString()}
                                </div>
                                <CopyToClipboard 
                                  text={new URL(`profile/${result.uid}`, url).toString()}>
                                  <div 
                                    className="input-group-append"
                                    onClick={this.onCopy}>
                                    <span className="input-group-text copy-btn">
                                      Copy Link
                                    </span>
                                  </div>
                                </CopyToClipboard>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <p>{result.hashtags.toString().length > 60 ? result.hashtags.toString().slice(0, 60) + '...' : result.hashtags.toString()}</p>
                      </Row>
                    </Col>
                    <div></div>
                  </Row>
                </Container>
              </Card>
            );
          })

        )}
      </>
    );
  }
}
