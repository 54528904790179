/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
// import './CompanyPage.scss';
import AppConfig from '../../components/App/AppConfig';
import { withAuthorization } from '../../components/Session';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';

import Layout from '../../components/Layout';
import InsightsSideBar from '../Insights/InsightsSideBar';
import { Spinner } from 'react-bootstrap';
import { fadeInUp } from 'react-animations';
import styled, { keyframes } from 'styled-components';
// const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;

const LOADING_LIMIT = 12;

const LoadingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
`;

// const BackButton = styled.div`
//   display: flex;
//   position: fixed;
//   left: 0.5rem;
//   top: 6rem;
//   background: white;
//   width: 3rem;
//   height: 3rem;
//   border-radius: 100%;
//   cursor: pointer;
//   user-select: none;
//   img {
//     margin: auto;
//     width: 2rem;
//     height: 2rem;
//   }
// `;

class AffinityMembers extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      companyMembersList: [],
      hasMore: true,
      lastDoc: 0,
    };

    this.getCompanyMembersList  = this.getCompanyMembersList.bind(this);
    this.handleprofileSelect    = this.handleprofileSelect.bind(this)
    this.loadMembers = this.loadMembers.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getCompanyMembersList = async () => {
    if (this.props.profile?.data){
      if (this.props.profile.data?.company?.id) {
        const res = await this.props.profile.getAffinityGroupsMembers(this.props.profile.data?.company?.id, this.props.match.params.id, this.state.lastDoc, LOADING_LIMIT);
        if (res && res.length){
          this.setState({
            companyMembersList: [...this.state.companyMembersList, ...res],
            lastDoc: res[res.length - 1].data.creationDate
          });
          if (res.length < LOADING_LIMIT) {
            this.setState({
              hasMore: false
            });
          }
        } else {
          this.setState({
            hasMore: false,
          });
        }
      }
    }
  }

  loadMembers = () => {
    this.getCompanyMembersList();
  }

  handleprofileSelect = async(profileId) =>{
    this.props.history.push(ROUTES.INSIGHTS +"/" + profileId)
  }

  render() {
    const {companyMembersList} = this.state

    if(!this.props.match.params.id){
      return <Redirect to={ROUTES.AFFINITYGROUPSPAGE} />
    }

    return (
      <>
        
        <div className="insightsPage">
          <Layout>
            <div className="insightsBox">
            
              <AuthDataContext.StreamConsumer requireAccount={true}>
                {profile => (
                  <>
                    <InsightsSideBar 
                      image={profile.data.profiles.default.profilePicture}
                      name={profile.data.profiles.default.fullName}
                      profile={profile}
                    />               
                    <div className="wrapper">
                    <FadeUp className="my-auto">
                      <div className="company">
                        <div className="title">
                        <span><b>{this.props.location.pathname.slice(17)} Group Members</b></span>
                        </div>
                        
                        <section>
                          {/* <BackButton onClick={()=>this.props.history.push(ROUTES.AFFINITYGROUPSPAGE)}>
                              <img src={icons.flip} alt='Flip Card'/>
                          </BackButton> */}
                          {/* <h3>this is where company folks will go</h3> */}
                          <div className="row m-0 mb-3">
                            <div className="col-md-4 pt-3 pb-3" >
                              <button className="button btn-solid-purple createBtnBottom"
                                onClick={()=>this.props.history.push(ROUTES.VIBEPAGE)}
                              >
                                TEAM'S VIBE
                              </button>
                            </div>
                            {(profile.data.company.name !== "open") && 
                            <div className="col-md-4 pt-3 pb-3 text-center" >
                              <button className="button btn-solid-purple createBtnBottom"
                                onClick={()=>this.props.history.push(ROUTES.INNOVATION_PAGE)}
                              >
                                TEAM'S INNOVATION
                              </button>
                            </div>}
                            <div className="col-md-4 pt-3 pb-3 btn-right-corner-innovation" >
                              <button className="button btn-solid-purple createBtnBottom"
                                onClick={()=>this.props.history.push(`${ROUTES.AFFINITY_GROUP_CHAT}/${this.props.match.params.id}`)}
                              >
                                Group Chat
                              </button>
                            </div>
                          </div>
                            <InfiniteScroll
                              className="profileGrid"
                              pageStart={0}
                              hasMore={this.state.hasMore}
                              loadMore={this.loadMembers}
                              loader={
                                <LoadingBox key="loader">
                                  <Spinner animation='border' role='status'>
                                    <span className='sr-only'>Loading...</span>
                                  </Spinner>
                                </LoadingBox>
                              }
                            >
                              {companyMembersList.map((profil, i) => {
                                let profilePicture;
                                if(profil?.data?.profiles?.default?.profilePicture==="https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"||profil?.data?.profiles?.default?.profilePicture==="/images/default-avatar.svg"){
                                  profilePicture='/assets/media/profile/default-avatar.jpeg'
                                }else{
                                  profilePicture=profil?.data?.profiles?.default?.profilePicture;
                                }
                                const fullName = profil?.data?.profiles?.default?.fullName;
                                const myRole = profil?.data?.profiles?.default?.myRole;
                                return (
                                  <div key={i} className="profileBlock" onClick={()=>this.handleprofileSelect(profil?.id)}>
                                    <div className="imageBlock">
                                      <img src={profilePicture} alt="profile_image" />
                                    </div>
                                    <div className="textBlock">
                                      <div className="profileName"><span>{fullName}</span></div>
                                      <div className="profileRole"><span>Role: {myRole}</span></div>                        
                                    </div>
                                  </div>
                                )})}
                            </InfiniteScroll>
                        </section> 
                      </div>
                    </FadeUp>
                    </div>
                  </>
                )}               
              </AuthDataContext.StreamConsumer>
            </div>
          </Layout>
        </div>     
      </>
    );
  }
}

const condition = (profile) => {
  if(AppConfig.isJobsMode()){
    return profile.uid !== null;
  }  
  return profile.uid !== null && profile.emailVerified === true;
};
  
export default compose(
  withAuthorization(condition),
)(AffinityMembers);





