/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import { compose } from 'recompose';
import { withAuthorization } from '../../components/Session';
import React, { Component } from 'react';
import './MyProfile.scss';
import Cards from '../../components/Cards';
import Layout from '../../components/Layout';
import Container from 'react-bootstrap/Container';
import ProfileController from '../../components/ShowProfile/ProfileController';
import ProfileHeader from '../../components/ShowProfile/ProfileHeader';
import ProfileFooter from '../../components/ShowProfile/ProfileFooter';
import { AuthDataContext, withAuthDataContext } from '../../components/Firebase/authdataprovider';
import AppTemplates from '../../constants/apptemplates';
import styled, { keyframes } from 'styled-components';
import { fadeIn, fadeInUp } from 'react-animations';
import CurrentUserPDF from '../../components/PDFReader/currentUserPDF';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';
import AppConfig from '../../components/App/AppConfig';

const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;
const FadeIn = styled.div`animation: 0.6s ${keyframes`${fadeIn}`}`;


const INITIAL_STATE = {
  //hardcoded
  user:'kljhasdf',
  review: true,
  loading: false
};

class MyProfileBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  static defaultProps = {
    match: '',
  }

  componentDidMount = async() => {
    let profileId = null;
    let uid = null;
    try {
      profileId = this.props.match.params.id;
      uid = this.props.profile.uid;
    } catch(exception){
      // console.log(exception);
    }    

    if(profileId === uid){
      this.setState({
        user:'user',
        review: true,
      });
    } else {
      this.setState({
        user:'',
        review: false,
      });
    }
  }

  changeLoading = (value) => {
    this.setState({ loading: value});
  }

  render (){
    const { user, review } = this.state;
    document.documentElement.scrollTop = 0;
    return (
      <Layout>
        <ScrollTopOnPageLoad/>
        <AuthDataContext.StreamConsumer>
          {profile => (
            <div className="MyProfile">
              <FadeIn>
                <ProfileHeader
                  user={user}
                  review={review}
                  isMyProfile={true}
                  userProfile={profile.data.profiles.default}
                  match={this.props.match}
                />
              </FadeIn>
              <Container>
                <FadeUp>
                  <div className='render-attributes'>
                    <ProfileController profile={this.props.profile} parentLoading={this.state.loading} updateLoading = {this.changeLoading} />
                    <Cards 
                      flip={false} 
                      edit={false} 
                      template={AppTemplates.default.softSkills}
                      softSkillsAnswers={profile.data.profiles.default.softSkillsAnswers} 
                      profile={profile}/>
                  </div>
                  {profile.data.profiles.default.resumeURL && <CurrentUserPDF profile={profile}/>}
                  {review && <ProfileFooter user={user} review={review} profile={profile} parentLoading={this.state.loading} updateLoading = {this.changeLoading}/>}
                </FadeUp>
              </Container>
            </div>
          )}
        </AuthDataContext.StreamConsumer>
      </Layout>
    );
  }
}

const MyProfile = withAuthDataContext(MyProfileBase);

const condition = (profile) => {
  // console.log("JObs mode? Verified? ", AppConfig.isJobsMode(), profile.emailVerified)
  if(AppConfig.isJobsMode()){
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(
  withAuthorization(condition),
)(MyProfile);
