/* eslint-disable no-undef */
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';


const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const config = {
//   apiKey: "AIzaSyCm6GZrFR2AVgy_Tn0SY1GBZ7ljxg6n3zQ",
//   authDomain: "keeper-hr-developer.firebaseapp.com",
//   projectId: "keeper-hr-developer",
//   storageBucket: "keeper-hr-developer.appspot.com",
//   messagingSenderId: "488816630262",
//   appId: "1:488816630262:web:59cdcb6bf3ac2782c0cfd7",
//   measurementId: "G-1K7P9GYW86"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

class Firebase {
  constructor() {
    app.initializeApp(config);
  }
}

export default Firebase;
