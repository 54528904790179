import React, { Component } from 'react';
import AppConfig from '../../components/App/AppConfig';
import {images} from '../../assets'
import { Row, Col } from 'react-bootstrap';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import FontAwesome from 'react-fontawesome';

class HomeIntroSection extends Component {
    constructor(props) {
      super(props);
  
      this.state = {};
    }

    RenderJobsMode = () => {
        return (
            <AuthDataContext.StreamConsumer>
            {profile => (
                <div className='video-info'>
                    <div className='videoHeaderContainer'> 
                   {(profile.data?.profiles?.default?.isComplete === true) ?
                        <Row>
                            <Col>
                                <p className='videoHeader show'>Welcome Back</p>
                                <p className='videoHeader'></p>
                                <p className='videoHeader multiline'>to <span className='multiline'>Keeper!</span></p>
                                {/* <p className='videoHeaderSub'><b>Sign up and create your profile.</b></p> */}
                                <p className='videoHeaderSub'>Let's go to your profile! </p>
                                <div className='btnHeaderContainer'>
                                    {/*todo: extract this to own component. Make surre animation is css base, config css class via props*/}
                                   
                                   <Link to={ROUTES.INSIGHTS + '/' + profile.uid}>
                                   <button 
                                   disabled={!(profile && profile.uid)}
                                   className='createBtnHeader'
                                       >
                                           <span className='createBtnText'>MY PROFILE</span>
                                           <FontAwesome 
                                           name='arrow-right' 
                                           className='start-arrow'/>
                                       </button>
                                   </Link> 
                              
                                    
                                </div>
                            </Col>
                            {/* <Col>
                                <div className='video-container responsive-square'>
                                <iframe title="myframe" className='responsive-square-inner' src="https://player.vimeo.com/video/383338535" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                </div>
                            </Col> */}
                        </Row>
                    :
                        ((profile.data?.profiles?.default?.email && profile.data?.profiles?.default?.email !== "") ?
                       
                          (
                            <Row>
                                <Col>
                                    <p className='videoHeader show'>Welcome</p>
                                    <p className='videoHeader'></p>
                                    <p className='videoHeader multiline'>to <span className='multiline'>Keeper!</span></p>
                                    {/* <p className='videoHeaderSub'><b>Sign up and create your profile.</b></p> */}
                                    <p className='videoHeaderSub'>Let’s create your profile, so you can shine through to your friends, prospective employers and even your work colleagues. </p>
                                    <div className='btnHeaderContainer'>
                                        {/*todo: extract this to own component. Make surre animation is css base, config css class via props*/}
                                    
                                    <Link to={ROUTES.MY_CONTENT}>
                                    <button 
                                    disabled={!(profile && profile.uid)}
                                    className='createBtnHeader'
                                        >
                                            <span className='createBtnText'>CREATE PROFILE</span>
                                            <FontAwesome 
                                            name='arrow-right' 
                                            className='start-arrow'/>
                                        </button>
                                    </Link> 
                                
                                        
                                    </div>
                                </Col>
                                {/* <Col>
                                    <div className='video-container responsive-square'>
                                    <iframe title="myframe" className='responsive-square-inner' src="https://player.vimeo.com/video/383338535" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                    </div>
                                </Col> */}
                            </Row>
                          ):( 
                            <Row>
                            <Col>
                                <p className='videoHeader show'>Ready</p>
                                <p className='videoHeader'></p>
                                <p className='videoHeader multiline'>to shine <span className='multiline'>through?</span></p>
                                <p className='videoHeaderSub'><b>Sign up and create your profile.</b></p>
                                <p className='videoHeaderSub'>Creating your profile is fun and you might learn a thing or two about yourself. Try it out and see for yourself!</p>
                                <div className='btnHeaderContainer'>
                                    {/*todo: extract this to own component. Make surre animation is css base, config css class via props*/}
                                    
                                    <button 
                                        disabled={!(profile && profile.uid)}
                                        className='createBtnHeader shouldTriggerSignUpModal'
                                    >
                                        <span className='createBtnText shouldTriggerSignUpModal'>SIGN UP</span>
                                        <FontAwesome 
                                        name='arrow-right' 
                                        className='start-arrow'/>
                                    </button>
                                
                                    
                                </div>
                            </Col>
                            {/* <Col>
                                <div className='video-container responsive-square'>
                                <iframe title="myframe" className='responsive-square-inner' src="https://player.vimeo.com/video/383338535" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                </div>
                            </Col> */}
                            </Row>
                           )
                        )
                    }
                    </div>
                </div>
            )}</AuthDataContext.StreamConsumer>
        );
    }

    RenderTeamsMode = () => {
        return (
            <AuthDataContext.StreamConsumer>
            {profile => (
            <div className='video-info'>
            <div className='videoHeaderContainer'>
            <Row>
                <Col>
                <p className='videoHeader show'>Show</p>
                <p className='videoHeader'>your team</p>
                <p className='videoHeader multiline'>who you <span className='multiline'>are.</span></p>
                <p className='videoHeaderSub'>Express your personality and get to know your teammates.</p>
                <div className='btnHeaderContainer'>
                    {/*todo: extract this to own component. Make sure animation is css base, config css class via props*/}
                    {(profile && profile.uid && !profile.isAnonymous && profile.data?.profiles?.default?.email && profile.data?.profiles?.default?.email !== "") && 
                        <Link to={ROUTES.MY_CONTENT}>
                            <button 
                            disabled={!(profile && profile.uid)}
                            className='createBtnHeader'
                            >
                                <span className='createBtnText'>CREATE PROFILE</span>
                                <FontAwesome 
                                name='arrow-right' 
                                className='start-arrow'/>
                            </button>
                        </Link>
                    }
                    {((profile && profile.isAnonymous) || !profile.data?.profiles?.default?.email || profile.data?.profiles?.default?.email === "") && 
                        <button 
                            disabled={!(profile && profile.uid)}
                            className='createBtnHeader shouldTriggerSignUpModal'
                        >
                            <span className='createBtnText shouldTriggerSignUpModal'>SIGN UP</span>
                            <FontAwesome 
                            name='arrow-right' 
                            className='start-arrow'/>
                        </button>
                    }
                </div>
                </Col>
                <Col>
                    <img className='video-container' src={images.teams_home_splash_image} alt=""/>
                </Col>
            </Row>
            </div>
            </div>
            )}</AuthDataContext.StreamConsumer>
        );
    }

    render(){
        if (AppConfig.isJobsMode()){
            return this.RenderJobsMode();
        }
        else{
            return this.RenderTeamsMode();
        }
    }
}

export default HomeIntroSection;
