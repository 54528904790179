/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'react-bootstrap';
import '../../styles/index.scss';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles'
import * as firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';

import HomePage from '../../modules/Home';
import AccountPage from '../../modules/Account';
import Admin from '../../modules/Admin';
import MyContent from '../../modules/MyContent';
import CCC from '../../modules/CCC';
import MyInfo from '../../modules/MyInfo';
import MyProfile from '../../modules/MyProfile';
import Profile from '../../modules/Profile';
import Privacy from '../../modules/Privacy';
import Terms from '../../modules/Terms';
import FourOFour from '../../modules/FourOFour';
import HashtagSearch from '../../modules/HashtagSearch';
import Complete from '../../modules/Complete';
import AuthDataProvider from '../Firebase/authdataprovider';
import Gallery from '../../modules/Gallery';
import GalleryDashboard from '../../modules/GalleryDashboard';
import GalleryBuilder from '../../modules/GalleryBuilder';
import Search from '../../modules/Search';
import TeamGame from '../../modules/TeamGame';
import TeamGameDemo from '../../modules/TeamGameDemo';
import CompanyPage from '../../modules/CompanyPage';
import MyTeam from '../../modules/MyTeam';
import JPMORGANHomePage from '../../modules/LandingPages/JPMorgan/JPMORGAN';
import InsightsPage from '../../modules/Insights';
import TeamVibePage from '../../modules/TeamVibe';
import TeamInnovationPage from '../../modules/TeamInnovation';
import AffinityGroupsPage from '../../modules/AffinityGroups';
import AffinityMembersPage from '../../modules/AffinityGroupMembers';
import CompanyAdminPage from '../../modules/CompanyAdminPage';
import CompanySummaryPage from '../../modules/CompanySummary';
import AdminDailyQuestion from '../../modules/AdminDailyQuestion';
import AffinityGroupChat from '../../modules/AffinityGroupChat';
import UserPage from '../../modules/UserPage/UserPage';
import AdminPage from '../../modules/AdminPage/AdminPage';
import ManagerPage from '../../modules/ManagerPage/ManagerPage';
import NoAccessPage from '../../modules/NoAccess/NoAccess';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';



function App() {
  const [/*user*/, initialising] = useAuthState(firebase.auth());
  
  if(initialising) {
    return (
      <div className="spinner-container d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  
  return (
    <AuthDataProvider>
      <Router>
        <Switch>
          <Route exact path={ROUTES.LANDING} component={HomePage} />
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route exact path={ROUTES.HOMEref} component={HomePage} />
          <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route exact path={ROUTES.ADMIN} component={Admin} />
          <Route exact path={ROUTES.MY_CONTENT} component={MyContent} />
          <Route exact path={ROUTES.CCC} component={CCC} />
          <Route exact path={ROUTES.MY_INFO} component={MyInfo} />
          <Route exact path={ROUTES.MY_PROFILE} component={MyProfile} />
          <Route exact path={ROUTES.PROFILE} component={Profile} />
          <Route exact path={ROUTES.PRIVACY} component={Privacy} />
          <Route exact path={ROUTES.TERMS} component={Terms} />
          <Route exact path={ROUTES.GALLERY} component={Gallery}/>
          <Route exact path={ROUTES.SEARCH} component={Search}/>
          <Route exact path={ROUTES.GALLERYBUILDER} component={GalleryBuilder}/>
          <Route exact path={ROUTES.GALLERYDASHBOARD} component={GalleryDashboard}/>
          <Route exact path={ROUTES.COMPLETE} component={Complete}/>
          <Route exact path={ROUTES.FOF} component={FourOFour}/>
          <Route exact path={ROUTES.HASHSEARCH} component={HashtagSearch}/>
          <Route exact path={ROUTES.TEAMGAMEDemo} component={TeamGameDemo} />
          <Route exact path={ROUTES.TEAMGAME} component={TeamGame} />
          <Route exact path={ROUTES.JPMORGAN} component={JPMORGANHomePage} />
          <Route exact path={ROUTES.MY_TEAM} component={MyTeam} />
          <Route exact path={ROUTES.INSIGHTS + '/:id'} component={InsightsPage} />
          <Route exact path={ROUTES.COMPANYPAGE} component={CompanyPage} />
          {/* <Route exact path={ROUTES.COMPANYADMINPAGE} component={CompanyAdminPage} /> */}
          <Route exact path={ROUTES.COMPANYSUMMARY} component={CompanySummaryPage} />
          <Route exact path={ROUTES.VIBEPAGE} component={TeamVibePage} />
          <Route exact path={ROUTES.INNOVATION_PAGE} component={TeamInnovationPage} />
          <Route exact path={ROUTES.AFFINITYGROUPSPAGE} component={AffinityGroupsPage} />
          <Route exact path={ROUTES.AFFINITY_MEMBERS_PAGE + '/:id'} component={AffinityMembersPage} />
          <Route exact path={ROUTES.ADMINDAILYQUESTION} component={AdminDailyQuestion} />
          <Route exact path={ROUTES.AFFINITY_GROUP_CHAT + '/:id'} component={AffinityGroupChat} />
          {/* <ProtectedRoute path={ROUTES.USER_PAGE} component={UserPage} role={ROLES.USER} /> */}
          {/* <ProtectedRoute path={ROUTES.ADMIN_PAGE} component={CompanyAdminPage} role={ROLES.ADMIN} /> */}
          <ProtectedRoute path={ROUTES.COMPANYADMINPAGE} component={AdminPage} role={ROLES.ADMIN} />
          <ProtectedRoute path={ROUTES.MANAGER_PAGE} component={ManagerPage} role={ROLES.MANAGER} />
          <Route exact path={ROUTES.NOACCESS_PAGE} component={NoAccessPage} />

          <Route component={FourOFour}/>
        </Switch>
      </Router>
    </AuthDataProvider>
  );


}

export default App;
