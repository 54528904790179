/* eslint-disable react/prop-types */
import React from 'react';
import Layout from '../../components/Layout';
import View from '../../components/Gallery/view';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';

const Gallery = ({ match }) => {
  const id = match.params.id;
  return (
    <Layout>
      <AuthDataContext.StreamConsumer>
        {profile => (
          <View profile={profile} galId={id}/>
        )}
      </AuthDataContext.StreamConsumer>
    </Layout>
  );};

export default Gallery;
