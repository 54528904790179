/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Row, Col, Container, Jumbotron } from 'react-bootstrap';
import _gifs from '../../constants/completeGameGIFs.json';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import f from 'firebase';

const FadeIn = styled.div`animation: 1.5s ${keyframes`${fadeIn}`}`;

const INITIAL_STATE = {
  grade: '',
  timestamp: null,
  saving: false
};

class CompleteGame extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  handleClick = async(event) => {
    event.preventDefault();
    const name = event.target.name;
    const time = Date.now();
    const uid = this.props.profile.currentUser.uid;
    this.setState({ saving: true });
    const update = () => {
      this.setState({
        grade: name,
        timestamp: time
      });
    };

    switch(name) {
      case 'poor':
        update();
        break;
      case 'okay':
        update();
        break;
      case 'good':
        update();
        break;
      case 'excellent':
        update();
        break;
      default:
        break;
    }

    const addGrade = f.functions().httpsCallable('addGrade');
    await addGrade({
      uid,
      grade: name
    });
    this.props.profile.save();
  }

  render() {
    const { saving } = this.state;
    if(saving) {
      return (
        <FadeIn>
          <Jumbotron>
            <h1 className="heading">THANK YOU!</h1>
          </Jumbotron>
        </FadeIn>
      );
    }

    return (
      <Container className="mb-4 text-center">
        <FadeIn>
          <h3 className="text-center">HOW WAS YOUR EXPERIENCE?</h3>
          <Row>
            <Col md={3} lg={3}>
              <div className="complete-gif-container">
                <img 
                  className="complete-gif"
                  name="poor"
                  alt="poor"
                  src={_gifs.poor}
                  onClick={this.handleClick} />
              </div>
            </Col>
            <Col md={3} lg={3}>
              <div className="complete-gif-container">
                <img
                  className="complete-gif" 
                  name="okay"
                  alt="okay"
                  src={_gifs.okay}
                  onClick={this.handleClick} />
              </div>
            </Col>
            <Col md={3} lg={3}>
              <div className="complete-gif-container">
                <img 
                  className="complete-gif"
                  name="good"
                  alt="good"
                  src={_gifs.good}
                  onClick={this.handleClick} />
              </div>
            </Col>
            <Col md={3} lg={3}>
              <div className="complete-gif-container">
                <img
                  className="complete-gif"
                  name="excellent"
                  alt="excellent"
                  src={_gifs.excellent}
                  onClick={this.handleClick} />
              </div>
            </Col>
          </Row>
        </FadeIn>
      </Container>
    );
  }
}

export default CompleteGame;
