/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
} from 'react-bootstrap';
import f from 'firebase';
import Notiflix from 'notiflix';
import './panel.scss';
import CreateTeam from '../TeamsCRUD/create';
import AppConfig from '../../components/App/AppConfig';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminEmail: '',
      domainName: '',
      newCompanyName: '',
      companyLogo: '',
      referralCode: '',
      cssOverride: '',
      greeting: '',
      userTitle: '',
      recruiterCompany: '',
      recruiterEmail: '',
      companyLogoWeb: '',
      mode: 'job',
      companyAdminEmail: '',
      companyAdminCompany: '',
      companies: [],
      companyNumbers: [],
      inviteNumbers: [],
      loading: true,
      showCompanyModal: false,
      companyModal: {},
      companyModalTeams: [],
    };
  }

  componentDidMount = async() => {
    if(AppConfig.isJobsMode()) {
      this.setState({ mode: 'job' });
    } else {
      this.setState({ mode: 'team' });
    }
    Notiflix.Notify.init({
      cssAnimationStyle: 'from-top',
      success: { background: '#7846c2' },
      cssAnimationDuration: 600,
    });
    if(!AppConfig.isJobsMode()) {
      await this.getCompanies();
    }
    await this.getUserInviteCodeCount();
  };

  onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  getCompanyNumbers = async(companyId) => {
    const getCompanyNumbers = f
      .functions()
      .httpsCallable('getCompanyNumbers');
    const result = await getCompanyNumbers({
      companyId,
    });
    console.log('companyNumbers', result.data);
    return result.data;
  };

  async getCompanies() {
    const getCom = f.functions().httpsCallable('getAllTeamsCompanies');
    const companies = (await getCom({})).data;
    const numbers = [];
    for(let i = 0; i < companies.length; i++) {
      const res = await this.getCompanyNumbers(companies[i].id);
      numbers.push({
        id: companies[i].id,
        name: companies[i].data.name,
        profiles: res,
      });
    }
    console.log('numbers:', companies);
    this.setState({ companyNumbers: numbers, loading: false });
  }

  async getUserInviteCodeCount() {
    const getUserInviteCodeCount = f
      .functions()
      .httpsCallable('getUserInviteCodeCount');
    const codes = ['US', 'India', 'TEST'];
    const numbers = [];
    for(let i = 0; i < codes.length; i++) {
      const result = await getUserInviteCodeCount({
        code: codes[i],
      });
      numbers.push({ code: codes[i], numbers: result.data });
    }
    console.log('numbers', numbers);
    this.setState({ inviteNumbers: numbers, loading: false });
  }

  createCompany = async() => {
    const addCompany = f.functions().httpsCallable('addCompany');
    const result = await addCompany({
      newCompanyName: this.state.newCompanyName,
      companyLogo: this.state.companyLogo,
      companyLogoWeb: this.state.companyLogoWeb,
      referralCode: this.state.referralCode,
      cssOverride: this.state.cssOverride,
      greeting: this.state.greeting,
      userTitle: this.state.userTitle,
    });
    if(result && result.data) {
      if(result.data === 'success') {
        Notiflix.Notify.success('Successfully created company.');
      } else {
        Notiflix.Notify.failure(result.data);
      }
    }
  };

  createTeamsCompany = async() => {
    const addCompany = f
      .functions()
      .httpsCallable('createTeamsCompany');
    const result = await addCompany({
      name: this.state.newCompanyName,
      companyLogo: this.state.companyLogo,
      companyLogoWeb: this.state.companyLogoWeb,
      domainName: this.state.domainName,
    });
    if(result && result.data) {
      if(result.data.success) {
        Notiflix.Notify.success('Successfully created company.');
      } else {
        Notiflix.Notify.failure(result.data.data);
      }
    }
  };

  addAdmin = async() => {
    const addRole = f.functions().httpsCallable('addRole');
    const result = await addRole({
      email: this.state.adminEmail,
      role: 'admin',
    });
    if(result !== {}) {
      Notiflix.Notify.success('Successfully added admin');
    } else {
      Notiflix.Notify.failure('Failed to add admin');
    }
  };

  addRecruiter = async() => {
    const addRole = f.functions().httpsCallable('addRole');
    const result = await addRole({
      email: this.state.recruiterEmail,
      role: 'recruiter',
    });
    if(result.data !== {}) {
      const addRecruiter = f
        .functions()
        .httpsCallable('addRecruiter');
      const result2 = await addRecruiter({
        email: this.state.recruiterEmail,
        company: this.state.recruiterCompany,
      });
      if(result2 && result2.data) {
        if(result2.data === 'success') {
          Notiflix.Notify.success(
            'Successfully added user as recruiter',
          );
        } else {
          Notiflix.Notify.failure('Something went wrong');
        }
      }
    } else {
      Notiflix.Notify.failure('Something went wrong');
    }
  };

  addCompanyAdmin = async() => {
    const addRole = f.functions().httpsCallable('addRole');
    const result = await addRole({
      email: this.state.companyAdminEmail,
      role: 'companyAdmin',
    });
    if(result.data !== {}) {
      const addCompanyAdmin = f
        .functions()
        .httpsCallable('addCompanyAdmin');
      const result2 = await addCompanyAdmin({
        email: this.state.companyAdminEmail,
        company: this.state.companyAdminCompany,
      });
      if(result2 && result2.data) {
        if(result2.data === 'success') {
          Notiflix.Notify.success(
            'Successfully added user as company admin',
          );
        } else {
          Notiflix.Notify.failure('Something went wrong');
        }
      }
    } else {
      Notiflix.Notify.failure('Something went wrong');
    }
  };

  addCompanyAdminASCompanyAdmin = async(companyName) => {
    const addRole = f.functions().httpsCallable('addRole');
    const result = await addRole({
      email: this.state.companyAdminEmail,
      role: 'companyAdmin',
    });
    if(result.data !== {}) {
      const addCompanyAdmin = f
        .functions()
        .httpsCallable('addCompanyAdmin');
      const result2 = await addCompanyAdmin({
        email: this.state.companyAdminEmail,
        company: companyName,
      });
      if(result2 && result2.data) {
        if(result2.data === 'success') {
          Notiflix.Notify.success(
            'Successfully added user as company admin',
          );
        } else {
          Notiflix.Notify.failure('Something went wrong');
        }
      }
    } else {
      Notiflix.Notify.failure('Something went wrong');
    }
  };

  importUser = async() => {
    const createUser = f.functions().httpsCallable('createEmailUser');
    const userJson = JSON.parse(this.state.importuserjson);
    console.log('Data object? ', userJson);
    console.log('Data sub', userJson.profiles);
    const userEmail = userJson.profiles.default.email;
    const userFullName = userJson.profiles.default.fullName;
    const result = await createUser({
      email: userEmail,
      fullName: userFullName,
    });
    console.log('Got result', result);
    if(result.data.success === true) {
      Notiflix.Notify.success(
        'Created email user: ' + result.data.userId,
      );
    } else {
      Notiflix.Notify.failure(
        'Something went wrong creating email user',
      );
    }

    const db = f.firestore();
    db.collection('users')
      .doc(result.data.userId)
      .set(userJson, { merge: true });
  };

  fixUserAssets = async() => {
    const userUrl = this.state.fixuserassetsid;
    const userId = userUrl.substring(
      userUrl.lastIndexOf('/') + 1,
      userUrl.length,
    );
    const db = f.firestore();
    console.log('userId?', userId);
    const user = (
      await db.collection('users').doc(userId).get()
    ).data();
    console.log('USER? ', user);

    const profilePicUrl = user.profiles.default.profilePicture;
    if(profilePicUrl !== null) {
      console.log('Profile pic url', profilePicUrl);
      const profilePicId = profilePicUrl.substring(
        profilePicUrl.lastIndexOf('/') + 1,
        profilePicUrl.length,
      );
      console.log('Profile Pic id', profilePicId);

      const imageRef = f.storage().ref('Uploads/' + profilePicId);
      console.log('imageRefffff? ', imageRef);

      const fileurl = imageRef
        .getDownloadURL()
        .then((url) => {
          console.log('GOt the actual url:', url);

          const userJson = {
            profiles: {
              default: {
                profilePicture: url,
              },
            },
          };

          console.log('UPdating the user', userId);
          db.collection('users')
            .doc(userId)
            .set(userJson, { merge: true });
          console.log('UPdated the user');

          return url;
        })
        .catch((error) => {
          console.log('Getting the url failed', fileurl);
        });
    }

    const resumeURL = user.profiles.default.resumeURL;
    console.log('Got resume url', resumeURL);
    if(resumeURL !== null) {
      const resumeId = resumeURL.substring(
        resumeURL.lastIndexOf('/') + 1,
        resumeURL.length,
      );
      console.log('Got resume id', resumeId);
      const resumeRef = f.storage().ref('Uploads/' + resumeId);
      resumeRef.getDownloadURL()
        .then((url) => {
          console.log('GOt the actual resume url:', url);

          const userJson = {
            profiles: {
              default: {
                resumeURL: url,
              },
            },
          };

          console.log('UPdating the user', userId);
          db.collection('users')
            .doc(userId)
            .set(userJson, { merge: true });
          console.log('UPdated the user');

          return url;
        })
        .catch((error) => {
          console.log('Getting the resume url failed');
        });
    }
  };

  async openCompanyModal(company) {
    //get Teams
    // let getTeams = f.functions().httpsCallable('getAllTeamsForCompany');
    // let teams = (await getTeams({cid: company.id})).data;

    //show Modal
    this.setState({
      showCompanyModal: true,
      companyModal: company,
      // companyModalTeams: teams,
    });

    const getTeams = f
      .functions()
      .httpsCallable('getAllTeamsForCompany');
    const teams = (await getTeams({ cid: company.id })).data;
    console.log('teams:', teams);

    this.setState({
      companyModalTeams: teams,
    });
  }

  closeCompanyModal() {
    this.setState({
      showCompanyModal: false,
    });
  }

  async toggleGame(team) {
    let opp = '';
    if(team.data.showGame === true || team.data.showGame === false){
      opp = !team.data.showGame;
    } else {
      opp = true;
    }

    const toggleTeamGame = f
      .functions()
      .httpsCallable('toggleTeamGame');
    await toggleTeamGame({ tid: team.id, showGame: opp });
  }

  reindexProfiles = async() => {
    const reindex = f.functions().httpsCallable('reindexAllProfiles');
    const res = await reindex({});
    console.log(res);
  };
  JobCompany = () => {
    return (
      <Form.Group>
        <Row>
          <Col>
            <h3>Create Company </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              onChange={this.onChange}
              placeholder="Company Name"
              name="newCompanyName"
              value={this.state.newCompanyName}
            />
          </Col>
          <Col>
            <Form.Label>Email Logo</Form.Label>
            <Form.Control
              type="text"
              onChange={this.onChange}
              placeholder="newcompany.com/logo.jpg"
              name="companyLogo"
              value={this.state.companyLogo}
            />
          </Col>
        </Row>
        <Col>
          <Form.Label>Refferal Code</Form.Label>
          <Form.Control
            type="text"
            onChange={this.onChange}
            placeholder="newcompany"
            name="referralCode"
            value={this.state.referralCode}
          />
        </Col>
        <Row>
          <Col>
            <Form.Label>CSS Override</Form.Label>
            <Form.Control
              type="textarea"
              onChange={this.onChange}
              placeholder="h1{color:red}"
              name="cssOverride"
              value={this.state.cssOverride}
            />
          </Col>
          <Col>
            <Form.Label>Greeting</Form.Label>
            <Form.Control
              type="text"
              onChange={this.onChange}
              placeholder="Dear {0}"
              name="greeting"
              value={this.state.greeting}
            />
          </Col>
          <Col>
            <Form.Label>User Title</Form.Label>
            <Form.Control
              type="text"
              onChange={this.onChange}
              placeholder="Candidate"
              name="userTitle"
              value={this.state.userTitle}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Web Logo</Form.Label>
            <Form.Control
              type="text"
              onChange={this.onChange}
              placeholder="newcompany.com/logo.jpg"
              name="companyLogoWeb"
              value={this.state.companyLogoWeb}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={this.createCompany}>
              Create Company
            </Button>
          </Col>
        </Row>
      </Form.Group>
    );
  };

  TeamCompany = () => {
    return (
      <Form.Group>
        <Row>
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              onChange={this.onChange}
              placeholder="Company Name"
              name="newCompanyName"
              value={this.state.newCompanyName}
            />
          </Col>
          <Col>
            <Form.Label>Email Logo</Form.Label>
            <Form.Control
              type="text"
              onChange={this.onChange}
              placeholder="newcompany.com/logo.jpg"
              name="companyLogo"
              value={this.state.companyLogo}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Website Logo</Form.Label>
            <Form.Control
              type="text"
              onChange={this.onChange}
              placeholder="newcompany.com/logo.jpg"
              name="companyLogoWeb"
              value={this.state.companyLogoWeb}
            />
          </Col>
          <Col>
            <Form.Label>Domain Name</Form.Label>
            <Form.Control
              type="textarea"
              onChange={this.onChange}
              placeholder="newcompany.com"
              name="domainName"
              value={this.state.domainName}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={this.createTeamsCompany}>
              Create Company
            </Button>
          </Col>
        </Row>
      </Form.Group>
    );
  };

  CompanyNums = () => {
    const { mode, loading, companyNumbers } = this.state;
    if(mode === 'team') {
      if(loading === true) {
        return (
          <div>
            <hr></hr>
            <h3>Number of Profiles</h3>
            <div>Loading...</div>
          </div>
        );
      } else if(loading === false) {
        return (
          <div>
            <hr></hr>
            <h3>Number of Profiles</h3>
            <table>
              <tr>
                <th>Company ID</th>
                <th>Company Name</th>
                <th># Profiles</th>
                <th>View</th>
              </tr>
              {companyNumbers.map((company) => (
                <tr>
                  <td>{company.id}</td>
                  <td>{company.name}</td>
                  <td>{company.profiles}</td>
                  <td>
                    <Button
                      onClick={() => this.openCompanyModal(company)}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  InviteNums = () => {
    const { loading, inviteNumbers } = this.state;
    if(loading === true) {
      return (
        <div>
          <hr></hr>
          <h3>Number of Profiles for each Invite</h3>
          <div>Loading...</div>
        </div>
      );
    } else if(loading === false) {
      return (
        <div>
          <hr></hr>
          <h3>Number of Profiles for each Invite</h3>
          <table>
            <tr>
              <th>Invite Code</th>
              <th>#Profiles</th>
            </tr>
            {inviteNumbers.map((invites) => (
              <tr>
                <td>{invites.code}</td>
                <td>{invites.numbers}</td>
              </tr>
            ))}
          </table>
        </div>
      );
    }
  };

  render() {
    const {
      mode,
      // companyNumbers,
      showCompanyModal,
      companyModal,
      companyModalTeams,
    } = this.state;
    const { isCompanyAdmin } = this.props;
    return (
      <>
        {isCompanyAdmin ? (
          <AuthDataContext.StreamConsumer requireAccount={true}>
            {(profile) =>
              profile ? (
                <>
                  <Form.Group>
                    <Row>
                      <Col>
                        <h3>Add Company Admin to Company</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={this.onChange}
                          placeholder="newadmin@company.com"
                          name="companyAdminEmail"
                          value={this.state.companyAdminEmail}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          onClick={() =>
                            this.addCompanyAdminASCompanyAdmin(
                              profile.data.company.data.name,
                            )
                          }
                        >
                          Add Company Admin
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                  <CreateTeam
                    isCompanyAdmin={isCompanyAdmin}
                    profile={profile}
                  />
                </>
              ) : (
                <div>
                  <h1>Oops! Something went wrong!</h1>
                </div>
              )
            }
          </AuthDataContext.StreamConsumer>
        ) : (
          <>
            <Form.Group className="admin-form">
              <Row>
                <Col>
                  <h3> Add Admin </h3>
                </Col>
              </Row>
              <Row className="text-left">
                <Col>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.onChange}
                    placeholder="newadmin@keeperai.com"
                    name="adminEmail"
                    value={this.state.adminEmail}
                  />
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Button onClick={this.addAdmin}>Add Admin</Button>
                </Col>
              </Row>
            </Form.Group>
            {mode === 'job' ? (
              <this.JobCompany />
            ) : (
              <this.TeamCompany />
            )}
            <Form.Group>
              <Row>
                <Col>
                  <h3>Add User to Company</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.onChange}
                    placeholder="newrecruiter@company.com"
                    name="recruiterEmail"
                    value={this.state.recruiterEmail}
                  />
                </Col>
                <Col>
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.onChange}
                    placeholder="KeeperAI"
                    name="recruiterCompany"
                    value={this.state.recruiterCompany}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button onClick={this.addRecruiter}>
                    Add Recruiter
                  </Button>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col>
                  <h3>Add Company Admin to Company</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.onChange}
                    placeholder="newadmin@company.com"
                    name="companyAdminEmail"
                    value={this.state.companyAdminEmail}
                  />
                </Col>
                <Col>
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.onChange}
                    placeholder="KeeperAI"
                    name="companyAdminCompany"
                    value={this.state.companyAdminCompany}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button onClick={this.addCompanyAdmin}>
                    Add Company Admin
                  </Button>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col>
                  <h3>Import User</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    as="textarea"
                    onChange={this.onChange}
                    placeholder="importeduser@email.com"
                    name="importuserjson"
                    rows="10"
                    value={this.state.importuserjson}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button onClick={this.importUser}>
                    Import User
                  </Button>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col>
                  <h3>FIx user assets</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.onChange}
                    placeholder="user id here"
                    name="fixuserassetsid"
                    value={this.state.fixuserassetsid}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button onClick={this.fixUserAssets}>
                    Fix User Assets
                  </Button>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <button onClick={this.reindexProfiles}>
                Reindex All Profiles
              </button>
              <span>For Search</span>
            </Form.Group>
            <Row>
              <CreateTeam />
            </Row>
            <Row>
              <this.CompanyNums />
            </Row>
            <Row>
              <this.InviteNums />
            </Row>
            <Modal
              show={showCompanyModal}
              onHide={() => this.closeCompanyModal()}
            >
              <Modal.Header closeButton>
                <Modal.Title>{companyModal.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>ID: {companyModal.id}</p>
                <p># Profiles: {companyModal.profiles}</p>
                <table>
                  <tr>
                    <th>Team Name</th>
                    <th>ID</th>
                    <th>toggle game</th>
                  </tr>
                  {companyModalTeams.map((team) => (
                    <tr>
                      <td>{team.data.name}</td>
                      <td>{team.id}</td>
                      <td>
                        <Button onClick={() => this.toggleGame(team)}>
                          Toggle
                        </Button>
                      </td>
                    </tr>
                  ))}
                </table>
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}
export default Panel;
