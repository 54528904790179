/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDrop } from 'react-dnd';
import { images } from '../../../assets';

// The dustbins act as the "dropzones" for boxes to be dropped into

// Setup functional component with hooks for:
// isOver: detects if a box is over a dustbin
// canDrop: informs whether or not a dustbin can receive a type of box
// drop: callback method to complete the drop
const Dustbin = ({ accept, lastDroppedItem, onDrop }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  // change class to alter styles depending on dustbin state
  let dropState = 'profile-pic';
  if(canDrop) {
    dropState = 'profile-pic can-drop';
  }
  if(isOver) {
    dropState = 'profile-pic drop';
  }
  return (
    <div className="dustbin" ref={drop}>
      <div className="profile-pic-container">
        <img 
          className={dropState}
          alt="Drop teammate here!"
          title="Drop teammate here!"
          src={lastDroppedItem && lastDroppedItem.profile && lastDroppedItem.profile.profilePicture ? lastDroppedItem.profile.profilePicture : images.avatar} />            
      </div>
      <p className="profile-name">
        {lastDroppedItem && lastDroppedItem.name}
      </p>
    </div>
  );
};
export default Dustbin;
