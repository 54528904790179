/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import f from 'firebase';
import _template from '../../constants/apptemplates';
import { Spinner, Row, Col, Jumbotron, Container } from 'react-bootstrap';
import Profile from './profile';
import styled, { keyframes } from 'styled-components';
import { fadeInUp, fadeInDown } from 'react-animations';
import * as ROUTES from  '../../constants/routes';
import { Redirect } from 'react-router-dom';

const FadeUp = styled.div`animation: 1.2s ${keyframes`${fadeInUp}`}`;
const FadeDown = styled.div`animation: 1.2s ${keyframes`${fadeInDown}`}`;

const INITIAL_STATE = {
  gallery: null,
  skills: [],
  redirect: false,
};

class View extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  async componentDidMount(){
    const id = this.props.galId;
    const result = await this.getGallery(id);
    if(!result){
      this.setState({
        redirect: true,
      });
    } else {
      const skills = this.getSkills(result.template);
      this.setState({ gallery: result, skills });
    }
  }

  getGallery = async(id) => {
    const getGal = f.functions().httpsCallable('getGallery');
    return (await getGal({ id })).data;
  }

  getSkills = (templateName) => {
    const template = _template[templateName];
    return template.softSkills;
  }

  render(){
    const { gallery, skills, redirect } = this.state;
    const profiles = gallery && gallery.profiles;
    const profileList = gallery && gallery.profileList;
    if(redirect){
      return <Redirect to={ROUTES.FOF}/>;
    }
    return (
      <>
        {!gallery && (
          <div className="spinner-container d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        <FadeDown>
          <Jumbotron>
            <h1 
              className="text-center heading">
              {gallery && gallery.title}
            </h1>
          </Jumbotron>
        </FadeDown>
        <FadeUp>
          <Container>
            <div className="cards">
              <Row>
                {gallery && profileList && profileList.map(profile => {
                  return (
                    <Col md={6}>
                      <div className="singleCard">
                        <div className="cards-container">
                          <Profile 
                            profile={profiles[profile].profiles[gallery.template]} 
                            skills={skills}/>
                          {console.log('View')};
                        </div>
                      </div>
                    </Col> 
                  );
                })}
              </Row>
            </div>
          </Container>
        </FadeUp>
      </>
    );
  }
}

export default View; 
