/* eslint-disable react/prop-types */
import React, { useRef, Component, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  Input,
  Button as AntdButton,
  Space,
  Table,
  Switch,
  Row,
  Col,
  Tooltip,
} from 'antd';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { compose } from 'recompose';
import { Redirect, Link } from 'react-router-dom';
import { fadeInUp } from 'react-animations';
import {
  Jumbotron,
  Spinner,
  Button,
  Modal,
} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import Highlighter from 'react-highlight-words';
import * as ROUTES from '../../constants/routes';
import Layout from '../../components/Layout';
import AppConfig from '../../components/App/AppConfig';
import InsightsSideBar from '../Insights/InsightsSideBar';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import { withAuthorization } from '../../components/Session';
import './CompanyAdminPage.scss';
import CompanyInfo from '../../components/CompanyInfo';

const FadeUp = styled.div`
  animation: 0.6s ${keyframes`${fadeInUp}`};
`;

const LOADING_LIMIT = 10000;

const LoadingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
`;

const MyTable = ({ data, handleChange }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const generateDataArray = (data) => {
    if (!data) return false;
    // console.log('0218', data)

    return data.map((_data, i) => {
      const { profiles, accountStatus, uid } = _data.data;
      if (!profiles?.default) return false;
      const newData = profiles?.default;
      const { fullName, email, joiningDate, profilePicture, myRole } =
        newData;

      return {
        id: _data.id,
        disabled: accountStatus,
        name: fullName,
        uid: uid,
        email,
        joiningDate,
        profilePicture,
        myRole,
        handleChange,
        userRoles: _data.userRoles,
        company: _data.company,
        profile: newData,
      };
    });
  };

  let newData = generateDataArray(data);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <AntdButton
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </AntdButton>
          <AntdButton
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </AntdButton>
          <AntdButton
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </AntdButton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : record.uid ? (
        <Link to={'/insights/' + record.uid} className="textLink">
          <Tooltip
            placement="top"
            title={
              dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)
            }
          >
            <span>{text}</span>
          </Tooltip>
        </Link>
      ) : (
        <Tooltip
          placement="top"
          title={
            dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)
          }
        >
          <span>{text}</span>
        </Tooltip>
      ),
  });
  const columns = [
    {
      title: '',
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      render: (profilePicture, record) =>
        record.uid ? (
          <Link to={'/insights/' + record.uid}>
            <img
              src={
                profilePicture ||
                'https://ramcotubular.com/wp-content/uploads/default-avatar.jpg'
              }
              alt="picture"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
              }}
            />
          </Link>
        ) : (
          <img
            src={
              profilePicture ||
              'https://ramcotubular.com/wp-content/uploads/default-avatar.jpg'
            }
            alt="picture"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          />
        ),
    },

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // width: '30%',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      // width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Role',
      dataIndex: 'myRole',
      key: 'myRole',
      // width: '30%',
      ...getColumnSearchProps('myRole'),
    },
    {
      title: 'Joining Date',
      dataIndex: 'joiningDate',
      key: 'joiningDate',
      // width: '30%',
      ...getColumnSearchProps('joiningDate'),
      // sorter: (a, b) => a.joiningDate.length - b.joiningDate.length,
      // sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Action',
    //   dataIndex: '',
    //   key: 'x',
    //   render: () => (
    //     <Tooltip placement="top" title={'Delete'}>
    //       <AntdButton
    //         type="primary"
    //         danger
    //         ghost
    //         icon={<DeleteOutlined />}

    //       />
    //     </Tooltip>
    //   ),
    // },
    {
      title: 'Disable',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (disabled, rawData) => (
        <Tooltip placement="topLeft" title={`Disabled- ${disabled}`}>
          <Switch
            checked={disabled}
            onChange={(e) => handleChange(rawData, ' ', e)}
          />
        </Tooltip>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={newData}
      scroll={{ x: 'calc(100% - 4em)' }}
    />
  );
};

class CompanyAdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyMembersList: [],
      hasMore: true,
      lastDoc: 0,
      checkboxChecked: false,
      showDeleteModal: false,
      companyInfo: null,
      companyId: props.profile?.data.company.id,
    };

    this.getCompanyMembersList =
      this.getCompanyMembersList.bind(this);
    this.loadMembers = this.loadMembers.bind(this);
    this.loadCompanyInfo = this.loadCompanyInfo.bind(this);
  }

  componentDidMount = () => {
    this.loadCompanyInfo();
  };

  loadCompanyInfo = async () => {
    try {
      const result = await this.props.profile.getCompanyInfo(
        this.state.companyId,
      );
      if (result) {
        this.setState({
          companyInfo: result.company.data,
        });
      }
    } catch (error) {
      console.log(`Error in loadCompanyInfo ${error}`);
    }
  };

  loadMembers = () => {
    this.getCompanyMembersList();
  };

  getCompanyMembersList = async () => {
    if (this.props.profile?.data) {
      if (this.props.profile.data?.company?.id) {
        let res = await this.props.profile.getAllUsersByCompany(
          this.props.profile.data?.company?.id,
          this.state.lastDoc,
          LOADING_LIMIT,
        );
        if (res && res.length) {
          res = res.map((element) => {
            let data = element.data;

            data.accountStatus =
              'accountStatus' in element.data
                ? element.data.accountStatus
                : true;

            return { data, ...element };
          });
          this.setState({
            companyMembersList: [
              ...this.state.companyMembersList,
              ...res,
            ],
            lastDoc: res[res.length - 1].data.creationDate,
          });
          if (res.length < LOADING_LIMIT) {
            this.setState({
              hasMore: false,
            });
          }
        } else {
          this.setState({
            hasMore: false,
          });
        }
      }
    }
  };

  handleprofileSelect = (profileId) => {
    this.props.history.push(ROUTES.INSIGHTS + '/' + profileId);
  };

  handleChange = (profil, i, e) => {
    if (!this.state.companyMembersList) return;

    const indexUser = this.state.companyMembersList.findIndex((_f) => _f.id === profil.id);
    this.setState({
      showDeleteModal: true,
      enableDisableUser: this.state.companyMembersList[indexUser],
      enableDisableUserIndex: indexUser,
      yesClicked: false,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      enableDisableUser: undefined,
      enableDisableUserIndex: undefined,
      yesClicked: false,
    });
  };

  handleConfirmDeleteModal = async () => {
    this.setState({
      yesClicked: true,
    });
    console.log('this.state.enableDisableUser :: ', this.state.enableDisableUser);
    let res;
    if (this.state.enableDisableUser.data.accountStatus) {
      // deactivate
      res = await this.props.profile.deactivateUser(
        this.state.enableDisableUser.id,
        this.props.profile.uid,
        this.state.enableDisableUser.data.profiles?.default?.email,
      );
    } else {
      // activate
      res = await this.props.profile.activateUser(
        this.state.enableDisableUser.id,
        this.props.profile.uid,
        this.state.enableDisableUser.data.profiles?.default?.email,
      );
    }

    if (res) {
      this.state.companyMembersList[
        this.state.enableDisableUserIndex
      ].data.accountStatus =
        !this.state.enableDisableUser.data.accountStatus;
    }
    this.setState({
      showDeleteModal: false,
      enableDisableUser: undefined,
      enableDisableUserIndex: undefined,
      yesClicked: false,
    });
  };

  render() {
    const { companyMembersList } = this.state;

    if (
      !this.props.profile.data.userRoles ||
      this.props.profile.data.userRoles.length <= 0 ||
      this.props.profile.data.userRoles.indexOf('ADMIN') === -1
    ) {
      return (
        <Redirect
          to={ROUTES.INSIGHTS + '/' + this.props.profile.uid}
        />
      );
    }

    return (
      <>
        <div className="insightsPage">
          <Layout>
            <div className="insightsBox">
              <AuthDataContext.StreamConsumer requireAccount={true}>
                {(profile) => (
                  <>
                    <InsightsSideBar
                      image={
                        profile.data.profiles.default.profilePicture
                      }
                      name={profile.data.profiles.default.fullName}
                      profile={profile}
                    />
                    <div className="wrapper">
                      <FadeUp className="my-auto">


                        <div className="company">
                          <div className="title">
                            <span>
                              <b>Company Members</b>
                            </span>
                          </div>
                          <section>
                            <Row>
                              <Col span={24}>
                                <MyTable
                                  data={this.state.companyMembersList}
                                  handleChange={this.handleChange}

                                />
                              </Col>
                            </Row>
                          </section>
                        </div>
                        <div className="company">
                          <div className="title">
                            <span>
                              <b>Company Info</b>
                            </span>
                          </div>
                          <div className="company-info">
                            {this.state.companyInfo && (
                              <CompanyInfo
                                profile={profile}
                                info={this.state.companyInfo}
                                companyId={this.state.companyId}
                              />
                            )}
                          </div>
                        </div>
                        <div className="company">
                          {/* <div className="title">
                            <span><b>Company Users</b></span>
                          </div> */}

                          <section>
                            <div className="row m-0 ">
                              <div className="col-md-6 pt-3 pb-3">
                                <button
                                  className="button btn-solid-purple createBtnBottom"
                                  onClick={() =>
                                    this.props.history.push(
                                      ROUTES.VIBEPAGE,
                                    )
                                  }
                                >
                                  TEAM'S VIBE
                                </button>
                              </div>
                              <div className="col-md-6 pt-3 pb-3 btn-right-corner-innovation">
                                <button
                                  className="button btn-solid-purple createBtnBottom"
                                  onClick={() =>
                                    this.props.history.push(
                                      ROUTES.INNOVATION_PAGE,
                                    )
                                  }
                                >
                                  TEAM'S INNOVATION
                                </button>
                              </div>
                            </div>
                            {/* <h3>this is where company folks will go</h3> */}
                            <InfiniteScroll
                              className="profileGrid"
                              pageStart={0}
                              hasMore={this.state.hasMore}
                              loadMore={this.loadMembers}
                              loader={
                                <LoadingBox key="loader">
                                  <Spinner
                                    animation="border"
                                    role="status"
                                  >
                                    <span className="sr-only">
                                      Loading...
                                    </span>
                                  </Spinner>
                                </LoadingBox>
                              }
                            >
                              {/* {(companyMembersList ? companyMembersList.map((profil, i) => {

                                const profilePicture = profil?.data?.profiles?.default?.profilePicture;
                                const fullName = profil?.data?.profiles?.default?.fullName;
                                const myRole = profil?.data?.profiles?.default?.myRole;
                                return (

                                  <div key={i} className="profileBlock" >
                                    <div className="imageBlock" onClick={(e) => this.handleprofileSelect(profil?.id)}>
                                      <img src={profilePicture} alt="profile_image" />
                                    </div>
                                    <div className="textBlock" onClick={(e) => this.handleprofileSelect(profil?.id)}>
                                      <div className="profileName"><span>{fullName}</span></div>
                                      <div className="profileRole"><span>Role: {myRole}</span></div>

                                    </div>
                                    <div className="textBlock">
                                      <Form>
                                        <Form.Check
                                          type="switch"
                                          id={"custom-switch" + i}
                                          label=""
                                          checked={profil.data?.accountStatus}
                                          onChange={(e) => this.handleChange(profil, i, e)}
                                        />
                                      </Form>
                                    </div>
                                  </div>
                                )
                              }) : <div><span>No Team Member Found</span></div> */}
                            </InfiniteScroll>
                          </section>
                          <Modal
                            show={this.state.showDeleteModal}
                            onHide={this.handleCloseDeleteModal}
                            backdrop="static"
                          >
                            <Modal.Header />
                            <Modal.Body>
                              <Jumbotron>
                                <h1 className="modal-title">
                                  {this.state.enableDisableUser &&
                                    (this.state.enableDisableUser.data
                                      .accountStatus
                                      ? 'Disable User'
                                      : 'Enable User')}
                                </h1>
                                <h5> Are you sure? </h5>
                              </Jumbotron>
                              <div className="textBlock">
                                {this.state.yesClicked ? (
                                  <div className="m-3">
                                    <Spinner
                                      as="span"
                                      className="spinner-border-2"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <Button
                                      variant="info"
                                      className="cancel-button m-3"
                                      onClick={
                                        this.handleCloseDeleteModal
                                      }
                                    >
                                      No
                                    </Button>
                                    <Button
                                      variant="danger"
                                      className="btn-solid-purple1 cancel-button m-3"
                                      onClick={
                                        this.handleConfirmDeleteModal
                                      }
                                    >
                                      Yes
                                    </Button>
                                  </>
                                )}
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </FadeUp>
                    </div>
                  </>
                )}
              </AuthDataContext.StreamConsumer>
            </div>
          </Layout>
        </div>
      </>
    );
  }
}

const condition = (profile) => {
  if (AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(withAuthorization(condition))(
  CompanyAdminPage,
);
