import React, { useRef, useState, useEffect } from 'react';
import {
  Input,
  Button as AntdButton,
  Space,
  Table,
  Tooltip,
  Checkbox,
  Switch,
  Modal
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

const ManagerTable = ({ data, props, setSelectedMembersID, setSelectedNames, selectedRowKeys, setSelectedRowKeys, setManagerTableLoading, managerTableLoading }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const generateDataArray = (data) => {
    if (!data) return false;
    // console.log('0218',data)

    return data.map((_data, i) => {
      const { profiles, accountStatus, uid } = _data.data;
      if (!profiles?.default) return false;
      const newData = profiles?.default;
      const { fullName, email, joiningDate, profilePicture, myRole } = newData;
      let groupName = "";
      for (let i = 0; i < _data.groups.length; i++) {
        groupName += _data.groups[i].groupName;
        if (i !== _data.groups.length - 1) {
          groupName += ", ";
        }
      }
      return {
        id: _data.id,
        accountStatus: accountStatus,
        name: fullName,
        uid: uid,
        email,
        joiningDate,
        profilePicture,
        myRole,
        // handleChange,
        userRoles: _data.data.userRoles,
        company: _data.data.company,
        profile: newData,
        key: _data.id,
        groupName: groupName
      };
    });
  };

  // let newData = generateDataArray(data);
  const [newData, setNewData] = useState(generateDataArray(data));
  useEffect(() => {
    setNewData(generateDataArray(data));
  }, [data]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <AntdButton
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </AntdButton>
          <AntdButton
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </AntdButton>
          <AntdButton
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </AntdButton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (
      // console.log('record', record),
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : record.uid ? (
        <Link to={'/insights/' + record.uid} className="textLink">

          <Tooltip
            placement="top"
            title={
              dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)
            }
          >
            <span>{text}</span>
          </Tooltip>

        </Link>
      ) : (
        <Tooltip
          placement="top"
          title={
            dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)
          }
        >
          <span>{text}</span>
        </Tooltip>
      ),
  });

  const columns = [

    {
      title: '',
      dataIndex: 'profilePicture',
      key: '1',
      align: 'center',
      width: 80,
      render: (profilePicture, record) =>
        record.uid ? (
          <Link to={'/insights/' + record.uid}>
            <img
              src={
                profilePicture ||
                'https://ramcotubular.com/wp-content/uploads/default-avatar.jpg'
              }
              alt="picture"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
              }}
            />
          </Link>
        ) : (
          <img
            src={
              profilePicture ||
              'https://ramcotubular.com/wp-content/uploads/default-avatar.jpg'
            }
            alt="picture"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          />
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: '2',
      align: 'center',
      width: 150,
      ...getColumnSearchProps('name'),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: '3',
      align: 'center',
      width: 200,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Role',
      dataIndex: 'myRole',
      key: '4',
      align: 'center',
      width: 200,
      ...getColumnSearchProps('myRole'),
    },
    {
      title: 'Joining Date',
      dataIndex: 'joiningDate',
      key: '5',
      align: 'center',
      width: 150,
      ...getColumnSearchProps('joiningDate'),
      // sorter: (a, b) => a.joiningDate.length - b.joiningDate.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Group',
      dataIndex: 'groupName',
      key: '6',
      align: 'center',
      // width: '17%',
      ...getColumnSearchProps('groupName'),
      // sorter: (a, b) => a.joiningDate.length - b.joiningDate.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Disable',
      dataIndex: 'accountStatus',
      key: '6',
      align: 'center',
      width: 100,
      render: (accountStatus, rawData) => (
        <Tooltip placement="topLeft" title={`${accountStatus ? "Disabled" : " Enabled"}`}>
          <Switch
            checked={accountStatus}
            onChange={(e) => {
              console.log("111", accountStatus);
              console.log("222", rawData);
              console.log("333", e);
              const prompt = accountStatus ? "disable" : "enable";
              Modal.confirm({
                title: `Are you sure to ${prompt} ${rawData.name}?`,
                okText: 'Yes',
                okType: 'danger',
                onOk: async () => {
                  setManagerTableLoading(true);
                  if (accountStatus) {
                    const isSucceed = await props.profile.deactivateUser(
                      rawData.id,
                      props.profile.data?.uid,
                      rawData.Email
                    );
                    if (isSucceed === false) {
                      Modal.error({
                        title: "There are some errors, please try again.",
                      })
                      // console.log("Disable member failed");
                    }
                  } else {
                    const isSucceed = await props.profile.activateUser(
                      rawData.id,
                      props.profile.data?.uid,
                      rawData.Email
                    );
                    if (isSucceed === false) {
                      Modal.error({
                        title: "There are some errors, please try again.",
                      })
                      // console.log("Enable member failed");
                    }
                  }
                }
              });
            }}
          />
        </Tooltip>
      ),
    },
  ];
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      const selectedMembersID = selectedRows.map((selectedRow) => {
        return selectedRow.id;
      });
      const selectedNames = selectedRows.map((selectedRow) => {
        return selectedRow.name;
      });
      // console.log("names", selectedNames);
      setSelectedMembersID(selectedMembersID);
      setSelectedNames(selectedNames);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => (
      // console.log('ooo',record),
      {
        // disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,

      }),
  };
  return (
    <div>
      <Table
        rowSelection={{
          type: Checkbox,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={newData}
        scroll={{
          x: 1100,
          y: 400
        }}
        loading={managerTableLoading}
      />

    </div>
  );
};
export default ManagerTable;