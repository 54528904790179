import React from 'react';
import styled from 'styled-components';
import { Modal, Button, Spinner } from "react-bootstrap";

const CustomSpinner = styled(Spinner)`
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
`

const ConfirmModal = ({show, onClose, onOk, title, msg, actionText, isWorking}) => {  
    return (
        <Modal show={show} onHide={onClose}>
          <Modal.Header>
            <Modal.Title style={{margin: "auto", paddingTop: "10px"}}>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{marginBottom: "1rem"}}>{msg}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onClose} disabled={isWorking?true:false}>
              Close
            </Button>
            <Button variant="primary" onClick={onOk} disabled={isWorking?true:false}>
              {isWorking?<CustomSpinner animation="border" variant="light" size="sm"/>:(null)}
              {actionText}
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }

  export default ConfirmModal;