import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import Dustbin from '../Dustbin';
import Box from '../Box';
import update from 'immutability-helper';
import FontAwesome from 'react-fontawesome';
import { Spinner } from 'react-bootstrap';
// import Teammates from '../../../constants/guessWhoTeamTemplates';

// Method to take in a full name and return a formatted one
// ie. "Andre Hansen" => "Andre H."
const formatName = (fullName) => {
  if(!fullName) return '';
  const splitName = fullName.split(' ');
  const initials = `${splitName[0]} ${(splitName[1]?.match(/\b\w/g) || [])}.`;
  return initials;
};

// Method to take in a answer key and return a formatted one
// ie. "power_to_change" => "POWER TO CHANGE."
const formatAnswerKey = (keyName) => {
  return (keyName.split('_').join(' ')).toUpperCase();
};

// Establish scoring array and percentage score vars "globally"
let scoreArr = [];
let usedArr = [];

const checkUsed = (teammate, lastDropped, index) => {
  const found = usedArr.find((element) => { 
    return element === teammate.uid; 
  });
  // console.log("found: ", found);
  if(!found){
    usedArr[index] = teammate.uid;
    // console.log("usedArr: ", usedArr)
    return false;
  } else {
    return true;
  }
};

// Method to compare correct answer array at a given index 
// to the provided answer array at the same index
const checkCorrect = (teammate, lastDropped, index) => {
  if(teammate.uid === lastDropped.uid) {
    scoreArr[index] = [teammate.uid, true];
    // console.log("scoreArr", scoreArr)
    return true;
  } else {
    scoreArr[index] = [teammate.uid, false];
    return false;
  }
};

// Setup functional component with several callbacks to establish 
// game board
const GameContainer = ({ profile }) => {
  // Hooks/callback methods for handling the dropping/updating of game
  const [dustbins, setDustbins] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [droppedBoxNames, setDroppedBoxNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [availableBoxes, setAvailableBoxes] = useState(0);
  const [completed, setCompleted] = useState(false);
  
  useEffect(() => {
    if(profile && profile.data?.company?.id)
      setupGame(profile);
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [profile]);

  // Method to get randdom IDs array from companyMembersList
  // generate random ID array from total member count
  const getRandomIDs = (totalMembers, membersList) => {
    if(!totalMembers) return [];
    if(totalMembers < 5) return [...Array(totalMembers).keys()];
    const randomIDs = [];
    while(randomIDs.length < 5) {
      const newID = parseInt(Math.random() * totalMembers * totalMembers) % totalMembers;
      if(!randomIDs.find(id => id === newID)) randomIDs.push(newID);
    }
    return randomIDs;
  };

  // check if softSkillsAnswers field is filled
  const checkContent = (profile) => {
    if(!profile || !profile.softSkillsAnswers) return false;
    const { softSkillsAnswers } = profile;
    if(Object.keys(softSkillsAnswers).length < 4) return false;
    return true;
  };

  // generate random 4 answers from softSkillsAnswers
  const get4RandomAnswers = (softSkillsAnswers) => {
    const answersKeys = Object.keys(softSkillsAnswers);
    const randomAnswersKeys = [];
    let cnt = 0;
    while(cnt < 4) {
      const newID = (parseInt(Math.random() * 9999)) % answersKeys.length;
      if(!randomAnswersKeys.find(key => key === answersKeys[newID])) {
        randomAnswersKeys.push(answersKeys[newID]);
        cnt ++;
      }
    }
    return [...randomAnswersKeys];
  };

  // setup game with profile data
  const setupGame = async(profile) => {
    try {
      const res = await profile.getActiveUsersByCompany(profile.data.company.id);
      const companyMembersList = res.filter(member => member?.data?.profiles?.default && checkContent(member.data.profiles.default));

      if(companyMembersList?.length) {

        const randomIDs = getRandomIDs(companyMembersList.length, companyMembersList);
        console.log(companyMembersList);
        const dustbinArr = [];
        const boxesArr = [];

        // Map out users to dustbin array in a random order    
        randomIDs.map(id => {
          const { data, id: uid } = companyMembersList[id];
          const profile = data.profiles.default;

          dustbinArr.push(({
            accepts: [formatName(profile.fullName), 'any'],
            lastDroppedItem: null,
            randomAnswersKeys: get4RandomAnswers(profile.softSkillsAnswers),
            profile: {
              ...profile,
              uid
            }
          }));
          dustbinArr.sort(() =>  0.5 - Math.random());

          boxesArr.push({
            name: formatName(profile.fullName),
            type: 'any',
            profile: {
              ...profile,
              uid
            }
          });

          return null;
        });

        setDustbins([...dustbinArr]);
        setBoxes([...boxesArr]);
        setAvailableBoxes(companyMembersList.length > 4 ? 5 : companyMembersList.length);
      }
    } catch(e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const reloadGame = () => {
    setScore('');
    scoreArr = [];
    usedArr = [];
    setDustbins([]);
    setBoxes([]);
    setDroppedBoxNames([]);
    setAvailableBoxes(0);
    setCompleted(false);
    setLoading(true);
    setupGame(profile);
  };

  // Set up dropped box names (from boilerplate)
  // function isDropped(boxName) {
  //   return droppedBoxNames.indexOf(boxName) > -1
  // }

  // Method to compare correct array w/ provided answer array and
  // generate score as a percentage
  const [pScore, setScore] = useState('');
  const handleScore = () => {
    usedArr = [];
    let rawScore = 0;
    for(let i = 0; i < scoreArr.length; i++) {
      const s = scoreArr[i][1];
      if(s === true) {
        rawScore += (100 / availableBoxes);
      }
    }
    const percentScore = `${parseInt(rawScore)}%`;
    setScore(percentScore);
    setCompleted(true);
    return percentScore;
  };

  // Users();

  // Callback method to set dropped box name on dustbin, check if dropped
  // box is correct, and update the dustbins array with the corresponding
  // box
  const handleDrop = useCallback(
    (index, item, userProfile) => {
      if(completed) return;
      const { name, profile } = item;
      checkCorrect(profile, userProfile, index);
      if(!checkUsed(profile, userProfile, index)){
        setDroppedBoxNames(
          update(droppedBoxNames, name ? { $push: [name, profile] } : { $push: [] }),
        );
        setDustbins(
          update(dustbins, {
            [index]: {
              lastDroppedItem: {
                $set: item,
              },
            },
          }),
        );
      }
    },
    [droppedBoxNames, dustbins, completed],
  );
  // Callback method that fires when a user clicks the x button above
  // a box after dropping one in a dustbin
  const clearDustbin = useCallback(
    (index) => {
      if(completed) return;
      usedArr[index] = null;
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: null,
            }
          },
        }),
      );
    },
    [dustbins, completed],
  );

  const isSelectedAllTeamMembers = () => {
    if(loading || completed) return false;
    if(!dustbins || !dustbins.length) return false;
    if(usedArr.length < availableBoxes) return false;
    if(usedArr.findIndex(item => !item) >= 0) return false;
    return true;
  };

  return (
    <div className="team-game-container">
      <div className="sticky-header">
        <Jumbotron className="text-center">
          <h1 className="heading">GUESS WHO !</h1>
          {/* <span className='sub-script'>*For Demonstration Purposes Only*</span> */}
          {!pScore && 
            <p className="font-weight-bold">
              THINK YOU KNOW YOUR TEAM?<br />
              Drag a co-worker's profile photo into their correct Keeper profile!
            </p>
          }
        </Jumbotron>
        <Row className="team-container" style={{ overflow: 'hidden', clear: 'both' }}>
          {boxes.map(({ name, type, profile }, index) => (
            <Box
              name={name}
              type={type}
              profile={profile}
              // isDropped={isDropped(name)}
              usedArr={usedArr}
              key={index}
              index={index}
              pScore={pScore}
              scoreArr={scoreArr}
              boxesArr={boxes}
            />
          ))}
        </Row>
        {loading && <div className="d-flex justify-content-center" style={{ padding: '1rem' }}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}
        {pScore &&
          <>
            <Row>
              <span className="mx-auto score-text" id="score">
                YOU SCORED 
                <span className={pScore === '100%' ? 'purple ml-2' : 'score-text ml-2'}>
                  {pScore}
                </span>
              </span>  
            </Row>
            <Row>
              <button 
                className="btn-solid-purple mx-auto mb-3"
                onClick={() => reloadGame()}>PLAY AGAIN?</button>
            </Row>
          </>}
        <hr />
      </div>

      <Container className={pScore ? 'game-card-container spacer' : 'game-card-container'}>
        {dustbins.map(({ randomAnswersKeys, accepts, lastDroppedItem, profile }, index) => (
          <Card className="teammate-card" key={index}>
            <div className="card-top">
              <Row>
                <Col xs="2" sm="2" md="2" lg="2">
                  {lastDroppedItem && 
                  <div 
                    className="delete-btn"
                    onClick={() => clearDustbin(index)}>
                    <FontAwesome name="times" />
                  </div>}
                  <Dustbin
                    className=""
                    accept={accepts}
                    lastDroppedItem={lastDroppedItem}
                    onDrop={item => handleDrop(index, item, profile)}
                    key={index} />
                </Col>
                <Col 
                  xs="10" 
                  sm="10" 
                  md="10" 
                  lg="10" 
                  className="attribute-card-container">
                  <Row>
                    {randomAnswersKeys.map((answerKey, index) => {
                   
                      return (
                        <Col key={index}>
                          <Card className="attribute-card">
                            <div className="attribute-header">
                              {formatAnswerKey(answerKey)}
                            </div>
                            {/* <FontAwesome name='external-link' /> */}
                            {/* {(profile.softSkillsAnswers[answerKey].content).includes('youtube') === true ?
                              <iframe 
                                className='attribute-image' 
                                src={profile.softSkillsAnswers[answerKey].content}
                                title='Activities' 
                                scrolling="no"
                                />
                              : */}
                            <img 
                              alt="activities" 
                              className="attribute-image" 
                              src={profile.softSkillsAnswers[answerKey].content} />
                          
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="card-bottom-menu">
              <Row>
                <div className="my-auto ml-1 text-center col-2">
                  {pScore ? 
                    <a 
                      alt={`${profile.fullName}'s profile`} 
                      title={`${profile.fullName}'s profile`} 
                      href={profile.profileURL} 
                      target="_blank" 
                      rel="noopener noreferrer">
                      <span className="active-bottom-menu-link">SHOW ME</span>
                    </a>
                    :
                    <span className="bottom-menu-link">SHOW ME</span>
                  }
                </div>
                <div className="my-auto text-right col">
                  <span className="bottom-menu-link mx-4">
                    INVITE TO PLAY
                  </span>
                  <span className="bottom-menu-link mx-4">
                    ADD TO MY LINKS
                    <FontAwesome name="link" className="ml-2" />
                  </span>
                  <span className="bottom-menu-link mx-4">
                    OPEN PROFILE
                    <FontAwesome name="external-link" className="ml-2" />
                  </span>
                </div>
              </Row>
            </div>
          </Card>
        ))}
      </Container>
      <Row>
        <button 
          className="btn-solid-purple mx-auto mb-4"
          onClick={() => handleScore()}
          disabled={!isSelectedAllTeamMembers()}
          title={!isSelectedAllTeamMembers() ? 'Please select all teammates' : 'Submit'}>
            SUBMIT
        </button>
      </Row>
    </div>
  );
};
export default GameContainer;
