import React from 'react';
import { Button } from 'react-bootstrap';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';


const SignOutButton = () => (
  <AuthDataContext.StreamConsumer>
    {profile => (
      <Button 
        type="button"
        onClick={profile.doSignOut}
        className="auth-btn"
        variant="outline-light">
        Sign Out
      </Button>
    )}
  </AuthDataContext.StreamConsumer>
);

export default SignOutButton;
