/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Reader from './Reader';

const INITIAL_STATE = {
  resumeURL: '',
  resumeFileName: '',
};

class CurrentUserPDF extends Component{
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  async componentDidMount(){
    const id = this.props.profile.uid;
    const user = await this.props.profile.getUserProfile(id);
    this.setState({ resumeURL: user.resumeURL, resumeFileName: user.resumeFileName });
  }

  

  render(){
    const { resumeURL } = this.state;
    return <Reader resumeURL={resumeURL} isMyProfile={true}/>;
  }
}

export default CurrentUserPDF;
