/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './ResetPassword.scss';
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import AppConfig from '../App/AppConfig';
const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  passwordError: null,
  passwordMsg: '',
  passwordType: 'password',
  confirmType: 'password',
  email: '',
  emailMsg: '',
  emailError: null,
  disableEmail: true,
};
class ResetPassword extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  async componentDidMount(){
    if(this.props.profile.currentUser){
      const user = await this.props.profile.getEntireUser(this.props.profile.currentUser.uid);
      const disableEmail = (user && user.signInMethod && user.signInMethod === 'email') ? false : true;
      this.setState({ email: this.props.profile.currentUser.email, disableEmail });
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  updateEmail = event => {
    const { email } = this.state;
    
    this.props.profile.currentUser.updateEmail(email)
      .then(() => { 
        this.setState({ emailMsg: 'Email Updated Successfully!' });
        const defaultProfile = this.props.profile.getDefaultProfile();
        defaultProfile.email = email;
        this.props.profile.save();
      })
      .catch(error => {
        this.setState({ emailError: error, emailMsg: error.message });
      });

    event.preventDefault();
  }

  resetPassword = (event) => {
    event.preventDefault();
    this.props.profile.doResetPassword(this.state.passwordOne)
      .then(() => {
        this.setState({  passwordOne: '',
          passwordTwo: '',
          passwordError: null,
          passwordMsg: 'Successfully changed password',
          passwordType: 'password',
          confirmType: 'password',
          emailMsg: '',
          emailError: null,
        });
      })
      .catch(error => {
        console.log('error', error);
        this.setState({ passwordError: error, passwordMsg: error.message });
      });
  }

  showHidePassword = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
    });
  };

  showHideConfirm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      confirmType: this.state.confirmType === 'password' ? 'text' : 'password'
    });
  };

  render(){
    const {
      passwordOne,
      passwordTwo,
      passwordType,
      confirmType,
      emailMsg,
      passwordMsg,
      disableEmail
    } = this.state;

    const passwordRegex = 
      (passwordOne.length >= 8 && passwordOne.match(/[A-Z]/) && passwordOne.match(/[0-9]/));

    const isInvalid =
      passwordOne !== passwordTwo ||
      !(passwordOne.length >= 8 && passwordOne.match(/[A-Z]/) && passwordOne.match(/[0-9]/));
    return (
      <>
        <Form className="reset-pw-submit">
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  name="email"
                  type="email"
                  disabled={disableEmail || !AppConfig.isJobsMode()}
                  onChange={this.onChange}
                  value={this.state.email || ''} />
              </Col>
            </Row>
            <Row>
              {emailMsg !== '' && <p>{emailMsg}</p>}
            </Row>
          </Form.Group>
          {(AppConfig.isJobsMode() &&
          <Form.Group>
            <Row>
              <Col className="d-flex">
                <Button 
                  onClick={this.updateEmail} 
                  className="btn-solid-purple mx-auto"
                  disabled={disableEmail}
                >
                  Update My Email
                </Button>
              </Col>
            </Row>
          </Form.Group>
          )}
          <Row>
            <Col md={6}>
              <Form.Label className="mt-1">Password</Form.Label> 
              <InputGroup>
                <Form.Control
                  name="passwordOne"
                  value={passwordOne}
                  onChange={this.onChange}
                  type={passwordType}
                  placeholder="Password"
                  className="password-fields"
                />
                <InputGroup.Append onClick={this.showHidePassword}>
                  <InputGroup.Text>
                    {/* <i className={passwordType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye' } /> */}
                    <FontAwesome name={passwordType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye' } />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col md={6}>
              <Form.Label className="mt-1">Confirm Password</Form.Label>
              <InputGroup>
                <Form.Control
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={this.onChange}
                  type={confirmType}
                  placeholder="Confirm Password"
                  className="password-fields"
                />
                <InputGroup.Append onClick={this.showHideConfirm}>
                  <InputGroup.Text>
                    {/* <i className={confirmType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye' } /> */}
                    <FontAwesome name={passwordType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye' } />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {passwordOne !== '' && (passwordOne !== passwordTwo || !passwordRegex) ?
                <p className="sub-label text-left mt-4">
                  Password must contain:
                  <ul className="pw-rules">
                    <li>
                      {passwordOne.length >= 8 ? <FontAwesome name="check" /> : <FontAwesome name="times" />}
                      8 characters
                    </li>
                    <li>
                      {passwordOne.match(/[A-Z]/) ? <FontAwesome name="check" /> : <FontAwesome name="times" />}
                      An uppercase letter
                    </li>
                    <li>
                      {passwordOne.match(/[0-9]/) ? <FontAwesome name="check" /> : <FontAwesome name="times" />}
                      A number
                    </li>
                  </ul>
                </p> : ''
              }
            </Col>
          </Row>
          <Row>
            {passwordMsg && <p>{passwordMsg}</p>}
          </Row>
          <Row>
            <Col className="d-flex">
              <Button
                disabled={isInvalid} 
                type="submit"
                className="mx-auto my-3 btn-solid-purple"
                onClick={this.resetPassword}
              >
                Reset Password
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}


export default ResetPassword;
