import React, { Component } from 'react';
import { 
  Row, 
  Col, 
  Form, 
} from 'react-bootstrap';

class MyBirthday extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      displayBirthday: '',
      error: ''
    };
  }

  componentDidMount = async() => {
    const currRole = this.props.profile.getDefaultProfile().myBirthday;
    this.setState({
      displayBirthday: currRole || ''
    });
  }

  onBlur = event => {
    event.preventDefault();
    const { displayBirthday } = this.state;
    this.props.profile.getDefaultProfile().myBirthday = displayBirthday;
    this.props.profile.save();
  };

  onChange = event => {
   
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <Form.Group>
        <Row className="text-left">
          <Col>
            <Form.Label>My Birthday *</Form.Label> 
            <Form.Control
              name="displayBirthday"
              value={this.state.displayBirthday}
              onBlur={this.onBlur}
              onChange={this.onChange}
              type="date"
              max={new Date().toISOString().split("T")[0]}
              
            />
        
     
          </Col>
        </Row>
      </Form.Group>
    );
  }
}

export default MyBirthday;
