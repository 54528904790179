/* eslint-disable react/no-deprecated */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import RenderCCC from '../Render';
import { Spinner, Row, Col, Button, Modal } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import fetch from 'node-fetch';
import GIPHYLogo from '../../../assets/giphy_logos/PoweredBy_200px-White_HorizText.png';

const Fade = styled.div`animation: 1.5s ${keyframes`${fadeIn}`}`;

const SearchType = {
  Giphy: 'GIPHY',
  Youtube: 'YOUTUBE',
  Google: 'GOOGLE',
  Imgur: 'IMGUR LINK',
  Upload: 'UPLOAD'
};

const INITIAL_STATE = {
  uploading: false,
  limit: 8,
  giphyResults: [],
  youtubeResults: [],
  googleResults: [],
  error: false,
  showLoad: false,
  offset: 0,
  nextPageToken: '',
  searching: false,
  loadFired: false,
};

class API extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.searchGiphy = this.searchGiphy.bind(this);
    this.searchGoogle = this.searchGoogle.bind(this);
    this.searchYoutube = this.searchYoutube.bind(this);
    this.searchImgur = this.searchImgur.bind(this);
    this.uploadImgur = this.uploadImgur.bind(this);
  }
  static defaultProps = {
    term: '',
    searchType: '',
    id: '',
    title: '',
    sub: '',
    changed: false,
    file: null,
  };

  componentWillReceiveProps(){
    if(this.props.term !== '' || this.props.file !== null){
      switch(this.props.searchType) {
        case SearchType.Giphy:
          this.searchGiphy();
          break;
        case SearchType.Google:
          this.searchGoogle();
          break;
        case SearchType.Youtube:
          this.searchYoutube();
          break;
        case SearchType.Imgur:
          this.searchImgur();
          break;
        case SearchType.Upload:
          this.uploadImgur();
          break;
        default:
          break;
      }
    }
  }

  componentDidMount(){
    this.setState({
      showExampleModal: this.props.showExampleModal
    });
  }

  //TODO: try catch around all this, handle 500's, etc from giphy
  async searchGiphy() {
    this.setState({
      searching: true,
    });
    const {
      limit,
      loadFired, 
      giphyResults,
      offset,
    } = this.state;
    const {
      term,
    } = this.props;
    const { 
      REACT_APP_GIPHY_ENDPOINT,
      REACT_APP_GIPHY_API_KEY
    } = process.env;
    const url = `${REACT_APP_GIPHY_ENDPOINT}search?api_key=${REACT_APP_GIPHY_API_KEY}&q=${term.replace(/\s/g, '+')}&limit=${limit}&offset=${offset}&rating=g&lang=en`;
    const giphyResponse = await fetch(url).then(response => response.json());
    if(giphyResponse.error || 
        giphyResponse === null || 
        giphyResponse.data.length < 1) {
      this.setState({
        giphyResults: [],
        error: true,
        showLoad: false,
        searching: false
      });
    } else if(loadFired && giphyResponse.data.length <= 7) {
      this.setState({
        giphyResults: [...giphyResults, ...giphyResponse.data],
        error: false,
        loadFired: false,
        showLoad: false,
        searching: false
      });
    } else if(giphyResponse.data.length <= 7) {
      this.setState({
        giphyResults: giphyResponse.data,
        error: false,
        showLoad: false,
        searching: false
      });
    } else if(loadFired) {
      this.setState({
        giphyResults: [...giphyResults, ...giphyResponse.data],
        error: false,
        loadFired: false,
        searching: false
      });
    } else {
      this.setState({
        giphyResults: giphyResponse.data,
        showLoad: true,
        error: false,
        searching: false
      });
    }
  }

  async searchGoogle() {
    this.setState({
      searching: true,
    });
    const {
      limit,
      loadFired, 
      googleResults,
      offset,
    } = this.state;
    const {
      term,
    } = this.props;
    const { 
      REACT_APP_GOOGLE_ENDPOINT,
      REACT_APP_GOOGLE_API_KEY
    } = process.env;
    let url = `${REACT_APP_GOOGLE_ENDPOINT}v1?key=${REACT_APP_GOOGLE_API_KEY}&searchType=image&cx=000274128021816350826:qm1zmi1dm1y&q=${term.replace(/\s/g, '+')}&num=${limit}&safe=active&imgSize=large&filter=1&fileType=jpg&excludeTerms=pixnio`;
    if(loadFired) {
      url += `&start=${offset}`;
    }
    const googleResponse = 
      await fetch(url).then(response => response.json());
    if(googleResponse.error || 
        googleResponse === null || 
        !googleResponse.items) {
      this.setState({
        error: true,
        showLoad: false,
        searching: false
      });
    } else if(loadFired && googleResponse.items.length <= 7) {
      this.setState({
        googleResults: [...googleResults, ...googleResponse.items.filter(img => !img.image.contextLink.includes('.html'))],
        error: false,
        loadFired: false,
        showLoad: false,
        searching: false
      });
    } else if(googleResponse.items.length <= 7) {
      this.setState({
        googleResults: googleResponse.items.filter(img => !img.image.contextLink.includes('.html')),
        error: false,
        showLoad: false,
        searching: false
      });
    } else if(loadFired) {
      this.setState({
        googleResults: [...googleResults, ...googleResponse.items.filter(img => !img.image.contextLink.includes('.html'))],
        error: false,
        loadFired: false,
        searching: false
      });
    } else {
      this.setState({
        googleResults: googleResponse.items.filter(img => !img.image.contextLink.includes('.html')),
        showLoad: true,
        error: false,
        searching: false
      });
    }
  }

  async uploadImgur() {
    this.setState({
      uploading: true
    });
    // const {
    //   imgurResults,
    // } = this.state;
    const { REACT_APP_IMGUR_CLIENT_ID } = process.env;
    const {
      // term,
      file,
    } = this.props;
    if(!file || !file.type.match(/image.*/)) return;
    // creates formData for imgur API
    const fd = new FormData(); 
    fd.append('image', file); // Append the file prep for transfer

    //Define Headers
    const IMGheaders = {
      'Authorization': `Client-ID ${REACT_APP_IMGUR_CLIENT_ID}`
    };

    //api post
    const responseURL = 'https://api.imgur.com/3/image';

    const imgurResponse = await fetch(responseURL, { method: 'POST', headers: IMGheaders, body: fd })
      .then(response => response.json())
      .then(json => json.data.link)
      .catch(error => console.log('error', error));

    const directLink = imgurResponse;

    // const imgInput = new CustomEvent('imgInput', {
    //   detail: {
    //     input: directLink,
    //   }
    // });

    this.setState({
      imgurResults: { link: directLink, title: 'imgurLink' },
      error: false,
      loadFired: false,
      showLoad: false,
      uploading: false,
      searching: false,
    });

    //window.dispatchEvent(imgInput);
  }

  /*
  ImgurAPI
  */

  async searchImgur() {
    this.setState({
      searching: true
    });
    // const {
    //   imgurResults,
    // } = this.state;
    const { REACT_APP_IMGUR_CLIENT_ID } = process.env;
    const {
      term
    } = this.props;
    
    //checks if the url given is a direct image link (i.e. ends in file extension)

    const url = term;
    const isDirLink = (url) => {
      if(typeof url !== 'string') {
        return false;
      }
      //eslint-disable-next-line
      return (url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gmi) !== null);
    };

    //checks if the url is an imgur gallery link (i.e. imgur.com/gallery/)

    const isImgGalleryLink = (url) => {
      if(typeof url !== 'string') {
        return false;
      }
      //eslint-disable-next-line
      return (url.match(/^http[^\?]*imgur[^\?]*gallery[^\?]/gmi) !== null);
    };

    const isImgAlbumLink = (url) => {
      if(typeof url !== 'string') {
        return false;
      }
      //eslint-disable-next-line
      return (url.match(/^http[^\?]*imgur[^\?]*\/a\/*/gmi) !== null);
    };

    // checks if url is an imgur link
    const isImgImageLink = (url) => {
      if(typeof url !== 'string') {
        return false;
      }
      //eslint-disable-next-line
      return (url.match(/^http[^\?]*imgur.com\/*/gmi) !== null);
    };

    // const cleanLink = (url) => {
    //   //  if the scraped response link has a ? then it means that the link is not right because it probably has a modifier to make it for facebook etc
    //   //  this just gets the direct link and only the direct link
    //   //  i.e imgur.com/a/173497?fb -> we want to remove the ?fb to get the direct link only
    //   if (url.match(/^http[^\?]*imgur[^\?]*[?]/gmi) !== null) {
    //     return url.substring(0, url.indexOf('?'));
    //   } else {
    //     return url;
    //   }
    // }

    //define imgur headers:
    const IMGheaders = {
      'Authorization': `Client-ID ${REACT_APP_IMGUR_CLIENT_ID}`
    };

    // var request = require('request');
    if(url === null || url === '') {
      this.setState({
        error: true,
        showLoad: false,
        searching: false
      });
    } else if(isDirLink(url)) {
      //nothing to do here, plug and chug
      this.setState({
        imgurResults: { link: url, title: 'directImageLink' },
        error: false,
        loadFired: false,
        showLoad: false,
        searching: false
      });
    } else if(isImgGalleryLink(url)) {

      const galleryHash = url.substring(url.indexOf('gallery/') + 8);
      const responseURL = `https://api.imgur.com/3/gallery/${galleryHash}/images/`;
      
      const imgurResponse = await fetch(responseURL, { method: 'GET', headers: IMGheaders })
        .then(response => response.json())
        .then(json => json.data[0].link)
        .catch(error => console.log('error', error));

      this.setState({
        imgurResults: { link: imgurResponse, title: 'imgurLink' },
        error: false,
        loadFired: false,
        showLoad: false,
        searching: false
      });
    } else if(isImgAlbumLink(url)) {

      const albumHash = url.substring(url.indexOf('a/') + 2);
      const responseURL = `https://api.imgur.com/3/album/${albumHash}/images`;
      
      const imgurResponse = await fetch(responseURL, { method: 'GET', headers: IMGheaders })
        .then(response => response.json())
        .then(json => json.data[0].link)
        .catch(error => console.log('error', error));

      this.setState({
        imgurResults: { link: imgurResponse, title: 'imgurLink' },
        error: false,
        loadFired: false,
        showLoad: false,
        searching: false
      });
    } else if(isImgImageLink(url)) {

      const ImageHash = url.substring(url.indexOf('com/') + 4);
      const responseURL = `https://api.imgur.com/3/image/${ImageHash}`;
      
      const imgurResponse = await fetch(responseURL, { method: 'GET', headers: IMGheaders })
        .then(response => response.json())
        .then(json => json.data.link)
        .catch(error => console.log('error', error));

      this.setState({
        imgurResults: { link: imgurResponse, title: 'imgurLink' },
        error: false,
        loadFired: false,
        showLoad: false,
        searching: false
      });
    } else {
      this.setState({
        imgurResults: {},
        error: true,
        showLoad: false,
        searching: false
      });
    }
    
  }

  async searchYoutube() {
    this.setState({
      searching: true,
    });
    const {
      limit,
      loadFired, 
      youtubeResults,
      nextPageToken,
    } = this.state;
    const {
      term,
    } = this.props;
    const { 
      REACT_APP_YOUTUBE_ENDPOINT,
      REACT_APP_YOUTUBE_API_KEY
    } = process.env;
    let url = `${REACT_APP_YOUTUBE_ENDPOINT}search?part=snippet&maxResults=${limit}&q=${term.replace(/\s/g, '+')}&key=${REACT_APP_YOUTUBE_API_KEY}`;
    if(loadFired) {
      url += `&pageToken=${nextPageToken}`;
    }
    const youtubeResponse = 
      await fetch(url).then(response => response.json());
    if(youtubeResponse.error || 
        youtubeResponse === null || 
        youtubeResponse.items.length < 1) {
      this.setState({
        youtubeResults: [],
        error: true,
        showLoad: false,
        searching: false
      });
    } else if(loadFired && youtubeResponse.items.length <= 7) {
      this.setState({
        youtubeResults: [...youtubeResults, ...youtubeResponse.items],
        error: false,
        loadFired: false,
        showLoad: false,
        searching: false
      });
    } else if(youtubeResponse.items.length <= 7) {
      this.setState({
        youtubeResults: youtubeResponse.items,
        error: false,
        showLoad: false,
        searching: false
      });
    } else if(loadFired) {
      this.setState({
        youtubeResults: [...youtubeResults, ...youtubeResponse.items],
        error: false,
        loadFired: false,
        searching: false,
        nextPageToken: youtubeResponse.nextPageToken,
      });
    } else {
      this.setState({
        youtubeResults: youtubeResponse.items,
        showLoad: true,
        error: false,
        searching: false,
        nextPageToken: youtubeResponse.nextPageToken,
      });
    }
  
  }

  /// <summary>
  /// Handles the loadmore button for each searchtype
  /// </summary>
  handleLoadMore() {
    const { 
      offset, 
      limit,  
    } = this.state;
    const { 
      searchType
    } = this.props;
    this.setState(
      {
        offset: offset + limit,
        loadFired: true,
      },
      () => {
        switch(searchType) {
          case SearchType.Giphy:
            this.searchGiphy();
            this.setState({ 
              searching: false 
            });
            break;
          case SearchType.Youtube:
            this.searchYoutube();
            this.setState({ 
              searching: false
            });
            break;
          case SearchType.Google:
            this.searchGoogle();
            this.setState({ 
              searching: false 
            });
            break;
          case SearchType.Imgur:
            this.searchImgur();
            this.setState({ 
              searching: false 
            });
            break;
          case SearchType.Upload:
            this.uploadImgur();
            break;
          default:
            break;
        }
      }
    );
  }

  handleShow = () => {
    this.setState({
      showExampleModal: true
    });
  };

  handleClose = () => {
    this.setState({
      showExampleModal: false
    });
  };

  render() {
    const { title, sub, id, changed, term, searchType, onItemSelect } = this.props;

    let sponsor = (<div></div>);
    if(term !== '' && !this.state.searching) {
      if(searchType === SearchType.Giphy){
        sponsor = (<div className="sponser"> <img src={GIPHYLogo} alt = "logo.png"/> </div>);
      } else if(searchType === SearchType.Youtube) {
        sponsor = (<div className="sponser">Powered by YouTube</div>);
      } else if(searchType === SearchType.Google) {
        sponsor = (<div className="sponser">Powered by Google Images</div>);
      } else if(searchType === SearchType.Imgur) {
        sponsor = (<div className="sponser">Powered by Imgur</div>);
      }
    }
    

    return (
      <div>
        <Fade>
          <div>
            {this.state.showExampleModal && <Row className="my-2">
              <Col className="text-center">
                <Button 
                  variant="link"
                  onClick={this.handleShow}>
                    VIEW AN EXAMPLE
                </Button>
              </Col>
            </Row>}
            {this.state.searching ? 
              <div className="d-flex my-4">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div> 
              : 
              <RenderCCC
                giphyResults={this.state.giphyResults}
                youtubeResults={this.state.youtubeResults}
                googleResults={this.state.googleResults}
                imgurResults={this.state.imgurResults}
                searchType={this.props.searchType}
                id={id}
                title={title}
                sub={sub}
                changed={changed}
                profile={this.props.profile}
                showEditModal={this.props.showEditModal}
                setShowEditModal={this.props.setShowEditModal}
                term={this.props.term}
                offset={this.state.offset}
                limit={this.state.limit}
                onItemSelect={onItemSelect}
              />
            }
            {sponsor}
            {this.state.term !== '' && 
              this.state.offset < 92 && 
              this.state.showLoad && (
              <div className="loadMoreButtonsDiv">
                <button 
                  className="button btn-outline-purple loadMoreBtn" 
                  onClick={() => this.handleLoadMore()}> LOAD MORE </button>
              </div>
            )}
          </div>
        </Fade>
        <Modal 
          show={this.state.showExampleModal} 
          onHide={this.handleClose} 
          animation={true} 
          size="md"
          keyboard>
          <Modal.Header closeButton />
          <Modal.Body className="example-modal">
            <p className="attributeHeading">{title}</p>
            <p className="attributeSub">{sub}</p>
            <div className="contentDiv">
              <img className="content" src={this.props.example?.sampleContent} alt ="content.png"/>
            </div>
          </Modal.Body>
          <Modal.Footer className="example-modal">
            <p className="attribute-text">
              {this.props.example?.sampleAnswer}
            </p>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default API;
