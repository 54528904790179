import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthDataContext } from '../Firebase/authdataprovider';

const ProtectedRoute = ({ path, component: Component, role}) => {

    const hasRole = (userRoles, role) => {
      for (let i = 0; i < userRoles.length; i++) {
        if (userRoles[i].startsWith(role)) {
          return true;
        }
      }
      return false;
    }
    //console.log('role: ', Role);
    return (
        <AuthDataContext.StreamConsumer>
          {profile => (
            <Route exact path={path} render={(props) => (
              (profile.uid !== null && profile.uid !== undefined && profile.data !== undefined && profile.data.userRoles && profile.data.userRoles.length > 0 && hasRole(profile.data.userRoles, role))
              ? <Component {...props} />
              : <Redirect to={ROUTES.FOF} />
            )}/>
          )}
        </AuthDataContext.StreamConsumer>
    )
}

export default ProtectedRoute