import React, { Component } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import Notiflix from 'notiflix';
import { images } from '../../assets';
import FontAwesome from 'react-fontawesome';
import './CompanyInfo.scss';

class CompanyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            companyLogo: props.info.companyLogo,
            domainName: props.info.domainName,
            name: props.info.name,
            saving: false,
        };
    };

    uploadImage = async (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        const fileUrl = await this.props.profile.saveFile(file);
        const validFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif', 'image/svg+xml'];
        if ( validFileTypes.includes(file.type) === false ) {
            Notiflix.Notify.failure('Invalid picture type');
            return;
        }
        this.setState({
            companyLogo: fileUrl
        });
        Notiflix.Notify.success('Image uploaded successfully');
    }

    deleteImage = async (event) => {
        event.preventDefault();
        this.setState({
            companyLogo: ''
        });
    }

    onChangeName = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    onChangeDomainName = (event) => {
        this.setState({
            domainName: event.target.value
        });
    }

    handleUpdateCompanyInfo = async (event) => {
        event.preventDefault();
        if (this.state.saving) return;
        this.setState({
            saving: true
        });
        try {
            await this.props.profile.saveCompanyInfo(
                this.props.companyId,
                this.state.companyLogo,
                this.state.name,
                this.state.domainName
            );
        } catch (error) {
            console.log(`Error in handleUpdateCompanyInfo ${error}`);
        } finally {
            this.setState({
                saving: false
            });
        }
    }

    render() {
        const { companyLogo } = this.state;
        const imgStyle = {
            backgroundImage: `url(${companyLogo ? companyLogo : images.avatar})`
        };

        return (
            <Form
                style={{width: "100%"}}
                onSubmit={this.handleUpdateCompanyInfo}
            >
                <div className='companylogo-upload-container'>
                    <div className='companylogo-upload'>
                        {companyLogo && 
                            <div 
                                className='delete-btn'
                                onClick={this.deleteImage}>
                                <FontAwesome name='times' />
                            </div>}
                        <div className='companylogo-edit'>
                            <Form.Control 
                                name='companyLogo' 
                                onChange={this.uploadImage}
                                type='file' 
                                id='imageUpload' 
                                accept='.png, .jpg, .jpeg' />
                            <label className={companyLogo ? 'edit' : 'add'} htmlFor='imageUpload' />
                        </div>
                        <div className='companylogo-preview' style={imgStyle}>
                        </div>
                    </div>
                    <div className='company-details'>
                        <Form.Label>Company Name *</Form.Label> 
                        <Form.Control
                            name='companyName'
                            value={this.state.name}
                            onChange={this.onChangeName}
                            type='text'
                            required
                        />
                        <Form.Label>Company Domain *</Form.Label> 
                        <Form.Control
                            name='companyName'
                            value={this.state.domainName}
                            onChange={this.onChangeDomainName}
                            type='text'
                            required
                        />
                    </div>
                </div>          
                <div className="company-save-button">
                    <Button variant="info" className="button btn-solid-purple createBtnBottom" type="submit">
                        {this.state.saving && <Spinner animation="border" role="status"/>}
                        <div className="but-text">Save</div>
                    </Button>
                </div>
            </Form>
        )
    }
};

export default CompanyInfo;