import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Button as AntdButton, Modal, Select } from 'antd';
import './FixedCeilingComponent.css'

const FixedCeilingComponent = ({
  props,
  isUserList,
  setIsUserList,
  selectedAdminID,
  setSelectedAdminID,
  selectedMemberID,
  setSelectedMemberID,
  selectedGroupID,
  setSelectedGroupID,
  setSelectedGroupName,
  setSelectedRadioRowKeys,
  selectedMembersID,
  setSelectedMembersID,
  setSelectedCheckBoxRowKeys,
  assignUserAsAdminToGroup,
  assignUsersToGroup,
  revokeAdminFromGroup,
  createGroup,
  showAssignButton,
  groupList,
}) => {
  // const [isFixed, setIsFixed] = useState(false);
  const [value, setValue] = React.useState('1');

  // useEffect(() => {
  //   function handleScroll() {
  //     if (window.pageYOffset > 100 && !isFixed) {
  //       setIsFixed(true);
  //     } else if (window.pageYOffset <= 100 && isFixed) {
  //       setIsFixed(false);
  //     }
  //   }

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [isFixed]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };
  const generateGroupDropdown = (groupList) => {
    return groupList.map((group) => {
      return {
        value: group.groupID,
        label: group.groupName,
      };
    });
  };

  return (
    // className={isFixed ? 'fixed-component fixed' : 'fixed-component'}
    <div>
      <div className="buttonContainer">
        <Box
          sx={{
            width: '100%',
            typography: 'body1',
          }}
        >
          <TabContext value={value}>
            <Box
              // sx={{
              //   borderBottom: 1,
              //   borderColor: 'divider',
              // }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="User List"
                  value="1"
                  onClick={() => {
                    // window.scrollTo(0, 0);
                    // getCompanyMembersList(props.profile.data?.company?.id);
                    setIsUserList(true);
                    setSelectedGroupID(null);
                    setSelectedAdminID(null);
                    setSelectedMemberID(null);
                    setSelectedRadioRowKeys([]);
                    setSelectedMembersID([]);
                    setSelectedCheckBoxRowKeys([]);
                  }}
                />
                <Tab
                  label="Group List"
                  value="2"
                  onClick={() => {
                    // window.scrollTo(0, 0);
                    setIsUserList(false);
                    // getCompanyGroupList(props.profile.data?.company?.id);
                    setSelectedAdminID(null);
                    setSelectedMemberID(null);
                    setSelectedRadioRowKeys([]);
                    setSelectedMembersID([]);
                    setSelectedGroupID(null);
                    setSelectedCheckBoxRowKeys([]);
                  }}
                />
              </TabList>
            </Box>
          </TabContext>
        </Box>
        {isUserList ? (
          <div className="buttonContainer">
            <Select className='selectMenu'
              allowClear={true}
              showSearch
              labelInValue={true}
              style={{minWidth: 150}}
              // dropdownStyle={isFixed ? { position: "fixed", top: "110px" } : {top: "222px"}}
              placeholder="Select a group"
              optionFilterProp="children"
              onChange={(value, option) => {
                // console.log(`selected ${option.value}`);
                if (value === undefined) {
                  setSelectedGroupID(null);
                  setSelectedGroupName(null);
                } else {
                  setSelectedGroupID(value.value);
                  setSelectedGroupName(value.label);
                }
              }}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={generateGroupDropdown(
                groupList,
              )}
            />
            <AntdButton
              onClick={() => {
                // console.log("member", selectedMembersID, "group", selectedGroupID);
                if (selectedGroupID !== null && selectedMembersID.length > 0) {
                  assignUsersToGroup(props.profile.data?.company?.id, selectedMembersID, selectedGroupID);
                } else if (selectedMembersID.length === 0) {
                  Modal.error({
                    title: "You didn't select members, please select members to assign.",
                  })
                  // console.log('assign users to group failed.');
                } else if (selectedGroupID === null) {
                  Modal.error({
                    title: "You didn't select group, please select group.",
                  })
                }
              }}>
              Assign User to Group
            </AntdButton>
          </div>
        ) : (
          <div className="buttonContainer">
            {showAssignButton ? (
              <AntdButton onClick={() => {
                // console.log("111", selectedMemberID, selectedGroupID);
                if (selectedMemberID !== null && selectedGroupID !== null) {
                  assignUserAsAdminToGroup(selectedGroupID, selectedMemberID);
                } else {
                  Modal.error({
                    title: "You didn't select member, please select member to assign.",
                  })
                }
              }}>Assign Admin</AntdButton>
            ) : (
              <AntdButton onClick={() => {
                // console.log("222", selectedAdminID, selectedGroupID);
                if (selectedAdminID !== null && selectedGroupID !== null) {
                  revokeAdminFromGroup(selectedGroupID, selectedAdminID);
                } else {
                  Modal.error({
                    title: "You didn't select member, please select member to revoke.",
                  })
                }
              }}>Revoke Admin</AntdButton>
            )}
            <AntdButton onClick={() => {
              createGroup("New Group", props.profile.data?.company?.id);
            }}>Add a group</AntdButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default FixedCeilingComponent;
