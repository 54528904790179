import React, { useRef, useState, useEffect } from 'react';
import {
    Input,
    Button as AntdButton,
    Space,
    Table,
    Tooltip,
    Switch,
    Modal,
} from 'antd';
import {
    SearchOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

const AdminGroup = ({
    data,
    props,
    adminGroupLoading,
    setAdminGroupLoading
}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const generateDataArray = (data) => {
        if (!data) return false;
        return data.map((_data) => {
            _data.members.sort((a, b) => {
                if (a.role === b.role) {
                    if (a.joiningDate !== null && b.joiningDate !== null) {
                        return new Date(a.joiningDate.toDate()) - new Date(b.joiningDate.toDate())
                    } else if (a.joiningDate === null && b.joiningDate !== null) {
                        return 1;
                    } else if (a.joiningDate !== null && b.joiningDate === null) {
                        return -1;
                    } else {
                        return 0;
                    }
                } else {
                    if (a.role === "Admin" && b.role === "Member") {
                        return -1;
                    }
                    return 1;
                }
            });
            let adminName = '';
            if (_data.members.length > 0) {
                for (let i = 0; i < _data.members.length; i++) {
                    if (_data.members[i].role === "Admin") {
                        adminName += _data.members[i].profiles.default.fullName;
                        adminName += ", ";
                    }
                }
            }
            if (adminName.length > 0) {
                adminName = adminName.substring(0, adminName.length - 2);
            } else {
                adminName = "None";
            }
            if (_data.createTime !== null) {
                return {
                    name: _data.groupName,
                    key: _data.groupID,
                    id: _data.groupID,
                    adminName: adminName,
                    number: _data.members.length,
                    members: _data.members,
                    createTime: new Date(_data.createTime.toDate()).toDateString()
                };
            }
            return {
                name: _data.groupName,
                key: _data.groupID,
                id: _data.groupID,
                adminName: adminName,
                number: _data.members.length,
                members: _data.members
            };
        });
    };

    const [newData, setNewData] = useState([]);
    useEffect(() => {
        // console.log("props", props);
        setNewData(generateDataArray(data));
    }, [data]);

    const getGroupDetail = (record) => {
        // console.log('record', record);
        const membersList = record.members.map((member) => {
            if (member.joiningDate !== null) {
                return {
                    id: member.userID,
                    profilePicture: member.profiles.default.profilePicture,
                    Name: member.profiles.default.fullName,
                    Email: member.profiles.default.email,
                    Role: 'Member',
                    key: member.userID,
                    groupID: record.id,
                    joiningDate: new Date(member.joiningDate.toDate()).toDateString(),
                    accountStatus: member.accountStatus
                };
            }
            return {
                id: member.userID,
                profilePicture: member.profiles.default.profilePicture,
                Name: member.profiles.default.fullName,
                Email: member.profiles.default.email,
                Role: 'Member',
                key: member.userID,
                groupID: record.id,
                accountStatus: member.accountStatus
            };
        });
        return membersList;
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <AntdButton
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </AntdButton>
                    <AntdButton
                        onClick={() =>
                            clearFilters && handleReset(clearFilters, confirm)
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </AntdButton>
                    <AntdButton
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </AntdButton>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : record.uid ? (
                <Link to={'/insights/' + record.uid} className="textLink">
                    <Tooltip
                        placement="top"
                        title={
                            dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)
                        }
                    >
                        <span>{text}</span>
                    </Tooltip>
                </Link>
            ) : (
                <Tooltip
                    placement="top"
                    title={
                        dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)
                    }
                >
                    <span>{text}</span>
                </Tooltip>
            ),
    });
    const groupListColumns = [

        {
            title: '',
            dataIndex: 'profilePicture',
            key: '1',
            align: 'center',
            // width: 100,
            render: (profilePicture, record) =>
                record.id ? (
                    <Link to={'/insights/' + record.id}>
                        <img
                            src={
                                profilePicture ||
                                'https://ramcotubular.com/wp-content/uploads/default-avatar.jpg'
                            }
                            alt="picture"
                            style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                            }}
                        />
                    </Link>
                ) : (
                    <img
                        src={
                            profilePicture ||
                            'https://ramcotubular.com/wp-content/uploads/default-avatar.jpg'
                        }
                        alt="picture"
                        style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                        }}
                    />
                ),
        },
        {
            title: 'Name',
            dataIndex: 'Name',
            key: '2',
            align: 'center',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: '3',
            align: 'center',
            // width: '25%',
        },
        {
            title: 'Joining Time',
            dataIndex: 'joiningDate',
            key: '4',
            align: 'center',
            // width: '50%',
            // ...getColumnSearchProps('joiningDate'),
            // sorter: (a, b) => a.joiningDate.length - b.joiningDate.length,
            // sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: '5',
            align: 'center',
            width: '15%',
        },
        {
            title: 'Disable',
            dataIndex: 'accountStatus',
            key: '6',
            align: 'center',
            render: (accountStatus, rawData) => (
                <Tooltip placement="topLeft" title={`${accountStatus ? "Disabled" : " Enabled"}`}>
                    <Switch
                        checked={accountStatus}
                        onChange={(e) => {
                            // console.log("111", accountStatus);
                            // console.log("222", rawData);
                            // console.log("333", e);
                            const prompt = accountStatus ? "disable" : "enable";
                            Modal.confirm({
                                title: `Are you sure to ${prompt} ${rawData.Name}?`,
                                okText: 'Yes',
                                okType: 'danger',
                                onOk: async () => {
                                    setAdminGroupLoading(true);
                                    if (accountStatus) {
                                        const isSucceed = await props.profile.deactivateUser(
                                            rawData.id,
                                            props.profile.data?.uid,
                                            rawData.Email
                                        );
                                        if (isSucceed === false) {
                                            Modal.error({
                                                title: "There are some errors, please try again.",
                                            })
                                            // console.log("Disable member failed");
                                        }
                                    } else {
                                        const isSucceed = await props.profile.activateUser(
                                            rawData.id,
                                            props.profile.data?.uid,
                                            rawData.Email
                                        );
                                        if (isSucceed === false) {
                                            Modal.error({
                                                title: "There are some errors, please try again.",
                                            })
                                            // console.log("Enable member failed");
                                        }
                                    }
                                }
                            });
                        }}
                    />
                </Tooltip>
            ),
        },
    ];
    const columns = [
        // {

        //   title: 'ID',
        //   dataIndex: 'id',
        //   key: '1',

        // },
        {
            title: 'Group Name',
            dataIndex: 'name',
            key: '2',
            align: 'center',
            // render: (text) => <a onClick={() => hanldeClick()}></a>,
            // width: 100,
            ...getColumnSearchProps('name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Group Admin',
            dataIndex: 'adminName',
            key: '3',
            align: 'center',
            // width: '30%',
            ...getColumnSearchProps('adminName'),
        },
        {
            title: 'Member Number',
            dataIndex: 'number',
            key: '4',
            align: 'center',
            width: '20%',
            // ...getColumnSearchProps('number'),
            sorter: (a, b) => a.number - b.number,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Create Time',
            dataIndex: 'createTime',
            key: '5',
            align: 'center',
            ...getColumnSearchProps('createTime'),
        },
    ];
    // rowSelection object indicates the need for row selection
    // const rowSelection = {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         // console.log(
    //         //     `selectedRowKeys: ${selectedRowKeys}`,
    //         //     'selectedRows: ',
    //         //     selectedRows,
    //         // );
    //     },
    //     getCheckboxProps: (record) =>
    //     // console.log('ooo',record),
    //     ({
    //         // disabled: record.name === 'Disabled User',
    //         // Column configuration not to be checked
    //         name: record.name,
    //     }),
    // };

    return (
        <>
            <div>
                <Table
                    loading={adminGroupLoading}
                    columns={columns}
                    dataSource={newData}
                    scroll={{ x: 'calc(100% - 4em)' }}
                    expandable={{
                        rowExpandable: (record) => record.number >= 1,
                        expandedRowRender: (record) => {
                            return (
                                <Table
                                    // rowSelection={{
                                    //     type: "radio",
                                    //     ...rowSelection,
                                    // }}
                                    columns={groupListColumns}
                                    dataSource={getGroupDetail(record)}
                                    scroll={{ x: 'calc(100% - 120em)' }}
                                />
                            );
                        },
                    }}
                />
            </div>
        </>
    );
};
export default AdminGroup;