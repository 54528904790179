import React, { createContext, useContext } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
// import { query } from "firebase/firestore";
import { BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import app from 'firebase/app';
import f from 'firebase';
import { Spinner } from 'react-bootstrap';
import * as ROLES from '../../constants/roles'
export const AuthDataContext = createContext(null);

class AuthDataProvider extends React.Component {
  // This behavior subject holds the raw
  // profile data
  authData = new BehaviorSubject({});//<{ref/*:DocumentReference*/, data/*: any*/}>({});
  dataWithMethods = new BehaviorSubject();

  // googleProvider = new app.auth.GoogleAuthProvider();
  // facebookProvider = new app.auth.FacebookAuthProvider();

  auth = firebase.auth();
  store = firebase.firestore();

  constructor(props) {
    super(props);
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.googleAuthProvider = app.auth.GoogleAuthProvider;

    this.authData.pipe(
      map((raw) => raw),
      filter(raw => raw && raw.data),
      map((raw) => ({
        data: raw.data,
        isAnonymous: raw.isAnonymous,
        currentUser: this.auth.currentUser,
        uid: raw.uid,
        userCompanyId: raw.data.company?.id || '',
        emailVerified: raw.emailVerified,
        getUserProfile: async (id) => {
          return this.getUserProfile(id);
        },
        getCompanyMembers: async (id) => {
          return this.getCompanyMembers(id);
        },
        getEntireUser: async (id) => {
          return this.getEntireUser(id);
        },
        getDefaultProfile: () => {
          return this.getDefaultProfile(raw.data);
        },
        save: async () => {
          await this.save(raw);
        },
        saveFile: (data) => {
          return this.saveFile(data);
        },
        convertAndSaveFile: (file) => {
          return this.convertAndSaveFile(file);
        },
        getCompanyInfo: this.getCompanyInfo.bind(this),
        saveCompanyInfo: this.saveCompanyInfo.bind(this),
        doSendVerificationEmail: this.doSendVerificationEmail.bind(this),
        doCreateUserWithEmailAndPassword: this.doCreateUserWithEmailAndPassword.bind(this),
        doWhitelistedCreateUserWithEmailAndPassword: this.doWhitelistedCreateUserWithEmailAndPassword.bind(this),
        doSignInWithEmailAndPassword: this.doSignInWithEmailAndPassword.bind(this),
        doSignInWithGoogle: this.doSignInWithGoogle.bind(this),
        doSignInWithFacebook: this.doSignInWithFacebook.bind(this),
        doCreateAnonymousUser: this.doCreateAnonymousUser.bind(this),
        doConvertAnonymousUser: this.doConvertAnonymousUser.bind(this),
        createDefaultProfile: this.createDefaultProfile.bind(this),
        //   setAffinityGroups: this.setAffinityGroups(this),
        fetchSignInMethods: this.fetchSignInMethods.bind(this),
        doSignOut: this.doSignOut.bind(this),
        logout: this.emitCurrentProfile.bind(this),
        deleteAccount: this.deleteAccount.bind(this),
        doSendPasswordReset: this.doSendPasswordReset.bind(this),
        doResetPassword: this.doResetPassword.bind(this),
        getAllCompanyMembers: this.getAllCompanyMembers.bind(this),
        getUserToken: this.getUserToken.bind(this),
        getAllCompanyInnovations: this.getAllCompanyInnovations.bind(this),
        getAllCompanyVibes: this.getAllCompanyVibes.bind(this),
        saveUserVibe: this.saveUserVibe.bind(this),
        addVibeLike: this.addVibeLike.bind(this),
        removeVibeLike: this.removeVibeLike.bind(this),
        addInnnovationLike: this.addInnnovationLike.bind(this),
        removeInnovationLike: this.removeInnovationLike.bind(this),
        getAllAffinityGroups: this.getAllAffinityGroups.bind(this),
        getAffinityGroup: this.getAffinityGroup.bind(this),
        getCompanyFromEmail: this.getCompanyFromEmail.bind(this),
        getAffinityGroupsUsersByCompany: this.getAffinityGroupsUsersByCompany.bind(this),
        getAffinityGroupsMembers: this.getAffinityGroupsMembers.bind(this),
        subscribeUserToAffinityGroup: this.subscribeUserToAffinityGroup.bind(this),
        unsubscribeUserToAffinityGroup: this.unsubscribeUserToAffinityGroup.bind(this),
        getCompanyUser: this.getCompanyUser.bind(this),
        getAllUsersByCompany: this.getAllUsersByCompany.bind(this),
        activateUser: this.activateUser.bind(this),
        deactivateUser: this.deactivateUser.bind(this),
        getActiveUsersByCompany: this.getActiveUsersByCompany.bind(this),
        addDailyQuestion: this.addDailyQuestion.bind(this),
        getDailyQuestionForUser: this.getDailyQuestionForUser.bind(this),
        getDailyAnswers: this.getDailyAnswers.bind(this),
        getAllQuestions: this.getAllQuestions.bind(this),
        addUserAnswerToDailyQuestion: this.addUserAnswerToDailyQuestion.bind(this),
        createPost: this.createPost.bind(this),
        updatePost: this.updatePost.bind(this),
        getPost: this.getPost.bind(this),
        getPosts: this.getPosts.bind(this),
        likePost: this.likePost.bind(this),
        deletePost: this.deletePost.bind(this),
        createReply: this.createReply.bind(this),
        getReplies: this.getReplies.bind(this),
        updateReply: this.updateReply.bind(this),
        deleteReply: this.deleteReply.bind(this),
        likeReply: this.likeReply.bind(this),
        getGroupsByCompany: this.getGroupsByCompany.bind(this),
        getGroupByID: this.getGroupByID.bind(this),
        createGroup: this.createGroup.bind(this),
        updateGroupName: this.updateGroupName.bind(this),
        deleteGroup: this.deleteGroup.bind(this),
        assignUserToGroupIfNotExist: this.assignUserToGroupIfNotExist.bind(this),
        assignMultipleUsersToGroup: this.assignMultipleUsersToGroup.bind(this),
        deleteUserFromGroupIfExist: this.deleteUserFromGroupIfExist.bind(this),
        assignUserAsAdminToGroup: this.assignUserAsAdminToGroup.bind(this),
        deleteAdminFromGroup: this.deleteAdminFromGroup.bind(this),
        revokeAdminFromGroup: this.revokeAdminFromGroup.bind(this),
        getMembersByGroup: this.getMembersByGroup.bind(this),
        getAdminGroupsByUser: this.getAdminGroupsByUser.bind(this),
        getGroupsByUser: this.getGroupsByUser.bind(this),
        getGroupsIDByUser: this.getGroupsIDByUser.bind(this),
        addDailyQuestionByGroupID: this.addDailyQuestionByGroupID.bind(this),
        saveCompanyBusinessValue: this.saveCompanyBusinessValue.bind(this),
        getCompanyBusinessValue: this.getCompanyBusinessValue.bind(this)
      })),
    ).subscribe((data) => this.dataWithMethods.next(data));
  }

  async componentDidUpdate() {
    const user = this.auth.currentUser;
    if (!user) {
      await this.doCreateAnonymousUser();
      await this.emitCurrentProfile();
    }
  }

  async componentDidMount() {
    const user = this.auth.currentUser;
    if (!user) {
      // anonymous user is created on first webpage visit to a new system
      await this.doCreateAnonymousUser();
    }

    await this.emitCurrentProfile();
  }

  getUserToken = async () => {
    const token = await this.auth.currentUser.getIdToken();
    return token;
  }

  doSendVerificationEmail = async () => {
    //send verification email
    let message = 'Verification Email Sent';
    await this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_LOCAL,
    }).catch((error) => {
      console.log('error:', error.code);
      if (error.code === 'auth/too-many-requests') {
        message = 'too many requests';
      } else {
        message = 'something went wrong';
      }
    });
    return { message };
  }

  doCreateUserWithEmailAndPassword = async (email, password, fullName) => {
    await this.auth.createUserWithEmailAndPassword(email, password).catch((error) => {
      return error;
    });
    this.createDefaultProfile(fullName, email, null);
    // this.setAffinityGroups();
    return true;
  };

  deleteAccount = async (uid) => {
    const db = f.firestore();
    // delete all posts that user posted
    await db.collection('posts')
      .where('postedBy', '==', uid).get()
      .then(snapshots => {
        snapshots.forEach(snap => snap.ref.delete());
      }).catch(error => { console.log('Delete posts with user ID', error); return null; });
    // delete all replies that user replied
    await db.collection('replies')
      .where('repliedBy', '==', uid).get()
      .then(snapshots => {
        snapshots.forEach(snap => snap.ref.delete());
      }).catch(error => { console.log('Delete replies with user ID', error); return null; });

    await db.collection('users').doc(uid).delete()
      .then(() => {
        return true;
      }).catch(error => { console.log('deleteAccount', error); return false; });

    const user = this.auth.currentUser;
    await user.delete();
  }

  getCompanyForEmailAddress = async (email) => {
    try {
      const Functions = f.functions();
      const getCompany = Functions.httpsCallable('getCompanyFromEmail');
      const company = await getCompany({ email });

      if (company === null || company.data === null) {
        return false;
      }

      return company.data;
    } catch (exception) {
      return false;
    }
  };

  saveUserVibe = async (data) => {
    try {
      const Function = f.functions();
      const getMembers = Function.httpsCallable('sn_save_user_vibe');
      const vibes = await getMembers(data);
      if (vibes === null) {
        return false;
      }

      return vibes;
    } catch (err) {
      console.log('err :: ', err);
      return false;
    }
  }

  addDailyQuestion = async (data) => {
    try {
      const Function = f.functions();
      const getGroups = Function.httpsCallable('sn_addDaillyQuestion');
      const response = await getGroups(data);
      if (response === null) {
        return false;
      }

      return response;
    } catch (err) {
      console.log('err :: ', err);
      return false;
    }
  }


  getCompanyUser = async (user_id) => {
    try {
      const Function = f.functions();
      const getGroups = Function.httpsCallable('k_getCompanyUser');
      const groups = await getGroups({ user_id });
      if (groups === null) {
        return false;
      }

      return groups;
    } catch (err) {
      console.log('err :: ', err);
      return false;
    }
  }

  // updated by Jackie 2022-05-23
  getAffinityGroup = async (groupName) => {
    let result = null;
    const db = f.firestore();
    await db.collection('AffinityGroups').doc(groupName).get()
      .then(snapshot => {
        result = snapshot.data();
      }).catch(error => { console.log('error', error); return null; });
    return result;
  }

  // updated by Jackie 2022-05-23
  getAllAffinityGroups = async () => {
    const result = [];
    const db = f.firestore();
    await db.collection('AffinityGroups').get()
      .then(snapshots => {
        snapshots.forEach(snap => result.push({ id: snap.id, data: snap.data() }));
      })
      .catch(error => { console.log('error', error); return []; });
    return result;
  }

  getGroupsByCompany = (companyID) => {
    // const db = f.firestore();
    let result = [];
    return this.store.collection("groups")
      .where("companyID", "==", companyID)
      .orderBy('createTime')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          result.push({ groupID: doc.id, ...doc.data() });
        });
        return result;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return false;
      });
  }

  getGroupByID = (groupID) => {
    return this.store.collection("groups")
      .doc(groupID)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const groupInfo = { groupID: doc.id, ...doc.data() };
          // console.log("Document data:", doc.data());
          return groupInfo;
        } else {
          // doc.data() will be undefined in this case
          return Promise.reject("No such group!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        return false;
      });
  }

  createGroup = (groupName, companyID) => {
    return this.store.collection("groups")
      .add({
        groupName: groupName,
        companyID: companyID,
        createTime: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then((docRef) => {
        // console.log("Document written with ID: ", docRef.id);
        return docRef.id;
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        return false;
      });
  }

  updateGroupName = (groupName, groupID) => {
    return this.store.collection("groups")
      .doc(groupID)
      .update({
        groupName: groupName
      })
      .then(() => {
        // console.log("Document successfully updated!");
        return true;
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return false;
      });
  }

  deleteGroup = (groupID, membersList) => {
    // Get a new write batch
    var batch = this.store.batch();
    var groupRef = this.store.collection("groups").doc(groupID);
    batch.delete(groupRef);
    membersList.forEach((member) => {
      var memberRef = this.store.collection("junction_group_member").doc(groupID + "_" + member.userID);
      batch.delete(memberRef);
      if (member.role === "Admin") {
        var rolesRef = this.store.collection("users").doc(member.userID);
        batch.update(rolesRef, { userRoles: firebase.firestore.FieldValue.arrayRemove(ROLES.ADMIN + "_" + groupID) });
      }
    });
    // Commit the batch
    return batch
      .commit()
      .then(() => {
        console.log("Transaction successfully committed!");
        return true;
      }).catch((error) => {
        console.log("Transaction failed: ", error);
        return false;
      });
  }

  assignUserToGroupIfNotExist = (groupID, userID) => {
    let record = this.store.collection("junction_group_member").doc(groupID + "_" + userID);
    return this.store.runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(record)
        .then((doc) => {
          if (doc.exists) {
            return Promise.reject("User is already in the group.");
          }
          transaction.set(record, { groupID: groupID, userID: userID, createTime: firebase.firestore.FieldValue.serverTimestamp() });
          return true;
        })
        .catch((error) => {
          console.log("Transaction failed: ", error);
          return false;
        });
    }).then((isSucceed) => {
      if (isSucceed) {
        console.log("Transaction successfully committed!");
        return true;
      }
      return Promise.reject("Transaction failed.");
    }).catch((error) => {
      console.log("Transaction failed: ", error);
      return false;
    });
  }

  assignMultipleUsersToGroup = (companyID, groupID, usersID) => {
    // Get a new write batch
    var batch = this.store.batch();
    usersID.forEach((userID) => {
      var ref = this.store.collection("junction_group_member").doc(groupID + "_" + userID);
      batch.set(ref, { companyID: companyID, groupID: groupID, userID: userID, role: "Member", createTime: firebase.firestore.FieldValue.serverTimestamp() });
    });
    // Commit the batch
    return batch.commit().then(() => {
      console.log("Transaction successfully committed!");
      return true;
    }).catch((error) => {
      console.log("Transaction failed: ", error);
      return false;
    });
  }

  deleteUserFromGroupIfExist = (groupID, userID) => {
    let record = this.store.collection("junction_group_member").doc(groupID + "_" + userID);
    return this.store.runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction
        .get(record)
        .then((doc) => {
          if (!doc.exists) {
            return Promise.reject("User doesn't exist in the group.");
          }
          transaction.delete(record);
          return true;
        })
        .catch((error) => {
          console.log("Transaction failed: ", error);
          return false;
        });
    }).then((isSucceed) => {
      if (isSucceed) {
        console.log("Transaction successfully committed!");
        return true;
      }
      return Promise.reject("Transaction failed.");
    }).catch((error) => {
      console.log("Transaction failed: ", error);
      return false;
    });
  }

  assignUserAsAdminToGroup = (groupID, userID) => {
    var userRecord = this.store.collection("users").doc(userID);
    var memberRecord = this.store.collection("junction_group_member").doc(groupID + "_" + userID);
    return this.store.runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(memberRecord)
        .then((doc) => {
          if (!doc.exists) {
            return Promise.reject("Member isn't exist.");
          }
          transaction.update(userRecord, {
            userRoles: firebase.firestore.FieldValue.arrayUnion(ROLES.ADMIN + "_" + groupID)
          });
          transaction.update(memberRecord, { role: "Admin" });
          return true;
        })
        .catch((error) => {
          console.log("Transaction failed: ", error);
          return false;
        });
    }).then((isSucceed) => {
      if (isSucceed) {
        console.log("Transaction successfully committed!");
        return true;
      }
      return Promise.reject("Transaction failed.");
    }).catch((error) => {
      console.log("Transaction failed: ", error);
      return false;
    });
  }

  deleteAdminFromGroup = (groupID, userID) => {
    // Get a new write batch
    var batch = this.store.batch();
    var userRecord = this.store.collection("users").doc(userID);
    batch.update(userRecord, { userRoles: firebase.firestore.FieldValue.arrayRemove(ROLES.ADMIN + "_" + groupID) });
    var adminRecord = this.store.collection("junction_group_member").doc(groupID + "_" + userID);
    batch.delete(adminRecord);
    // Commit the batch
    return batch.commit().then(() => {
      console.log("Transaction successfully committed!");
      return true;
    }).catch((error) => {
      console.log("Transaction failed: ", error);
      return false;
    });
  }

  revokeAdminFromGroup = (groupID, userID) => {
    var userRecord = this.store.collection("users").doc(userID);
    var adminRecord = this.store.collection("junction_group_member").doc(groupID + "_" + userID);
    return this.store.runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(adminRecord)
        .then((doc) => {
          if (!doc.exists) {
            return Promise.reject("Admin isn't exist.");
          }
          transaction.update(userRecord, {
            userRoles: firebase.firestore.FieldValue.arrayRemove(ROLES.ADMIN + "_" + groupID)
          });
          transaction.update(adminRecord, { role: "Member" });
          return true;
        })
        .catch((error) => {
          console.log("Transaction failed: ", error);
          return false;
        });
    }).then((isSucceed) => {
      if (isSucceed) {
        console.log("Transaction successfully committed!");
        return true;
      }
      return Promise.reject("Transaction failed.");
    }).catch((error) => {
      console.log("Transaction failed: ", error);
      return false;
    });
  }

  sliceArray = (array, subGroupLength) => {
    let index = 0;
    let newArray = [];
    while (index < array.length) {
      newArray.push(array.slice(index, index += subGroupLength));
    }
    return newArray;
  }

  getMembersIDByGroup = (groupID) => {
    return this.store.collection("junction_group_member")
      .where("groupID", "==", groupID)
      .get()
      .then((querySnapshot) => {
        const membersID = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          membersID.push(doc.data());
        });
        return membersID;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return false;
      });
  }

  getUsersInfoByID = (usersID) => {
    if (usersID.length === 0) {
      return [];
    }
    const idArray = [];
    for (const id of usersID) {
      idArray.push(id.userID);
    }
    return this.store.collection("users")
      .where(firebase.firestore.FieldPath.documentId(), "in", idArray)
      .get()
      .then((querySnapshot) => {
        const usersInfo = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          let joiningDate = null;
          let role = null;
          for (let i = 0; i < usersID.length; i++) {
            if (usersID[i].userID === doc.id) {
              joiningDate = usersID[i].createTime;
              role = usersID[i].role;
              break;
            }
          }
          usersInfo.push({ userID: doc.id, joiningDate: joiningDate, role: role, ...doc.data() });
        });
        return usersInfo;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return false;
      });
  }

  getMembersByGroup = async (groupID) => {
    try {
      let membersID = await this.getMembersIDByGroup(groupID);
      if (membersID !== false) {
        membersID = this.sliceArray(membersID, 10);
        const promises = [];
        let membersInfo = [];
        for (let i = 0; i < membersID.length; i++) {
          promises.push(this.getUsersInfoByID(membersID[i]));
        }
        for await (let memberInfo of promises) {
          if (memberInfo !== false) {
            membersInfo = [...membersInfo, ...memberInfo];
          } else {
            throw new Error("Error getting documents.");
          }
        }
        return membersInfo;
      } else {
        throw new Error("Error getting documents.");
      }
    } catch (error) {
      console.log('Error getting documents: ', error);
      return false;
    }
  }

  getGroupsIDByUser = (userID) => {
    return this.store.collection("junction_group_member")
      .where("userID", "==", userID)
      .get()
      .then((querySnapshot) => {
        const groupsID = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          groupsID.push(doc.data().groupID);
        });
        return groupsID;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return false;
      });
  }

  getAdminGroupsIDByUser = (userID) => {
    return this.store.collection("junction_group_member")
      .where("userID", "==", userID)
      .where("role", "==", "Admin")
      .get()
      .then((querySnapshot) => {
        const groupsID = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          groupsID.push(doc.data().groupID);
        });
        return groupsID;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return false;
      });
  }

  getGroupsInfoByID = (companyID, groupsID) => {
    if (groupsID.length === 0) {
      return [];
    }
    return this.store.collection("groups")
      .where("companyID", "==", companyID)
      .where(firebase.firestore.FieldPath.documentId(), "in", groupsID)
      .get()
      .then((querySnapshot) => {
        const groupsInfo = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          groupsInfo.push({ groupID: doc.id, ...doc.data() });
        });
        return groupsInfo;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return false;
      });
  }

  getGroupsByUser = async (companyID, userID) => {
    try {
      let groupsID = await this.getGroupsIDByUser(userID);
      if (groupsID !== false) {
        groupsID = this.sliceArray(groupsID, 10);
        const promises = [];
        let groupsInfo = [];
        for (let i = 0; i < groupsID.length; i++) {
          promises.push(this.getGroupsInfoByID(companyID, groupsID[i]));
        }
        for await (let groupInfo of promises) {
          if (groupInfo !== false) {
            groupsInfo = [...groupsInfo, ...groupInfo];
          } else {
            throw new Error("Error getting documents.");
          }
        }
        return groupsInfo;
      } else {
        throw new Error("Error getting documents.");
      }
    } catch (error) {
      console.log('Error getting documents: ', error);
      return false;
    }
  }

  getAdminGroupsByUser = async (companyID, userID) => {
    try {
      let groupsID = await this.getAdminGroupsIDByUser(userID);
      if (groupsID !== false) {
        groupsID = this.sliceArray(groupsID, 10);
        const promises = [];
        let groupsInfo = [];
        for (let i = 0; i < groupsID.length; i++) {
          promises.push(this.getGroupsInfoByID(companyID, groupsID[i]));
        }
        for await (let groupInfo of promises) {
          if (groupInfo !== false) {
            groupsInfo = [...groupsInfo, ...groupInfo];
          } else {
            throw new Error("Error getting documents.");
          }
        }
        return groupsInfo;
      } else {
        throw new Error("Error getting documents.");
      }
    } catch (error) {
      console.log('Error getting documents: ', error);
      return false;
    }
  }

  getJoiningDateOfUser = (groupID, userID, role) => {
    const db = f.firestore();
    let collection = "";
    if (role === "admin") {
      collection = "junction_group_admin";
    } else {
      collection = "junction_group_member";
    }
    return db.collection(collection)
      .doc(groupID + "_" + userID)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const joiningDate = doc.data().createTime;
          // console.log("Document data:", doc.data());
          return joiningDate;
        } else {
          // doc.data() will be undefined in this case
          return Promise.reject("No such record!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        return false;
      });
  }

  addDailyQuestionByGroupID = (payload, groupID) => {
    return this.store.collection("dailyQuestion")
      .add({
        company_id: payload.company_id,
        question: payload.question,
        responses: payload.responses,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        user_id: payload.user_id,
        group_id: groupID
      })
      .then((docRef) => {
        // console.log("Document written with ID: ", docRef.id);
        return { "data": { "error": "error", "message": 'Question Saved Successfully!!!', "status": 200 } };
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        return false;
      });
  }

  saveCompanyBusinessValue = (companyID, businessValue) => {
    return this.store.collection("companyList")
      .doc(companyID)
      .update({
        businessValue: {
          title1: businessValue.title1,
          content1: businessValue.content1,
          title2: businessValue.title2,
          content2: businessValue.content2,
          title3: businessValue.title3,
          content3: businessValue.content3,
          title4: businessValue.title4,
          content4: businessValue.content4,
        }
      })
      .then(() => {
        // console.log("Document successfully updated!");
        return true;
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return false;
      });
  }

  getCompanyBusinessValue = (companyID) => {
    return this.store.collection("companyList")
      .doc(companyID)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const businessValue = doc.data().businessValue;
          // console.log("Document data:", doc.data());
          return businessValue;
        } else {
          // doc.data() will be undefined in this case
          return Promise.reject("No such business value!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        return false;
      });
  }

  getAllCompanyInnovations = async (company_id) => {
    const result = []
    const returnArray = []
    const db= f.firestore();
    await db.collection('users').where('company.id', '==', company_id).get()
      .then(snapshots=>{
        snapshots.forEach(snap=>result.push(snap.data()))
      }).catch(error => { console.log('error', error); return null; });
    result.forEach(item  =>{
      if(item.profiles?.default?.innovationAnswers?.innovations?.answer){
        returnArray.push({
          profilePicture: item.profiles.default.profilePicture,
          fullName: item.profiles.default.fullName,
          answer: item.profiles.default.innovationAnswers.innovations.answer,
          content: item.profiles.default.innovationAnswers.innovations.content,
          contentType: item.profiles.default.innovationAnswers.innovations.contentType,
          likedBy: item.profiles.default.innovationAnswers.innovations.likedBy,
          likes: item.profiles.default.innovationAnswers.innovations.likes,
          timeStamp: item.profiles.default.innovationAnswers.innovations.timeStamp,
          innovationId: item.profiles.default.innovationAnswers.innovations.innovationId,
          userId: item.uid

        })
      }
  
    })
      
      const returnObj = {data: returnArray}
      return returnObj;

    // try {
    //   const Function = f.functions();
    //   const getMembers = Function.httpsCallable('sn_user_fethCompanyInnovations');
    //   const vibes = await getMembers({ company_name });
    //   if (vibes === null) {
    //     return false;
    //   }

    //   return vibes;
    // } catch (err) {
    //   console.log('err :: ', err);
    //   return false;
    // }
  }

  getAllCompanyVibes = async (company_id) => {
  
    const result = []
    const returnArray = []
    const db= f.firestore();
    await db.collection('users').where('company.id', '==', company_id).get()
      .then(snapshots=>{
        snapshots.forEach(snap=>result.push(snap.data()))
      }).catch(error => { console.log('error', error); return null; });
    result.forEach(item  =>{
      if(item.profiles?.default?.vibeAnswers?.vibe?.answer){
        returnArray.push({
          profilePicture: item.profiles.default.profilePicture,
          fullName: item.profiles.default.fullName,
          answer: item.profiles.default.vibeAnswers.vibe.answer,
          content: item.profiles.default.vibeAnswers.vibe.content,
          contentType: item.profiles.default.vibeAnswers.vibe.contentType,
          likedBy: item.profiles.default.vibeAnswers.vibe.likedBy,
          likes: item.profiles.default.vibeAnswers.vibe.likes,
          timeStamp: item.profiles.default.vibeAnswers.vibe.timeStamp,
          vibeId: item.profiles.default.vibeAnswers.vibe.vibeId,
          userId: item.uid

        })
      }
  
    })
      
      const returnObj = {data: returnArray}
      return returnObj;

    // try {
    //   const Function = f.functions();
    //   const getMembers = Function.httpsCallable('sn_user_fetchCompanyVibes');
    //   const vibes = await getMembers({ company_name });
    //   if (vibes === null) {
    //     return false;
    //   }
      
    //   return vibes;
    // } catch (err) {
    //   console.log('err :: ', err);
    //   return false;
    // }
  }


  addVibeLike = async (ownerUserId, likeUserId) => {
    try {
      const Function = f.functions();
      const getMembers = Function.httpsCallable('sn_user_addLikeVibe');
      const vibes = await getMembers({ owner_user_id: ownerUserId, liked_user_id: likeUserId });
      if (vibes === null) {
        return false;
      }

      return vibes;
    } catch (err) {
      console.log('err :: ', err);
      return false;
    }
  }

  removeVibeLike = async (ownerUserId, likeUserId) => {
    try {
      const Function = f.functions();
      const getMembers = Function.httpsCallable('sn_user_removeVibeLike');
      const vibes = await getMembers({ owner_user_id: ownerUserId, liked_user_id: likeUserId });
      if (vibes === null) {
        return false;
      }

      return vibes;
    } catch (err) {
      console.log('err :: ', err);
      return false;
    }
  }


  addInnnovationLike = async (ownerUserId, likeUserId) => {
    try {
      const Function = f.functions();
      const getMembers = Function.httpsCallable('sn_user_addLikeInnovation');
      const vibes = await getMembers({ owner_user_id: ownerUserId, liked_user_id: likeUserId });
      if (vibes === null) {
        return false;
      }

      return vibes;
    } catch (err) {
      console.log('err :: ', err);
      return false;
    }
  }

  removeInnovationLike = async (ownerUserId, likeUserId) => {
    try {
      const Function = f.functions();
      const getMembers = Function.httpsCallable('sn_user_removeLikeInnovation');
      const vibes = await getMembers({ owner_user_id: ownerUserId, liked_user_id: likeUserId });
      if (vibes === null) {
        return false;
      }

      return vibes;
    } catch (err) {
      console.log('err :: ', err);
      return false;
    }
  }

  getAllCompanyMembers = async (company_id) => {
    try {
      const Function = f.functions();
      const getMembers = Function.httpsCallable('sn_getAllCompanyMembers');
      const members = await getMembers({ company_id });

      if (members === null || members.data === null) {
        return false;
      }

      return members.data;
    } catch (err) {
      return false;
    }
  }

  doWhitelistedCreateUserWithEmailAndPassword = async (email, password, fullName) => {
    const company = await this.getCompanyForEmailAddress(email);
    if (company === false) {
      return { message: 'Email not from allowed domain 22' };
    }

    const regResponse = await this.auth.createUserWithEmailAndPassword(email, password).catch((error) => {
      return { error: true, message: error.message };
    });

    if (regResponse.error === true) {
      return regResponse;
    }

    this.createDefaultProfile(fullName, email, null, company);
    // this.setAffinityGroups();
    this.doSendVerificationEmail();

    return true;
  };

  setAffinityGroups = () => {
    const user = this.auth.currentUser;
    if (!user.uid) {
      return;
    }
    const groupNames = ['Cooking', 'Sports'];
    for (const index in groupNames) {
      this.subscribeUserToAffinityGroup(groupNames[index], user.uid);
    }
  };

  doCreateAnonymousUser = async () =>
    await this.auth.signInAnonymously().then((res) => { this.createDefaultProfile(); });

  doSendPasswordReset = (resetEmail) => {
    const actionCodeSettings = { url: process.env.REACT_APP_LOCAL };
    return this.auth.sendPasswordResetEmail(resetEmail, actionCodeSettings);
  };

  doResetPassword = async (newPassword) => { return await this.auth.currentUser.updatePassword(newPassword).catch(error => error); };

  doSignInWithEmailAndPassword = async (email, password) => {
    await this.auth.signInWithEmailAndPassword(email, password);

    setTimeout(() => {
      this.auth.signOut()
      window.location.href = "/"
    }, "3600000")

    const data = await this.emitCurrentProfile();
    return data;
  };

  doSignInWithGoogle = async () => {
    const res = await this.auth.signInWithPopup(this.googleProvider);
    const profile = await this.getUserProfile(res.user.uid);
    if (profile) {
      await this.emitCurrentProfile();
    } else {
      this.doConvertAnonymousUserGoogle(res.user, {})
        .then(res => {
          return { success: true, res };
        })
        .catch(error => {
          return { success: false, res: error };
        });
    }
    return res;
  };

  doSignInWithFacebook = async () => {
    await this.auth.signInWithPopup(this.facebookProvider);
    await this.emitCurrentProfile();
  };
  doConvertAnonymousUserGoogle = async (user, roles) => {
    const email = user.email;
    const uid = user.uid;
    await this.store.collection('users').doc(uid).set({
      profiles: {
        default: {
          fullName: '',
          email
        }
      },
      signInMethod: 'google',
      roles
    }, { merge: true });
    await this.emitCurrentProfile();
  }

  // updated by Jackie 2022-05-23
  subscribeUserToAffinityGroup = async (groupName, userId) => {
    try {
      const db = f.firestore();
      await db.collection('users').doc(userId).update({
        'profiles.default.insights.affinityGroups': f.firestore.FieldValue.arrayUnion(groupName)
      });
    } catch (e) {
      return false;
    }
    return true;
  }

  // updated by Jackie 2022-05-23
  unsubscribeUserToAffinityGroup = async (groupName, userId) => {
    try {
      const db = f.firestore();
      await db.collection('users').doc(userId).update({
        'profiles.default.insights.affinityGroups': f.firestore.FieldValue.arrayRemove(groupName)
      });
    } catch (e) {
      return false;
    }
    return true;
  }

  getActiveUsersByCompany = async (company_id, latestDoc = 0, limit = 200) => {
    const result = [];
    const db = f.firestore();
    await db.collection('users').where('company.id', '==', company_id).where('accountStatus', '==', true)
      .orderBy('creationDate')
      .startAfter(latestDoc)
      .limit(limit).get()
      .then(snapshots => {
        snapshots.forEach(snap => result.push({ id: snap.id, data: snap.data() }));
      }).catch(error => { console.log('error', error); return null; });
    return result;
  };

  getAffinityGroupsUsersByCompany = async (company_id, affinity_group_name) => {
    try {
      const Functions = f.functions();
      const getCompany = Functions.httpsCallable('getAffinityGroupsUsersByCompany');
      const company = await getCompany({ company_id, affinity_group_name });

      if (company === null || company.data === null || !company.data) {
        return false;
      }

      return company.data;
    } catch (exception) {
      return false;
    }
  };

  // updataed by Jackie 2022-05-23
  getAffinityGroupsMembers = async (company_id, affinity_group_name, latestDoc = 0, limit = 12) => {
    const result = [];
    const db = f.firestore();
    await db.collection('users').where('company.id', '==', company_id).where('profiles.default.insights.affinityGroups', 'array-contains', affinity_group_name)
      .orderBy('creationDate')
      .startAfter(latestDoc)
      .limit(limit).get()
      .then(snapshots => {
        snapshots.forEach(snap => result.push({ id: snap.id, data: snap.data() }));
      }).catch(error => { console.log('error', error); return null; });
    return result;
  }

  getAllUsersByCompany = (company_id, latestDoc = 0, limit = 1000) => {
    // const result = [];
    const db = f.firestore();
    return db.collection('users').where('company.id', '==', company_id)
      .orderBy('creationDate')
      .startAfter(latestDoc)
      .limit(limit).get()
      .then(snapshots => {
        const result = [];
        snapshots.forEach(snap => {
          result.push({ id: snap.id, data: snap.data() });
        });
        return result;
      }).catch(error => { console.log('error', error); return false; });
    // return result;
  };

  activateUser = async (profile_user_id, admin_user_id, email) => {
    try {
      const Functions = f.functions();
      const getCompany = Functions.httpsCallable('activateUser');
      const company = await getCompany({ profile_user_id, admin_user_id, email });

      if (company === null || company.data === null || !company.data) {
        return false;
      }

      return company.data;
    } catch (exception) {
      return false;
    }
  };

  deactivateUser = async (profile_user_id, admin_user_id, email) => {
    try {
      const Functions = f.functions();
      const getCompany = Functions.httpsCallable('deactivateUser');
      const company = await getCompany({ profile_user_id, admin_user_id, email });

      if (company === null || company.data === null || !company.data) {
        return false;
      }

      return company.data;
    } catch (exception) {
      return false;
    }
  };



  getCompanyFromEmail = async (email) => {
    try {
      const Functions = f.functions();
      console.log("test:: ==> ", email)
      const getCompany = Functions.httpsCallable('k_getCompanyFromEmail');
      const company = await getCompany({ email });

      if (company === null || company.data === null || !company.data) {
        return false;
      }

      return company.data;
    } catch (exception) {
      return false;
    }
  };
  doConvertAnonymousUser = async (email, password, fullName, roles, company, successCallback, errorCallback) => {

    const credential = this.emailAuthProvider.credential(email, password);
    // this.setAffinityGroups();
    this.auth.currentUser.linkAndRetrieveDataWithCredential(credential)
      .then(async (authUserCred) => {
        //downside: we're doing this twice (on mergedUser, and properly via this.user().set())
        //upside: it works
        //but we should use promieses here or something async
        const mergedUser = authUserCred.user;
        mergedUser.roles = roles;
        //TODO: Set username and roles on user obj
        const uid = authUserCred.user.uid;

        await this.store.collection('users').doc(uid).set({
          company,
          profiles: {
            default: {
              fullName,
              email
            }
          },
          roles,
          signInMethod: 'email',
        }, { merge: true });



        await this.emitCurrentProfile();
        successCallback(mergedUser);
      }, error => {
        errorCallback(error);
      });
  };

  doSignOut = async () => {
    await this.auth.signOut();
    await this.doCreateAnonymousUser();
    await this.emitCurrentProfile();
  };

  async emitCurrentProfile() {
    const user = this.auth.currentUser;
    if (!user) {
      this.authData.next({ ref: null, data: {}, isAnonymous: true, uid: null });
      return;
    }

    const ref = this.store.collection('users').doc(user.uid);
    const snapshot = await ref.get();
    if (!snapshot.exists) {
    }
    // if (snapshot.data()){
    //   const userCompanyId = snapshot.data()
    //   if (userCompanyId.company.id){
    //      this.getCompanyMembers(userCompanyId.company.id)
    //   }
    // }
    const data = snapshot.exists ? snapshot.data() : {};
    data.uid = user.uid;
    let shouldUpdate = false;
    if (!data.userRoles) {
      data.userRoles = ['USER'];
      shouldUpdate = true;
    } else if (data.userRoles && data.userRoles.length > 0 && data.userRoles.indexOf('USER') === -1) {
      data.userRoles.push('USER');
      shouldUpdate = true;
    }
    if (!('accountStatus' in data)) {
      shouldUpdate = true;
      data.accountStatus = true;
    }
    if (shouldUpdate) {
      await ref.set(data);
    }
    this.authData.next({ ref, data, isAnonymous: user.isAnonymous, uid: user.uid, emailVerified: user.emailVerified });
    return data;
  }

  async save(dataState) {
    let ref = dataState.ref;
   
    if (!ref) {
      const user = await this.auth.signInAnonymously();
      ref = this.store.collection('users').doc(user.uid);
    }

    await ref.set(dataState.data);
  }

  getUserProfile = async (id) => {
    let result = null;
    await this.store.collection('users').doc(id).get()
      .then(snapshot => {
        result = snapshot.data().profiles.default;
      }).catch(error => { console.log('error', error); return null; });

    return result;
  }
  getEntireUser = async (id) => {
    let result = null;
    await this.store.collection('users').doc(id).get()
      .then(snapshot => {
        result = snapshot.data();
      }).catch(error => { console.log('error', error); return null; });
    return result;
  }

  getCompanyMembers = async (id) => {
    const result = [];
    await this.store.collection('users').where('company.id', '==', id).get()
      .then(snapshots => {
        snapshots.forEach(snap => result.push({ id: snap.id, data: snap.data() }));
      }).catch(error => { console.log('error', error); return null; });
    return result;
  }



  getAllQuestions = async (id) => {
    let result = [];
    const db = f.firestore();
    await db.collection('dailyQuestion').where('company_id', '==', id).get()
      .then(snapshots => {
        snapshots.forEach(snap => result.push({ id: snap.id, data: snap.data() }));

      }).catch(error => { console.log('error', error); return null; });
    if (result.length > 0) {
      result = result.map(res => {
        return { ...res, dateTime: res.data.timestamp.toDate() };
      });
    }
    return result;
  }


  getDailyQuestionForUser = async (id) => {
    let result = [];
    const db = f.firestore();
    await db.collection('dailyQuestion').where('company_id', '==', id).get()
      .then(snapshots => {
        snapshots.forEach(snap => result.push({ id: snap.id, data: snap.data() }));
      }).catch(error => { console.log('error', error); return null; });
    if (result.length > 0) {
      result = result.map(res => {
        return { ...res, dateTime: res.data.timestamp.toDate() };
      });
    }
    return result;
  }

  addUserAnswerToDailyQuestion = async (data, question_id) => {
    let result;
    const db = f.firestore();
    data['userRef'] = db.doc(`users/${data.userId}`);
    data['timeStamp'] = new Date();

    try {
      await db.collection('dailyQuestion').doc(question_id).update({
        responses: f.firestore.FieldValue.arrayUnion(data)
      });

      result = { message: 'Answer Successfully Submitted', status: true };
    } catch (error) {
      result = { message: error.message, status: false };
    }

    return result;

  }

  getDailyAnswers = async (id, company) => {
    // ====== not used currently
    const result = [];
    const db = f.firestore();
    await db.collection('users').where('company.id', '==', company).where('profiles.default.dailyQuestions.questionId', '==', id).get()
      .then(snapshots => {
        snapshots.forEach(snap => result.push({ id: snap.id, data: snap.data() }));

      }).catch(error => { console.log('Error', error); return null; });
    return result;
  }

  getDefaultProfile = (data) => {
    if (data === undefined || data.profiles === undefined || data.profiles.default === undefined) {
      return null;
    }

    return data.profiles.default;
  }

  createDefaultProfile = async (fullName, email, inviteCode, company, myLocation, myTeam, myRole, myBirthday, joiningDate) => {
    const db = f.firestore();
    const that = this;

    let userCompany = {};
    if (company !== null && company !== undefined) {
      userCompany = company;
    }


    await db.collection('users').doc(this.auth.currentUser.uid).set({
      company: userCompany,
      profiles: {
        default: {
          index: 0,
          fullName: fullName ? fullName : '',
          email: email ? email : '',
          myTeam: myTeam ? myTeam : '',
          myLocation: myLocation ? myLocation : '',
          myRole: myRole ? myRole : '',
          myBirthday: '1988-02-22',
          joiningDate: joiningDate ? joiningDate : 'Month/Day',
          isDefaultProfile: true,
          isComplete: false,
          isPublic: true,
          profilePicture: '/assets/media/profile/default-avatar.jpeg',
          creationDate: Date.now(),
          lastUpdate: Date.now(),
          softSkillsAnswers: {},
          dailyQuestion: [{
            dailyAnswer: '',
            questionId: ''
          }
          ],
          insights: {
            affinityGroups: [],
            color: '#FFFFFF',
            fiveFactorsScores: {},
            missionStatement: {},
            theme: '',
            wordCloud: [],
            chosenTraits: []

          },
          innovationAnswers: {
            innovations: {
              previousAnswers: []
            }
          },
          vibeAnswers: {
            vibe: {
              previousAnswers: []
            }
          },

          inviteCode: inviteCode ? inviteCode : null,
          gdpr: false
        }
      },
      accountStatus: true,
      userRoles: ['USER'],
      signInMethod: 'email',
      creationDate: Date.now(),
    })
      .then((res) => {
        that.emitCurrentProfile();
        //     .then(async ()=> {
        //       if(!AppConfig.isJobsMode()){
        //         let checkManagerStatus = f.functions().httpsCallable('checkManagerStatus');
        //         let res = await checkManagerStatus({email: email});
        //         if (res.data.length >= 1){
        //           let response = await that.getEntireUser(that.auth.currentUser.uid)
        //           let createTeam = f.functions().httpsCallable('createTeam');
        //           let result = await createTeam({managerEmail: email, template: "default", companyId: response.company.id});
        //           if (result.data.success){
        //             Notiflix.Notify.success("Manager Team Created");
        //             //Add People to Team
        //             let addUserToTeam = f.functions().httpsCallable('addUserToTeam');
        //             for(let i=0; i<res.data.length; i++){
        //               await addUserToTeam({template: 'default', managerEmail:  email, userId: res.data[i].id});
        //             }
        //           } else {
        //             Notiflix.Notify.failure(result.data.data);
        //           }
        //         }
        //       }
        //     })
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  }

  fetchSignInMethods = () => {
    if (!this.auth.currentUser) {
      return {};
    }

    return this.auth.currentUser.providerData;
  }

  convertMethod = async (data) => {
    try {
      const Functions = f.functions();
      const convert = Functions.httpsCallable('convertTest');
      const url = await convert(data);
      return url;
    } catch (exception) {
      return exception;
    }
  }

  //saves, converts, and saves converted file
  convertAndSaveFile = async (file) => {
    //urls to be saved to the db
    const urls = {
      rawURL: '',
      convertURL: '',
    };
    //generates guid - eventually replaced by userID?
    const uuidv1 = require('uuid/v1');
    const folderName = uuidv1();
    //gets file name
    const fileName = file.name;
    //gets file type - TODO: make better
    const fileURL = await this.saveFile(file, fileName, folderName);
    urls.rawURL = fileURL;
    urls.convertURL = fileURL;
    return urls;
  }

  saveFile = async (file, fileName, folderName) => {

    if (!fileName) {
      fileName = file.name;
    }

    if (!folderName) {
      const uuidv1 = require('uuid/v1');
      folderName = uuidv1();
    }

    const storageRef = f.storage().ref();
    const fileRef = storageRef.child(folderName + '_' + fileName);
    const uploadRes = await fileRef.put(file);
    const downloadURL = await uploadRes.ref.getDownloadURL();
    return downloadURL;
  }

  getCompanyInfo = async (companyId) => {
    let result = null;
    const db = f.firestore();
    await db.collection('companyList').doc(companyId).get()
      .then(snap => {
        return result = snap.data();
      }).catch(error => { console.log('error', error); return null; });
    return result;
  }

  saveCompanyInfo = async (companyId, companyLogo, companyName, domainName) => {
    const db = f.firestore();
    try {
      await db.collection('companyList').doc(companyId)
        .update({
          company: {
            data: {
              companyLogo,
              companyLogoWeb: companyLogo,
              domainName,
              name: companyName
            }
          },
          name: companyName
        });
    } catch (e) {
      return false;
    }
    return true;
  }

  // created by Jackie 5-25
  createPost = async (data) => {
    const db = f.firestore();
    const id = await db.collection('posts').add({
      ...data,
      creationDate: f.firestore.Timestamp.now(),
      lastUpdate: f.firestore.Timestamp.now(),
    })
      .then((docRef) => {
        return docRef.id;
      })
      .catch(error => null);
    return id;
  }

  // created by Jackie 5-25
  updatePost = async (postId, newImages, newContent) => {
    const db = f.firestore();
    try {
      await db.collection('posts').doc(postId)
        .update({
          images: [...newImages],
          content: newContent,
          lastUpdate: f.firestore.Timestamp.now(),
        });
    } catch (e) {
      return false;
    }
    return true;
  }

  // created by Jackie 5-25
  getPost = async (postId) => {
    let result = null;
    const db = f.firestore();
    await db.collection('posts').doc(postId).get()
      .then(snap => {
        result = { postId: snap.id, ...snap.data() };
      }).catch(error => { console.log('error', error); return null; });
    return result;
  }

  // created by Jackie 5-25
  getPosts = async (companyId, groupName, latestPost, limit = 12) => {
    const result = [];
    const db = f.firestore();
    await db.collection('posts')
      .where('companyId', '==', companyId).where('affinityGroupName', '==', groupName)
      .orderBy('creationDate', 'desc')
      .startAfter(latestPost)
      .limit(limit).get()
      .then(snapshots => {
        snapshots.forEach(snap => result.push({ postId: snap.id, ...snap.data() }));
      }).catch(error => { console.log('error', error); return null; });
    return result;
  }

  // created by Jackie 5-25
  likePost = async (postId, uid, likeAction = false) => {
    try {
      const db = f.firestore();
      if (likeAction)
        await db.collection('posts').doc(postId).update({
          'likedUsers': f.firestore.FieldValue.arrayUnion(uid)
        });
      else
        await db.collection('posts').doc(postId).update({
          'likedUsers': f.firestore.FieldValue.arrayRemove(uid)
        });
    } catch (e) {
      return false;
    }
    return true;
  }

  // created by Jackie 5-25
  deletePost = async (postId) => {
    try {
      const db = f.firestore();
      await db.collection('posts').doc(postId).delete();
    } catch (e) { return false; }
    return true;
  }

  // created by Jackie 5-26
  createReply = async (data) => {
    const db = f.firestore();
    const id = await db.collection('replies').add(data)
      .then((docRef) => {
        return docRef.id;
      })
      .catch(error => null);
    if (id) {
      await db.collection('posts').doc(data.postId).update({
        'replies': f.firestore.FieldValue.arrayUnion(id)
      });
    }
    return id;
  }

  // created by Jackie 5-25
  getReplies = async (postId, latestReply, limit = 3) => {
    const result = [];
    const db = f.firestore();
    await db.collection('replies')
      .where('postId', '==', postId)
      .orderBy('creationDate', 'desc')
      .startAfter(latestReply)
      .limit(limit).get()
      .then(snapshots => {
        snapshots.forEach(snap => result.push({ replyId: snap.id, ...snap.data() }));
      }).catch(error => { console.log('error', error); return null; });
    return result;
  }

  // created by Jackie 5-31
  updateReply = async (replyId, newImages, newComment) => {
    const db = f.firestore();
    try {
      await db.collection('replies').doc(replyId)
        .update({
          images: [...newImages],
          comment: newComment,
          lastUpdate: f.firestore.Timestamp.now(),
        });
    } catch (e) {
      return false;
    }
    return true;
  }

  // created by Jackie 5-26
  deleteReply = async (replyId, postId) => {
    try {
      const db = f.firestore();
      await db.collection('replies').doc(replyId).delete();
      await db.collection('posts').doc(postId).update({
        'replies': f.firestore.FieldValue.arrayRemove(replyId)
      });

    } catch (e) { return false; }
    return true;
  }

  // created by Jackie 5-25
  likeReply = async (replyId, uid, likeAction = false) => {
    try {
      const db = f.firestore();
      if (likeAction)
        await db.collection('replies').doc(replyId).update({
          'likedUsers': f.firestore.FieldValue.arrayUnion(uid)
        });
      else
        await db.collection('replies').doc(replyId).update({
          'likedUsers': f.firestore.FieldValue.arrayRemove(uid)
        });
    } catch (e) {
      return false;
    }
    return true;
  }

  render() {
    return <AuthDataContext.Provider value={this.dataWithMethods} {...this.props} />;
  }

}

class AuthDataStreamConsumer extends React.Component {
  static contextType = AuthDataContext;

  constructor(props) {
    super(props);

    this.loadingSpinner = (this.props.loadingSpinner) ?
      this.props.loadingSpinner :
      (
        // TODO: Fix this loading behavior. Spinner shows on home page load
        <div className="spinner-container d-flex justify-content-center">
          <Spinner className="d-flex justify-content-center" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );

    this.state = { profile: null, requireAccount: this.props.requireAccount ? true : false };
  }

  componentDidMount() {
    this.subscription = this.context.subscribe((dataWithMethods) => {
      this.setState({ profile: dataWithMethods });
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    return (
      (this.state.profile && this.state.profile.data)
        && (!this.state.requireAccount || this.state.profile.uid) ?
        <>
          {this.props.children(this.state.profile)}
        </>
        :
        <>
          {this.loadingSpinner}
        </>
    );
  }
}

AuthDataContext.StreamConsumer = AuthDataStreamConsumer;

export const useAuthDataContext = () => useContext(AuthDataContext);

export const withAuthDataContext = Component => props => (
  <AuthDataContext.StreamConsumer>
    {profile => <Component {...props} profile={profile} />}
  </AuthDataContext.StreamConsumer>
);

export default AuthDataProvider;
