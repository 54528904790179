/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  showGDPR: true
};

class GDPR extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = async() => {
    const currProfile = await this.props.profile.getDefaultProfile();
    const url = window.location.href;
    if((currProfile && currProfile.gdpr === true) || url.includes(ROUTES.PROFILE)) {
      this.setState({
        showGDPR: false
      });
    }
  };

  handleHideGDPR = async() => {
    this.setState({
      showGDPR: false
    });
    // let currProfile = await this.props.profile.getDefaultProfile();
    let currProfile = this.props.profile.data?.profiles?.default;
    if(currProfile){
      currProfile.gdpr = true;
    }
    this.props.profile.save();
  };

  render() {
    return (
      <div className={this.state.showGDPR === true ? 'gdpr' : 'hide'}>
        <FontAwesome name='times' onClick={() => this.handleHideGDPR()} />
        <div className='gdpr-text'>
          KeeperAI uses cookies to control certain site functionality. 
          By continuing to use this site, you agree to the use of cookies. 
          A cookie is a small data file, stored on the visitor's computer 
          which identifies the computer to the site and is used to control 
          access to the site content and other functionality such as the 
          ordering process.
        </div>
      </div>
    );
  }
}

export default GDPR;
