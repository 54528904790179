/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Card from '../Cards/Card';
import CardBootstrap from 'react-bootstrap/Card';
import { Carousel } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
const INITIAL_STATE = {
  
};

class Profile extends Component{
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  
  render(){
    const { skills, profile, manager, current } = this.props;
    if(profile){
      let className = 'text-center';
      if(manager){
        className = className.concat(' managerCard');
      }
      console.log('current: ', current);
      if(current){
        className = className.concat(' currentProfile');
      }
      return (
        <div className="card-body">
          {manager && (<div className="text-center"><FontAwesome name="crown" /></div>)}
          <h3 className="text-center">{profile.fullName}</h3>
          <CardBootstrap className={className}>
            <Carousel 
              className="gallery-carousel"
              interval={null}
              indicators={false}
              nextIcon={<FontAwesome name="chevron-right" />}
              prevIcon={<FontAwesome name="chevron-left" />}>
              {skills.map(skill => {
                return (
                  <Carousel.Item>
                    <Card id={skill} answers={profile.softSkillsAnswers} dontShowAddButton={null} >
                      {skill}
                    </Card>
                  </Carousel.Item>
                );})}
            </Carousel>
          </CardBootstrap>
        </div>
      );
    } else {
      return (<></>);
    }
  }
}

export default Profile;
