import React, { useEffect, useState, useRef } from 'react';
import { compose } from 'recompose';
import { Spinner, Form, Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import autoAnimate from '@formkit/auto-animate';
import FontAwesome from 'react-fontawesome';

import "./AffinityGroupChat.scss";

import {
  withAuthDataContext,
} from '../../components/Firebase/authdataprovider';
import Layout from '../../components/Layout';
import { withAuthorization } from '../../components/Session';
import AppConfig from '../../components/App/AppConfig';
import InsightsSideBar from '../Insights/InsightsSideBar';
import ImageSelector from './ImageSelector';
import Posts from './Posts';
import CustomCarousel from './CustomCarousel';

const LOADING_LIMIT = 10;

const LoadingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px auto;
`;

const AffinityGroupChat = (props) => {
    const groupName = props.match.params.id;
    const companyId = props.profile?.data?.company?.id;

    const postRef = useRef(null)
    const [showPostBox, setShowPostBox] = useState(false);
    const [postContent, setPostContent] = useState("");
    const [postImages, setPostImages] = useState([]);

    const [showSelectImageModal, setShowSelectImageModal] = useState(false);

    const [groupDetail, setGroupDetail] = useState(null);
    const [membersList, setMembersList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [lastMember, setLastMember] = useState(0);
    const [loadingMembers, setLoadingMembers] = useState(false);

    const [newPostId, setNewPostID] = useState(null);


    useEffect(() => {
        getGroupDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        postRef.current && autoAnimate(postRef.current)
    }, [postRef, showPostBox])
    
    const revealPostBox = () => setShowPostBox(!showPostBox);
    const showImageModal = () => setShowSelectImageModal(true);
    const hideImageModal = () => setShowSelectImageModal(false);

    const getGroupDetail = async () => {
        if (props.profile) {
            const groupDetail = await props.profile.getAffinityGroup(groupName);
            setGroupDetail(groupDetail);
        }
    }

    const loadFunc = () => {
        loadMoreMembers();
    }

    const loadMoreMembers = async () => {
        if (loadingMembers || !companyId) return;
        try {
            setLoadingMembers(true);
            const newMembers = await props.profile.getAffinityGroupsMembers(
                companyId,
                groupName,
                lastMember,
                LOADING_LIMIT
            );
            if (newMembers && newMembers.length) {
                setMembersList([...membersList, ...newMembers]);
                setLastMember(newMembers[newMembers.length - 1].data.creationDate);
                if (newMembers.length < LOADING_LIMIT) {
                  setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (e) {}
        finally {
            setLoadingMembers(false);
        }
    }

    const handleSaveImages = (images) => {
        setPostImages(images);
    }

    const handleCreatePost = async () => {
        if (postContent === "") return;
        const data = {
            postedBy: props.profile.uid,
            companyId: props.profile.userCompanyId,
            content: postContent,
            images: postImages,
            replies: [],
            likedUsers: [],
            affinityGroupName: groupName,
        }
        try {
            const postId = await props.profile.createPost(data);
            setNewPostID(postId);
            setPostContent("");
            setPostImages([]);
        } catch (error) {
            console.log(`Error in handlePost ${error}`);
        } finally {
            revealPostBox();
        }
    }

    const renderGroupDetail = () => {
        if (!groupDetail) 
            return (
                <div className='d-flex justify-content-center'>
                  <Spinner animation='border' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
                </div>
            );
        return (
            <div className="group-detail">
                <div className="group-title">
                    {groupName}
                </div>
                <img
                    className="groupImage"
                    src={groupDetail.groupImgUrl}
                    alt="Avatar"
                />
                <div className="group-tag">
                    {groupDetail.groupTagline}
                </div>
                <div className="members-list">
                    <InfiniteScroll
                        className="infiniteScroll"
                        pageStart={0}
                        hasMore={hasMore}
                        loadMore={loadFunc}
                        initialLoad={true}
                        useWindow={false}
                        loader={
                            <LoadingBox key="loader">
                                <Spinner animation='border' role='status'>
                                    <span className='sr-only'>Loading...</span>
                                </Spinner>
                            </LoadingBox>
                        }
                    >
                        {membersList.map((profil, index) => {
                             let profilePicture;
                             if(profil?.data?.profiles?.default?.profilePicture==="https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"||profil?.data?.profiles?.default?.profilePicture==="/images/default-avatar.svg"){
                               profilePicture='/assets/media/profile/default-avatar.jpeg'
                             }else{
                               profilePicture=profil?.data?.profiles?.default?.profilePicture;
                             }
                            const fullName = profil?.data?.profiles?.default?.fullName;
                            const myRole = profil?.data?.profiles?.default?.myRole;
                            return (
                                <div key={index} className="member-box">
                                    <img src={profilePicture} className="member_image" alt="Profile"/>
                                    <div className="member-detail">
                                        <a className="member-name" href={`/insights/${profil?.id}`}>{fullName}</a>
                                        <div className="member-role">{myRole}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </InfiniteScroll>
                </div>
            </div>
        )
    }

    const renderPostBox = () => {
        let profilePicture;
        if(props.profile?.data?.profiles?.default?.profilePicture==="https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"||props.profile?.data?.profiles?.default?.profilePicture==="/images/default-avatar.svg"){
          profilePicture='/assets/media/profile/default-avatar.jpeg'
        }else{
          profilePicture=props.profile?.data?.profiles?.default?.profilePicture;
        }
        const fullName = props.profile?.data?.profiles?.default?.fullName;
        const myRole = props.profile?.data?.profiles?.default?.myRole;
        return (
            <div ref={postRef} className="postBox">
                <div className="post-action">
                    <img src={profilePicture} className="profile-image" alt="profilePicture"/>
                    <div className="profile-detail">
                        <a className="profile-name" href={`/insights/${props.profile?.uid}`}>{fullName}</a>
                        <div className="profile-role">{myRole}</div>
                    </div>
                    <div className="start-post" onClick={() => revealPostBox()}>
                        {showPostBox?"Hide":"Start a post"}
                    </div>
                </div>
                { showPostBox && 
                    <div className="post-box-details">
                        <CustomCarousel images={postImages} onDeselectImages={() => {setPostImages([])}}/>
                        <Form.Control
                            style={{marginTop: "10px"}}
                            value={postContent}
                            onChange={(e) => setPostContent(e.target.value)}
                            as="textarea"
                            rows={3}
                            placeholder="What do you want to talk about?"
                        />
                        <div className="post-box-footer">
                            <div className="add-image" onClick={showImageModal}>
                                <FontAwesome name='image'/>
                            </div>
                            <div
                                className={(postContent === "") ? "post-button disabled" : "post-button"}
                                onClick={() => handleCreatePost()}
                            >
                                Post
                            </div>
                        </div>
                    </div>
                }
            </div>
        );        
    }

    return (
        <div className="groupChatPage">
            <Layout nofooter={true}>
                <div className="groupChatBox">
                    <>
                        <InsightsSideBar 
                            image={props.profile.data.profiles.default.profilePicture}
                            name={props.profile.data.profiles.default.fullName}
                            profile={props.profile}
                        />
                        <div className="page-container">
                            <div className="group-details">
                                {renderGroupDetail()}
                            </div>
                            <div className="group-chat">
                                {renderPostBox()}
                                <div className="post-list">
                                    <Posts
                                        profile={props.profile}
                                        groupName={groupName}
                                        newPostId={newPostId}
                                        history={props.history}
                                    />
                                </div>
                            </div>
                        </div>
                        <Modal
                            show={showSelectImageModal} 
                            onHide={hideImageModal} 
                            animation={true} 
                            size='lg'
                            keyboard
                        >
                            <Modal.Header closeButton/>
                            <Modal.Body>
                                <ImageSelector
                                    profile={props.profile}
                                    onSaveImages={handleSaveImages}
                                    onClose={hideImageModal}
                                    currentImages={postImages}
                                />
                            </Modal.Body>
                        </Modal>
                    </>
                </div>
            </Layout>
        </div>
    );
};

// export default AffinityGroupChat

const AffinityGroupChatWithCon = withAuthDataContext(AffinityGroupChat);

const condition = (profile) => {
  if (AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(withAuthorization(condition))(
    AffinityGroupChatWithCon,
);
