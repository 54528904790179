import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { compose } from 'recompose';
import { fadeInUp } from 'react-animations';
import {
  AuthDataContext,
  withAuthDataContext,
} from '../../components/Firebase/authdataprovider';
import InsightsSideBar from '../Insights/InsightsSideBar';
import Layout from '../../components/Layout';
import AppConfig from '../../components/App/AppConfig';
import { withAuthorization } from '../../components/Session';
import AdminGroup from '../../components/AdminGroup/AdminGroup';
import CompanyInfo from '../../components/CompanyInfo';
import * as ROUTES from '../../constants/routes';
import f from 'firebase';
const FadeUp = styled.div`
  animation: 0.6s ${keyframes`${fadeInUp}`};
`;
// const LoadingBox = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   margin: 0 auto;
// `;

const AdminPage = (props) => {

  const [groupList, setGroupList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [adminGroupLoading, setAdminGroupLoading] = useState(true);

  useEffect(() => {
    // console.log("props", props);
    if (
      props.profile.data?.company?.id &&
      props.profile.data?.company?.id !== ''
    ) {
      window.scrollTo(0, 0);
      // console.log("111", props);
      // getAdminGroupList(props.profile.data?.company?.id, props.profile.data?.uid);
      loadCompanyInfo();
      const db = f.firestore();
      var unsubscribeGroups = db.collection("groups")
        .where("companyID", "==", props.profile.data?.company?.id)
        .onSnapshot((querySnapshot) => {
          getAdminGroupList(props.profile.data?.company?.id, props.profile.data?.uid);
        });
      var unsubscribeGroupMember = db.collection("junction_group_member")
        .where("companyID", "==", props.profile.data?.company?.id)
        .onSnapshot((querySnapshot) => {
          getAdminGroupList(props.profile.data?.company?.id, props.profile.data?.uid);
        });
      var unsubscribeUsers = db.collection("users")
        .where("company.id", "==", props.profile.data?.company?.id)
        .onSnapshot((querySnapshot) => {
          getAdminGroupList(props.profile.data?.company?.id, props.profile.data?.uid);
        });
      return () => {
        unsubscribeGroups();
        unsubscribeGroupMember();
        unsubscribeUsers();
      }
    }
  }, []);

  const getAdminGroupList = async (companyID, userID) => {
    try {
      const adminGroups = await props.profile.getAdminGroupsByUser(companyID, userID);
      if (adminGroups === false) {
        throw new Error("Error getting adminGroups.");
      }
      const promises = [];
      const userLists = [];
      for (let i = 0; i < adminGroups.length; i++) {
        promises.push(props.profile.getMembersByGroup(adminGroups[i].groupID));
      }
      for await (let userList of promises) {
        if (userLists !== false) {
          userLists.push(userList);
        } else {
          throw new Error("Error getting userLists.");
        }
      }
      // console.log("userLists", userLists);
      for (let i = 0; i < adminGroups.length; i++) {
        adminGroups[i].members = userLists[i];
      }
      adminGroups.sort((a, b) => new Date(a.createTime.toDate()) - new Date(b.createTime.toDate()));
      setGroupList(adminGroups);
      setAdminGroupLoading(false);
      // console.log('adminGroups：', adminGroups);
    } catch (error) {
      console.log('Error: ', error);
      return false;
    }
  };

  const loadCompanyInfo = async () => {
    try {
      const result = await props.profile.getCompanyInfo(
        props.profile.data?.company?.id
      );
      if (result) {
        setCompanyInfo(result.company.data)
      }
    } catch (error) {
      console.log(`Error in loadCompanyInfo ${error}`);
    }
  };

  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };

  return (
    <>
      <div className="insightsPage">
        <Layout>
          <div className="insightsBox">
            <AuthDataContext.StreamConsumer requireAccount={true}>
              {(profile) => (
                <>
                  <InsightsSideBar
                    image={
                      profile.data.profiles.default.profilePicture
                    }
                    name={profile.data.profiles.default.fullName}
                    profile={profile}
                  />
                  <div className="wrapper">
                    <FadeUp className="my-auto">
                      <div className="company">
                        <div className="title">
                          <span>
                            <b>Company Groups List</b>
                          </span>
                        </div>
                        <section>
                          <div>
                            <AdminGroup
                              data={groupList}
                              props={props}
                              adminGroupLoading={adminGroupLoading}
                              setAdminGroupLoading={setAdminGroupLoading}
                            />
                          </div>
                          {/* <Row>
                            <Col span={24}></Col>
                          </Row> */}
                        </section>
                      </div>
                      {/* <div className="company">
                        <div className="title">
                          <span>
                            <b>Company Info</b>
                          </span>
                        </div>
                        <div className="company-info">
                          {companyInfo && (
                            <CompanyInfo
                              profile={profile}
                              info={companyInfo}
                              companyId={props.profile.data?.company?.id}
                            />
                          )}
                        </div>
                      </div> */}
                      <div className="company">
                        <section>
                          <div className="row m-0 ">
                            <div className="col-md-6 pt-3 pb-3">
                              <button
                                className="button btn-solid-purple createBtnBottom"
                                onClick={() =>
                                  props.history.push(
                                    ROUTES.VIBEPAGE,
                                  )
                                }
                              >
                                TEAM'S VIBE
                              </button>
                            </div>
                            <div className="col-md-6 pt-3 pb-3 btn-right-corner-innovation">
                              <button
                                className="button btn-solid-purple createBtnBottom"
                                onClick={() =>
                                  props.history.push(
                                    ROUTES.INNOVATION_PAGE,
                                  )
                                }
                              >
                                TEAM'S INNOVATION
                              </button>
                            </div>
                          </div>
                        </section>
                      </div>
                    </FadeUp>
                  </div>
                </>
              )}
            </AuthDataContext.StreamConsumer>
          </div>
        </Layout>
      </div>
    </>
  );
};
const Admin_Page = withAuthDataContext(AdminPage);

const condition = (profile) => {
  // console.log("JObs mode? Verified? ", AppConfig.isJobsMode(), profile.emailVerified)
  if (AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(withAuthorization(condition))(Admin_Page);