import React, { Component } from 'react';
import { 
  Row, 
  Col, 
  Form, 
} from 'react-bootstrap';

class MyTeam extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      displayTeam: '',
      error: ''
    };
  };

  componentDidMount = async () => {
    const currTeam = this.props.profile.getDefaultProfile().myTeam;
    this.setState({
      displayTeam: currTeam || ''
    })
  }

  onBlur = event => {
    event.preventDefault();
    const { displayTeam } = this.state;
    this.props.profile.getDefaultProfile().myTeam = displayTeam;
    this.props.profile.save();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <Form.Group>
        <Row className='text-left'>
          <Col>
            <Form.Label>My Team</Form.Label> 
            <Form.Control
              name='displayTeam'
              value={this.state.displayTeam}
              onBlur={this.onBlur}
              onChange={this.onChange}
              type='text'
              maxLength= {20}
              placeholder="max 20 characters"
            />
        
     
          </Col>
        </Row>
      </Form.Group>
     )
  }
};

export default MyTeam;