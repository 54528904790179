/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import './AffinityGroupsPage.scss';
import {
  AuthDataContext,
  withAuthDataContext,
} from '../../components/Firebase/authdataprovider';
import InsightsSideBar from '../Insights/InsightsSideBar';
import * as ROUTES from '../../constants/routes';
import { withAuthorization } from '../../components/Session';
import { compose } from 'recompose';
import AppConfig from '../../components/App/AppConfig';

import { Spinner } from 'react-bootstrap';
import { fadeInUp } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import Layout from '../../components/Layout';
import SearchBar from '../../components/SearchBar/SearchBar';
// const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;

const AffinityGroupsPage = (props) => {
  const [subscribing, setSubscribing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [affinityGroupsList, setAffinityGroupsList] = useState([
    {
      groupImgUrl: '/assets/media/5edc63d58376f479b4a4e04673c1455a.jpeg',
      groupName: 'Cats',
      subscribedByCurrentUser: true,
      groupTagline: 'Proud collaborative work with team',
    }
  ]);
  const [searchAffinityGroupsList, setSearchAffinityGroupsList] = useState(affinityGroupsList);

  useEffect(() => {
    window.scrollTo(0, 0);  
    getAllAffinityGroups();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllAffinityGroups = async() => {
    await props.profile.getAllAffinityGroups(props.profile.uid)
      .then(result => {
        const { profiles } = props.profile.data;
        let affinityGroups = [];
        if(profiles?.default?.insights?.affinityGroups) affinityGroups = profiles?.default?.insights?.affinityGroups;
        let groups = [];
        if(result && result.length)
          groups = result.map(group => {
            return {
              groupImgUrl: group.data.groupImgUrl,
              groupName: group.id,
              subscribedByCurrentUser: (affinityGroups.findIndex(id => id === group.id) >= 0) ? true : false,
              groupTagline: group.data.groupTagline,
            };
          });
        if (groups && groups.length > 0) {
          setAffinityGroupsList(groups);
          setSearchAffinityGroupsList(groups);
        } else {
          setAffinityGroupsList([]);
          setSearchAffinityGroupsList([]);
        }
        //setAffinityGroupsList(groups);
        //setSearchAffinityGroupsList(groups);
        setLoading(false);
      });
  };

  const updateFieldChanged = (index) => async(e) => {
    e.stopPropagation();
    if(subscribing) return;
    const { groupName, subscribedByCurrentUser } = affinityGroupsList[index];
    try {
      setSubscribing(true);
      let successed = false;
      if(!subscribedByCurrentUser)
        successed = await props.profile.subscribeUserToAffinityGroup(groupName, props.profile.uid);
      else
        successed = await props.profile.unsubscribeUserToAffinityGroup(groupName, props.profile.uid);
      if(successed) {
        const newArr = [...affinityGroupsList]; // copying the old datas array
        newArr[index].subscribedByCurrentUser = !newArr[index].subscribedByCurrentUser; // replace e.target.value with whatever you want to change it to
        setAffinityGroupsList(newArr);
      }
    } catch(e) {
      console.log(e);
    }
    finally {
      setSubscribing(false);
    }    
  };
 
  return (
    <>
      <div className="insightsPage">
        <Layout data = {affinityGroupsList} setSearchResults = {setSearchAffinityGroupsList}
         loading = {loading} filterBy={'groupName'} placeholder={'Enter a Group Name'}>
          <AuthDataContext.StreamConsumer requireAccount={true}>
            {(profile) => (
              <>
           
                <InsightsSideBar
                  image={profile.data.profiles.default.profilePicture}
                  name={profile.data.profiles.default.fullName}
                  profile={profile}
                />

                <div className="affinittyGroupsPage">
            
                  <FadeUp className="my-auto">
                    <div className="affinittyGroups">
                      <div className="title">
                        <span>
                          <b>Affinity Groups</b>
                        </span>
                      </div>
                      <div>Click the heart icon to join a group</div>
                      {/* <div>
                        { !loading && <SearchBar placeholder='Enter a Group Name' data={affinityGroupsList} setSearchResults={setSearchAffinityGroupsList} filterBy='groupName' /> }
                      </div> */}
                      <section>
                        <div className="row"></div>

                        {/* <h3>this is where company folks will go</h3> */}
                        <div className="row ag-gallery pt-2 pb-5">
                          { !loading ?
                    
                            searchAffinityGroupsList.length > 0 ? (
                              searchAffinityGroupsList.map((profile, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="col-xs-12 col-sm-12 col-md-6 col-lg-4 img-width-res group-overlay mt-3"
                                  >
                                    <span className="cursor-pointer"
                                      onClick={() => {
                                        props.history.push(ROUTES.AFFINITY_MEMBERS_PAGE + '/' + profile.groupName);
                                        return false;
                                      }}
                                    >
                                      <img
                                        src={profile.groupImgUrl}
                                        alt="Avatar"
                                        className="group-img-overlay"
                                        style={{ width: '100%' }}
                                      />
                                      <div className="group-title-div">{profile?.groupName}</div>
                                      <div className="top-right-corner">
                                        <div className="circleBase type1">
                                          <button
                                            className="btn btn-link btn-like"
                                            type="button"
                                            onClick={ updateFieldChanged(i) }
                                          >
                                            <span>
                                              { profile.subscribedByCurrentUser ? <i className="fa fa-heart icon-size btn-like-active" aria-hidden="true" ></i> : <i className="fa fa-heart-o icon-size" aria-hidden="true" ></i>}
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                      <div className="group-description-div">
                                        <div className="group-description-txt">{profile?.groupTagline}</div>
                                      </div>
                                    </span>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="col-md-12 text-center">
                                <span>No Groups Found</span>
                              </div>
                            )
                            : 
                            (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            )
                          }
                        </div>

                        <div className="row m-0 bottom-button">
                          <div className="col-md-12 pt-3 pb-3 btn-right-corner" >
                            <button
                              className="button shadow-none btn-solid-purple createBtnBottom"
                              onClick={() => props.history.push(ROUTES.INSIGHTS + '/' + profile.uid)}
                            >
                        MY INSIGHTS
                            </button>
                          </div>
                        </div>
                  
                      </section>
                    </div>
                  </FadeUp>
                </div>
              </>
            )}
          </AuthDataContext.StreamConsumer>
        </Layout>
      </div>
    </>
  );
};

// export default AffinityGroupsPage

const AffinityGroupsPageM = withAuthDataContext(AffinityGroupsPage);

const condition = (profile) => {
  // console.log("JObs mode? Verified? ", AppConfig.isJobsMode(), profile.emailVerified)
  if(AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(withAuthorization(condition))(
  AffinityGroupsPageM,
);
