/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

const INITIAL_STATE = {
  facebookURL : '',
  twitterURL : '',
  linkedInURL: '',
  instagramURL: '',
  customLinks: [],
  focus: false
};

class SocialLinks extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  async componentDidMount() {
    const currProfile = this.props.profile.getDefaultProfile();
    this.setState({
      customLinks: currProfile.customLinks || [''],
    });
  }

  validateLink(url){
    if(!url){
      return url;
    }
    url.trim();
    if(url.length === 0){
      return url;
    }
    if(url.startsWith('https://') || url.startsWith('http://')){
      return url;
    } else {
      return `https://${url}`;
    }
  }

  deleteLink = index => {
    const newLinks = this.state.customLinks;
    newLinks.splice(index, 1);
    this.setState({ customLinks: newLinks });
    const currProfile = this.props.profile.getDefaultProfile();
    currProfile.customLinks = newLinks;
    this.props.profile.save();
  }

  onBlur = event => {
    //event.preventDefault();
    const links = this.state.customLinks;
    const newLinks = [];
    links.map(link => {
      link = this.validateLink(link);
      return newLinks.push(link);
    });
    const currProfile = this.props.profile.getDefaultProfile();
    currProfile.customLinks = newLinks;
    this.props.profile.save();
  };

  onChange = event => {
    const index = parseInt(event.target.name);
    const customLinks = this.state.customLinks;
    customLinks[index] = event.target.value;
    this.setState({ customLinks });
  };

  addLink = event => {
    event.preventDefault();
    const newCustomLinks = this.state.customLinks;
    newCustomLinks.push('');
    this.setState({
      customLinks: newCustomLinks,
      focus: true
    });
  }

  render(){
    const {
      customLinks, 
      focus
    } = this.state;
    let count = -1;
    return (
      <>
        <Row className="text-left">
          <Col>
            <Form.Label>
              Personal Links
            </Form.Label>
            <p className="sub-label">
              Facebook, Twitter, Instagram, LinkedIn, YouTube, personal portfolio, projects, honors, etc.
            </p>
            <div>
              {customLinks.map(link => {
                count++;
                const index = count;
                return (
                  <Form key={index} onSubmit={(event) => {event.preventDefault();console.log('submit'); this.addLink(event);}}>
                    <div className="input-group mb-2 mr-sm-2" key={index}>
                      <Form.Control 
                        className="social-links" 
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        onPaste={this.onBlur}
                        name={index}
                        key={index}
                        value={link}
                        autoFocus={focus} />
                      <div 
                        className="input-group-append"
                        onClick={(event) => {event.preventDefault(); this.deleteLink(index);}}
                      >
                        <div 
                          className="input-group-text">
                          <FontAwesome name="times" />
                        </div>
                      </div>
                    </div>
                  </Form>
                );}
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Container className="text-left mb-4 mt-2">
            <button 
              type="button" 
              onClick={this.addLink}
              className="btn-outline-purple add-link-btn">
              <FontAwesome name="plus" />
                ADD LINK
            </button>
          </Container>
        </Row>
      </>
    );
  }
}

export default SocialLinks;
