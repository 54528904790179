import React, { Component } from 'react';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { debounce } from 'lodash';
import APIVIBE from '../APIVIBE';
import '../CCC.scss';
import DragAndDrop from '../Search/dragAndDrop';
import AppConfig from '../../../components/App/AppConfig'

const SearchType = {
  Giphy: 'GIPHY',
  Youtube: 'YOUTUBE',
  Google: 'GOOGLE',
  Imgur: 'IMGUR LINK',
  Upload: 'UPLOAD'
};

const INITIAL_STATE = {
  input: '',
  term: '',
  inputText: 'Insert a search term here to view content:',
  searchType: SearchType.Giphy,
  search: false,
  title: '',
  sub: '',
  changed: false,
  searching: false,
  showExampleModal: false,
  showEditModal: false,
  mode: 'job',
  searchedTerms: [],
  file : null,
  fileUploading: false
};

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.delay = debounce(this.changeTerm, 1000)
  };
  static defaultProps = {
    id: '',
  }

  componentDidMount = async() => {

    window.addEventListener('imgInput', (e) => this.handleImgurInput(e));

    if (AppConfig.isJobsMode()){
      this.setState({mode: 'job'})
    } else {
      this.setState({mode: 'team'});
    }
    let term = '';
    try {
      // term = _softSkills.softSkills[this.props.id].sampleTerm;
      // if(this.props.profile.data.profiles.default.softSkillsAnswers[this.props.id].selectedTerm){
      //   term = this.props.profile.data.profiles.default.softSkillsAnswers[this.props.id].selectedTerm
      // }
    } catch (expection){
    }

    await this.onChangeInputFirstTime(term);

    try{
      const vibeAns = this.props.profile.data.profiles.default.vibeAnswers;
      if (this.props.id in vibeAns && vibeAns[this.props.id].answer !== undefined){
        this.setState({showEditModal: true});
      }
    }
    catch(e){}
  }

  setShowEditModal = (showIt) => {
    this.setState({showEditModal: showIt});
  }

  onChangeInputFirstTime = async (input) => {
    this.setState({
        input: input,
        search: false,
    });
    this.delay();
  };
  handleImgurInput = async (e) => {
    console.log('change')
    this.setState({
      input: e.detail.input,
      search: false,
      changed: true,
      fileUploading: false,
  });
  this.changeSearch(SearchType.Imgur)
  }

  onChangeInput = async (input) => {
    
    this.setState({
        input: input,
        search: false,
        changed: true,
        
    });
    this.delay();
  };

  changeTerm = async () => {
    const hasHttp = (url) => {
      if (typeof url !== 'string') {
        return false;
      }
      //eslint-disable-next-line
      return (url.match(/^http[^\?]*/gmi) !== null);
    }
  
      if(this.state.input !== "" && this.state.searchType !== SearchType.Imgur && !hasHttp(this.state.input) ) {
        if (!this.props.profile.data.profiles.default.vibeAnswers){
          this.props.profile.data.profiles.default.vibeAnswers = {};
        }
  
        if (!(this.props.id in this.props.profile.data.profiles.default.vibeAnswers)){
          this.props.profile.data.profiles.default.vibeAnswers[this.props.id] = {};
        }
  
        if (!this.props.profile.data.profiles.default.vibeAnswers[this.props.id].searchedTerms){
          this.props.profile.data.profiles.default.vibeAnswers[this.props.id].searchedTerms = [];
        }
  
        let searchedTerms = this.props.profile.data.profiles.default.vibeAnswers[this.props.id].searchedTerms
        searchedTerms.push(this.state.input)
        let userAnswer = this.props.profile.data.profiles.default.vibeAnswers[this.props.id];
        userAnswer.searchedTerms = searchedTerms;
        this.props.profile.save();
      }
      // updates searchtext
      this.updateSearchInput();
  
      this.setState({
        term: this.state.input,
        search: true,
      },()=>{this.forceUpdate()});
  };

  changeSearch = (newSearchType) => {
    if(this.state.searchType !== newSearchType){
      this.setState({
        searchType: newSearchType,
        searching: true,
        search: false,
        changed: true,
      },()=>{this.forceUpdate();this.changeTerm()});
    }
  }
  updateSearchInput = async () => {
    /*
      Changing the search type will update the search input from 
    */
    switch (this.state.searchType) {
      case (SearchType.Imgur):
        this.setState({
          inputText: 'Place your imgur link below:'
        })
        break;
      default:
        this.setState({
          inputText: 'Insert a search term here to view content:'
        })
        break;
    }
  }

  handleDrop = (file) => {
    /**
     * Handles drop of file from drag and Drop
     * Takes in event.dataTransfer.files[0]
     */
    
    // let upFile = file;
    // Checks if the file is an image
    if (!file || !file.type.match(/image.*/)) return;
    this.changeSearch(SearchType.Upload);
    
    //Sets State to upload
    this.setState({
      search: false,
      changed: true,
      file: file,
      fileUploading: true
    });
    this.delay();
  }
  render() {
    const {
      input,
      searchType,
      inputText,
      term,
      searching,
      search,
      changed,
      mode,
      file,
      fileUploading,
     
    } = this.state;

    const {
      title, 
      sub, 
      id
    } = this.props;

    
    // console.log('JSON title', jsonTitle);

    // const examples = mode === 'job' ? AshSmith.profiles.default.softSkillsAnswers : AshleySmithTeams.profiles.default.softSkillsAnswers;
    // console.log('examples', examples);

    // const example = examples[jsonTitle].question;
    // console.log('example', example);

    return (
      <div className='search'>
        <Container>
        <>{inputText}</>
          <Row className='searchDiv'>
            <Col lg={6} className='inputDiv'>
            <div 
                className={`control has-icons-left 
                ${searching ? 'is-loading is-large' : ''}`}>
                  
                    <DragAndDrop handleDrop={this.handleDrop}>
                      <input 
                          className='input' 
                          placeholder={`Search`} 
                          value={input}
                          onChange={(event) => 
                          this.onChangeInput(event.target.value)} />
                    </DragAndDrop>
                
                <span className='icon'>
                  {!search || fileUploading ?
                    <div className='search-spinner'>
                      <Spinner animation='border' /> 
                    </div> :
                    <FontAwesome name='search' />
                  }
                </span>
              </div>
            </Col>
            <Col lg={6} className='searchButtonsDiv'>
              <button 
                className={searchType === SearchType.Giphy ? 
                  ('searchbtn searchbtnActive') : 
                  ('searchbtn')} 
                onClick={()=>this.changeSearch(SearchType.Giphy)}> GIPHY 
              </button>
              {/* <button 
                className={searchType === SearchType.Youtube ? 
                  ('searchbtn searchbtnActive') : 
                  ('searchbtn')} 
                onClick={()=>this.changeSearch(SearchType.Youtube)}> YOUTUBE
              </button> */}
              <button 
                className={searchType === SearchType.Google ? 
                  ('searchbtn searchbtnActive') : 
                  ('searchbtn')} 
                onClick={()=>this.changeSearch(SearchType.Google)}> GOOGLE
              </button>
              <button 
                className={searchType === SearchType.Imgur || searchType === SearchType.Upload ? 
                  ('searchbtn searchbtnActive') : 
                  ('searchbtn')} 
                onClick={()=>this.changeSearch(SearchType.Imgur)}> IMGUR LINK
              </button>
            </Col>
          </Row>
        </Container>
        {search && <APIVIBE
          term={term}
          file={file}
          searchType={searchType} 
          id={id}
          title={title}
          sub={sub}
          changed={changed}
          profile={this.props.profile}
          showEditModal={this.state.showEditModal}
          setShowEditModal={this.setShowEditModal}
          showExampleModal={this.showExampleModal}
          
        />}
      </div>
    )
  }
};

export default Search;