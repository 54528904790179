import React from 'react';
import './InsightsSideBar.scss';

export const InsightCard = ({ type, heading, uid, profile }) => {
  return (
    <>

      <span className="sideBar_heading"><b>Tip of the Week</b></span>

     


      { type === 'tipofWeek' ?
        <section className="section">
          <div className="tipHeadingWrapper">
            <div className="tipHeading">
              <span>
                                Stepping Outside the Comfort Zone for Reserved Individuals
              </span>
            </div>
          </div>
          <div className="tipTextWrapper">

            <div className="tipText">
              <span>
                            Commit to making at least one verbal contribution per meeting, to reach out to one new network acquaintance per week, or to get to know a colleague by asking a sincere question. These self-defined challenges should push you beyond your comfort zone to build your sociability muscle
              </span>
            </div>
          </div>
          <div>
            {/* <div><i class="fas fa-circle"></i></div> */}
          </div>
        </section> : null
      }
            
            

    </>
  );
};
