/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import f from 'firebase';

class CodeHelper extends Component {
  componentDidMount(){
    const { profile, code, reff } = this.props;
    const defaultProfile = profile.getDefaultProfile();
    if(defaultProfile !== null){
      if(defaultProfile.inviteCode === null){
        profile.data.profiles.default.inviteCode = code;
        // profile.getDefaultProfile().inviteCode = code;
        profile.save();
      }
    } 
    if(reff){
      const saveReference = f.functions().httpsCallable('saveReferral');
      saveReference({ ref: reff, uid: profile.uid });
    }
  }

  render(){
    return <></>;
  }
}
export default CodeHelper;
